.faq {

  &-list {

    &__val {
      text-align: right;
      font-weight: bold;
      font-size: 15px;
      padding: 3px 0;

      .faq-list__more {
        display: inline-block;
        margin-top: 30px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__more {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    &__action {
      margin-top: 13px;
      margin-bottom: 25px;
    }
  }

  &-box {

    &__action {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {
        margin-right: 11px;

        &:last-child {
          margin-right: 0;
        }
      }

      .social {
        margin-left: auto;

        li {
          margin-bottom: 0;
        }
      }
    }

    &__info {
      font-style: italic;
      font-size: 15px;
      margin-top: 19px;
      max-width: 290px;
      text-align: left;
      margin-left: auto;
    }

    &.active {
      .faq-box__btn-old,
      .faq-box__text-old {
        display: none;
      }
      .faq-box__text-alt {
        display: block;
      }
    }

    &__text {
      max-width: 520px;
      margin-left: auto;
      margin-right: auto;

      &-alt {
        display: none;
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &--alt {
      position: relative;

      &.opened {
        .title-box__content {
          max-height: 99999px;
        }
        .faq-box__trigger {
          display: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 20px;
        width: 100%;
        height: 114px;
        box-shadow: inset 0px -30px 25px rgba(255, 255, 255, 0.95);
      }

      .title-box__content {
        overflow: hidden;
        max-height: 114px;
      }

      .title-box__text {
        text-align: left;
        font-size: 17px;
        line-height: 22px;
      }
    }

    &__trigger {
      position: absolute;
      top: 100%;
      margin-top: 15px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }

    &--mod {
      padding-left: 74px;
      padding-right: 74px;

      @include media($lg) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .faq-box__action {
        @include media($sm) {
          flex-wrap: wrap;
          justify-content: flex-start;

          .btn {
            margin-bottom: 10px;
          }
        }
      }

      .social {
        @include media($sm) {
          flex: 0 0 100%;
          width: 100%;
        }
      }

      .title-box__text {
        max-width: 100%;
        text-align: left;
      }
    }
  }

  &-section {
    display: none;

    &.active {
      display: block;
      margin-bottom: 20px;
    }
  }

  &__btn {
    margin-left: 0;
    width: auto;
    padding: 0 30px;
  }
}