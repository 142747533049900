html {
  font-size: $fontSizeMain + px;
  line-height: $lineHeightMain;
}

body {
  font-family: $fontFamilyMain, Arial, sans-serif;
  font-weight: $fontWeightMain;
  background-color: $colorBgMain;
  color: $colorTextMain;
  min-width: 320px;

  &.menu-opened {
    @include media($lg) {
      overflow: hidden;
    }

    .header__nav {
      @include media($lg) {
        opacity: 1;
        visibility: visible;
      }

      &-content {
        @include media($lg) {
          transform: translateX(0);
        }
      }
    }
  }
}

textarea,
select,
input {
  &::placeholder {
    color: #BDBDBD;
  }
  -webkit-appearance: none;
}

.hidden {
  display: none;
}

p {
  margin-bottom: 10px;
}

button {
  font-family: inherit;
}