.testing-tabs {
  margin-bottom: 30px;

  ul {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #C2E9F5;

    @include media($sm) {
      display: block;
      text-align: center;
    }

    li {
      @include media($sm) {
        margin-bottom: 8px;
      }

      &:last-child {
        @include media($sm) {
          margin-bottom: 0;
        }
      }
    }

    a {
      font-weight: bold;
      font-size: em(18);
      color: #19124A;
      border-bottom: 2px solid transparent;


      &:hover,
      &.active {
        border-bottom-color: #CC1B28;
        color: #0A4798;
      }
    }
  }
}