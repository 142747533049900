.competition {

  &-tile {
    margin-bottom: 70px;

    &__content {
      max-width: 875px;
      margin-left: auto;
      margin-right: auto;
    }

    &__list {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @include media($sm) {
        display: block;
      }
    }

    &__item {
      position: relative;

      @include media($sm) {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-name {
        position: absolute;
        width: 100%;
        max-width: 155px;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 17px;
        text-align: center;
      }

      &-img {
        @include media($sm) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  &-gard {
    border: 5px solid #2ABFCB;
    border-top: none;
    padding: 56px 35px 35px;
    position: relative;
    margin-bottom: 70px;

    &__box {

      &-title {
        font-size: 31px;
        font-weight: bold;
        margin-bottom: 8px;
        line-height: 1;
        color: #2ABFCB;
        text-transform: uppercase;
        text-align: center;

        @include media($sm) {
          font-size: 24px;
        }
      }

      &-name {
        color: #4F4F4F;
        font-size: 21px;
        text-align: center;
        margin-bottom: 20px;
      }

      &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-right: -30px;

        li {
          width: 100%;
          max-width: 365px;
          margin-right: 30px;
          color: #4F4F4F;
          font-size: 24px;
          font-weight: bold;

          @include media($xl) {
            font-size: 20px;
          }

          &:last-child {
            max-width: 100%;
            text-align: center;

            @include media($md) {
              max-width: 365px;
              text-align: left;
            }
          }

          div {
            margin-bottom: 10px;
          }

          span {
            color: #2ABFCB;
          }
        }
      }
    }

    &__wrap {
      max-width: 920px;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      padding-top: 70px;
      margin-bottom: 70px;
    }

    &__title {
      position: absolute;
      font-size: 39px;
      font-weight: bold;
      text-transform: uppercase;
      color: #2ABFCB;
      left: 50%;
      top: 0;
      transform: translate(-50%,-50%);
      white-space: nowrap;
      padding: 0 20px;

      @include media($sm) {
        font-size: 24px;
      }

      @include media($xl) {
        white-space: pre-wrap;
        width: 100%;
        max-width: calc(100% - 20px);
        text-align: center;
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100vw;
        border-bottom: 5px solid #2ABFCB;
      }

      &:after {
        left: 100%;
      }

      &:before {
        right: 100%;
      }
    }

    &__content {
      color: #4F4F4F;
      text-align: center;
    }
  }

  &-cont {
    &.active {
      position: relative;

      & > * {
        filter: blur(2px);
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .competition-cont__form {
        display: block;
        filter: blur(0);
      }
    }

    &__form {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background: #D9F0F7;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      width: 100%;
      max-width: 622px;
      padding: 26px 40px 18px;
      z-index: 10;
      text-align: center;

      @include media($min) {
        padding-left: 15px;
        padding-right: 15px;
      }

      &-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 35px;
      }

      &-content {
        max-width: 314px;
        margin-left: auto;
        margin-right: auto;

        .btn {
          max-width: 178px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 10px;
          height: 40px;
        }

        .form__input {
          height: 45px;
        }

        .social {
          margin-bottom: 24px;

          ul {
            justify-content: center;
          }
        }
      }
    }
  }

  &-box {
    border: 2px solid #D9F0F7;
    padding: 30px;
    text-align: center;
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 30px;

    &__user {
      color: #CC1B28;
    }
  }

  &__form {

    &-action {
      justify-content: flex-start;
      flex-wrap: wrap;

      & + .form__help {
        margin-top: 0;
      }

      .btn {
        margin-right: 15px;
        width: auto;
        padding: 0 30px;
        display: inline-flex;
        margin-bottom: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-banner {
    height: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    margin-bottom: 50px;
    overflow: hidden;

    &__tile {
      position: relative;
      height: 100%;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        top: 14px;
        left: 14px;
        right: 14px;
        bottom: 14px;
        border: 7px solid #FFD428;

        @include media($sm) {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-width: 3px;
        }
      }

      &-logo {
        position: absolute;
        right: -14px;
        top: 57px;
        z-index: 1;

        @include media($sm) {
          right: 50%;
          transform: translateX(50%);
          top: 30px;
        }

        @include media($min) {
          img {
            display: block;
            max-width: 260px;
          }
        }
      }

      &-cont {
        height: 100%;
        background: #fff;
        max-width: 343px;
        padding: 52px 38px 52px 55px;
        text-align: right;

        @include media($sm) {
          max-width: 100%;
          text-align: center;
          padding: 150px 10px 30px;
        }
      }

      &-title {
        font-size: 70px;
        color: #000;
        letter-spacing: -0.07em;
        text-transform: uppercase;
        line-height: 1.1;
        margin-bottom: 20px;
        letter-spacing: -0.07em;
        display: flex;
        justify-content: flex-end;

        @include media($sm) {
          justify-content: center;
          font-size: 55px;
        }
      }

      &-name {
        color: #000;
        font-size: 45px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        line-height: 0.9;

        @include media($sm) {
          font-size: 40px;
        }

        sup {
          font-size: 96px;
        }

        span {
          color: #76BA1E;
          font-size: 110px;
          position: relative;
          right: -4px;
        }
      }
    }

    &__gard {
      background: #FFFFFF;
      border-radius: 8px;
      padding: 40px 30px;
      margin-top: 53px;
      margin-left: 64px;
      width: 100%;
      max-width: 314px;
      position: relative;
      z-index: 1;

      @include media($min) {
        margin-left: 10px;
        margin-right: 10px;
        max-width: calc(100% - 20px);
      }

      img {
        max-width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
      }

      &-title {
        margin-bottom: 20px;
        font-size: 21px;
        color: #4F4F4F;
        text-align: center;
      }

      &-name {
        color: #2ABFCB;
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 30px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    &__logo {
      position: absolute;
      top: 28px;
      right: 21px;

      @include media($md) {
        position: static;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        padding-top: 30px;
      }

      @include media($xl) {
        position: relative;
        top: 0;
        right: 0;
        z-index: 2;
      }
    }

    &__box {
      max-width: 490px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 75px;

      @include media($md) {
        padding-top: 0;
      }

      @include media($xl) {
        position: relative;
        z-index: 2;
      }
    }

    &__text {
      font-weight: bold;
      font-size: 27px;
      line-height: 45px;
      color: #000;
      text-align: center;
      margin-bottom: 55px;

      @include media($md) {
        font-size: 24px;
        margin-bottom: 40px;
      }

      @include media($xl) {
        line-height: 1.3;
      }

      @include media($min) {
        font-size: 20px;
      }
    }
  }

  &-action {
    display: flex;
    justify-content: center;

    &--alt {
      margin-bottom: 60px;
    }

    .btn {
      height: 40px;
      width: auto;
      padding: 0 30px;
      margin-right: 30px;
      min-width: 139px;

      @include media($xl) {
        margin-right: 15px;
        padding: 0 20px;
        min-width: 120px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-title {
    font-size: 24px;
    line-height: 31px;
    color: #333;
    margin-bottom: 30px;
    text-transform: uppercase;
    text-align: center;

    &--gard {
      font-weight: bold;
      color: #2ABFCB;
    }

    &--tile {
      text-transform: none;
      font-weight: bold;
      font-size: 23px;
      color: #19124A;
    }
  }

  &-text {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
    text-align: center;
    max-width: 615px;

    &__info {
      color: #19124A;
      font-size: 15px;
      font-style: italic;
      text-align: right;
      margin-right: -25px;
      margin-top: 20px;

      @include media($md) {
        margin-right: 0;
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &--lime {
      max-width: 868px;
      margin-top: 50px;
      background: #F0FAFD;
      padding: 30px 90px;

      @include media($md) {
        padding: 20px;
      }

      .competition-title {
        color: #19124A;
        font-size: 23px;
        font-weight: bold;
        margin-bottom: 20px;
        text-transform: none;
      }

      .competition-text__content {
        letter-spacing: 0.02em;
        font-size: 18px;
        color: #19124A;
        text-align: left;
      }
    }
  }

  &-social {
    margin-top: 30px;

    &--original {
      margin-bottom: 100px;

      @include media($md) {
        margin-bottom: 70px;
      }
    }

    &__title {
      margin-bottom: 17px;
      text-align: center;
    }

    &--green {
      .icon {
        background: #54B331;
      }
    }

    &--orange {
      .icon {
        background: #F15922;
      }
    }
  }

  &-date {
    margin-bottom: 70px;

    &--green {
      .competition-date__item-top {
        background: #54B331;
      }
    }

    &--gard {
      .competition-date__item-top {
        background: #2ABFCB;
        border-radius: 13px;
      }
      .competition-title {
        color: #2ABFCB;
        font-weight: bold;
      }
    }

    &__list {
      display: flex;
      @include row(-30px);

      @include media($lg) {
        margin: 0 -10px;
      }
    }

    &__item {
      @include col(1,3);
      padding: 0 30px;

      @include media($lg) {
        padding: 0 10px;
      }

      @include media($xl) {
        @include col(1,1);
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-top {
        height: 50px;
        margin-bottom: 25px;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        @include media($xl) {
          margin-bottom: 10px;
        }
      }

      &-text {
        font-size: 18px;
        line-height: 21px;
        color: #000;
        text-align: center;
      }
    }
  }

  &-info {
    margin-bottom: 144px;

    @include media($md) {
      margin-bottom: 100px;
    }

    @include media($sm) {
      margin-bottom: 50px;
    }

    &--green {
      .competition-info__box {
        background: #54B331;
      }
    }

    &__box {
      display: flex;
      font-size: 24px;
      line-height: 31px;
      color: #fff;

      @include media($sm) {
        display: block;
      }
    }

    &__img {
      width: 400px;
      min-width: 400px;

      @include media($md) {
        width: 250px;
        min-width: 250px;
      }

      @include media($sm) {
        width: 100%;
        max-width: 100%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__text {
      padding: 38px 100px;
      align-self: center;

      @include media($lg) {
        padding: 20px 50px;
      }

      @include media($md) {
        font-size: 18px;
      }

      @include media($sm) {
        padding: 15px;
      }
    }
  }

  &-place {
    display: flex;
    align-items: flex-end;
    padding: 0 65px;
    margin-bottom: 55px;

    @include media($md) {
      flex-wrap: wrap;
      padding: 0;
    }

    &-tile {
      margin-bottom: 70px;

      &__list {
        display: flex;
        align-items: flex-end;
        max-width: 877px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;

        @include media($sm) {
          flex-wrap: wrap;
        }
      }

      &__item {
        flex: 1;
        margin: 0 8px;

        @include media($sm) {
          width: 100%;
          flex: 0 0 100%;
          margin: 0 0 25px 0;
        }

        &:nth-child(2) {

          @include media($sm) {
            order: -4;
          }

          .competition-place-tile__item-area {
            height: 134px;
          }
        }

        &:nth-child(3) {

          @include media($sm) {
            order: -5;
          }

          .competition-place-tile__item-area {
            height: 164px;

            & > span {
              font-size: 96px;

              span {
                margin-top: 15px;
              }
            }
          }
        }

        &:nth-child(4) {

          @include media($sm) {
            order: -3;
          }

          .competition-place-tile__item-area {
            height: 111px;
          }
        }

        &-img {
          display: flex;
          justify-content: center;
          margin-bottom: 10px;

          img {
            display: block;
            max-width: 100%;
          }
        }

        &-area {
          background: #BDBDBD;
          position: relative;
          height: 12px;
          margin-top: 30px;

          & > span {
            position: absolute;
            bottom: 100%;
            left: 0;
            text-align: center;
            color: #BDBDBD;
            letter-spacing: -0.03em;
            font-size: 24px;
            width: 100%;

            span {
              margin-left: 3px;
            }
          }
        }

        &--alt {
          .competition-place-tile__item-area {
            margin-top: 0;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            & > span {
              position: static;
              display: block;
              color: #FFFFFF;
              font-size: 72px;
              line-height: 1;
              padding-bottom: 14px;
              font-weight: bold;

              span {
                display: block;
                font-size: 24px;
                letter-spacing: -0.03em;
                line-height: 1;
                margin-left: 0;
              }
            }
          }
        }
      }

      &__text {
        text-align: center;
        font-size: 18px;
        letter-spacing: 0.02em;
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 35px;
      }

      &__action {
        display: flex;
        justify-content: center;

        .btn {
          max-width: 140px;
        }
      }
    }

    &-gard {

      &__line {
        margin-bottom: 70px;

        &-title {
          text-transform: uppercase;
          font-size: 31px;
          margin-bottom: 53px;
          font-weight: bold;
          color: #2ABFCB;
          text-align: center;
        }

        &-content {
          padding: 0 100px;
          display: flex;
          justify-content: center;
          margin: 0 45px;

          @include media($lg) {
            padding: 0;
          }

          @include media($md) {
            flex-wrap: wrap;
          }
        }

        &-card {
          @include col(1,4);
          padding: 0 8px;
          margin-bottom: 16px;

          @include media($md) {
            @include col(1,2);
          }

          @include media($xl) {
            @include col(1,1);
          }

          &s {
            @include row(-8px);
          }

          &-content {
            background: #C4C4C4;
            border-radius: 13px;
            padding: 55px 37px 24px;
            position: relative;
            height: 100%;

            &:hover {
              box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
            }

            @supports (flex-direction: column) {
              display: flex;
              flex-direction: column;
            }
          }

          &-num {
            position: absolute;
            letter-spacing: -0.03em;
            color: #fff;
            font-size: 100px;
            font-weight: bold;
            line-height: 1;
            top: 0;
            left: 5px;
          }

          &-img {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 14px;
            height: 100px;

            img {
              display: block;
              max-width: 100%;
              max-height: 100%;
            }
          }

          &-name {
            color: #fff;
            font-size: 15px;
            margin-bottom: 20px;
            text-align: center;
            font-weight: bold;
            max-width: 140px;
            margin-left: auto;
            margin-right: auto;

            @supports (flex-direction: column) {
              margin-top: auto;
            }
          }
        }
      }

      &__item {
        position: relative;
        padding: 0 45px;
        width: 100%;
        max-width: 50%;
        flex: 0 0 50%;
        min-height: 160px;
        padding-left: 225px;
        display: flex;
        align-items: flex-end;

        @include media($md) {
          max-width: 100%;
          flex: 0 0 100%;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        @include media($sm) {
          padding-left: 0;
          padding-right: 0;
          min-height: 0;
          display: block;
          text-align: center;
        }

        &-name {
          font-size: 15px;
          line-height: 1.1;
          font-weight: bold;
          margin-bottom: 20px;
          color: #4F4F4F;
        }

        &-btn {
          max-width: 131px;
          margin-left: auto;
          margin-right: auto;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }

        &-img {
          position: absolute;
          width: 205px;
          height: 160px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          justify-content: center;

          @include media($sm) {
            position: static;
            transform: translateY(0);
            margin-bottom: 20px;
            margin-left: auto;
            margin-right: auto;
          }

          img {
            display: block;
            max-width: 100%;
            max-height: 100%;
          }
        }

        &--big {
          max-width: 510px;
          flex: 0 0 510px;
          padding-left: 0;
          height: 240px;
          margin-top: -50px;

          @include media($sm) {
            height: auto;
          }

          .competition-place-gard__item-img {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateY(0);

            @include media($sm) {
              position: static;
              height: auto;
            }
          }

          .competition-place-gard__item-name {
            position: absolute;
            max-width: 234px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            @include media($sm) {
              position: static;
              transform: translateY(0);
              max-width: 100%;
            }
          }

          .competition-place-gard__item-btn {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;

            @include media($sm) {
              position: static;
            }
          }
        }
      }
    }

    &--green {}

    &__box {
      flex: 1;

      @include media($md) {
        width: 100%;
        flex: 0 0 100%;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-second {
        .competition-place__box-title {
          font-size: 30px;

          @include media($lg) {
            font-size: 26px;
          }
        }
        .competition-place__box-position {
          font-size: 39px;
          height: 59px;
        }
      }

      &-first {

        @include media($md) {
          order: -2;
        }

        .competition-place__box-title {
          font-size: 40px;

          @include media($lg) {
            font-size: 30px;
          }
        }
        .competition-place__box-position {
          font-size: 56px;
          height: 87px;
        }
      }

      &-cont {
        margin-left: auto;
        margin-right: auto;
        max-width: 290px;

        @include media($lg) {
          max-width: 230px;
        }

        @include media($md) {
          max-width: 100%;
        }
      }

      &-title {
        font-size: 27px;
        line-height: 32px;
        letter-spacing: -0.03em;
        color: #54B331;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 16px;

        @include media($lg) {
          font-size: 24px;
        }

        @include media($sm) {
          text-align: center;
        }
      }

      &-text {
        color: #000;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 41px;

        @include media($sm) {
          margin-bottom: 20px;
          text-align: center;
        }
      }

      &-card {
        border: 3px solid #54B331;
        border-radius: 13px;
        padding: 17px 10px;
        position: relative;
        height: 126px;
        width: 231px;
        margin-bottom: 13px;

        @include media($sm) {
          margin-left: auto;
          margin-right: auto;
        }

        &-content {
          display: inline-block;
        }

        &-name {
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.03em;

          span {
            display: inline-block;
            padding-bottom: 2px;
            border-bottom: 1px solid #54B331;
          }
        }

        &-price {
          letter-spacing: -0.03em;
          font-size: 27px;
          color: #54B331;
          font-weight: bold;
          text-align: center;
        }

        &-logo {
          position: absolute;
          bottom: 16px;
          right: 12px;
          width: 77px;
          height: 46px;

          img {
            display: block;
            width: 100%;
          }
        }
      }

      &-position {
        height: 38px;
        text-align: center;
        padding-top: 2px;
        font-weight: bold;
        font-size: 28px;
        line-height: 1;
        color: #fff;
        background: #54B331;
        display: flex;
        align-items: center;
        align-items: center;
        justify-content: center;

        @include media($md) {
          display: none;
        }
      }
    }
  }

  &-steps {
    margin-bottom: 50px;
    position: relative;

    &--tile {
      .competition-steps__item {
        &:before {
          background: #FFD428;
        }
      }
      .competition-steps__content {
        padding-top: 160px;
      }

      .competition-title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 50px;
      }
    }

    &--green {
      .competition-steps__item {
        &:before {
          background: #54B331;
        }
      }
    }

    &--gard {
      .competition-title {
        font-weight: bold;
        color: #2ABFCB;
        font-size: 31px;

        @include media($xl) {
          font-size: 24px;
        }
      }

      .competition-steps__item {
        &:before {
          background: #2ABFCB;
        }
      }
    }

    &__content {
      padding: 130px 55px 50px;
      display: flex;
      flex-wrap: wrap;
      background: #E6E6E6;
      justify-content: space-between;
      position: relative;

      @include media($md) {
        padding-top: 70px;
      }

      @include media($min) {
        padding: 30px;
      }
    }

    &__item {
      position: relative;
      width: calc(50% - 110px);
      background: #FFFFFF;
      border-radius: 7px;
      margin-bottom: 80px;
      padding: 40px 40px 35px 55px;
      letter-spacing: -0.03em;
      margin-left: 50px;
      color: #000;

      @include media($lg) {
        margin-left: 0;
        width: calc(50% - 30px);
      }

      @include media($sm) {
        width: 100%;
      }

      @include media($min) {
        margin-bottom: 30px;
      }

      a {
        color: #0A4798;

        &:hover {
          text-decoration: underline;
        }
      }

      &:before {
        content: attr(data-num);
        position: absolute;
        top: -50px;
        left: -50px;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        border: 10px solid #E6E6E6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 60px;
        font-weight: 600;
        line-height: 1;

        @include media($min) {
          font-size: 40px;
          width: 70px;
          height: 70px;
          top: -20px;
          left: -20px;
        }
      }
    }

    &__action {
      width: 100%;
      flex: 0 0 100%;
      display: flex;
      justify-content: center;

      .btn {
        width: auto;
        padding: 0 30px;
        display: inline-flex;
      }
    }
  }

  &-works {

    &__info {
      padding-left: 40px;
      font-size: 14px;
      line-height: 1;
      color: #000;
    }
  }
}