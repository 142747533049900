.board {

  &-title {
    p {
      font-size: 17px;
    }
  }

  &-block {
    display: none;

    &.active {
      display: block;
    }
  }

  &-form {
    &__action {
      justify-content: flex-start;
      flex-wrap: wrap;

      .btn {
        width: auto;
        padding: 0 30px;
        margin-right: 30px;
        height: 40px;
        margin-bottom: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}