.footer {
  background: #19124A;
  color: #fff;
  font-size: 15px;

  &__main {
    padding: 50px 44px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include media($md) {
      display: block;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__info {
    font-size: 15px;
    margin-right: 77px;
    width: 300px;

    @include media($md) {
      width: 100%;
      margin-bottom: 30px;
      margin-right: 0;
    }
  }

  a {
    color: inherit;
  }

  &__social {
    max-width: 220px;

    @include media($md) {
      max-width: 100%;
      margin-bottom: 30px;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    width: 420px;

    @include media($md) {
      width: 100%;
      margin-bottom: 30px;
      justify-content: flex-start;
    }

    @include media($min) {
      display: block;
    }

    &-list {
      margin-right: 20px;

      @include media($md) {
        margin-right: 0;
        width: 100%;
        max-width: 300px;
      }

      @include media($min) {
        max-width: 100%;
        margin-bottom: 10px;
      }

      &:last-child {
        margin-right: 0;
      }

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        font-weight: bold;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    padding: 13px 44px 20px;

    @include media($md) {
      display: block;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__copy {
    @include media($md) {
      text-align: center;
    }
  }

  &__logo {
    position: relative;
    top: -15px;
    display: block;

    @include media($md) {
      display: none;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }
}