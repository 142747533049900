.pagination {

  &__prev,
  &__next {
    @include media($min) {
      display: none;
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li {
    border: 1px solid #C2E9F5;
    margin-right: 3px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      a {
        background: #C2E9F5;
        color: #0A4798;
        text-decoration: underline;
        font-weight: bold;
      }
    }

    &.disabled {
      a {
        color: #BDBDBD;
        pointer-events: none;
      }
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-last-child(2),
    &:nth-last-child(3) {
      margin-right: 10px;
    }
  }

  a {
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 8px;
    font-size: 15px;

    &:hover {
      background: #C2E9F5;
    }
  }
}