.board-filter {
  display: flex;

  &__select {
    position: relative;
    width: 100%;
    max-width: 160px;

    &.opened {
      .board-filter__select-text {
        border-bottom-color: #E0E0E0;
      }
      .board-filter__select-drop {
        display: block;
      }
    }

    &-trigger {
      width: 100%;
      background: #D9F0F7;
      border-radius: 5px;
      cursor: pointer;
      position: relative;
      padding-right: 32px;

      &:after {
        content: '';
        border-bottom: 3px solid #19124A;
        border-right: 3px solid #19124A;
        transform: rotate(45deg);
        width: 10px;
        height: 10px;
        margin-left: auto;
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }

    &-text {
      height: 30px;
      display: flex;
      align-items: center;
      margin: 0 10px;
      white-space: nowrap;
      font-size: 14px;
      height: 35px;
      position: relative;
      z-index: 1;
      border-bottom: 1px solid transparent;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        text-decoration: none;
      }
    }

    &-drop {
      position: absolute;
      display: none;
      top: 100%;
      margin-top: -3px;
      padding-top: 3px;
      left: 0;
      background: #D9F0F7;
      border-radius: 0 0 5px 5px;
      padding: 7px 15px;
      width: 100%;
      cursor: initial;

      ul {
        padding-top: 8px;
      }

      li {
        margin-bottom: 9px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      label {
        display: flex;

        input {
          display: none;

          & + span {
            border: 0.5px solid #19124A;
            border-radius: 2px;
            display: block;
            width: 14px;
            min-width: 14px;
            height: 14px;
            margin-right: 10px;
            position: relative;

            &:after {
              content: '';
              display: block;
              width: 5px;
              height: 8px;
              border-bottom: 2px solid #19124A;
              border-right: 2px solid #19124A;
              transform: rotate(45deg);
              position: absolute;
              top: 1px;
              left: 5px;
              opacity: 0;
            }
          }

          &:checked {
            & + span {
              &:after {
                opacity: 1;
              }
            }
          }
        }

        span {
          line-height: 1;
          font-size: 14px;
        }
      }
    }

    &-action {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
    }

    &-btn {
      font-size: 13px;
      font-weight: bold;
      color: #0A4798;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}