.testing-slider {
  padding: 0 40px;
  margin-top: 50px;

  @include media($sm) {
    padding: 0 20px;
  }

  .slick-prev {
    @include media($sm) {
      margin-left: -20px;
    }
  }

  .slick-next {
    @include media($sm) {
      margin-right: -20px;
    }
  }

  .slick-arrow {
    top: 90px;
    background-color: transparent;

    @include media($big) {
      top: 70px;
    }

    @include media($lg) {
      top: 55px;
    }

    @include media($md) {
      top: 60px;
    }

    @include media($sm) {
      top: 100px;
    }

    &.slick-disabled {
      opacity: .6;
    }

    &:after {
      border-color: #19124A;
    }
  }

  &__item {
    .card {
      max-width: 100%;
    }

    @include media($sm) {
      .card-alt .card__img:before{
        padding-top: 200px;
      }
    }
  }
}