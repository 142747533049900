.price-list {

  &__title {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }

  &__top {
    text-align: center;
    position: relative;
    margin-top: 58px;
    padding-bottom: 50px;
    border-bottom: 1px solid #19124A;
    margin-bottom: 30px;

    &-title {
      font-size: 24px;
      margin-bottom: 7px;
    }

    &-line {
      margin-bottom: 9px;
      font-size: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        color: #CC1B28;
      }
    }

    &-logo {
      position: absolute;
      top: 0;
      right: 0;
      width: 126px;

      @include media($sm) {
        display: none;
      }

      img {
        width: 100%;
        display: block;
        margin-bottom: 8px;
      }

      span {
        font-style: italic;
        font-size: 11px;
        line-height: 1;
      }
    }
  }
}