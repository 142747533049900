.stars-view {
  display: flex;
  align-items: center;

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    margin-right: 4px;
    font-size: 12px;
    color: #E0E0E0;

    &.active {
      color: #FFD428;
    }

    &:last-child {
      margin-right: 0;
    }

    .icon {
      position: relative;
      bottom: -1px;
    }
  }

  &__points {
    margin-left: 11px;
    font-size: 11px;
  }
}