.sort {

  &__list {
    display: flex;
    justify-content: flex-end;

    @include media($md) {
      flex-wrap: wrap;
    }

    li {
      margin-right: 15px;

      @include media($md) {
        margin-bottom: 10px;
        margin-right: 0;
        margin-left: 15px;

        &:first-child {
          margin-left: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        a {
          border-bottom-color: #CC1B28;
        }
      }
    }

    a {
      font-size: 15px;
      font-weight: bold;
      border-bottom: 2px solid transparent;
      white-space: nowrap;

      &:hover {
        border-bottom-color: #CC1B28;
      }
    }
  }
}