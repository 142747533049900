.section-slider {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
  margin-top: 40px;
  padding: 30px 18px;

  &__item {
    padding: 5px 0;

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 10px;

      &:hover {
        box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
      }

      img {
        display: block;
        max-width: 100%;
      }
    }
  }

  .slick-arrow {
    background-color: transparent;
  }
}