.onTop {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 20;
  background: #C2E9F5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: .3s;

   @include media($big) {
      right: 30px;
      bottom: 30px;
   }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    border-bottom: 7px solid #fff;
    border-right: 7px solid #fff;
    transform: rotate(-135deg);
    border-radius: 3px;
    margin-top: 5px;
  }
}