.user-card {
  @include col(1,5);
  padding: 0 10px;
  margin-bottom: 30px;

  @include media($sm) {
    @include col(1,4);
  }

  @include media($xl) {
    @include col(1,3);
  }

  @include media($min) {
    @include col(1,2);
  }

  &__content {
    height: 100%;

    @supports (flex-direction: column) {
      display: flex;
      flex-direction: column;

      .user-card__name {
        margin-bottom: auto;
      }
    }
  }

  &__avatar {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
    position: relative;

    a {
      border-radius: 50%;
      overflow: hidden;
      width: 80px;
      height: 80px;
      display: block;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__status {
    background: #FFD428;
    border-radius: 3px;
    text-align: center;
    font-size: em(10);
    padding: 0 10px;
    line-height: 1;
    padding-bottom: 1px;
    position: absolute;
    top: 100%;
    left: 50%;
    height: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%,-50%);
    white-space: nowrap;
  }

  &__name {
    text-align: center;
    font-size: em(15);

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__city {
    margin-top: 6px;
    color: #0A4798;
    font-size: em(13);
    text-align: center;
  }
}