.form {

  &__tooltip {
    position: relative;

    &-btn {
      display: flex;
      align-items: center;
      height: 36px;
      font-size: em(14);
      box-shadow: none;
      border: none;
      background-color: transparent;

      &:hover {
        i {
          text-decoration: none;
        }

        & + .form__tooltip-drop {
          display: block;
        }
      }

      span {
        color: #CC1B28;
        font-size: em(20);
        font-weight: bold;
        margin-right: 10px;
      }

      i {
        text-decoration: underline;
      }
    }

    &-drop {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 100;
      background: #F0FAFD;
      border-radius: 3px;
      width: 100vw;
      max-width: 297px;
      padding: 15px 20px;

      &-item {
        font-size: em(12);
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          font-weight: bold;
          color: #0A4798;
        }

        i {
          display: block;
          color: #0A4798;
        }
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-right: -30px;

    .form__label--half {
      width: 100%;
      max-width: calc(50% - 30px);
      flex: 0 0 calc(50% - 30px);
      margin-right: 30px;

      @include media($sm) {
        max-width: calc(100% - 30px);
        flex: 0 0 calc(100% - 30px);
      }
    }
  }

  &__avatar {
    display: flex;
    align-items: center;

    &-img {
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 25px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-btn {
      text-decoration: underline;
      font-size: 15px;
      color: #19124A;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        text-decoration: none;
      }

      label,
      &-del {
        border: none;
        text-decoration: inherit;
        background-color: transparent;
        font-size: inherit;
        color: inherit;
        padding: 0;
        cursor: pointer;
      }
    }
  }

  &__add {
    &-rub {
      border: none;
      background-color: transparent;
      padding-left: 25px;
      padding-right: 0;
      font-size: 17px;
      color: #19124A;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      margin-top: 10px;

      &:hover {
        color: #0A4798;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0;
      }
      &:nth-child(3) {
        @include media($sm) {
          margin-top: 10px;
        }
      }
      &:nth-child(2) {
        @include media($sm) {
          margin-top: 10px;
        }
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        background: #CC1B28;
        transform: translateY(-50%);
      }

      &:after {
        left: 0;
        width: 13px;
        height: 3px;
      }

      &:before {
        left: 5px;
        height: 13px;
        width: 3px;
      }
    }
  }

  &__act {
    margin-bottom: 10px;
    font-size: 19px;

    &:last-child {
      margin-bottom: 0;
    }

    &--right {
      text-align: right;
    }

    &--small {
      font-size: 15px;
    }

    a {
      color: #0A4798;

      &:hover {
        text-decoration: underline;
      }
    }

    &--new {
      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__checkbox {
    display: none;

    &:checked {
      & ~ .form__label-check-box {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  & > .form__help {
    margin-left: 0;
    margin-top: 20px;
  }

  &__help {
    display: inline-block;
    margin-left: 40px;
    font-size: 15px;
    font-style: italic;
  }

  &__label {
    display: block;
    margin-bottom: 20px;

    &--datepicker {
      position: relative;

      input {
        cursor: pointer;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 36px;
        height: 36px;
        background: #D9F0F7;
        border-radius: 0 3px 3px 0;
        cursor: pointer;
      }

      &:before {
        content: '';
        height: 8px;
        width: 8px;
        border-bottom: 2px solid #19124A;
        border-right: 2px solid #19124A;
        transform: rotate(45deg);
        position: absolute;
        bottom: 15px;
        right: 13px;
        z-index: 1;
        cursor: pointer;
      }
    }

    &-withcheck {
      display: flex;

      @include media($xl) {
        display: block;
      }

      &-box {
        width: 100%;
        max-width: 345px;
        margin-right: 30px;
        display: flex;
        align-items: center;

        @include media($xl) {
          margin-bottom: 15px;
        }

        .form__select {
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .form__label-check {
        margin-bottom: 0;
        display: inline-flex;
      }

      .form__label-check-box {
        margin-right: 15px;
      }

      .form__label-name {
        font-style: italic;
        font-size: 15px;
        color: #828282;
        margin-bottom: 2px;
      }
    }

    &-line {
      display: flex;
      flex-wrap: wrap;
      margin-right: -20px;

      .form__select,
      .form__input {
        margin-right: 20px;
        margin-top: 10px;
        width: 100%;
        max-width: calc(33.333% - 20px);
        flex: 0 0 calc(33.333% - 20px);

        @include media($sm) {
          max-width: calc(50% - 20px);
          flex: 0 0 calc(50% - 20px);
        }

        @include media($xl) {
          max-width: calc(100% - 20px);
          flex: 0 0 calc(100% - 20px);
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0;
        }

        &:nth-child(3) {
          @include media($sm) {
            margin-top: 10px;
          }
        }

        &:nth-child(2) {
          @include media($xl) {
            margin-top: 10px;
          }
        }
      }
    }

    &--sm {
      max-width: 327px;
    }

    &--min {
      max-width: 260px;
    }

    &-name {
      display: block;
      margin-bottom: 8px;

      &--line {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media($xl) {
          display: block;
        }

        &-alt {
          display: flex;
          align-items: center;

          span {
            font-size: 15px;
            color: #828282;
            margin-left: 20px;
          }
        }
      }

      small {
        font-size: 15px;
        white-space: nowrap;

        @include media($xl) {
          display: block;
          white-space: normal;
          margin-top: 5px;
        }
      }
    }

    &-comb {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;

      .form__input {
        margin-right: 15px;
        width: auto;
      }
    }

    &-check {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 10px;

      &-box {
        display: block;
        min-width: 14px;
        width: 14px;
        height: 14px;
        border-radius: 3px;
        border: 1px solid $colorTextMain;
        margin-right: 1px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 4px;
          width: 5px;
          height: 10px;
          border-bottom: 2px solid;
          border-right: 2px solid;
          transform: rotate(45deg);
          opacity: 0;
        }
      }

      &-circle {
        min-width: 16px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #19124A;
        position: absolute;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          border-radius: 50%;
          width: 6px;
          height: 6px;
          background: #19124A;
          opacity: 0;
        }
      }

      &-name {
        margin-left: 6px;
        font-size: 16px;
        white-space: nowrap;
      }

      &-color {
        width: 9px;
        height: 14px;

        &--mix {
          position: relative;
          width: 12px;

          &:after,
          &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 50%;
          }

          &:after {
            bottom: 0;
            background-image: linear-gradient(90deg, #219653 50%,#CC1B28 50%);
          }

          &:before {
            top: 0;
            background-image: linear-gradient(90deg, #0A4798 50%,#EB8E02 50%);
          }
        }
      }
    }

    &-file {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        .icon {
          box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
        }
      }

      &-name {
        font-size: 15px;
      }
    }

    &--line {
      display: flex;
      align-items: center;

      .form__label-name {
        margin-right: 8px;
        margin-bottom: 0;
      }
    }
  }

  &__input {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #C2E9F5;
    border-radius: 5px;
    background: #fff;
    height: 36px;
    padding: 0 10px;
    font-size: 15px;

    & + .form__input {
      margin-top: 10px;
    }

    &--cl {
      color: #CC1B28;
      font-size: 17px !important;
    }

    &-checkbox {
      display: none;

      &:checked ~ .form__label-check-box {
        &:after {
          opacity: 1;
        }
      }
    }

    &-radio {
      display: none;

      &:checked ~ .form__label-check-circle {
        &:after {
          opacity: 1;
        }
      }
    }

    &-file {
      display: none;
    }
  }

  &__textarea {
    border: 1px solid #D9F0F7;
    border-radius: 3px;
    display: block;
    width: 100%;
    min-height: 60px;
    padding: 10px;
    font-size: 14px;
    resize: vertical;

    &--big {
      height: 270px;
    }
  }

  &__action {
    display: flex;
    justify-content: center;

    &-alt {
      display: flex;
      align-items: flex-end;

      @include media($sm) {
        flex-wrap: wrap;

        .form__help {
          flex: 0 0 100%;
          width: 100%;
          margin-top: 15px;
          margin-left: 0;
        }
      }
    }
  }

  &__btn {
    width: auto;
    padding: 0 30px;
  }



  &__select {

    &--data {
      .jq-selectbox__trigger {
        background-color: transparent;
      }
      .jq-selectbox__trigger-arrow {
        border: none;
        transform: rotate(0);
        left: 20px;
        width: 13px;

        &:after,
        &:before {
          content: '';
          position: absolute;
          left: 0;
          border: none;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
        }

        &:after {
          border-bottom: 7px solid #19124A;
          bottom: 6px;
        }

        &:before {
          top: 6px;
          border-top: 8px solid #19124A;
        }
      }
    }

    &-day {
      max-width: 96px;
    }
    &-mon {
      max-width: 120px;
    }
    &-year {
      max-width: 96px;
    }

    &--alt {
      .jq-selectbox__select {
        border-radius: 10px;
      }
      .jq-selectbox__trigger {
        background-color: transparent;
      }
      .jq-selectbox__select-text,
      .jq-selectbox__select-text.placeholder {
        color: #19124A;
      }
    }

    &--cl {
      max-width: 215px;

      .jq-selectbox__select-text,
      .jq-selectbox__select-text.placeholder {
        color: #CC1B28;
      }
    }

    &--min{
      max-width: 190px;
    }

    &--other {
      min-width: 170px;

      .jq-selectbox__select {
        background: #D9F0F7 !important;
        border-radius: 10px;
        padding-right: 30px;
      }
      .jq-selectbox__trigger {
        background-color: transparent;
        width: 22px;
      }
      .jq-selectbox__trigger-arrow {
        right: 12px;
      }
      .jq-selectbox__select-text,
      .jq-selectbox__select-text.placeholder {
        font-size: 14px;
      }
    }
  }
}

.files-rezult {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    &:hover {
      button {
        opacity: 1;
      }
    }

    .delete-link {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      right: 0;
      cursor: pointer;

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 2px;
        background: #CC1B28;
      }

      &:after {
        transform: translate(-50%,-50%) rotate(45deg);
      }

      &:before {
        transform: translate(-50%,-50%) rotate(-45deg);
      }
    }
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: 0;

    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 2px;
      background: $colorTextMain;
    }

    &:after {
      transform: translate(-50%,-50%) rotate(45deg);
    }

    &:before {
      transform: translate(-50%,-50%) rotate(-45deg);
    }
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

.files {
  &-area {
    border: 1px solid #D9F0F7;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    padding: 2px 8px;
    align-items: center;

    .form__label-file {
      &:hover {
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);

        .icon {
          box-shadow: none;
        }
      }
    }
  }

  .files-rezult {
    display: flex;
    flex-wrap: wrap;

    &__item {
      display: none;

      &-input {
        display: none;
      }

      &.active {
        display: block;
      }
    }
  }

  &-add {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__input {
      display: none;
    }

    label {
      width: 50px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #D9F0F7;
      border-radius: 5px;
      cursor: pointer;
    }

    .icon {
      font-size: 25px;
    }
  }
}

.editor__help {
  margin-left: auto;

  a {
    text-decoration: underline;
    color: #CC1B28;

    &:hover {
      text-decoration: none;
    }
  }
}