@import 'utils/vars';
@import 'utils/reset';
@import 'utils/fonts';
@import 'utils/_icons';
@import 'utils/mixins';
@import 'utils/default';
@import 'utils/grid';

/* Plugins*/
@import 'utils/plugins';
/* Plugins rewrite*/
@import 'utils/plugins-rewrite';

/* Blocks */
@import '../blocks/icon/icon';
@import '../blocks/logo/logo';
@import '../blocks/btn/btn';
@import '../blocks/burger/burger';
@import '../blocks/header/header';
@import '../blocks/nav/nav';
@import '../blocks/title/title';
@import '../blocks/footer/footer';
@import '../blocks/social/social';
@import '../blocks/search-box/search-box';
@import '../blocks/section/section';
@import '../blocks/favorite/favorite';
@import '../blocks/card/card';
@import '../blocks/slider/slider';
@import '../blocks/card-alt/card-alt';
@import '../blocks/services/services';
@import '../blocks/banner/banner';
@import '../blocks/advertisement/advertisement';
@import '../blocks/calendar-box/calendar-box';
@import '../blocks/page/page';
@import '../blocks/form/form';
@import '../blocks/breadcrumbs/breadcrumbs';
@import '../blocks/inner/inner';
@import '../blocks/aside/aside';
@import '../blocks/sort/sort';
@import '../blocks/search-page/search-page';
@import '../blocks/filter/filter';
@import '../blocks/card-sim/card-sim';
@import '../blocks/stars-view/stars-view';
@import '../blocks/text/text';
@import '../blocks/alert/alert';
@import '../blocks/review/review';
@import '../blocks/like/like';
@import '../blocks/slider-cards/slider-cards';
@import '../blocks/comments/comments';
@import '../blocks/faq/faq';
@import '../blocks/card-faq/card-faq';
@import '../blocks/pagination/pagination';
@import '../blocks/card-download/card-download';
@import '../blocks/joke/joke';
@import '../blocks/competition/competition';
@import '../blocks/card-comp/card-comp';
@import '../blocks/contacts/contacts';
@import '../blocks/header-alt/header-alt';
@import '../blocks/nav-alt/nav-alt';
@import '../blocks/work/work';
@import '../blocks/tabs/tabs';
@import '../blocks/card-work/card-work';
@import '../blocks/card-mark/card-mark';
@import '../blocks/market/market';
@import '../blocks/section-slider/section-slider';
@import '../blocks/calculator/calculator';
@import '../blocks/brif/brif';
@import '../blocks/firm/firm';
@import '../blocks/question/question';
@import '../blocks/card-exp/card-exp';
@import '../blocks/popup/popup';
@import '../blocks/onTop/onTop';
@import '../blocks/comments-form/comments-form';
@import '../blocks/filter-letters/filter-letters';
@import '../blocks/filter-nav/filter-nav';
@import '../blocks/tests/tests';
@import '../blocks/tests-partner/tests-partner';
@import '../blocks/exp/exp';
@import '../blocks/calendar/calendar';
@import '../blocks/calendar-item/calendar-item';
@import '../blocks/calendar-card/calendar-card';
@import '../blocks/other-calendars/other-calendars';
@import '../blocks/calendar-slider/calendar-slider';
@import '../blocks/calendar-tabs/calendar-tabs';
@import '../blocks/testing-banner/testing-banner';
@import '../blocks/testing-tabs/testing-tabs';
@import '../blocks/testing/testing';
@import '../blocks/testing-slider/testing-slider';
@import '../blocks/see-also/see-also';
@import '../blocks/popup-test-request/popup-test-request';
@import '../blocks/user-card/user-card';
@import '../blocks/rezult-card/rezult-card';
@import '../blocks/board/board';
@import '../blocks/board-form/board-form';
@import '../blocks/tabs-alt/tabs-alt';
@import '../blocks/board-filter/board-filter';
@import '../blocks/price-list/price-list';
@import '../blocks/table-box/table-box';