.slider-cards {

  &__list {
    margin: 0 -10px 0;
  }

  &__item {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .card-sim {
    max-width: 100%;
    margin-bottom: 0;
  }

  &__more {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;

    a {
      text-decoration: underline;
      font-size: 15px;
      font-weight: bold;
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: none;
      }

      &:after {
        content: '';
        width: 11px;
        height: 11px;
        border-bottom: 3px solid #CC1B28;
        border-right: 3px solid #CC1B28;
        transform: rotate(-45deg);
        margin-left: 8px;
      }
    }
  }

  .slick-arrow {
    top: 222px;

    @include media($big) {
      top: 166px;
    }

    @include media($lg) {
      top: 200px;
    }

    @include media($md) {
      top: 240px;
    }

    @include media($sm) {
      top: 48%;
    }
  }
}