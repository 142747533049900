.advert {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #D9F0F7;
  color: #19124A;
  margin: 0 auto;
  text-decoration: underline;
  font-weight: bold;
  width: 100%;

  &--fixed {
    position: relative;
  }

  &-page {
    margin-bottom: 35px;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.sticky {
  position: fixed;
  z-index: 20;
}
.stop {
  position: relative;
  z-index: 20;
}