.calendar-card {
  margin-bottom: 30px;
  width: 100%;
  max-width: calc(33.33333% - 21px);
  flex: 0 0 calc(33.33333% - 21px);
  background: #F0FAFD;
  margin-right: 21px;
  position: relative;
  padding: 10px;

  @include media($sm) {
    max-width: calc(50% - 21px);
    flex: 0 0 calc(50% - 21px);
  }

  @include media($min) {
    max-width: calc(100% - 21px);
    flex: 0 0 calc(100% - 21px);
  }

  &s {
    margin-right: -21px;
    display: flex;
    flex-wrap: wrap;
  }

  &.today {
    .calendar-card__date-num {
      span {
        background: #CC1B28;
      }
    }

    &:before {
      content: 'Сегодня';
      position: absolute;
      bottom: 100%;
      right: 0;
      font-size: 13px;
      font-weight: bold;
      color: #CC1B28;
      background: #F0FAFD;
      padding: 3px 12px;

      @supports (clip-path: polygon(0 0, 100% 100%)) {
        padding-left: 22px;
        clip-path: polygon(12px 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  &__year {
    font-weight: bold;
    font-size: 15px;
  }

  &__line {
    color: #19124A;
    font-size: 15px;
    margin-bottom: 10px;

    &--main {
      font-size: 17px;
      display: flex;
      flex-wrap: wrap;
    }

    &--alt {
      display: flex;
      padding-right: 25px;
      padding-left: 15px;
      align-items: center;
    }
  }

  &__moon {
    min-width: 41px;
    width: 41px;
    margin-right: 50%;
  }

  &__symbol {
    min-width: 44px;
    width: 44px;
  }

    &__moon,
    &__symbol {
      img {
        display: block;
        max-width: 100%;
      }
    }

  &__day {
    font-weight: bold;

    &-moon {
      margin-right: 15px;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    position: relative;
    top: -18px;

    &-num {
      display: flex;
      margin-right: 12px;

      span {
        display: block;
        background: #19124A;
        margin-right: 2px;
        color: #fff;
        font-size: 36px;
        // font-family: $ffBook;
        font-weight: bold;
        width: 30px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-month {
      position: relative;
      bottom: -4px;
      color: #19124A;
      font-weight: bold;
      font-size: 23px;
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
  }

  &__link {
    font-size: 15px;
    font-weight: bold;
    color: #0A4798;

    &:hover {
      span {
        text-decoration: none;
      }
    }

    span {
      text-decoration: underline;
    }
  }
}