.social {

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-right: -13px;
  }

  li {
    margin-right: 13px;
    margin-bottom: 13px;
  }

  a {
    display: block;
    color: #fff;
    font-size: 27px;
    width: 42px;
    height: 42px;

    &:hover {
      opacity: .7;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }

  &-icon {
    background-repeat: no-repeat;
    background-size: contain;

    &--vk {
      background-image: url('../img/icon-vk.svg');
    }
    &--od {
      background-image: url('../img/icon-od.svg');
    }
    &--facebook {
      background-image: url('../img/icon-facebook.svg');
    }
    &--pinterest {
      background-image: url('../img/icon-pinterest.svg');
    }
    &--twitter {
      background-image: url('../img/icon-twitter.svg');
    }
    &--flag {
      background-image: url('../img/icon-flag.svg');
    }
    &--telegram {
      background-image: url('../img/icon-telegram.svg');
    }
    &--pi {
      background-image: url('../img/icon-pi.svg');
    }
    &--yandex {
      background-image: url('../img/icon-yandex.svg');
    }
    &--google {
      background-image: url('../img/icon-google.svg');
    }
    &--instagram {
      background-image: url('../img/icon-instagram.svg');
    }
  }

  &--sm {
    ul {
      margin-right: -6px;
    }

    li {
      margin-right: 6px;
      margin-bottom: 6px;

      @include media($lg) {
        margin-right: 4px;
      }
    }

    a {
      width: 30px;
      height: 30px;
    }
  }

  &--center {
    ul {
      justify-content: center;
    }
  }
}