.comment {
  margin-bottom: 30px;

  &s {

    &-alert {
      width: 100%;
      max-width: 600px;
      padding: 60px;
      background: #F2F2F2;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 18px;
      line-height: 23px;
      color: #19124A;
      text-align: center;

      @include media($md) {
        padding: 20px;
      }
    }

    &__form {
      margin-top: 40px;
    }

    &-form {

      & > .form__help {
        margin-top: 20px;
        opacity: .7;
      }

      .files-rezult {
        li {
          width: auto;
          max-width: 84px;
          max-height: 60px;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;

          &:hover {
            box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }

          .delete-link {
            width: 12px;
            height: 12px;
            background: #D9F0F7;
            border-radius: 1px;
            top: 4px;
            right: 4px;

            &:before,
            &:after {
              background: #19124A;
            }
          }
        }
      }

      &__title {
        font-size: em(15);
        margin-bottom: 5px;
      }

      &__top {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        @include media($md) {
          flex-wrap: wrap;
        }

        .form__label {
          margin-right: 15px;
          flex: 1;
          margin-bottom: 0;

          @include media($min) {
            flex: 0 0 100%;
            width: 100%;
            margin-top: 10px;

            &:first-child {
              margin-top: 0;
            }
          }
        }

        .form__input {
          height: 36px;
        }
      }

      &__text {
        color: #828282;
        font-size: em(14);
        margin-right: 30px;

        @include media($md) {
          margin: 15px 0;
          flex: 0 0 100%;
          width: 100%;
        }
      }

      &__cabinet {
        max-width: 95px;
        margin-left: 30px;
      }

      &__social {
        margin-left: auto;
        margin-right: auto;

        @include media($md) {
          margin-left: 0;
          margin-right: 0;
        }

        ul {
          flex-wrap: nowrap;
        }
      }

      &__cont {
        margin-bottom: 20px;

        .form__help {
          @include media($sm) {
            display: block;
            margin-left: 0;
            margin-top: 5px;
          }
        }
      }

      &__bottom {
        display: flex;
        align-items: center;

        @include media($xl) {
          display: block;
        }
      }

      &__btn {
        width: auto;
        height: 40px;
        padding: 0 30px;
        margin-left: auto;

        @include media($xl) {
          margin-top: 15px;
        }
      }

      &__icon {
        background: #D9F0F7;
        border-radius: 5px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        margin-right: 10px;
        font-size: em(26);
      }

      &__info {
        margin-top: 27px;
        color: #0A4798;
        font-size: em(15);
        line-height: 16px;
      }
    }

    &__content {
      position: relative;
      overflow: hidden;
      max-height: 520px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.491713) 25.97%, #FFFFFF 82.87%);
        z-index: 1;
      }
    }

    &.active {
      .comments__content {
        overflow: visible;
        max-height: 99999px;

        &:after {
          display: none;
        }
      }

      .comments__more a {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &__more {
      display: flex;
      justify-content: center;

      .btn {
        width: auto;
        padding: 0 45px 0 27px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          border-top: 12px solid #19124A;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
        }
      }
    }
  }

  &__top {
    display: flex;
    align-items: flex-end;
    margin-bottom: 6px;
    flex-wrap: wrap;
  }

  &__avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 12px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__name {
    letter-spacing: 0.02em;
    font-size: em(18);
    font-style: italic;
    margin-right: 20px;
  }

  &__date {
    color: #828282;
  }

  &__text {
    background: #F0FAFD;
    border-radius: 3px;
    padding: 15px 19px;
    margin-bottom: 20px;
  }

  &__action {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__btn {
    width: auto;
    padding: 0 30px;
    height: 40px;
  }

  &__like {
    font-size: em(14);
    display: flex;
    align-items: center;

    &-val {
      margin-right: 11px;
    }

    &-btn {
      font-size: em(27);
      border: none;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
    }
  }

  &__answer {
    padding-left: 25px;
  }
}