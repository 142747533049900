.inner {
  display: flex;

  &--alt {
    .inner__aside {
      padding-top: 0;
    }
  }

  &__main {
    flex: 1;
    width: calc(100% - 345px);

    @include media($lg) {
      width: calc(100% - 330px);
    }

    @include media($md) {
      width: 100%;
    }
  }

  &__aside {
    margin-left: 45px;
    width: 100%;
    max-width: 300px;
    flex: 0 0 300px;
    height: inherit;
    padding-top: 30px;

    @include media($lg) {
      margin-left: 30px;
    }

    @include media($md) {
      display: none;
    }

    .btn--dzen {
      margin-bottom: 50px;
    }

    .calendar-box {
      margin-top: 77px;

      &:first-child {
        margin-top: 0;
      }

      &__more {
        a {
          height: 31px;
          padding: 0 14px;
        }
      }
    }

    &-item {
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .page-form {
      border: 1px solid #C2E9F5;
      padding-top: 9px;
    }

    .card__img {
      &:before {
        padding-top: 77.6%;
      }
    }
  }

  .calendar-box {
    border: 1px solid #C2E9F5;

    &__top {
      margin-top: -19px;
      background: #fff;
      padding: 0 10px 0 20px;
      margin-left: -10px;
    }

    &__num {
      left: 20px;
      top: -19px;
    }

    &__title {
      margin-left: 56px;
    }

    &__text {
      font-size: 16px;
      margin-bottom: 15px;
    }

    &__date {
      &-month {
        margin-right: auto;
      }
    }

    &__pic {
      width: 33px;
    }
  }
}