.card-work {
  @include col(1,2);
  padding: 0 10px;
  margin-bottom: 30px;

  @include media($md) {
    @include col(1,1);
  }

  &__content {
    border: 1px solid #C2E9F5;
    padding: 15px;
    height: 100%;
    font-size: em(15);
    position: relative;

    @include media($xl) {
      padding-bottom: 5px;
    }

    @supports (flex-direction: column) {
      display: flex;
      flex-direction: column;
    }

    &:hover {
      border-color: transparent;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    }
  }

  &__main {
    position: relative;
    padding-right: 180px;
    min-height: 120px;

    @include media($xl) {
      padding-right: 0;
      min-height: 0;
    }

    @supports (flex-direction: column) {
      margin-bottom: auto;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    width: 165px;
    height: 120px;
    overflow: hidden;

    @include media($xl) {
      position: static;
      margin-bottom: 20px;
      height: auto;
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__title {
    font-weight: bold;
    margin-bottom: 10px;

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__text {
    font-size: 17px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 20px;
    padding-top: 7px;

    @include media($xl) {
      display: block;

      & > * {
        display: inline-flex;
        margin-left: 0;
        margin-right: 15px;
        vertical-align: middle;
        margin-bottom: 10px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border: 1px solid #D9F0F7;
      width: calc(100% - 170px);
    }
  }

  &__user {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    &-avatar {
      width: 25px;
      min-width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 15px;
      overflow: hidden;
      display: block;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-name {
      font-weight: bold;
      font-size: 14px;

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__comment,
  &__view,
  &__date {
    display: flex;
    align-items: center;
    font-size: 12px;
    position: relative;
    bottom: -1px;

    .icon {
      font-size: em(22) !important;
      position: relative;
      bottom: -2px;
      margin-right: 8px;
    }
  }
  &__date {
    .icon {
      font-size: 10px;
    }
  }

  &__view {
    .icon {
      font-size: 12px;
    }
  }

  &__like {
    position: relative;
    z-index: 2;

    &.active {
      .like__btn {
        color: $colorTextMain;
      }
    }

    .like__btn {
      display: flex;
      background: #FFD428;
      border-radius: 7px;
      border: none;
      align-items: center;
      height: 23px;
      padding: 0 8px;
      cursor: pointer;
      font-size: 12px;

      .icon {
        margin-right: 8px;
      }
    }
  }
}