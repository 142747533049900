.card-faq {
  margin-bottom: 30px;
  border: 1px solid #C2E9F5;
  position: relative;
  display: flex;

  @include media($sm) {
    display: block;
  }

  &:hover {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-color: transparent;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__favorite {
    position: absolute;
    top: 3px;
    right: -5px;
    font-size: em(20);
  }

  &__text,
  &__name {
    a {
      &:hover {
        text-decoration: underline;
        color: #0A4798;
      }
    }
  }

  &__img {
    width: 260px;
    min-width: 260px;
    overflow: hidden;
    margin-right: 4px;
    display: block;

    @include media($lg) {
      width: 200px;
      min-width: 200px;
    }

    @include media($sm) {
      width: 100%;
      max-width: 100%;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__info {
    padding: 8px 15px 8px 15px;
    flex: 1;

    @supports (flex-direction: column) {
      display: flex;
      flex-direction: column;
    }
  }

  &__name {
    font-size: em(18);
    font-weight: bold;
    margin-bottom: 10px;

    span {
      color: #0A4798;
    }
  }

  &__text {

    @supports (flex-direction: column) {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__bottom {
    display: flex;
    margin-top: 8px;
    align-items: center;
    padding-right: 15px;
    font-size: em(14);

    @include media($lg) {
      // flex-wrap: wrap;
    }

    @include media($sm) {
      display: block;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding-right: 10px;

    &:hover {
      .card-faq__user-name {
        text-decoration: underline;
        color: #0A4798;
      }
    }

    &-avatar {
      border-radius: 50%;
      overflow: hidden;
      width: 25px;
      min-width: 25px;
      height: 25px;
      margin-right: 10px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-name {
      font-size: em(14);
    }
  }

  .icon {
    margin-right: 10px;
    position: relative;
    bottom: -2px;
  }

  &__comments {
    margin-left: 44px;
    padding: 3px 9px;
    background: #F0FAFD;
    border-radius: 7px;
    display: flex;
    align-items: center;

    @include media($lg) {
      margin-left: auto;
    }

    @include media($sm) {
      display: inline-block;
    }

    .icon {
      font-size: em(13);
    }
  }

  &__view {
    display: flex;
    align-items: center;
    margin-left: 22px;

    @include media($sm) {
      margin-left: 0;
      display: inline-block;
      margin-right: 10px;
    }
    
    .icon {
      font-size: em(20);
    }
  }

  &__time {
    display: flex;
    align-items: center;

    @include media($sm) {
      display: inline-block;
      margin-right: 10px;
    }

    .icon {
      font-size: em(12);
    }
  }
}