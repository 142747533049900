.section-title {
  font-size: em(23);
  color: #19124A;
  text-align: center;

  a {
    &:hover {
      color: $hover;
    }
  }
}

.section-title--alt {
  font-weight: bold;
}

.page-title {
  font-size: em(25);
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 44px;

  span {
    color: #0A4798;
  }
}

.title {
  font-size: em(19);
  text-align: center;
  font-weight: 400;
  margin-bottom: 45px;

  span {
    display: inline-flex;
    height: 36px;
    padding: 0 25px;
    align-items: center;
    background: #D9F0F7;
    position: relative;
    min-width: 210px;
    justify-content: center;

    &:after {
      content: '';
      position: absolute;
      top: 6px;
      right: 15px;
      height: 100%;
      width: calc(100% - 6px);
      background: #19124A;
      z-index: -1;
    }
  }

  &-sm {
    font-size: em(14);

    span {
      min-width: 171px;
    }
  }
}

.title-alt {
  font-size: em(20);
  color: #fff;
  font-weight: bold;
  position: relative;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 21px;

  span {
    display: flex;
    align-items: center;
    background: #CC1B28;
    height: 38px;
    padding: 0 22px;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    height: 65px;
    border: 7px solid;
    border-radius: 10px;
    left: 50%;
    z-index: -1;
  }

  &:after {
    width: 67px;
    bottom: 0;
    border-color: #19124A;
    transform: translateX(-20%);
  }

  &:before {
    width: 78px;
    top: 0;
    border-color: #D9F0F7;
    transform: translateX(-70%);
  }
}

.sub-title {
  font-style: italic;
  font-size: em(18);
  letter-spacing: 0.02em;
  margin-bottom: 30px;
  margin-top: -20px;
}

.title-relevant {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding-top: 23px;

  span {
    padding: 10px 15px 10px 52px;
    background: #D9F0F7;
    font-size: em(20);
    font-weight: bold;
    position: relative;

    &:before {
      content: attr(data-num);
      position: absolute;
      top: -24px;
      left: -15px;
      background-image: url(../img/calendar.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 55px;
      height: 63px;
      color: #fff;
      font-weight: bold;
      font-size: em(31);
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-top: 10px;
    }
  }
}

.title-box {
  border: 1px solid #C2E9F5;
  padding: 52px 30px 27px;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
  margin-top: 40px;

  &__alert {
    display: flex;
    justify-content: flex-end;
    color: #0A4798;
    font-size: 15px;
    font-weight: bold;
  }

  &__text {
    margin-bottom: 30px;
    font-size: em(15);
  }

  &__btn {
    height: 42px;
    width: auto;
    padding: 0 30px;
    display: inline-flex;
  }
  
  &__title {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%,-50%);
    white-space: nowrap;

    @include media($min) {
      white-space: normal;
      width: 100%;
      max-width: calc(100% - 40px);

      span {
        height: auto;
        min-height: 36px;
      }
    }

    &:after{
      content: '';
      position: absolute;
      height: 100%;
      background: #fff;
      width: calc(100% + 115px);
      left: -65px;
      z-index: -2;

      @include media($xl) {
        display: none;
      }
    }
  }
}