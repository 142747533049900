.burger {
  display: none;
  position: relative;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;

  @include media($lg) {
    display: flex;
  }

  span {
    width: 100%;
    position: relative;
    display: block;
    height: 2px;
    background: #000;

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
    }

    &:after {
      top: -7px;
    }

    &:before {
      bottom: -7px;
    }
  }
}