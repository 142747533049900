@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

ol, ul, li {
  list-style-type: none; }

a {
  color: #19124A;
  text-decoration: none; }

textarea {
  resize: none; }

a,
input,
select,
button,
*:focus {
  outline: none; }

*, *:before, *:after {
  box-sizing: border-box;
  outline: none; }

@font-face {
  font-family: "icons";
  src: url("../fonts/icons/icons.eot");
  src: url("../fonts/icons/icons.eot?#iefix") format("eot"), url("../fonts/icons/icons.woff2") format("woff2"), url("../fonts/icons/icons.woff") format("woff"), url("../fonts/icons/icons.ttf") format("truetype"), url("../fonts/icons/icons.svg#icons") format("svg"); }

.icon:before {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none; }

.icon-arrow-alt:before {
  content: "\E001"; }

.icon-avatar:before {
  content: "\E002"; }

.icon-basket:before {
  content: "\E003"; }

.icon-board:before {
  content: "\E004"; }

.icon-calc:before {
  content: "\E005"; }

.icon-clock:before {
  content: "\E006"; }

.icon-close:before {
  content: "\E007"; }

.icon-cloud:before {
  content: "\E008"; }

.icon-eye:before {
  content: "\E009"; }

.icon-facebook:before {
  content: "\E00A"; }

.icon-faq:before {
  content: "\E00B"; }

.icon-file:before {
  content: "\E00C"; }

.icon-flag:before {
  content: "\E00D"; }

.icon-like-full:before {
  content: "\E00E"; }

.icon-like:before {
  content: "\E00F"; }

.icon-long-arrow-down:before {
  content: "\E010"; }

.icon-medal:before {
  content: "\E011"; }

.icon-od:before {
  content: "\E012"; }

.icon-pi:before {
  content: "\E013"; }

.icon-pinterest:before {
  content: "\E014"; }

.icon-print:before {
  content: "\E015"; }

.icon-reload:before {
  content: "\E016"; }

.icon-search:before {
  content: "\E017"; }

.icon-star-full:before {
  content: "\E018"; }

.icon-star:before {
  content: "\E019"; }

.icon-telegram:before {
  content: "\E01A"; }

.icon-test:before {
  content: "\E01B"; }

.icon-trello:before {
  content: "\E01C"; }

.icon-twitter:before {
  content: "\E01D"; }

.icon-vk:before {
  content: "\E01E"; }

html {
  font-size: 17px;
  line-height: 1.29; }

body {
  font-family: "PT Sans", Arial, sans-serif;
  font-weight: 400;
  background-color: #ffffff;
  color: #19124A;
  min-width: 320px; }
  @media (max-width: 1200px) {
    body.menu-opened {
      overflow: hidden; } }
  @media (max-width: 1200px) {
    body.menu-opened .header__nav {
      opacity: 1;
      visibility: visible; } }
  @media (max-width: 1200px) {
    body.menu-opened .header__nav-content {
      transform: translateX(0); } }

textarea,
select,
input {
  -webkit-appearance: none; }
  textarea::placeholder,
  select::placeholder,
  input::placeholder {
    color: #BDBDBD; }

.hidden {
  display: none; }

p {
  margin-bottom: 10px; }

button {
  font-family: inherit; }

.wrapper {
  width: 100%;
  overflow: hidden; }
  @supports (flex-direction: column) {
    .wrapper {
      display: flex;
      flex-direction: column;
      min-height: 100vh; } }

.content {
  flex: 1;
  padding-bottom: 80px; }
  @media (max-width: 1023px) {
    .content {
      padding-bottom: 30px; } }

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto; }
  .container-sm {
    max-width: 814px;
    margin-left: auto;
    margin-right: auto; }
  @media (max-width: 1400px) {
    .container {
      max-width: 1170px; } }
  @media (max-width: 1200px) {
    .container {
      max-width: 1023px;
      padding: 0 15px; } }
  @media (max-width: 1023px) {
    .container {
      max-width: 768px; } }
  @media (max-width: 768px) {
    .container {
      max-width: 100%; } }

/* Plugins*/
.jq-checkbox,
.jq-radio {
  position: relative;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.jq-checkbox input,
.jq-radio input {
  position: absolute;
  z-index: -1;
  margin: 0;
  padding: 0;
  opacity: 0; }

.jq-file {
  position: relative;
  display: inline-block;
  overflow: hidden; }

.jq-file input {
  position: absolute;
  top: 0;
  right: 0;
  height: auto;
  margin: 0;
  padding: 0;
  opacity: 0;
  font-size: 100px;
  line-height: 1em; }

.jq-file__name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.jq-selectbox,
.jq-select-multiple {
  position: relative;
  display: inline-block; }

.jq-selectbox select,
.jq-select-multiple select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0; }

.jq-selectbox li,
.jq-select-multiple li {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.jq-selectbox {
  z-index: 10; }

.jq-selectbox__select {
  position: relative; }

.jq-selectbox__select-text {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis; }

.jq-selectbox__dropdown {
  position: absolute; }

.jq-selectbox__search input {
  -webkit-appearance: textfield; }

.jq-selectbox__search input::-webkit-search-cancel-button,
.jq-selectbox__search input::-webkit-search-decoration {
  -webkit-appearance: none; }

.jq-selectbox__dropdown ul {
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  list-style: none;
  -webkit-overflow-scrolling: touch; }

.jq-select-multiple ul {
  position: relative;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }

.jq-number {
  display: inline-block; }

.jq-number__field input {
  -moz-appearance: textfield;
  text-align: left;
  /* для Opera Presto */ }

.jq-number__field input::-webkit-inner-spin-button,
.jq-number__field input::-webkit-outer-spin-button {
  margin: 0;
  /* в каких-то браузерах присутствует отступ */
  -webkit-appearance: none; }

.jq-checkbox,
.jq-radio {
  vertical-align: -4px;
  width: 16px;
  height: 16px;
  margin: 0 4px 0 0;
  border: 1px solid #C3C3C3;
  background: linear-gradient(#FFF, #E6E6E6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), inset -1px -1px #FFF, inset 1px -1px #FFF;
  cursor: pointer; }

.jq-checkbox.focused,
.jq-radio.focused {
  border: 1px solid #08C; }

.jq-checkbox.disabled,
.jq-radio.disabled {
  opacity: .55; }

.jq-checkbox {
  border-radius: 3px; }

.jq-checkbox.checked .jq-checkbox__div {
  width: 8px;
  height: 4px;
  margin: 3px 0 0 3px;
  border-bottom: 2px solid #666;
  border-left: 2px solid #666;
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg); }

.jq-radio {
  border-radius: 50%; }

.jq-radio.checked .jq-radio__div {
  width: 10px;
  height: 10px;
  margin: 3px 0 0 3px;
  border-radius: 50%;
  background: #777;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.7); }

.jq-file {
  width: 270px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); }

.jq-file input {
  cursor: pointer; }

.jq-file__name {
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  padding: 0 80px 0 10px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  background: #FFF;
  box-shadow: inset 1px 1px #F1F1F1;
  font: 14px/32px Arial, sans-serif;
  color: #333; }

.jq-file__browse {
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 0 10px;
  border-left: 1px solid #CCC;
  border-radius: 0 4px 4px 0;
  background: linear-gradient(#FFF, #E6E6E6);
  box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 14px/32px Arial, sans-serif;
  color: #333;
  text-shadow: 1px 1px #FFF; }

.jq-file:hover .jq-file__browse {
  background: linear-gradient(#F6F6F6, #E6E6E6); }

.jq-file:active .jq-file__browse {
  background: #F5F5F5;
  box-shadow: inset 1px 1px 3px #DDD; }

.jq-file.focused .jq-file__name {
  border: 1px solid #5794BF; }

.jq-file.disabled,
.jq-file.disabled .jq-file__name,
.jq-file.disabled .jq-file__browse {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888; }

.jq-number {
  position: relative;
  vertical-align: middle;
  padding: 0 36px 0 0; }

.jq-number__field {
  width: 100px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1); }

.jq-number__field:hover {
  border-color: #B3B3B3; }

.jq-number__field input {
  box-sizing: border-box;
  width: 100%;
  padding: 8px 9px;
  border: none;
  outline: none;
  background: none;
  font: 14px Arial, sans-serif;
  color: #333; }

.jq-number__spin {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 14px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  background: linear-gradient(#FFF, #E6E6E6);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-shadow: 1px 1px #FFF;
  cursor: pointer; }

.jq-number__spin.minus {
  top: auto;
  bottom: 0; }

.jq-number__spin:hover {
  background: linear-gradient(#F6F6F6, #E6E6E6); }

.jq-number__spin:active {
  background: #F5F5F5;
  box-shadow: inset 1px 1px 3px #DDD; }

.jq-number__spin:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 11px;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #999;
  border-left: 5px solid transparent; }

.jq-number__spin.minus:after {
  top: 5px;
  border-top: 5px solid #999;
  border-right: 5px solid transparent;
  border-bottom: none;
  border-left: 5px solid transparent; }

.jq-number__spin.minus:hover:after {
  border-top-color: #000; }

.jq-number__spin.plus:hover:after {
  border-bottom-color: #000; }

.jq-number.focused .jq-number__field {
  border: 1px solid #5794BF; }

.jq-number.disabled .jq-number__field,
.jq-number.disabled .jq-number__spin {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888; }

.jq-number.disabled .jq-number__spin:after {
  border-bottom-color: #AAA; }

.jq-number.disabled .jq-number__spin.minus:after {
  border-top-color: #AAA; }

.jq-selectbox {
  vertical-align: middle;
  cursor: pointer; }

.jq-selectbox__select {
  height: 32px;
  padding: 0 45px 0 10px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  background: linear-gradient(#FFF, #E6E6E6);
  box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 14px/32px Arial, sans-serif;
  color: #333;
  text-shadow: 1px 1px #FFF; }

.jq-selectbox__select:hover {
  background: linear-gradient(#F6F6F6, #E6E6E6); }

.jq-selectbox__select:active {
  background: #F5F5F5;
  box-shadow: inset 1px 1px 3px #DDD; }

.jq-selectbox.focused .jq-selectbox__select {
  border: 1px solid #5794BF; }

.jq-selectbox.disabled .jq-selectbox__select {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888; }

.jq-selectbox__select-text {
  display: block;
  width: 100%; }

.jq-selectbox .placeholder {
  color: #888; }

.jq-selectbox__trigger {
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 100%;
  border-left: 1px solid #CCC; }

.jq-selectbox__trigger-arrow {
  position: absolute;
  top: 14px;
  right: 12px;
  width: 0;
  height: 0;
  border-top: 5px solid #999;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent; }

.jq-selectbox:hover .jq-selectbox__trigger-arrow {
  border-top-color: #000; }

.jq-selectbox.disabled .jq-selectbox__trigger-arrow {
  border-top-color: #AAA; }

.jq-selectbox__dropdown {
  box-sizing: border-box;
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  border: 1px solid #CCC;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font: 14px/18px Arial, sans-serif; }

.jq-selectbox__search {
  margin: 5px; }

.jq-selectbox__search input {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 5px 27px 6px 8px;
  border: 1px solid #CCC;
  border-radius: 3px;
  outline: none;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==") no-repeat 100% 50%;
  box-shadow: inset 1px 1px #F1F1F1;
  color: #333; }

.jq-selectbox__not-found {
  margin: 5px;
  padding: 5px 8px 6px;
  background: #F0F0F0;
  font-size: 13px; }

.jq-selectbox ul {
  margin: 0;
  padding: 0; }

.jq-selectbox li {
  min-height: 18px;
  padding: 5px 10px 6px;
  color: #231F20; }

.jq-selectbox li.selected {
  background-color: #A3ABB1;
  color: #FFF; }

.jq-selectbox li:hover {
  background-color: #08C;
  color: #FFF; }

.jq-selectbox li.disabled {
  color: #AAA; }

.jq-selectbox li.disabled:hover {
  background: none; }

.jq-selectbox li.optgroup {
  font-weight: bold; }

.jq-selectbox li.optgroup:hover {
  background: none;
  color: #231F20;
  cursor: default; }

.jq-selectbox li.option {
  padding-left: 25px; }

.jq-select-multiple {
  box-sizing: border-box;
  padding: 1px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  background: #FFF;
  box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 14px/18px Arial, sans-serif;
  color: #333;
  cursor: default; }

.jq-select-multiple.focused {
  border: 1px solid #5794BF; }

.jq-select-multiple.disabled {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888; }

.jq-select-multiple ul {
  margin: 0;
  padding: 0; }

.jq-select-multiple li {
  padding: 3px 9px 4px;
  list-style: none; }

.jq-select-multiple li:first-child {
  border-radius: 3px 3px 0 0; }

.jq-select-multiple li:last-child {
  border-radius: 0 0 3px 3px; }

.jq-select-multiple li.selected {
  background: #08C;
  color: #FFF; }

.jq-select-multiple li.disabled {
  color: #AAA; }

.jq-select-multiple li.optgroup {
  font-weight: bold; }

.jq-select-multiple li.option {
  padding-left: 25px; }

.jq-select-multiple.disabled li.selected,
.jq-select-multiple li.selected.disabled {
  background: #CCC;
  color: #FFF; }

input[type='email'].styler,
input[type='password'].styler,
input[type='search'].styler,
input[type='tel'].styler,
input[type='text'].styler,
input[type='url'].styler,
textarea.styler {
  padding: 8px 9px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 14px Arial, sans-serif;
  color: #333; }

input[type='search'].styler {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

textarea.styler {
  overflow: auto; }

input[type='email'].styler:hover,
input[type='password'].styler:hover,
input[type='search'].styler:hover,
input[type='tel'].styler:hover,
input[type='text'].styler:hover,
input[type='url'].styler:hover,
textarea.styler:hover {
  border-color: #B3B3B3; }

input[type='email'].styler:hover:focus,
input[type='password'].styler:hover:focus,
input[type='search'].styler:hover:focus,
input[type='tel'].styler:hover:focus,
input[type='text'].styler:hover:focus,
input[type='url'].styler:hover:focus,
textarea.styler:hover:focus {
  border-color: #CCC;
  border-top-color: #B3B3B3;
  outline: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }

button.styler,
input[type='button'].styler,
input[type='submit'].styler,
input[type='reset'].styler {
  overflow: visible;
  padding: 8px 11px;
  border: 1px solid #CCC;
  border-bottom-color: #B3B3B3;
  border-radius: 4px;
  outline: none;
  background: linear-gradient(#FFF, #E6E6E6);
  box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 14px Arial, sans-serif;
  color: #333;
  text-shadow: 1px 1px #FFF;
  cursor: pointer; }

button.styler.styler::-moz-focus-inner,
input[type='button'].styler.styler::-moz-focus-inner,
input[type='submit'].styler.styler::-moz-focus-inner,
input[type='reset'].styler.styler::-moz-focus-inner {
  padding: 0;
  border: 0; }

button.styler:not([disabled]):hover,
input[type='button'].styler:not([disabled]):hover,
input[type='submit'].styler:not([disabled]):hover,
input[type='reset'].styler:not([disabled]):hover,
input[type='reset'].styler:hover {
  background: linear-gradient(#F6F6F6, #E6E6E6); }

button.styler:active,
input[type='button'].styler:active,
input[type='submit'].styler:active,
input[type='reset'].styler:active {
  background: #F5F5F5;
  box-shadow: inset 1px 1px 3px #DDD; }

button.styler[disabled],
input[type='button'].styler[disabled],
input[type='submit'].styler[disabled] {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888; }

body.compensate-for-scrollbar {
  overflow: hidden;
  -ms-overflow-style: none; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-caption, .fancybox-infobar, .fancybox-navigation .fancybox-button, .fancybox-toolbar {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s,visibility 0s linear .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-caption .fancybox-caption, .fancybox-show-infobar .fancybox-infobar, .fancybox-show-nav .fancybox-navigation .fancybox-button, .fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  transition: opacity .25s,visibility 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px 44px 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide:before {
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide, .fancybox-slide--current, .fancybox-slide--next, .fancybox-slide--previous {
  display: block; }

.fancybox-slide--next {
  z-index: 99995; }

.fancybox-slide--image {
  padding: 44px 0 0;
  overflow: visible; }

.fancybox-slide--image:before {
  display: none; }

.fancybox-slide--html {
  padding: 6px 6px 0; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 44px;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-slide--html .fancybox-content {
  margin: 0 0 6px; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-pan .fancybox-content, .fancybox-can-swipe .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable=true] {
  cursor: text; }

.fancybox-image, .fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--iframe .fancybox-content, .fancybox-slide--map .fancybox-content, .fancybox-slide--video .fancybox-content {
  height: calc(100% - 44px);
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-iframe, .fancybox-video {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  vertical-align: top;
  width: 100%; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  transition: color .2s;
  vertical-align: top;
  width: 44px; }

.fancybox-button, .fancybox-button:link, .fancybox-button:visited {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button.disabled, .fancybox-button.disabled:hover, .fancybox-button[disabled], .fancybox-button[disabled]:hover {
  color: #888;
  cursor: default; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--fsenter svg:nth-child(2), .fancybox-button--fsexit svg:nth-child(1), .fancybox-button--pause svg:nth-child(1), .fancybox-button--play svg:nth-child(2) {
  display: none; }

.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--html .fancybox-close-small:hover {
  color: currentColor;
  opacity: 1; }

.fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

.fancybox-navigation .fancybox-button {
  height: 38px;
  margin: 0;
  opacity: 0;
  padding: 7px;
  position: absolute;
  top: calc(50% - 19px);
  width: 38px; }

.fancybox-show-nav .fancybox-navigation .fancybox-button {
  transition: opacity .25s,visibility 0s,color .25s; }

.fancybox-navigation .fancybox-button:after {
  content: "";
  height: 100px;
  left: -26px;
  position: absolute;
  top: -31px;
  width: 70px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  right: 6px; }

.fancybox-caption {
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px;
  right: 0; }

.fancybox-caption:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -25px;
  z-index: -1; }

.fancybox-caption:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  content: "";
  display: block;
  left: 44px;
  position: absolute;
  right: 44px;
  top: 0; }

.fancybox-caption a, .fancybox-caption a:link, .fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

.fancybox-loading {
  -webkit-animation: a .8s infinite linear;
  animation: a .8s infinite linear;
  background: transparent;
  border: 6px solid rgba(99, 99, 99, 0.5);
  border-radius: 100%;
  border-top-color: #fff;
  height: 60px;
  left: 50%;
  margin: -30px 0 0 -30px;
  opacity: .6;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 99999; }

@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.fancybox-fx-fade.fancybox-slide--next, .fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1);
  transform: scaleX(1); }

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-1turn);
  transform: rotate(-1turn); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg); }

.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0); }

.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1); }

@media (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0 0; }
  .fancybox-slide--image .fancybox-content {
    margin-bottom: 6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; } }

.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:link, .fancybox-share__button:visited {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

.fancybox-thumbs {
  background: #fff;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a:before {
  border: 4px solid #4ea7f9;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus:before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active:before {
  opacity: 1; }

@media (max-width: 768px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.sticky {
  position: fixed;
  z-index: 90; }

.stop {
  position: relative;
  z-index: 90; }

.datepicker--cells {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.datepicker--cell {
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 32px;
  z-index: 1; }

.datepicker--cell.-focus- {
  background: #f0f0f0; }

.datepicker--cell.-current- {
  color: #4EB5E6; }

.datepicker--cell.-current-.-focus- {
  color: #4a4a4a; }

.datepicker--cell.-current-.-in-range- {
  color: #4EB5E6; }

.datepicker--cell.-in-range- {
  background: rgba(92, 196, 239, 0.1);
  color: #4a4a4a;
  border-radius: 0; }

.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell.-disabled- {
  cursor: default;
  color: #aeaeae; }

.datepicker--cell.-disabled-.-focus- {
  color: #aeaeae; }

.datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1; }

.datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae; }

.datepicker--cell.-range-from- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 4px 0 0 4px; }

.datepicker--cell.-range-to- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 0 4px 4px 0; }

.datepicker--cell.-selected-, .datepicker--cell.-selected-.-current- {
  color: #fff;
  background: #5cc4ef; }

.datepicker--cell.-range-from-.-range-to- {
  border-radius: 4px; }

.datepicker--cell.-selected- {
  border: none; }

.datepicker--cell.-selected-.-focus- {
  background: #45bced; }

.datepicker--cell:empty {
  cursor: default; }

.datepicker--days-names {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 8px 0 3px; }

.datepicker--day-name {
  color: #FF9A19;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: .8em; }

.-only-timepicker- .datepicker--content, .datepicker--body, .datepicker-inline .datepicker--pointer {
  display: none; }

.datepicker--cell-day {
  width: 14.28571%; }

.datepicker--cells-months {
  height: 170px; }

.datepicker--cell-month {
  width: 33.33%;
  height: 25%; }

.datepicker--cells-years, .datepicker--years {
  height: 170px; }

.datepicker--cell-year {
  width: 25%;
  height: 33.33%; }

.datepickers-container {
  position: absolute;
  left: 0;
  top: 0; }

@media print {
  .datepickers-container {
    display: none; } }

.datepicker {
  background: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: content-box;
  font-family: Tahoma,sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  position: absolute;
  left: -100000px;
  opacity: 0;
  transition: opacity .3s ease,left 0s .3s,-webkit-transform .3s ease;
  transition: opacity .3s ease,transform .3s ease,left 0s .3s;
  transition: opacity .3s ease,transform .3s ease,left 0s .3s,-webkit-transform .3s ease;
  z-index: 100; }

.datepicker.-from-top- {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px); }

.datepicker.-from-right- {
  -webkit-transform: translateX(8px);
  transform: translateX(8px); }

.datepicker.-from-bottom- {
  -webkit-transform: translateY(8px);
  transform: translateY(8px); }

.datepicker.-from-left- {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px); }

.datepicker.active {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  transition: opacity .3s ease,left 0s 0s,-webkit-transform .3s ease;
  transition: opacity .3s ease,transform .3s ease,left 0s 0s;
  transition: opacity .3s ease,transform .3s ease,left 0s 0s,-webkit-transform .3s ease; }

.datepicker-inline .datepicker {
  border-color: #d7d7d7;
  box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  -webkit-transform: none;
  transform: none; }

.datepicker--content {
  box-sizing: content-box;
  padding: 4px; }

.datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1; }

.datepicker--nav-action:hover, .datepicker--nav-title:hover {
  background: #f0f0f0; }

.-top-center- .datepicker--pointer, .-top-left- .datepicker--pointer, .-top-right- .datepicker--pointer {
  top: calc(100% - 4px);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg); }

.-right-bottom- .datepicker--pointer, .-right-center- .datepicker--pointer, .-right-top- .datepicker--pointer {
  right: calc(100% - 4px);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg); }

.-bottom-center- .datepicker--pointer, .-bottom-left- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  bottom: calc(100% - 4px);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg); }

.-left-bottom- .datepicker--pointer, .-left-center- .datepicker--pointer, .-left-top- .datepicker--pointer {
  left: calc(100% - 4px);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.-bottom-left- .datepicker--pointer, .-top-left- .datepicker--pointer {
  left: 10px; }

.-bottom-right- .datepicker--pointer, .-top-right- .datepicker--pointer {
  right: 10px; }

.-bottom-center- .datepicker--pointer, .-top-center- .datepicker--pointer {
  left: calc(50% - 10px / 2); }

.-left-top- .datepicker--pointer, .-right-top- .datepicker--pointer {
  top: 10px; }

.-left-bottom- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  bottom: 10px; }

.-left-center- .datepicker--pointer, .-right-center- .datepicker--pointer {
  top: calc(50% - 10px / 2); }

.datepicker--body.active {
  display: block; }

.datepicker--nav {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px; }

.-only-timepicker- .datepicker--nav {
  display: none; }

.datepicker--nav-action, .datepicker--nav-title {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.datepicker--nav-action {
  width: 32px;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.datepicker--nav-action.-disabled- {
  visibility: hidden; }

.datepicker--nav-action svg {
  width: 32px;
  height: 32px; }

.datepicker--nav-action path {
  fill: none;
  stroke: #9c9c9c;
  stroke-width: 2px; }

.datepicker--nav-title {
  border-radius: 4px;
  padding: 0 8px; }

.datepicker--buttons, .datepicker--time {
  border-top: 1px solid #efefef;
  padding: 4px; }

.datepicker--nav-title i {
  font-style: normal;
  color: #9c9c9c;
  margin-left: 5px; }

.datepicker--nav-title.-disabled- {
  cursor: default;
  background: 0 0; }

.datepicker--buttons {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.datepicker--button {
  color: #4EB5E6;
  cursor: pointer;
  border-radius: 4px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px; }

.datepicker--button:hover {
  color: #4a4a4a;
  background: #f0f0f0; }

.datepicker--time {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative; }

.datepicker--time.-am-pm- .datepicker--time-sliders {
  -webkit-flex: 0 1 138px;
  -ms-flex: 0 1 138px;
  flex: 0 1 138px;
  max-width: 138px; }

.-only-timepicker- .datepicker--time {
  border-top: none; }

.datepicker--time-sliders {
  -webkit-flex: 0 1 153px;
  -ms-flex: 0 1 153px;
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px; }

.datepicker--time-label {
  display: none;
  font-size: 12px; }

.datepicker--time-current {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px; }

.datepicker--time-current-colon {
  margin: 0 2px 3px;
  line-height: 1; }

.datepicker--time-current-hours, .datepicker--time-current-minutes {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic",CenturyGothic,AppleGothic,sans-serif;
  position: relative;
  z-index: 1; }

.datepicker--time-current-hours:after, .datepicker--time-current-minutes:after {
  content: '';
  background: #f0f0f0;
  border-radius: 4px;
  position: absolute;
  left: -2px;
  top: -3px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  opacity: 0; }

.datepicker--time-current-hours.-focus-:after, .datepicker--time-current-minutes.-focus-:after {
  opacity: 1; }

.datepicker--time-current-ampm {
  text-transform: uppercase;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #9c9c9c;
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px; }

.datepicker--time-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat; }

.datepicker--time-row:first-child {
  margin-bottom: 4px; }

.datepicker--time-row input[type=range] {
  background: 0 0;
  cursor: pointer;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none; }

.datepicker--time-row input[type=range]::-ms-tooltip {
  display: none; }

.datepicker--time-row input[type=range]:hover::-webkit-slider-thumb {
  border-color: #b8b8b8; }

.datepicker--time-row input[type=range]:hover::-moz-range-thumb {
  border-color: #b8b8b8; }

.datepicker--time-row input[type=range]:hover::-ms-thumb {
  border-color: #b8b8b8; }

.datepicker--time-row input[type=range]:focus {
  outline: 0; }

.datepicker--time-row input[type=range]:focus::-webkit-slider-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef; }

.datepicker--time-row input[type=range]:focus::-moz-range-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef; }

.datepicker--time-row input[type=range]:focus::-ms-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef; }

.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background .2s;
  margin-top: -6px; }

.datepicker--time-row input[type=range]::-moz-range-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background .2s; }

.datepicker--time-row input[type=range]::-ms-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background .2s; }

.datepicker--time-row input[type=range]::-webkit-slider-runnable-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: 0 0; }

.datepicker--time-row input[type=range]::-moz-range-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: 0 0; }

.datepicker--time-row input[type=range]::-ms-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: 0 0; }

.datepicker--time-row input[type=range]::-ms-fill-lower {
  background: 0 0; }

.datepicker--time-row input[type=range]::-ms-fill-upper {
  background: 0 0; }

.datepicker--time-row span {
  padding: 0 12px; }

.datepicker--time-icon {
  color: #9c9c9c;
  border: 1px solid;
  border-radius: 50%;
  font-size: 16px;
  position: relative;
  margin: 0 5px -1px 0;
  width: 1em;
  height: 1em; }

.datepicker--time-icon:after, .datepicker--time-icon:before {
  content: '';
  background: currentColor;
  position: absolute; }

.datepicker--time-icon:after {
  height: .4em;
  width: 1px;
  left: calc(50% - 1px);
  top: calc(50% + 1px);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%); }

.datepicker--time-icon:before {
  width: .4em;
  height: 1px;
  top: calc(50% + 1px);
  left: calc(50% - 1px); }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5; }

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede; }

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6; }

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4; }

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #ccc; }

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2); }

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: 0 0;
  border: none; }

/*! nouislider - 12.1.0 - 10/25/2018 */
.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base, .noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect, .noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

html:not([dir=rtl]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0; }

.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  position: absolute; }

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  -webkit-transition: transform .3s;
  transition: transform .3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

.noUi-horizontal {
  height: 18px; }

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px; }

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto; }

.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0,0 3px 6px -5px #BBB; }

.noUi-connects {
  border-radius: 3px; }

.noUi-connect {
  background: #3FB8AF; }

.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF,inset 0 1px 7px #EBEBEB,0 3px 6px -3px #BBB; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFF,inset 0 1px 7px #DDD,0 3px 6px -3px #BBB; }

.noUi-handle:after, .noUi-handle:before {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px; }

.noUi-handle:after {
  left: 17px; }

.noUi-vertical .noUi-handle:after, .noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  top: 17px; }

[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled] .noUi-handle, [disabled].noUi-handle, [disabled].noUi-target {
  cursor: not-allowed; }

.noUi-pips, .noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-sub {
  background: #AAA; }

.noUi-marker-large {
  background: #AAA; }

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

/*************** SCROLLBAR BASE CSS ***************/
.scroll-wrapper {
  overflow: hidden !important;
  /*padding: 0 !important;*/
  position: relative; }

.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  width: auto !important; }

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0; }

.scroll-element {
  display: none; }

.scroll-element, .scroll-element div {
  box-sizing: content-box; }

.scroll-element div {
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%; }

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block; }

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
  cursor: default; }

.scroll-textarea {
  border: 1px solid #cccccc;
  border-top-color: #999999; }

.scroll-textarea > .scroll-content {
  overflow: hidden !important; }

.scroll-textarea > .scroll-content > textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: none;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important; }

.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0; }

/*************** SIMPLE INNER SCROLLBAR ***************/
.scrollbar-inner > .scroll-element,
.scrollbar-inner > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scrollbar-inner > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scrollbar-inner > .scroll-element.scroll-x {
  bottom: 2px;
  height: 8px;
  left: 0;
  width: 100%; }

.scrollbar-inner > .scroll-element.scroll-y {
  height: 100%;
  right: 2px;
  top: 0;
  width: 8px; }

.scrollbar-inner > .scroll-element .scroll-element_outer {
  overflow: hidden; }

.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px; }

.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  opacity: 0.4; }

.scrollbar-inner > .scroll-element .scroll-element_track {
  background-color: #e0e0e0; }

.scrollbar-inner > .scroll-element .scroll-bar {
  background-color: #c2c2c2; }

.scrollbar-inner > .scroll-element:hover .scroll-bar {
  background-color: #919191; }

.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191; }

/* update scrollbar offset if both scrolls are visible */
.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -12px; }

.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -12px; }

.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -12px; }

.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -12px; }

/*************** SIMPLE OUTER SCROLLBAR ***************/
.scrollbar-outer > .scroll-element,
.scrollbar-outer > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scrollbar-outer > .scroll-element {
  background-color: #ffffff; }

.scrollbar-outer > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scrollbar-outer > .scroll-element.scroll-x {
  bottom: 0;
  height: 12px;
  left: 0;
  width: 100%; }

.scrollbar-outer > .scroll-element.scroll-y {
  height: 100%;
  right: 0;
  top: 0;
  width: 12px; }

.scrollbar-outer > .scroll-element.scroll-x .scroll-element_outer {
  height: 8px;
  top: 2px; }

.scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
  left: 2px;
  width: 8px; }

.scrollbar-outer > .scroll-element .scroll-element_outer {
  overflow: hidden; }

.scrollbar-outer > .scroll-element .scroll-element_track {
  background-color: #eeeeee; }

.scrollbar-outer > .scroll-element .scroll-element_outer,
.scrollbar-outer > .scroll-element .scroll-element_track,
.scrollbar-outer > .scroll-element .scroll-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px; }

.scrollbar-outer > .scroll-element .scroll-bar {
  background-color: #d9d9d9; }

.scrollbar-outer > .scroll-element .scroll-bar:hover {
  background-color: #c2c2c2; }

.scrollbar-outer > .scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191; }

/* scrollbar height/width & offset from container borders */
.scrollbar-outer > .scroll-content.scroll-scrolly_visible {
  left: -12px;
  margin-left: 12px; }

.scrollbar-outer > .scroll-content.scroll-scrollx_visible {
  top: -12px;
  margin-top: 12px; }

.scrollbar-outer > .scroll-element.scroll-x .scroll-bar {
  min-width: 10px; }

.scrollbar-outer > .scroll-element.scroll-y .scroll-bar {
  min-height: 10px; }

/* update scrollbar offset if both scrolls are visible */
.scrollbar-outer > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -14px; }

.scrollbar-outer > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -14px; }

.scrollbar-outer > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -14px; }

.scrollbar-outer > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -14px; }

/*************** SCROLLBAR MAC OS X ***************/
.scrollbar-macosx > .scroll-element,
.scrollbar-macosx > .scroll-element div {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scrollbar-macosx > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scrollbar-macosx > .scroll-element .scroll-element_track {
  display: none; }

.scrollbar-macosx > .scroll-element .scroll-bar {
  background-color: #6C6E71;
  display: block;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }

.scrollbar-macosx:hover > .scroll-element .scroll-bar,
.scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  opacity: 0.7; }

.scrollbar-macosx > .scroll-element.scroll-x {
  bottom: 0px;
  height: 0px;
  left: 0;
  min-width: 100%;
  overflow: visible;
  width: 100%; }

.scrollbar-macosx > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 0px;
  top: 0;
  width: 0px; }

/* scrollbar height/width & offset from container borders */
.scrollbar-macosx > .scroll-element.scroll-x .scroll-bar {
  height: 7px;
  min-width: 10px;
  top: -9px; }

.scrollbar-macosx > .scroll-element.scroll-y .scroll-bar {
  left: -9px;
  min-height: 10px;
  width: 7px; }

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer {
  left: 2px; }

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size {
  left: -4px; }

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer {
  top: 2px; }

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size {
  top: -4px; }

/* update scrollbar offset if both scrolls are visible */
.scrollbar-macosx > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -11px; }

.scrollbar-macosx > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -11px; }

/*************** SCROLLBAR LIGHT ***************/
.scrollbar-light > .scroll-element,
.scrollbar-light > .scroll-element div {
  border: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scrollbar-light > .scroll-element {
  background-color: #ffffff; }

.scrollbar-light > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scrollbar-light > .scroll-element .scroll-element_outer {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }

.scrollbar-light > .scroll-element .scroll-element_size {
  background: #dbdbdb;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2RiZGJkYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlOGU4ZTgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+");
  background: -moz-linear-gradient(left, #dbdbdb 0%, #e8e8e8 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #dbdbdb), color-stop(100%, #e8e8e8));
  background: -webkit-linear-gradient(left, #dbdbdb 0%, #e8e8e8 100%);
  background: -o-linear-gradient(left, #dbdbdb 0%, #e8e8e8 100%);
  background: -ms-linear-gradient(left, #dbdbdb 0%, #e8e8e8 100%);
  background: linear-gradient(to right, #dbdbdb 0%, #e8e8e8 100%);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }

.scrollbar-light > .scroll-element.scroll-x {
  bottom: 0;
  height: 17px;
  left: 0;
  min-width: 100%;
  width: 100%; }

.scrollbar-light > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 0;
  top: 0;
  width: 17px; }

.scrollbar-light > .scroll-element .scroll-bar {
  background: #fefefe;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZlZmVmZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNWY1ZjUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+");
  background: -moz-linear-gradient(left, #fefefe 0%, #f5f5f5 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #fefefe), color-stop(100%, #f5f5f5));
  background: -webkit-linear-gradient(left, #fefefe 0%, #f5f5f5 100%);
  background: -o-linear-gradient(left, #fefefe 0%, #f5f5f5 100%);
  background: -ms-linear-gradient(left, #fefefe 0%, #f5f5f5 100%);
  background: linear-gradient(to right, #fefefe 0%, #f5f5f5 100%);
  border: 1px solid #dbdbdb;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }

/* scrollbar height/width & offset from container borders */
.scrollbar-light > .scroll-content.scroll-scrolly_visible {
  left: -17px;
  margin-left: 17px; }

.scrollbar-light > .scroll-content.scroll-scrollx_visible {
  top: -17px;
  margin-top: 17px; }

.scrollbar-light > .scroll-element.scroll-x .scroll-bar {
  height: 10px;
  min-width: 10px;
  top: 0px; }

.scrollbar-light > .scroll-element.scroll-y .scroll-bar {
  left: 0px;
  min-height: 10px;
  width: 10px; }

.scrollbar-light > .scroll-element.scroll-x .scroll-element_outer {
  height: 12px;
  left: 2px;
  top: 2px; }

.scrollbar-light > .scroll-element.scroll-x .scroll-element_size {
  left: -4px; }

.scrollbar-light > .scroll-element.scroll-y .scroll-element_outer {
  left: 2px;
  top: 2px;
  width: 12px; }

.scrollbar-light > .scroll-element.scroll-y .scroll-element_size {
  top: -4px; }

/* update scrollbar offset if both scrolls are visible */
.scrollbar-light > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -19px; }

.scrollbar-light > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -19px; }

.scrollbar-light > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -19px; }

.scrollbar-light > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -19px; }

/*************** SCROLLBAR RAIL ***************/
.scrollbar-rail > .scroll-element,
.scrollbar-rail > .scroll-element div {
  border: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scrollbar-rail > .scroll-element {
  background-color: #ffffff; }

.scrollbar-rail > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scrollbar-rail > .scroll-element .scroll-element_size {
  background-color: #999;
  background-color: rgba(0, 0, 0, 0.3); }

.scrollbar-rail > .scroll-element .scroll-element_outer:hover .scroll-element_size {
  background-color: #666;
  background-color: rgba(0, 0, 0, 0.5); }

.scrollbar-rail > .scroll-element.scroll-x {
  bottom: 0;
  height: 12px;
  left: 0;
  min-width: 100%;
  padding: 3px 0 2px;
  width: 100%; }

.scrollbar-rail > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  padding: 0 2px 0 3px;
  right: 0;
  top: 0;
  width: 12px; }

.scrollbar-rail > .scroll-element .scroll-bar {
  background-color: #d0b9a0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); }

.scrollbar-rail > .scroll-element .scroll-element_outer:hover .scroll-bar {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6); }

/* scrollbar height/width & offset from container borders */
.scrollbar-rail > .scroll-content.scroll-scrolly_visible {
  left: -17px;
  margin-left: 17px; }

.scrollbar-rail > .scroll-content.scroll-scrollx_visible {
  margin-top: 17px;
  top: -17px; }

.scrollbar-rail > .scroll-element.scroll-x .scroll-bar {
  height: 10px;
  min-width: 10px;
  top: 1px; }

.scrollbar-rail > .scroll-element.scroll-y .scroll-bar {
  left: 1px;
  min-height: 10px;
  width: 10px; }

.scrollbar-rail > .scroll-element.scroll-x .scroll-element_outer {
  height: 15px;
  left: 5px; }

.scrollbar-rail > .scroll-element.scroll-x .scroll-element_size {
  height: 2px;
  left: -10px;
  top: 5px; }

.scrollbar-rail > .scroll-element.scroll-y .scroll-element_outer {
  top: 5px;
  width: 15px; }

.scrollbar-rail > .scroll-element.scroll-y .scroll-element_size {
  left: 5px;
  top: -10px;
  width: 2px; }

/* update scrollbar offset if both scrolls are visible */
.scrollbar-rail > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -25px; }

.scrollbar-rail > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -25px; }

.scrollbar-rail > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -25px; }

.scrollbar-rail > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -25px; }

/*************** SCROLLBAR DYNAMIC ***************/
.scrollbar-dynamic > .scroll-element,
.scrollbar-dynamic > .scroll-element div {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scrollbar-dynamic > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scrollbar-dynamic > .scroll-element.scroll-x {
  bottom: 2px;
  height: 7px;
  left: 0;
  min-width: 100%;
  width: 100%; }

.scrollbar-dynamic > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 2px;
  top: 0;
  width: 7px; }

.scrollbar-dynamic > .scroll-element .scroll-element_outer {
  opacity: 0.3;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px; }

.scrollbar-dynamic > .scroll-element .scroll-element_size {
  background-color: #cccccc;
  opacity: 0;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.scrollbar-dynamic > .scroll-element .scroll-bar {
  background-color: #6c6e71;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

/* scrollbar height/width & offset from container borders */
.scrollbar-dynamic > .scroll-element.scroll-x .scroll-bar {
  bottom: 0;
  height: 7px;
  min-width: 24px;
  top: auto; }

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-bar {
  left: auto;
  min-height: 24px;
  right: 0;
  width: 7px; }

.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_outer {
  bottom: 0;
  top: auto;
  left: 2px;
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  -o-transition: height 0.2s;
  -ms-transition: height 0.2s;
  transition: height 0.2s; }

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_outer {
  left: auto;
  right: 0;
  top: 2px;
  -webkit-transition: width 0.2s;
  -moz-transition: width 0.2s;
  -o-transition: width 0.2s;
  -ms-transition: width 0.2s;
  transition: width 0.2s; }

.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_size {
  left: -4px; }

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_size {
  top: -4px; }

/* update scrollbar offset if both scrolls are visible */
.scrollbar-dynamic > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -11px; }

.scrollbar-dynamic > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -11px; }

/* hover & drag */
.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer {
  overflow: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  opacity: 0.7; }

.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-element_size,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-element_size {
  opacity: 1; }

.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-bar,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-bar {
  height: 100%;
  width: 100%;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px; }

.scrollbar-dynamic > .scroll-element.scroll-x:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-x.scroll-draggable .scroll-element_outer {
  height: 20px;
  min-height: 7px; }

.scrollbar-dynamic > .scroll-element.scroll-y:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-y.scroll-draggable .scroll-element_outer {
  min-width: 7px;
  width: 20px; }

/*************** SCROLLBAR GOOGLE CHROME ***************/
.scrollbar-chrome > .scroll-element,
.scrollbar-chrome > .scroll-element div {
  border: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scrollbar-chrome > .scroll-element {
  background-color: #ffffff; }

.scrollbar-chrome > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scrollbar-chrome > .scroll-element .scroll-element_track {
  background: #f1f1f1;
  border: 1px solid #dbdbdb; }

.scrollbar-chrome > .scroll-element.scroll-x {
  bottom: 0;
  height: 16px;
  left: 0;
  min-width: 100%;
  width: 100%; }

.scrollbar-chrome > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 0;
  top: 0;
  width: 16px; }

.scrollbar-chrome > .scroll-element .scroll-bar {
  background-color: #d9d9d9;
  border: 1px solid #bdbdbd;
  cursor: default;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.scrollbar-chrome > .scroll-element .scroll-bar:hover {
  background-color: #c2c2c2;
  border-color: #a9a9a9; }

.scrollbar-chrome > .scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191;
  border-color: #7e7e7e; }

/* scrollbar height/width & offset from container borders */
.scrollbar-chrome > .scroll-content.scroll-scrolly_visible {
  left: -16px;
  margin-left: 16px; }

.scrollbar-chrome > .scroll-content.scroll-scrollx_visible {
  top: -16px;
  margin-top: 16px; }

.scrollbar-chrome > .scroll-element.scroll-x .scroll-bar {
  height: 8px;
  min-width: 10px;
  top: 3px; }

.scrollbar-chrome > .scroll-element.scroll-y .scroll-bar {
  left: 3px;
  min-height: 10px;
  width: 8px; }

.scrollbar-chrome > .scroll-element.scroll-x .scroll-element_outer {
  border-left: 1px solid #dbdbdb; }

.scrollbar-chrome > .scroll-element.scroll-x .scroll-element_track {
  height: 14px;
  left: -3px; }

.scrollbar-chrome > .scroll-element.scroll-x .scroll-element_size {
  height: 14px;
  left: -4px; }

.scrollbar-chrome > .scroll-element.scroll-y .scroll-element_outer {
  border-top: 1px solid #dbdbdb; }

.scrollbar-chrome > .scroll-element.scroll-y .scroll-element_track {
  top: -3px;
  width: 14px; }

.scrollbar-chrome > .scroll-element.scroll-y .scroll-element_size {
  top: -4px;
  width: 14px; }

/* update scrollbar offset if both scrolls are visible */
.scrollbar-chrome > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -19px; }

.scrollbar-chrome > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -19px; }

.scrollbar-chrome > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -19px; }

.scrollbar-chrome > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -19px; }

/* Plugins rewrite*/
.jq-selectbox {
  background: transparent;
  width: 100%; }
  .jq-selectbox.focused, .jq-selectbox:active {
    box-shadow: none; }
    .jq-selectbox.focused .jq-selectbox__select, .jq-selectbox:active .jq-selectbox__select {
      background: transparent;
      box-shadow: none;
      border: 1px solid #D9F0F7; }

.jq-selectbox__select {
  background: transparent;
  border: 1px solid #D9F0F7;
  border-radius: 3px;
  padding: 0  43px 0 10px;
  box-shadow: none;
  height: 36px; }
  .jq-selectbox__select:hover {
    background: transparent; }

.jq-selectbox__trigger {
  background: #D9F0F7;
  width: 36px;
  border: none; }

.jq-selectbox__trigger-arrow {
  top: 12px;
  height: 8px;
  width: 8px;
  border: none;
  border-bottom: 2px solid #19124A;
  border-right: 2px solid #19124A;
  transform: rotate(45deg); }

.jq-selectbox__select-text {
  height: 100%;
  font-size: 15px;
  text-shadow: none; }
  .jq-selectbox__select-text.placeholder {
    color: #828282; }

.jq-selectbox li:hover {
  background: #afdfee; }

.slider--alt .slick-arrow:after {
  border-color: #CC1B28; }

.slider--alt .slick-arrow.slick-prev {
  left: 23px; }

.slider--alt .slick-arrow.slick-next {
  right: 23px; }

.slick-arrow {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #F2F2F2;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  border: none;
  font-size: 0;
  z-index: 1;
  cursor: pointer; }
  .slick-arrow:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-bottom: 4px solid #CC1B28;
    border-right: 4px solid #CC1B28;
    transform-origin: center; }
  .slick-arrow.slick-prev {
    left: 10px; }
    .slick-arrow.slick-prev:after {
      margin-left: 1px;
      transform: translate(-50%, -50%) rotate(135deg); }
  .slick-arrow.slick-next {
    right: 10px; }
    .slick-arrow.slick-next:after {
      margin-left: -1px;
      transform: translate(-50%, -50%) rotate(-45deg); }
  .slick-arrow.slick-disabled {
    cursor: auto; }
    .slick-arrow.slick-disabled:after {
      border-color: #f6bdc1; }

.slick-dots {
  position: absolute;
  bottom: 7px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 1;
  padding: 0 10px; }
  .slick-dots li {
    margin-right: 10px; }
    .slick-dots li:last-child {
      margin-right: 0; }
    .slick-dots li.slick-active button {
      background: #FFD428; }
  .slick-dots button {
    border: none;
    border-radius: 50%;
    background: #fff;
    font-size: 0;
    padding: 0;
    width: 10px;
    height: 10px;
    cursor: pointer; }

.noUi-horizontal {
  height: 3px;
  border: none;
  background: #f3f3f3;
  margin-bottom: 25px; }

.noUi-connect {
  background: #D9F0F7; }

.noUi-pips-horizontal {
  display: none; }

.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  box-shadow: none;
  background: #19124A;
  border-radius: 3px;
  border: none;
  top: -9px;
  cursor: pointer; }
  .noUi-horizontal .noUi-handle:after {
    display: none; }
  .noUi-horizontal .noUi-handle:before {
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    background: none;
    top: 50%;
    left: 50%; }
  .noUi-horizontal .noUi-handle.noUi-handle-lower:before {
    margin-left: -1px;
    transform: translate(-50%, -50%) rotate(-45deg); }
  .noUi-horizontal .noUi-handle.noUi-handle-upper:before {
    margin-left: 2px;
    transform: translate(-50%, -50%) rotate(135deg); }

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -10px; }

ul.flexMenu-popup {
  z-index: 20;
  background: #fff;
  padding: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  top: 100%;
  margin-top: 5px;
  right: 0; }
  ul.flexMenu-popup li {
    margin-bottom: 5px; }
    ul.flexMenu-popup li:last-child {
      margin-bottom: 0; }
  ul.flexMenu-popup a {
    white-space: nowrap;
    border-bottom: 2px solid transparent; }
    ul.flexMenu-popup a:hover, ul.flexMenu-popup a.active {
      border-color: #CC1B28; }

/******************* SCROLLBAR *******************/
.scroll-element.scroll-y.scroll-scrolly_visible {
  background: #f3f3f3;
  cursor: pointer;
  position: absolute;
  height: 100%;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  border-radius: 4px; }

.scroll-element.scroll-y.scroll-scrolly_visible {
  right: 0; }

.scroll-element.scroll_y.scroll-scrolly_visible div {
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%; }

.scroll-element.scroll-y.scroll-scrolly_visible .scroll-bar {
  background: #C2E9F5;
  z-index: 12;
  height: 46px;
  border-radius: 4px !important;
  cursor: pointer; }

.scroll-element.scroll-x.scroll-scrollx_visible {
  background: #f3f3f3;
  cursor: pointer;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 12px;
  border-radius: 4px; }

.scroll-element.scroll-x.scroll-scrollx_visible {
  bottom: 4px; }

.scroll-element.scroll_x.scroll-scrollx_visible div {
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%; }

.scroll-element.scroll-x.scroll-scrollx_visible .scroll-bar {
  background: #C2E9F5;
  z-index: 12;
  border-radius: 4px !important;
  cursor: pointer; }

.fancybox-close-small svg {
  color: #CC1B28; }

ul.flexMenu-popup {
  padding: 0; }
  ul.flexMenu-popup li {
    margin: 0 0 3px; }
    ul.flexMenu-popup li:last-child {
      margin-bottom: 0; }
    ul.flexMenu-popup li:hover {
      background: #F0FAFD; }
      ul.flexMenu-popup li:hover a {
        color: #0A4798; }
    ul.flexMenu-popup li a {
      display: inline-block;
      padding: 5px 10px; }

/* Blocks */
.icon {
  line-height: 1; }

.logo {
  display: block;
  font-size: 38px;
  font-weight: bold;
  text-transform: uppercase; }
  .logo:first-letter {
    font-size: 48px; }
  .logo span {
    color: #CC1B28; }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 37px;
  border-radius: 10px;
  width: 100%;
  font-size: 15px;
  border: none;
  cursor: pointer;
  padding: 0; }
  .btn:hover {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
  .btn--emp {
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  .btn--banner {
    background: linear-gradient(162.63deg, #042C45 1.77%, #D9F0F7 98.87%);
    border-radius: 6px;
    height: 49px;
    max-width: 152px;
    color: #fff;
    position: absolute;
    bottom: 40px;
    right: 214px; }
    @media (max-width: 1200px) {
      .btn--banner {
        right: 160px;
        bottom: 30px;
        max-width: 130px;
        height: 40px; } }
    @media (max-width: 1023px) {
      .btn--banner {
        right: 100px;
        bottom: 20px; } }
  .btn--accent {
    background: #FFD428;
    color: #19124A; }
    .btn--accent:hover {
      background: #F4C400; }
  .btn--alt {
    color: #fff;
    background: #19124A; }
    .btn--alt:hover {
      background: #0F0B2D; }
  .btn--light {
    background: #D9F0F7; }
    .btn--light:hover {
      background: #C2E9F5; }
  .btn--dzen {
    height: 65px;
    max-width: 225px;
    padding: 0 30px 0 85px;
    justify-content: flex-start;
    background-image: url("../img/dzen.svg");
    background-repeat: no-repeat;
    background-size: 49px;
    background-position: 7px center;
    font-size: 17px;
    line-height: 22px;
    margin-left: auto;
    margin-right: auto; }
    .btn--dzen:hover {
      background-image: url("../img/dzen.svg");
      background-repeat: no-repeat;
      background-size: 49px;
      background-position: 7px center; }
  .btn--green {
    background: #54B331;
    color: #fff; }
  .btn--orange {
    background: #F15922;
    color: #fff; }
  .btn--lime {
    background: #76BA1E;
    color: #fff; }

.burger {
  display: none;
  position: relative;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer; }
  @media (max-width: 1200px) {
    .burger {
      display: flex; } }
  .burger span {
    width: 100%;
    position: relative;
    display: block;
    height: 2px;
    background: #000; }
    .burger span:after, .burger span:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit; }
    .burger span:after {
      top: -7px; }
    .burger span:before {
      bottom: -7px; }

.header {
  margin-bottom: 50px;
  position: relative;
  z-index: 10; }
  @media (max-width: 1200px) {
    .header {
      margin-bottom: 0; } }
  .header__mobile {
    display: none;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 1200px) {
      .header__mobile {
        display: flex;
        padding: 20px 0; } }
    @media (max-width: 1023px) {
      .header__mobile {
        padding: 10px 0; } }
    @media (max-width: 1023px) {
      .header__mobile .logo {
        max-width: 150px; } }
  @media (max-width: 1200px) {
    .header__logo {
      display: none; } }
  @media (max-width: 1200px) {
    .header__nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s; } }
  .header__nav-bg, .header__nav-close {
    display: none; }
  @media (max-width: 1200px) {
    .header__nav-bg {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 101;
      background: rgba(0, 0, 0, 0.4);
      transition: 0.3s; } }
  @media (max-width: 1200px) {
    .header__nav-close {
      display: block;
      position: fixed;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      border: none;
      background: transparent;
      box-shadow: none;
      cursor: pointer;
      z-index: 110; }
      .header__nav-close:after, .header__nav-close:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 2px;
        background: #000; }
      .header__nav-close:after {
        transform: translate(-50%, -50%) rotate(45deg); }
      .header__nav-close:before {
        transform: translate(-50%, -50%) rotate(-45deg); } }
  .header__nav-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 1200px) {
      .header__nav-content {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 320px;
        height: 100vh;
        background: #fff;
        padding: 50px 10px;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 103;
        transform: translateX(100%);
        transition: 0.3s; } }
  .header__items {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center; }
    @media (max-width: 1200px) {
      .header__items {
        margin-left: 0;
        display: block;
        margin-top: 30px;
        order: 3; } }
    .header__items li {
      margin-right: 27px; }
      @media (max-width: 1200px) {
        .header__items li {
          margin-right: 0;
          margin-bottom: 15px; } }
      .header__items li:last-child {
        margin-right: 0; }
        @media (max-width: 1200px) {
          .header__items li:last-child {
            margin-bottom: 0; } }
      .header__items li:nth-child(odd) .icon {
        background: #FFD428;
        color: #19124A; }
    .header__items a {
      display: flex;
      align-items: center;
      font-size: 15px; }
    .header__items .icon {
      margin-right: 6px;
      width: 25px;
      height: 25px;
      background: #19124A;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 13px; }
  .header__search-box {
    max-width: 157px; }
    @media (max-width: 1200px) {
      .header__search-box {
        max-width: 100%;
        margin-bottom: 30px; } }
  .header__btn {
    margin-left: 45px;
    max-width: 95px; }
    @media (max-width: 1200px) {
      .header__btn {
        margin-left: 0; } }

.nav {
  flex: 0 0 100%;
  border-top: 1px solid #D9F0F7;
  padding-top: 19px;
  margin-top: 10px; }
  .nav__list {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1200px) {
      .nav__list {
        display: block; } }
  .nav__item {
    position: relative; }
    @media (max-width: 1200px) {
      .nav__item {
        margin-bottom: 10px; }
        .nav__item.opened > a {
          color: #0A4798; }
          .nav__item.opened > a:after {
            border-color: #0A4798; } }
    .nav__item:hover > a {
      border-bottom: 2px solid red; }
      @media (max-width: 1200px) {
        .nav__item:hover > a {
          border: none; } }
    @media (max-width: 1200px) {
      .nav__item-drop > a {
        display: flex;
        align-items: center; }
        .nav__item-drop > a:after {
          content: '';
          display: block;
          border-bottom: 2px solid #19124A;
          border-right: 2px solid #19124A;
          transform: rotate(45deg);
          width: 10px;
          height: 10px;
          margin-left: 20px; } }
    @media (max-width: 1200px) {
      .nav__item-drop.opened .nav-drop {
        display: block; } }
    .nav__item-drop:hover .nav-drop {
      opacity: 1;
      visibility: visible; }
    .nav__item-drop:hover:after {
      opacity: 1;
      visibility: visible; }
    .nav__item-drop:after {
      opacity: 0;
      visibility: hidden;
      content: '';
      position: absolute;
      top: 100%;
      margin-top: 5px;
      left: 40%;
      border-bottom: 13px solid #D9F0F7;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent; }
      @media (max-width: 1200px) {
        .nav__item-drop:after {
          display: none; } }
    .nav__item-all .nav-drop {
      left: auto;
      right: 0; }
    @media (max-width: 1200px) {
      .nav__item-all > a:after {
        border-color: red; } }
    .nav__item.active > a {
      border-bottom: 2px solid red; }
  .nav__link {
    font-size: 21px; }
    .nav__link--alt {
      color: #CC1B28; }
  .nav-drop {
    position: absolute;
    background: #D9F0F7;
    border-radius: 10px;
    display: flex;
    padding: 15px;
    left: 0;
    top: 100%;
    margin-top: 15px;
    opacity: 0;
    visibility: hidden;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
    @media (max-width: 1200px) {
      .nav-drop {
        position: static;
        display: block;
        padding: 0;
        background-color: transparent;
        visibility: visible;
        opacity: 1;
        display: none;
        margin-bottom: 20px;
        box-shadow: none;
        padding-left: 15px; } }
    .nav-drop:before {
      position: absolute;
      content: '';
      height: 15px;
      left: 0;
      bottom: 100%;
      width: 100%; }
      @media (max-width: 1200px) {
        .nav-drop:before {
          display: none; } }
    .nav-drop__more {
      text-align: right; }
      @media (max-width: 1200px) {
        .nav-drop__more {
          text-align: left; } }
      .nav-drop__more a {
        margin-top: 7px;
        font-size: 16px;
        color: #CC1B28; }
        .nav-drop__more a:hover {
          text-decoration: underline; }
    .nav-drop__col {
      margin-right: 35px;
      min-width: 140px; }
      @media (max-width: 1200px) {
        .nav-drop__col {
          margin-right: 0;
          min-width: 0;
          margin-bottom: 10px; } }
      .nav-drop__col:last-child {
        margin-right: 0; }
    .nav-drop__box {
      margin-bottom: 25px; }
      @media (max-width: 1200px) {
        .nav-drop__box {
          margin-bottom: 10px; } }
      @media (max-width: 1200px) {
        .nav-drop__box.opened .nav-drop__box-title + .nav-drop__box-content {
          display: block; }
        .nav-drop__box.opened .nav-drop__box-title {
          color: #0A4798; }
          .nav-drop__box.opened .nav-drop__box-title:after {
            border-color: #0A4798; } }
      .nav-drop__box:last-child {
        margin-bottom: 0; }
      .nav-drop__box-link {
        margin-bottom: 20px; }
        .nav-drop__box-link a {
          font-size: 19px; }
          .nav-drop__box-link a:hover {
            text-decoration: underline; }
      .nav-drop__box-btn {
        display: inline-flex;
        width: auto;
        padding: 0 22px;
        height: 40px; }
      .nav-drop__box-title {
        font-size: 19px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        white-space: nowrap; }
        @media (max-width: 1200px) {
          .nav-drop__box-title + .nav-drop__box-content {
            display: none; } }
        .nav-drop__box-title:after {
          content: '';
          display: block;
          margin-left: 7px;
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-right: 2px solid;
          transform: rotate(45deg); }
      .nav-drop__box ul li {
        margin-bottom: 4px; }
        .nav-drop__box ul li:last-child {
          margin-bottom: 0; }
      .nav-drop__box ul a {
        font-size: 16px; }
        .nav-drop__box ul a:hover {
          text-decoration: underline; }

.section-title {
  font-size: 1.35294em;
  color: #19124A;
  text-align: center; }
  .section-title a:hover {
    color: #0A4798; }

.section-title--alt {
  font-weight: bold; }

.page-title {
  font-size: 1.47059em;
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 44px; }
  .page-title span {
    color: #0A4798; }

.title {
  font-size: 1.11765em;
  text-align: center;
  font-weight: 400;
  margin-bottom: 45px; }
  .title span {
    display: inline-flex;
    height: 36px;
    padding: 0 25px;
    align-items: center;
    background: #D9F0F7;
    position: relative;
    min-width: 210px;
    justify-content: center; }
    .title span:after {
      content: '';
      position: absolute;
      top: 6px;
      right: 15px;
      height: 100%;
      width: calc(100% - 6px);
      background: #19124A;
      z-index: -1; }
  .title-sm {
    font-size: 0.82353em; }
    .title-sm span {
      min-width: 171px; }

.title-alt {
  font-size: 1.17647em;
  color: #fff;
  font-weight: bold;
  position: relative;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 21px; }
  .title-alt span {
    display: flex;
    align-items: center;
    background: #CC1B28;
    height: 38px;
    padding: 0 22px; }
  .title-alt:after, .title-alt:before {
    content: '';
    position: absolute;
    height: 65px;
    border: 7px solid;
    border-radius: 10px;
    left: 50%;
    z-index: -1; }
  .title-alt:after {
    width: 67px;
    bottom: 0;
    border-color: #19124A;
    transform: translateX(-20%); }
  .title-alt:before {
    width: 78px;
    top: 0;
    border-color: #D9F0F7;
    transform: translateX(-70%); }

.sub-title {
  font-style: italic;
  font-size: 1.05882em;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
  margin-top: -20px; }

.title-relevant {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding-top: 23px; }
  .title-relevant span {
    padding: 10px 15px 10px 52px;
    background: #D9F0F7;
    font-size: 1.17647em;
    font-weight: bold;
    position: relative; }
    .title-relevant span:before {
      content: attr(data-num);
      position: absolute;
      top: -24px;
      left: -15px;
      background-image: url(../img/calendar.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 55px;
      height: 63px;
      color: #fff;
      font-weight: bold;
      font-size: 1.82353em;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-top: 10px; }

.title-box {
  border: 1px solid #C2E9F5;
  padding: 52px 30px 27px;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
  margin-top: 40px; }
  .title-box__alert {
    display: flex;
    justify-content: flex-end;
    color: #0A4798;
    font-size: 15px;
    font-weight: bold; }
  .title-box__text {
    margin-bottom: 30px;
    font-size: 0.88235em; }
  .title-box__btn {
    height: 42px;
    width: auto;
    padding: 0 30px;
    display: inline-flex; }
  .title-box__title {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    white-space: nowrap; }
    @media (max-width: 480px) {
      .title-box__title {
        white-space: normal;
        width: 100%;
        max-width: calc(100% - 40px); }
        .title-box__title span {
          height: auto;
          min-height: 36px; } }
    .title-box__title:after {
      content: '';
      position: absolute;
      height: 100%;
      background: #fff;
      width: calc(100% + 115px);
      left: -65px;
      z-index: -2; }
      @media (max-width: 576px) {
        .title-box__title:after {
          display: none; } }

.footer {
  background: #19124A;
  color: #fff;
  font-size: 15px; }
  .footer__main {
    padding: 50px 44px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      .footer__main {
        display: block;
        padding-left: 0;
        padding-right: 0; } }
  .footer__info {
    font-size: 15px;
    margin-right: 77px;
    width: 300px; }
    @media (max-width: 1023px) {
      .footer__info {
        width: 100%;
        margin-bottom: 30px;
        margin-right: 0; } }
  .footer a {
    color: inherit; }
  .footer__social {
    max-width: 220px; }
    @media (max-width: 1023px) {
      .footer__social {
        max-width: 100%;
        margin-bottom: 30px; } }
  .footer__nav {
    display: flex;
    justify-content: space-between;
    width: 420px; }
    @media (max-width: 1023px) {
      .footer__nav {
        width: 100%;
        margin-bottom: 30px;
        justify-content: flex-start; } }
    @media (max-width: 480px) {
      .footer__nav {
        display: block; } }
    .footer__nav-list {
      margin-right: 20px; }
      @media (max-width: 1023px) {
        .footer__nav-list {
          margin-right: 0;
          width: 100%;
          max-width: 300px; } }
      @media (max-width: 480px) {
        .footer__nav-list {
          max-width: 100%;
          margin-bottom: 10px; } }
      .footer__nav-list:last-child {
        margin-right: 0; }
      .footer__nav-list li {
        margin-bottom: 10px; }
        .footer__nav-list li:last-child {
          margin-bottom: 0; }
      .footer__nav-list a {
        font-weight: bold; }
  .footer__bottom {
    display: flex;
    justify-content: space-between;
    padding: 13px 44px 20px; }
    @media (max-width: 1023px) {
      .footer__bottom {
        display: block;
        padding-left: 0;
        padding-right: 0; } }
  @media (max-width: 1023px) {
    .footer__copy {
      text-align: center; } }
  .footer__logo {
    position: relative;
    top: -15px;
    display: block; }
    @media (max-width: 1023px) {
      .footer__logo {
        display: none; } }
    .footer__logo img {
      display: block;
      max-width: 100%; }

.social ul {
  display: flex;
  flex-wrap: wrap;
  margin-right: -13px; }

.social li {
  margin-right: 13px;
  margin-bottom: 13px; }

.social a {
  display: block;
  color: #fff;
  font-size: 27px;
  width: 42px;
  height: 42px; }
  .social a:hover {
    opacity: .7; }
  .social a .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    width: 100%;
    height: 100%;
    border-radius: 12px; }

.social-icon {
  background-repeat: no-repeat;
  background-size: contain; }
  .social-icon--vk {
    background-image: url("../img/icon-vk.svg"); }
  .social-icon--od {
    background-image: url("../img/icon-od.svg"); }
  .social-icon--facebook {
    background-image: url("../img/icon-facebook.svg"); }
  .social-icon--pinterest {
    background-image: url("../img/icon-pinterest.svg"); }
  .social-icon--twitter {
    background-image: url("../img/icon-twitter.svg"); }
  .social-icon--flag {
    background-image: url("../img/icon-flag.svg"); }
  .social-icon--telegram {
    background-image: url("../img/icon-telegram.svg"); }
  .social-icon--pi {
    background-image: url("../img/icon-pi.svg"); }
  .social-icon--yandex {
    background-image: url("../img/icon-yandex.svg"); }
  .social-icon--google {
    background-image: url("../img/icon-google.svg"); }
  .social-icon--instagram {
    background-image: url("../img/icon-instagram.svg"); }

.social--sm ul {
  margin-right: -6px; }

.social--sm li {
  margin-right: 6px;
  margin-bottom: 6px; }
  @media (max-width: 1200px) {
    .social--sm li {
      margin-right: 4px; } }

.social--sm a {
  width: 30px;
  height: 30px; }

.social--center ul {
  justify-content: center; }

.search-box {
  display: flex;
  height: 37px;
  background: #F0FAFD;
  border-radius: 20px; }
  .search-box input {
    border: none;
    background-color: transparent;
    flex: 1;
    min-width: 0;
    padding: 0 0 0 16px;
    font-size: 15px; }
  .search-box button {
    padding: 0;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    cursor: pointer;
    font-size: 15px; }

.section {
  padding: 20px 0 30px; }
  @media (max-width: 1023px) {
    .section {
      padding: 10px 0 20px; } }
  .section:first-child {
    padding-top: 0; }
  .section:last-child {
    padding-bottom: 0; }
  .section .advert {
    margin-bottom: 0; }
  .section-scroll {
    margin-bottom: 30px; }
  .section__header {
    margin-bottom: 20px; }
  .section-comb {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      .section-comb {
        display: block; } }
    .section-comb .section {
      width: 100%;
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 10px; }
      @media (max-width: 1023px) {
        .section-comb .section {
          max-width: 100%; } }
      .section-comb .section .card--full {
        padding: 0 10px; }
        @media (max-width: 1023px) {
          .section-comb .section .card--full {
            max-width: 100%;
            flex: 0 0 100%; } }
        .section-comb .section .card--full .card__img:before {
          padding-top: 53.5%; }
          @media (max-width: 768px) {
            .section-comb .section .card--full .card__img:before {
              padding-top: 300px; } }
      .section-comb .section .card-alt {
        max-width: 50%;
        flex: 0 0 50%; }
        @media (max-width: 768px) {
          .section-comb .section .card-alt {
            max-width: 100%;
            flex: 0 0 100%; } }

.favorite__btn {
  display: flex;
  align-items: center;
  font-size: 11px; }
  .favorite__btn:hover .favorite__btn-text {
    text-decoration: none; }
  .favorite__btn .icon {
    font-size: 15px;
    margin-right: 8px; }
  .favorite__btn-text {
    text-decoration: underline; }

.card {
  max-width: 33.33333%;
  flex: 0 0 33.33333%;
  padding: 0 10px;
  margin-bottom: 20px; }
  @media (max-width: 1023px) {
    .card {
      max-width: 50%;
      flex: 0 0 50%; } }
  @media (max-width: 768px) {
    .card {
      max-width: 100%;
      flex: 0 0 100%; } }
  .card:nth-child(even) .card__box {
    background: #CC1B28; }
  .card__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .card--big {
    max-width: 50%;
    flex: 0 0 50%; }
    .card--big .card__img:before {
      padding-top: 53.5%; }
      @media (max-width: 1023px) {
        .card--big .card__img:before {
          padding-top: 66.5%; } }
      @media (max-width: 768px) {
        .card--big .card__img:before {
          padding-top: 300px; } }
    .card--big .card__name-text {
      font-size: 1.35294em; }
      @media (max-width: 1200px) {
        .card--big .card__name-text {
          font-size: 1.17647em; } }
      @media (max-width: 1023px) {
        .card--big .card__name-text {
          font-size: 0.94118em; } }
  .card--full {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0; }
  .cards {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px; }
    .cards__slider {
      padding: 0 10px;
      width: 100%;
      max-width: 63.5%;
      flex: 0 0 63.5%;
      margin-bottom: 20px; }
      @media (max-width: 1023px) {
        .cards__slider {
          max-width: 100%;
          flex: 0 0 100%; } }
      @media (max-width: 1023px) {
        .cards__slider .card {
          max-width: 100%;
          flex: 0 0 100%; } }
      .cards__slider .card__name-text {
        font-size: 1.35294em; }
        @media (max-width: 1200px) {
          .cards__slider .card__name-text {
            font-size: 1.17647em; } }
    .cards--auto .card-comp {
      padding-bottom: 10px; }
    .cards--auto .card-comp__name {
      min-height: 0; }
    .cards-more {
      display: flex;
      justify-content: center; }
      .cards-more .btn {
        max-width: 300px; }
    .cards__box {
      flex: 1;
      padding: 0 10px;
      margin-bottom: 20px; }
      @media (max-width: 1023px) {
        .cards__box {
          width: 100%;
          flex: 0 0 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0; } }
      @media (max-width: 768px) {
        .cards__box {
          display: block; } }
      .cards__box .card {
        height: calc(50% - 10px);
        margin-bottom: 20px; }
        @media (max-width: 1023px) {
          .cards__box .card {
            height: auto;
            max-width: calc(50% - 10px);
            flex: 0 0 calc(50% - 10px); } }
        @media (max-width: 768px) {
          .cards__box .card {
            max-width: 100%;
            flex: 0 0 100%; } }
        .cards__box .card:last-child {
          margin-bottom: 0; }
          @media (max-width: 1023px) {
            .cards__box .card:last-child {
              margin-bottom: 20px; } }
        .cards__box .card__content {
          height: 100%; }
        .cards__box .card__img {
          height: 100%; }
          @media (max-width: 1023px) {
            .cards__box .card__img {
              height: auto; } }
          .cards__box .card__img:before {
            display: none; }
            @media (max-width: 1023px) {
              .cards__box .card__img:before {
                display: block; } }
    .cards--inner .card {
      max-width: 50%;
      flex: 0 0 50%; }
      @media (max-width: 768px) {
        .cards--inner .card {
          max-width: 100%;
          flex: 0 0 100%; } }
    .cards--alt {
      display: flex;
      flex-wrap: wrap;
      margin-left: -22px;
      margin-right: -22px; }
      @media (max-width: 1200px) {
        .cards--alt {
          margin-left: -10px;
          margin-right: -10px; } }
  .card__content:hover .card__img:after {
    opacity: .9; }
  .card__img {
    position: relative;
    display: block;
    overflow: hidden; }
    .card__img:before {
      content: '';
      display: block;
      padding-top: 66.5%; }
      @media (max-width: 768px) {
        .card__img:before {
          padding-top: 300px; } }
    .card__img:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
      opacity: .7;
      transition: .3s; }
    .card__img img {
      position: absolute;
      transition: .3s;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .card__status {
    position: absolute;
    top: 25px;
    left: 0;
    font-size: 0.88235em;
    color: #0A4798;
    font-weight: bold;
    padding: 0 20px;
    background: rgba(255, 255, 255, 0.93);
    z-index: 3; }
  .card__info {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 0 20px 15px;
    color: #fff;
    display: block; }
  .card__categories {
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 3; }
    .card__categories li {
      margin-bottom: 5px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      height: 20px;
      padding: 0 10px;
      border-radius: 5px;
      border: 1px solid;
      line-height: 18px;
      padding-bottom: 2px; }
      .card__categories li:hover {
        background: #fff;
        color: #19124A;
        border-color: #fff; }
      .card__categories li:last-child {
        margin-right: 0; }
      .card__categories li a {
        color: inherit; }
  .card__name {
    display: flex;
    font-size: 0.94118em;
    font-weight: bold;
    align-items: flex-end;
    position: relative;
    z-index: 3; }
    .card__name-text {
      flex: 1;
      color: inherit; }
      .card__name-text:hover {
        text-decoration: underline; }
  .card__btn {
    white-space: nowrap;
    width: auto;
    padding: 0 10px;
    margin-left: 20px;
    font-weight: 400; }
    .card__btn-alt {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-left: 0;
      bottom: 100%;
      margin-bottom: 85px;
      z-index: 3; }
      @media (max-width: 1400px) {
        .card__btn-alt {
          margin-bottom: 55px; } }
  .card__box {
    position: absolute;
    left: 0;
    right: 20px;
    bottom: 13px;
    font-weight: bold;
    font-size: 0.88235em;
    color: #fff;
    background: #19124A;
    padding: 6px;
    min-height: 50px;
    display: flex;
    align-items: center; }
  .card-spec {
    font-family: "Roboto Slab", serif; }
    .card-spec .card__content {
      background: linear-gradient(160.37deg, #042C45 43.07%, #D9F0F7 98.94%);
      height: 100%;
      position: relative;
      padding-bottom: 10px; }
      @supports (flex-direction: column) {
        .card-spec .card__content {
          display: flex;
          flex-direction: column;
          justify-content: space-around; } }
    .card-spec--green .card__content {
      background: linear-gradient(156.14deg, #284504 43.07%, #E8F7D9 98.94%); }
    .card-spec__top {
      position: absolute;
      left: 50%;
      top: -29px;
      transform: translateX(-50%);
      padding-top: 50px; }
      .card-spec__top span {
        display: inline-block;
        height: 20px;
        padding: 0 10px;
        color: #B88B58;
        font-size: 0.88235em;
        border: 1px solid;
        border-radius: 5px;
        line-height: 1; }
      .card-spec__top:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 75px;
        height: 41px;
        background-image: url("../img/crown.svg");
        background-repeat: no-repeat;
        background-position: center; }
    .card-spec__pic {
      position: absolute;
      right: 12px;
      bottom: 12px;
      width: 200px;
      height: 200px;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: right bottom; }
      @media (max-width: 1200px) {
        .card-spec__pic {
          width: 70px;
          height: 70px;
          background-size: contain; } }
      @media (max-width: 1023px) {
        .card-spec__pic {
          display: none; } }
    .card-spec__title {
      color: #B88B58;
      font-size: 1.76471em;
      text-align: center;
      padding-top: 70px;
      margin-bottom: 22px;
      position: relative;
      z-index: 1; }
      @media (max-width: 1200px) {
        .card-spec__title {
          padding-top: 50px;
          margin-bottom: 15px; } }
      @media (max-width: 1023px) {
        .card-spec__title {
          font-size: 1.41176em; } }
    .card-spec__text {
      font-size: 1.05882em;
      color: #fff;
      margin-bottom: 35px;
      margin-left: auto;
      margin-right: auto;
      max-width: 350px;
      text-align: center;
      position: relative;
      z-index: 1; }
      @media (max-width: 1200px) {
        .card-spec__text {
          margin-bottom: 15px; } }
      @media (max-width: 1023px) {
        .card-spec__text {
          font-size: 0.94118em; } }
    .card-spec__btn {
      max-width: 212px;
      margin: 0 auto 20px;
      font-family: "PT Sans";
      position: relative;
      z-index: 1; }
      @media (max-width: 1200px) {
        .card-spec__btn {
          margin-bottom: 15px; } }
    .card-spec__bottom {
      color: #042C45;
      font-size: 1.05882em;
      text-align: center;
      position: relative;
      z-index: 1; }
      @media (max-width: 1023px) {
        .card-spec__bottom {
          font-size: 0.94118em; } }
    .card-spec--sm .card-spec__title {
      padding-top: 59px;
      font-size: 1.41176em; }
      @media (max-width: 1200px) {
        .card-spec--sm .card-spec__title {
          padding-top: 50px;
          font-size: 1.29412em;
          margin-bottom: 10px; } }
      @media (max-width: 1023px) {
        .card-spec--sm .card-spec__title {
          font-size: 1.41176em; } }
    .card-spec--sm .card-spec__text {
      font-size: 0.94118em;
      margin-bottom: 23px; }
      @media (max-width: 1200px) {
        .card-spec--sm .card-spec__text {
          margin-bottom: 10px;
          font-size: 0.82353em; } }
      @media (max-width: 1023px) {
        .card-spec--sm .card-spec__text {
          font-size: 0.94118em; } }
    .card-spec--sm .card-spec__btn {
      margin-bottom: 19px;
      height: 35px; }
      @media (max-width: 1200px) {
        .card-spec--sm .card-spec__btn {
          margin-bottom: 10px;
          height: 30px;
          font-size: 0.82353em;
          max-width: 180px; } }
    .card-spec--sm .card-spec__bottom {
      font-size: 0.94118em; }
      @media (max-width: 1200px) {
        .card-spec--sm .card-spec__bottom {
          font-size: 0.82353em; } }
      @media (max-width: 1023px) {
        .card-spec--sm .card-spec__bottom {
          font-size: 0.94118em; } }
    .card-spec--sm .card-spec__pic {
      background-size: 60px auto;
      right: 10px;
      bottom: 28px; }

.slider .card {
  margin-bottom: 0; }

.slider .card__img:before {
  padding-top: 58.9%; }
  @media (max-width: 768px) {
    .slider .card__img:before {
      padding-top: 400px; } }

.slider .card__info {
  padding: 0 20px 20px; }

.card-alt {
  margin-top: -10px;
  padding: 10px; }
  .card-alt:hover {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
  .card-alt .card__info {
    position: static;
    color: #19124A;
    padding: 8px 0 15px; }
  .card-alt .card__img:before {
    padding-top: 58.5%; }
    @media (max-width: 768px) {
      .card-alt .card__img:before {
        padding-top: 300px; } }
  .card-alt .card__categories {
    padding-top: 8px; }
    .card-alt .card__categories li {
      border-color: #19124A;
      color: inherit; }
      .card-alt .card__categories li:hover {
        color: #fff;
        background: #19124A; }
  .card-alt .card__text {
    margin-top: 8px;
    display: block; }
  .card-alt .card__btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
    margin-left: 0;
    z-index: 3; }

.services-box {
  background: linear-gradient(100.95deg, #8CAEB8 11.04%, rgba(240, 250, 253, 0.14) 98.93%);
  padding: 25px 0 35px 30px;
  display: flex;
  align-items: flex-start; }
  @media (max-width: 1023px) {
    .services-box {
      flex-wrap: wrap; } }
  @media (max-width: 768px) {
    .services-box {
      display: block;
      text-align: center;
      padding: 20px; } }
  .services-box__nav {
    width: 33.33333%;
    padding-right: 30px; }
    @media (max-width: 1023px) {
      .services-box__nav {
        width: 100%;
        padding-right: 0;
        margin-bottom: 30px; } }
    .services-box__nav ul {
      column-count: 2;
      margin-bottom: 30px; }
      @media (max-width: 480px) {
        .services-box__nav ul {
          column-count: 1; } }
      .services-box__nav ul li {
        margin-bottom: 6px;
        padding-right: 10px; }
        @media (max-width: 480px) {
          .services-box__nav ul li {
            text-align: center; } }
      .services-box__nav ul a {
        font-weight: bold;
        color: #19124A; }
  .services-box__item {
    width: 33.33333%;
    padding-right: 30px;
    display: flex; }
    @media (max-width: 1023px) {
      .services-box__item {
        width: 50%; } }
    @media (max-width: 768px) {
      .services-box__item {
        width: 100%;
        padding-right: 0;
        margin-bottom: 30px; }
        .services-box__item:last-child {
          margin-bottom: 0; } }
    @media (max-width: 480px) {
      .services-box__item {
        display: block; } }
    .services-box__item-img {
      width: 150px;
      min-width: 150px;
      margin-right: 20px; }
      @media (max-width: 480px) {
        .services-box__item-img {
          min-width: 0;
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
          display: flex;
          justify-content: center; } }
      .services-box__item-img img {
        display: block;
        width: 100%; }
        @media (max-width: 480px) {
          .services-box__item-img img {
            max-width: 100%;
            width: auto; } }
    .services-box__item-info {
      padding-top: 6px;
      text-align: center; }
      @media (max-width: 768px) {
        .services-box__item-info {
          width: 100%;
          padding: 0 15px; } }
    .services-box__item-name {
      color: #0A4798;
      margin-bottom: 7px;
      font-weight: bold; }
    .services-box__item-theme {
      font-weight: bold;
      color: #19124A;
      margin-bottom: 9px; }
    .services-box__item-text {
      margin-bottom: 23px; }
    .services-box__item-btn {
      max-width: 134px;
      margin-left: auto;
      margin-right: auto; }
  .services-box__btn {
    height: 48px;
    width: auto;
    display: inline-flex;
    max-width: 100%;
    padding: 0 23px 0 55px;
    justify-content: flex-start;
    background-image: url("../img/dzen.svg");
    background-repeat: no-repeat;
    background-position: 7px center; }

.banner {
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center; }
  .banner-tmp {
    position: relative;
    width: 100%;
    max-width: 525px;
    margin: 0 auto;
    padding: 40px 0 35px;
    text-align: center; }
    @media (max-width: 1023px) {
      .banner-tmp {
        background: #F2F2F2;
        max-width: 100%;
        padding: 20px 35px; } }
    @media (max-width: 768px) {
      .banner-tmp {
        padding-bottom: 80px;
        padding-left: 15px;
        padding-right: 15px; } }
    @media (max-width: 1023px) {
      .banner-tmp ~ .btn {
        right: 30px; } }
    @media (max-width: 768px) {
      .banner-tmp ~ .btn {
        position: relative;
        bottom: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: -60px; } }
    .banner-tmp__title {
      font-size: 20px;
      margin-bottom: 19px;
      color: #042C45; }
    .banner-tmp__name {
      font-size: 24px;
      font-weight: bold;
      color: #A5A73C;
      margin-bottom: 20px; }
    .banner-tmp__text {
      font-weight: bold;
      font-size: 16px;
      color: #042C45; }
      .banner-tmp__text p {
        margin-bottom: 8px; }
        .banner-tmp__text p:last-child {
          margin-bottom: 0; }
  .banner-pic {
    position: absolute;
    bottom: 37px;
    left: 170px;
    width: 92px;
    height: 90px; }
    @media (max-width: 1023px) {
      .banner-pic {
        display: none; } }
    .banner-pic img {
      display: block;
      max-width: 100%;
      max-height: 100%; }
  .banner-stat {
    font-weight: bold;
    top: 25px;
    right: 50px;
    position: absolute;
    color: #B88B58;
    font-size: 24px; }
    @media (max-width: 1023px) {
      .banner-stat {
        display: none; } }
    .banner-stat span {
      font-size: 39px; }
  .banner__btn-pic {
    position: absolute;
    right: 30px;
    bottom: 15px;
    max-width: 134px;
    height: 40px;
    z-index: 1; }
  .banner-interesting {
    margin: 30px 0;
    display: flex;
    background: linear-gradient(#697A0A, #E2E25A);
    position: relative;
    width: calc(100% - 30px); }
    .banner-interesting:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      height: 100%;
      width: 30px;
      background: linear-gradient(#697A0A, #E2E25A); }
      @supports (clip-path: polygon(0 0, 100% 100%)) {
        .banner-interesting:after {
          clip-path: polygon(0 0, 100% 50%, 0 100%); } }
    .banner-interesting__img {
      overflow: hidden;
      width: 170px;
      min-width: 170px;
      margin-right: 20px; }
      @media (max-width: 768px) {
        .banner-interesting__img {
          display: none; } }
      .banner-interesting__img img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .banner-interesting__info {
      padding: 16px 30px 10px 0; }
      @media (max-width: 768px) {
        .banner-interesting__info {
          padding-left: 30px; } }
      @supports (flex-direction: column) {
        .banner-interesting__info {
          display: flex;
          flex-direction: column; } }
    .banner-interesting__title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 9px;
      color: #fff; }
    .banner-interesting__action {
      display: flex;
      align-items: flex-end;
      justify-content: space-between; }
      @supports (flex-direction: column) {
        .banner-interesting__action {
          margin-top: auto; } }
    .banner-interesting__status {
      font-size: 19px;
      font-weight: bold;
      color: #042C45; }
    .banner-interesting__btn {
      width: auto;
      padding: 0 30px; }
  .banner-sort img {
    display: block;
    width: 100%; }
  .banner-stats {
    padding: 19px 23px 23px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center; }
    .banner-stats__title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 41px; }
    .banner-stats__btn {
      height: 40px;
      width: auto;
      display: inline-flex;
      padding: 0 30px; }
  .banner--form {
    background: linear-gradient(95.67deg, #344A30 1.85%, #84A289 87.07%);
    display: flex;
    color: #fff;
    margin-bottom: 30px; }
    @media (max-width: 1023px) {
      .banner--form {
        display: block; } }
  .banner__cont {
    flex: 1;
    padding: 44px 66px 30px 50px; }
    @media (max-width: 768px) {
      .banner__cont {
        padding: 20px; } }
  .banner__title {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 26px; }
  .banner__text {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 576px) {
      .banner__text {
        display: block; } }
    .banner__text-main {
      flex: 1;
      font-size: 19px;
      line-height: 25px; }
      @media (max-width: 576px) {
        .banner__text-main {
          margin-bottom: 20px; } }
  .banner__btn {
    max-width: 134px; }
  .banner__img {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 1023px) {
      .banner__img {
        display: none; } }
    .banner__img img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: center; }
  .banner--blog {
    display: flex;
    background: #F0FAFD;
    padding: 20px 30px 0; }
    @media (max-width: 1023px) {
      .banner--blog {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        padding: 20px 10px 0; } }
    .banner--blog .cards {
      flex: 1; }
      @media (max-width: 1023px) {
        .banner--blog .cards {
          order: 2; } }
    .banner--blog .card {
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 0; }
      @media (max-width: 1023px) {
        .banner--blog .card {
          max-width: 100%;
          flex: 0 0 100%; } }
      .banner--blog .card:hover {
        box-shadow: none; }
    .banner--blog .card__img:before {
      padding-top: 82.7%; }
      @media (max-width: 1023px) {
        .banner--blog .card__img:before {
          padding-top: 60%; } }
    .banner--blog .card__box {
      justify-content: center;
      text-align: center;
      left: 8px;
      right: 8px; }
  .banner-blog {
    margin-left: 43px;
    width: 225px;
    min-width: 225px;
    text-align: center; }
    @media (max-width: 1023px) {
      .banner-blog {
        width: 100%;
        min-width: 0;
        margin-left: 0;
        order: 1; } }
    @supports (flex-direction: column) {
      .banner-blog {
        flex-direction: column;
        display: flex;
        padding-bottom: 30px; } }
    .banner-blog__top {
      margin-top: -45px;
      background-image: url("../img/banner-blog__bg.svg");
      background-repeat: no-repeat;
      background-position: center top;
      height: 97px;
      position: relative;
      margin-bottom: 28px; }
      .banner-blog__top-text {
        position: absolute;
        left: 50%;
        top: 15px;
        transform: translateX(-50%);
        padding: 0 20px;
        color: #fff;
        height: 46px;
        font-weight: bold;
        display: flex;
        align-items: center;
        font-size: 20px;
        background: #19124A;
        white-space: nowrap; }
        @supports (clip-path: polygon(0 100%, 100% 0, 100% 100%)) {
          .banner-blog__top-text {
            clip-path: polygon(0 5px, 100% 0, 100% 100%, 0 40px); } }
    .banner-blog__title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px; }
    .banner-blog__text {
      font-size: 15px;
      margin-bottom: 20px; }
    .banner-blog__btn {
      background: #C2E9F5;
      border-radius: 10px;
      max-width: 188px;
      margin-left: auto;
      margin-right: auto; }
      @supports (flex-direction: column) {
        .banner-blog__btn {
          margin-top: auto; } }

.advert {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #D9F0F7;
  color: #19124A;
  margin: 0 auto;
  text-decoration: underline;
  font-weight: bold;
  width: 100%; }
  .advert--fixed {
    position: relative; }
  .advert-page {
    margin-bottom: 35px; }
  .advert__text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }

.sticky {
  position: fixed;
  z-index: 20; }

.stop {
  position: relative;
  z-index: 20; }

.calendar-box {
  padding: 13px 20px 28px;
  position: relative; }
  .calendar-box__top {
    display: flex;
    padding-left: 38px;
    margin-bottom: 24px; }
    @media (max-width: 1200px) {
      .calendar-box__top {
        margin-bottom: 15px; } }
  .calendar-box__title {
    margin-top: -21px;
    padding: 0 37px 0 41px;
    background: #19124A;
    color: #fff;
    height: 62px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    margin-right: auto; }
    @media (max-width: 1200px) {
      .calendar-box__title {
        height: 55px;
        font-size: 16px;
        line-height: 19px;
        padding: 0 30px 0 30px; } }
    @supports (clip-path: polygon(0 0, 100% 100%)) {
      .calendar-box__title {
        clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%); } }
  .calendar-box__pic {
    margin-right: 16px;
    width: 44px; }
    @media (max-width: 1200px) {
      .calendar-box__pic {
        margin-left: 8px;
        margin-right: 0;
        width: 35px; } }
    .calendar-box__pic:last-child {
      margin-right: 0; }
    .calendar-box__pic img {
      max-width: 100%; }
  .calendar-box__num {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 53px;
    line-height: 62px;
    font-weight: bold;
    color: #fff;
    display: flex;
    z-index: 1; }
    @media (max-width: 1200px) {
      .calendar-box__num {
        font-size: 48px;
        line-height: 55px; } }
    .calendar-box__num span {
      display: block;
      width: 40px;
      height: 62px;
      text-align: center;
      background: #CC1B28;
      margin-right: 3px; }
      @media (max-width: 1200px) {
        .calendar-box__num span {
          height: 55px;
          width: 36px; } }
  .calendar-box__date {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    font-size: 24px; }
    .calendar-box__date-month {
      font-weight: bold;
      margin-right: 30px; }
    .calendar-box__date-day {
      font-weight: 400; }
  .calendar-box__info {
    margin-bottom: 16px; }
    .calendar-box__info li {
      margin-bottom: 8px; }
      .calendar-box__info li:last-child {
        margin-bottom: 0; }
      .calendar-box__info li .calendar-box__date-day {
        display: inline-block;
        font-weight: bold;
        margin-right: 20px; }
    .calendar-box__info-line {
      display: flex;
      justify-content: space-between; }
  .calendar-box__text {
    margin-bottom: 3px;
    line-height: 22px; }
    .calendar-box__text p {
      margin-bottom: 0; }
    .calendar-box__text-name {
      float: left;
      display: inline-block;
      margin-right: 3px;
      color: #0A4798;
      font-weight: bold; }
  .calendar-box__more {
    text-align: right; }
    .calendar-box__more a {
      font-size: 15px;
      text-decoration: underline;
      font-weight: bold; }
      .calendar-box__more a:hover {
        text-decoration: none; }
    .calendar-box__more .btn {
      text-decoration: none;
      display: inline-flex;
      width: auto;
      padding: 0 10px;
      font-weight: 400; }
  .calendar-box__nav {
    display: flex;
    justify-content: center;
    margin-top: 20px; }
    .calendar-box__nav li {
      padding-right: 10px;
      margin-right: 10px;
      border-right: 1px solid #0A4798;
      line-height: 1; }
      .calendar-box__nav li:last-child {
        margin-right: 0;
        padding-right: 0;
        border-right: none; }
    .calendar-box__nav a {
      font-size: 15px;
      color: #0A4798;
      font-weight: bold; }

.calendar-block {
  margin-bottom: 30px; }
  .calendar-block__action {
    display: flex;
    justify-content: center;
    margin-top: 5px; }
    .calendar-block__action .btn {
      max-width: 180px; }
  .calendar-block__title {
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px; }
  .calendar-block__btn {
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: calc(100% - 40px); }
  .calendar-block__content {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 576px) {
      .calendar-block__content {
        display: block; } }
  .calendar-block__item {
    margin-bottom: 15px;
    font-size: 18px; }
    .calendar-block__item:last-child {
      margin-bottom: 0; }
    .calendar-block__item-title {
      display: inline;
      color: #0A4798;
      font-weight: bold; }
    .calendar-block__item-text {
      display: inline; }
  .calendar-block__card {
    min-width: 261px;
    width: 261px;
    margin-right: 20px; }
    @media (max-width: 576px) {
      .calendar-block__card {
        min-width: 0;
        margin-right: 0;
        width: 100%;
        margin-bottom: 20px; } }
    .calendar-block__card .calendar-card {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 15px; }
      .calendar-block__card .calendar-card.today:before {
        display: none; }
  .calendar-block--half .calendar-block__content {
    max-height: 225px;
    overflow: hidden;
    position: relative;
    padding-top: 25px;
    margin-top: -25px; }
    @media (max-width: 576px) {
      .calendar-block--half .calendar-block__content {
        max-height: 450px; } }
    .calendar-block--half .calendar-block__content:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(179.48deg, rgba(255, 255, 255, 0) 0, #FFFFFF 80%);
      height: 50px; }
    .calendar-block--half .calendar-block__content .calendar-card.today:before {
      display: block; }

.page-product__box {
  display: none; }
  .page-product__box.active {
    display: block; }
  .page-product__box-cards {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    align-items: center; }
  .page-product__box-card {
    width: 100%;
    max-width: calc(20% - 24px);
    flex: 0 0 calc(20% - 24px);
    margin: 0 12px;
    margin-bottom: 10px;
    display: block; }
    @media (max-width: 1200px) {
      .page-product__box-card {
        max-width: calc(25% - 24px);
        flex: 0 0 calc(25% - 24px); } }
    @media (max-width: 768px) {
      .page-product__box-card {
        max-width: calc(33.3333% - 24px);
        flex: 0 0 calc(33.3333% - 24px); } }
    @media (max-width: 576px) {
      .page-product__box-card {
        max-width: calc(50% - 24px);
        flex: 0 0 calc(50% - 24px); } }
    .page-product__box-card:hover {
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
    .page-product__box-card img {
      display: block;
      width: 100%;
      max-width: 100%; }
  .page-product__box-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 40px; }
    .page-product__box-items li {
      max-width: 20%;
      flex: 0 0 20%;
      padding: 0 10px;
      margin-bottom: 10px; }
      @media (max-width: 1200px) {
        .page-product__box-items li {
          max-width: 25%;
          flex: 0 0 25%; } }
      @media (max-width: 768px) {
        .page-product__box-items li {
          max-width: 33.33333%;
          flex: 0 0 33.33333%; } }
      @media (max-width: 576px) {
        .page-product__box-items li {
          max-width: 50%;
          flex: 0 0 50%; } }
    .page-product__box-items a {
      text-decoration: underline;
      color: #0A4798; }
      .page-product__box-items a:hover {
        text-decoration: none; }

.page-product__controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 20px; }

.page-product__btn {
  margin: 0 22px;
  display: block;
  border-bottom: 2px solid transparent;
  font-size: 1.05882em;
  color: #19124A;
  font-weight: bold;
  margin-bottom: 10px; }
  .page-product__btn:hover, .page-product__btn.active {
    border-bottom-color: #CC1B28;
    color: #0A4798; }

.page__action {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px; }
  .page__action:last-child {
    margin-bottom: 0; }
  .page__action .btn {
    height: 40px;
    width: auto;
    padding: 0 30px; }

.page-calc {
  border-radius: 8px;
  margin-bottom: 50px;
  padding: 10px;
  border: 1px solid #FFD428;
  overflow: hidden; }
  .page-calc__title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 2px;
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    height: 40px; }
  .page-calc__item {
    margin-bottom: 2px;
    display: flex;
    align-items: center; }
    .page-calc__item-name {
      background: #ddd;
      margin-right: 2px;
      width: 300px;
      min-width: 300px;
      height: 40px;
      padding: 0 10px;
      display: flex;
      align-items: center; }
    .page-calc__item-val {
      display: flex;
      align-items: center;
      flex: 1;
      background: #ddd;
      height: 40px;
      padding: 0 10px; }
      .page-calc__item-val input {
        height: 30px;
        width: 300px;
        padding: 0 10px; }
  .page-calc__action {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    background: #ddd; }
    .page-calc__action .btn {
      display: inline-flex;
      width: auto;
      padding: 0 30px;
      height: 30px; }

.page-combox {
  display: flex;
  margin-bottom: 50px; }
  @media (max-width: 768px) {
    .page-combox {
      display: block; } }
  .page-combox__img {
    width: 260px;
    min-width: 260px;
    margin-right: 20px; }
    @media (max-width: 768px) {
      .page-combox__img {
        margin-right: 0;
        margin-bottom: 20px;
        min-width: 0;
        width: 100%;
        max-width: 400px;
        max-height: 300px;
        overflow: hidden; } }
    .page-combox__img img {
      display: block;
      width: 100%; }
      @media (max-width: 768px) {
        .page-combox__img img {
          max-height: 100%; } }

.page-experts {
  background: #F0FAFD;
  padding: 30px 20px 70px; }
  @media (max-width: 768px) {
    .page-experts {
      padding-bottom: 40px; } }
  .page-experts__content {
    position: relative; }
    .page-experts__content--cards {
      min-height: 380px; }
      @media (max-width: 768px) {
        .page-experts__content--cards {
          min-height: 0;
          display: flex;
          flex-wrap: wrap;
          margin-right: -20px; } }
  .page-experts__cols {
    display: flex;
    padding: 0 40px;
    margin-left: -65px;
    margin-right: -65px; }
    @media (max-width: 768px) {
      .page-experts__cols {
        display: block;
        padding: 0 20px; } }
  .page-experts__col {
    padding: 0 65px;
    width: 100%;
    max-width: 50%;
    flex: 0 0 50%;
    text-align: center;
    margin-top: 50px; }
    @media (max-width: 768px) {
      .page-experts__col {
        max-width: 100%; } }
    .page-experts__col-text {
      margin-bottom: 45px;
      font-size: 15px;
      max-width: 276px;
      margin-left: auto;
      margin-right: auto; }
    .page-experts__col-btn {
      max-width: 185px;
      margin-left: auto;
      margin-right: auto; }
    .page-experts__col-title {
      font-size: 19px;
      margin-bottom: 40px;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto; }
    .page-experts__col-img img {
      display: block;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto; }
  .page-experts__price {
    display: inline-flex;
    position: relative;
    font-size: 30px;
    font-weight: bold;
    color: #CC1B28;
    margin-bottom: 45px;
    padding-top: 20px; }
    .page-experts__price span {
      position: absolute;
      color: #19124A; }
      .page-experts__price span:first-child {
        right: 100%;
        margin-right: 13px;
        font-size: 25px;
        bottom: -5px; }
      .page-experts__price span:last-child {
        font-weight: 400;
        right: -5px;
        top: 0;
        top: -3px;
        font-size: 18px; }
  .page-experts-card {
    position: absolute;
    background: #fff;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    padding: 20px 13px 15px;
    text-align: center;
    width: 24.7%;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s; }
    @media (max-width: 1200px) {
      .page-experts-card {
        padding-left: 10px;
        padding-right: 10px; } }
    @media (max-width: 768px) {
      .page-experts-card {
        position: static;
        transform: translate(0);
        width: 100%;
        max-width: calc(50% - 20px);
        flex: 0 0 calc(50% - 20px);
        margin-right: 20px;
        margin-bottom: 20px; }
        @supports (flex-direction: column) {
          .page-experts-card {
            display: flex;
            flex-direction: column; } } }
    @media (max-width: 576px) {
      .page-experts-card {
        max-width: calc(100% - 20px);
        flex: 0 0 calc(100% - 20px); } }
    .page-experts-card:hover {
      z-index: 999;
      transform: translateY(-50%) scale(1.1);
      box-shadow: 2px 2px 17px rgba(0, 0, 0, 0.25); }
      @media (max-width: 768px) {
        .page-experts-card:hover {
          transform: translateY(0) scale(1.1); } }
    .page-experts-card:nth-child(1) {
      left: 0; }
    .page-experts-card:nth-child(2) {
      left: 16.45%; }
    .page-experts-card:nth-child(3) {
      left: 50%;
      transform: translate(-50%, -50%); }
      @media (max-width: 768px) {
        .page-experts-card:nth-child(3) {
          transform: translate(0); } }
    .page-experts-card:nth-child(4) {
      right: 16.45%; }
    .page-experts-card:nth-child(5) {
      right: 0; }
    .page-experts-card--sm {
      z-index: 2;
      width: 26.2%; }
      .page-experts-card--sm .page-experts-card__avatar {
        width: 72px;
        height: 72px; }
        @media (max-width: 768px) {
          .page-experts-card--sm .page-experts-card__avatar {
            width: 80px;
            height: 80px; } }
      .page-experts-card--sm .page-experts-card__name {
        font-size: 1.05882em;
        line-height: 1.35294em;
        margin-bottom: 25px;
        padding-bottom: 2px; }
        @media (max-width: 768px) {
          .page-experts-card--sm .page-experts-card__name {
            font-size: 1.23529em;
            margin-bottom: 30px; } }
      .page-experts-card--sm .page-experts-card__title {
        font-size: 0.76471em;
        margin-bottom: 18px; }
        @media (max-width: 768px) {
          .page-experts-card--sm .page-experts-card__title {
            margin-bottom: 25px;
            font-size: 0.88235em; } }
      .page-experts-card--sm .page-experts-card__text {
        min-height: 76px;
        font-size: 0.88235em;
        margin-bottom: 24px; }
        @media (max-width: 768px) {
          .page-experts-card--sm .page-experts-card__text {
            min-height: 0;
            font-size: 1em;
            margin-bottom: 30px; } }
      .page-experts-card--sm .page-experts-card__action {
        font-size: 0.58824em; }
        @media (max-width: 768px) {
          .page-experts-card--sm .page-experts-card__action {
            font-size: 0.64706em; } }
    .page-experts-card--big {
      width: 29.3%;
      z-index: 3; }
      .page-experts-card--big .page-experts-card__avatar {
        width: 80px;
        height: 80px;
        margin-bottom: 12px; }
      .page-experts-card--big .page-experts-card__name {
        font-size: 1.23529em;
        margin-bottom: 30px;
        border-bottom: 10px;
        padding-bottom: 3px; }
      .page-experts-card--big .page-experts-card__title {
        font-size: 0.88235em;
        margin-bottom: 25px; }
      .page-experts-card--big .page-experts-card__text {
        font-size: 1em;
        margin-bottom: 30px;
        min-height: 85px; }
        @media (max-width: 768px) {
          .page-experts-card--big .page-experts-card__text {
            min-height: 0; } }
      .page-experts-card--big .page-experts-card__action {
        font-size: 0.64706em; }
    .page-experts-card__avatar {
      border-radius: 50%;
      overflow: hidden;
      width: 67px;
      height: 67px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px; }
      @media (max-width: 768px) {
        .page-experts-card__avatar {
          width: 80px;
          height: 80px; } }
      .page-experts-card__avatar img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        display: block; }
    .page-experts-card__name {
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 0.94118em;
      margin-bottom: 23px;
      position: relative; }
      @media (max-width: 768px) {
        .page-experts-card__name {
          font-size: 1.23529em;
          margin-bottom: 30px; } }
      .page-experts-card__name:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px solid #D9F0F7;
        width: 80%; }
    .page-experts-card__title {
      text-align: center;
      font-size: 0.70588em;
      font-weight: bold;
      margin-bottom: 20px; }
      @media (max-width: 768px) {
        .page-experts-card__title {
          margin-bottom: 25px;
          font-size: 0.88235em; } }
    .page-experts-card__text {
      min-height: 72px;
      font-size: 0.76471em;
      line-height: 17px;
      margin-bottom: 17px; }
      @media (max-width: 768px) {
        .page-experts-card__text {
          min-height: 0;
          font-size: 1em;
          margin-bottom: 30px; } }
    .page-experts-card__action {
      display: flex;
      justify-content: space-between;
      font-size: 0.52941em; }
      @media (max-width: 768px) {
        .page-experts-card__action {
          font-size: 0.64706em; }
          @supports (flex-direction: column) {
            .page-experts-card__action {
              margin-top: auto; } } }
    .page-experts-card__stat span {
      color: #CC1B28; }

.page-cat {
  margin: 0 -12px; }
  .page-cat li {
    display: inline-block;
    margin-bottom: 14px;
    padding: 0 12px; }
  .page-cat a {
    border-bottom: 3px solid transparent; }
    .page-cat a:hover, .page-cat a.active {
      color: #19124A;
      border-bottom-color: #CC1B28; }
    .page-cat a.active {
      font-weight: bold; }

.page-cont {
  border: 1px solid #D9F0F7;
  padding: 20px; }
  .page-cont .text {
    font-style: italic; }
    .page-cont .text p a {
      color: #0A4798; }
      .page-cont .text p a:hover {
        text-decoration: underline; }
    .page-cont .text ul {
      padding-left: 0; }

.page-block {
  margin-bottom: 30px; }
  .page-block:last-child {
    margin-bottom: 0; }
  .page-block__title {
    text-align: center;
    height: 60px;
    font-size: 1.23529em;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D9F0F7; }
    .page-block__title:hover {
      background: #afdfee; }
  .page-block__content {
    background: #F0FAFD;
    padding: 20px 25px 25px; }
    .page-block__content li {
      margin-bottom: 10px; }
      .page-block__content li:last-child {
        margin-bottom: 0; }
    .page-block__content a {
      font-size: 1.11765em; }
      .page-block__content a:hover {
        text-decoration: underline; }

.page-col {
  max-width: 33.33333%;
  flex: 0 0 33.33333%;
  padding: 0 10px; }
  @media (max-width: 768px) {
    .page-col {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 30px; }
      .page-col:last-child {
        margin-bottom: 0; } }
  .page-cols {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px; }

.page-need {
  margin: 30px 0;
  border: 2px solid #C2E9F5;
  padding: 20px 20px 32px; }
  .page-need__title {
    margin-bottom: 35px; }
  .page-need__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end; }
  .page-need__item {
    margin-right: 20px; }
    .page-need__item:last-child {
      margin-right: 0; }

.page-slider {
  padding: 0 66px; }
  @media (max-width: 768px) {
    .page-slider {
      padding: 0; } }
  .page-slider__item {
    position: relative; }
    .page-slider__item-img {
      display: block;
      position: relative;
      overflow: hidden; }
      .page-slider__item-img:before {
        content: '';
        display: block;
        padding-top: 67.3%; }
      .page-slider__item-img img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .page-slider__item-title {
      position: absolute;
      left: 0;
      bottom: 76px;
      background: rgba(0, 0, 0, 0.5);
      padding: 14px;
      font-size: 1.05882em;
      font-style: italic;
      font-weight: bold;
      color: #fff; }
      @media (max-width: 1023px) {
        .page-slider__item-title {
          position: static;
          background-color: transparent;
          color: #000;
          font-size: 14px;
          padding: 10px 0; } }
      @media (max-width: 576px) {
        .page-slider__item-title {
          display: none; } }
      @media (max-width: 480px) {
        .page-slider__item-title {
          bottom: 60px; } }
  .page-slider__dots {
    flex: 1;
    width: 100%; }
    .page-slider__dots-wrap {
      position: relative;
      display: flex;
      margin-top: 15px;
      overflow: hidden; }
    .page-slider__dots-link {
      background: #D9F0F7;
      border-radius: 3px;
      width: 70px;
      min-width: 70px;
      height: 50px;
      display: flex !important;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      font-size: 1.47059em;
      cursor: pointer;
      transition: .3s;
      margin-right: 15px; }
      .page-slider__dots-link span {
        flex: 0  0 100%;
        font-size: 0.47059em;
        text-align: center; }
      .page-slider__dots-link .icon {
        margin-bottom: -10px; }
      .page-slider__dots-link:hover {
        background: #afdfee;
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
    .page-slider__dots-item {
      height: 50px;
      margin: 0 8px 15px;
      position: relative;
      overflow: hidden;
      cursor: pointer; }
      .page-slider__dots-item img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
      .page-slider__dots-item.slick-current {
        position: relative; }
        .page-slider__dots-item.slick-current:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 2px solid red; }
    .page-slider__dots .slick-track {
      margin-left: 0; }

.page-files {
  display: flex;
  flex-wrap: wrap;
  margin-right: -17px; }
  .page-files__info {
    padding-left: 34px;
    font-size: 0.88235em;
    color: #0A4798;
    font-style: italic; }
  .page-files__item {
    width: 100%;
    max-width: calc(20% - 17px);
    flex: 0 0 calc(20% - 17px);
    margin-right: 17px;
    margin-bottom: 17px; }
    @media (max-width: 768px) {
      .page-files__item {
        max-width: calc(33.3333% - 17px);
        flex: 0 0 calc(33.3333% - 17px); } }
    @media (max-width: 480px) {
      .page-files__item {
        max-width: calc(50% - 17px);
        flex: 0 0 calc(50% - 17px); } }
    .page-files__item a {
      display: block;
      position: relative;
      overflow: hidden; }
      .page-files__item a:before {
        content: '';
        display: block;
        padding-top: 70%; }
    .page-files__item img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    .page-files__item-add {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-size: 0.88235em;
      font-style: italic; }
      @media (max-width: 1200px) {
        .page-files__item-add {
          font-size: 0.76471em; } }
      .page-files__item-add-wrap {
        background: #D9F0F7;
        border-radius: 8px;
        transition: .3s; }
        .page-files__item-add-wrap:hover {
          background: #afdfee;
          box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
      .page-files__item-add span {
        display: block; }
      .page-files__item-add .icon {
        font-size: 3.17647em; }
        @media (max-width: 1200px) {
          .page-files__item-add .icon {
            font-size: 2.82353em; } }

.page-video {
  height: 451px; }

.page-next-post {
  height: 0;
  display: flex;
  justify-content: center;
  position: relative; }
  .page-next-post a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    background: #B88B58;
    width: 115px;
    height: 115px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    font-size: 0.94118em;
    letter-spacing: 0.04em;
    color: #fff;
    padding-bottom: 7px;
    position: relative;
    z-index: 10; }
    .page-next-post a.fixed {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%); }
    .page-next-post a:after {
      position: absolute;
      left: 50%;
      margin-left: -11px;
      bottom: 18px;
      content: '';
      border-top: 11px solid;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent; }

.page-add {
  padding: 0 35px; }
  .page-add__line {
    display: flex;
    align-items: center;
    margin-bottom: 15px; }
    @media (max-width: 576px) {
      .page-add__line {
        display: block;
        text-align: center; } }
    .page-add__line:last-child {
      margin-bottom: 0; }
  .page-add__action {
    margin-right: 36px;
    width: 100%;
    max-width: 228px;
    min-width: 228px; }
    @media (max-width: 576px) {
      .page-add__action {
        min-width: 0;
        max-width: 100%;
        margin-bottom: 15px; } }
    .page-add__action .btn {
      height: 40px; }
  .page-add__text {
    font-style: italic;
    font-size: 0.88235em;
    line-height: 1; }

.page-stats {
  border: 1px solid #C2E9F5;
  padding: 20px 25px 25px; }
  .page-stats__main {
    display: flex;
    align-items: flex-start;
    margin-bottom: 18px; }
    @media (max-width: 576px) {
      .page-stats__main {
        display: block; } }
  .page-stats__info {
    display: flex;
    align-items: flex-start;
    flex: 1; }
  .page-stats__avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 28px;
    overflow: hidden; }
    .page-stats__avatar img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .page-stats__text {
    flex: 1;
    font-size: 0.94118em;
    font-style: italic; }
    .page-stats__text a {
      font-weight: bold; }
      .page-stats__text a:hover {
        text-decoration: underline;
        color: #0A4798; }
  .page-stats__action {
    margin-left: 32px;
    width: 100%;
    max-width: 208px; }
    @media (max-width: 576px) {
      .page-stats__action {
        max-width: 100%;
        margin-left: 0;
        margin-top: 15px; } }
    .page-stats__action .btn {
      margin-bottom: 8px;
      height: 40px; }
      .page-stats__action .btn:last-child {
        margin-bottom: 0; }
  .page-stats__stats {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px; }
    @media (max-width: 768px) {
      .page-stats__stats {
        flex-wrap: wrap; } }
  .page-stats__stat {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 0.88235em;
    padding: 0 20px;
    align-items: center;
    min-width: 150px; }
    @media (max-width: 1200px) {
      .page-stats__stat {
        padding: 0 10px;
        min-width: 100px; } }
    @media (max-width: 768px) {
      .page-stats__stat {
        margin-bottom: 10px; } }
    .page-stats__stat-icon {
      font-size: 1.47059em; }
      .page-stats__stat-icon .icon {
        position: relative;
        bottom: -2px; }
    .page-stats__stat-icon, .page-stats__stat-num {
      padding: 0 8px; }
    .page-stats__stat-name {
      margin-top: 10px;
      width: 100%;
      flex: 0 0 100%;
      text-align: center;
      white-space: nowrap; }
  .page-stats__social {
    justify-content: center;
    display: flex; }

.page-buy {
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 768px) {
    .page-buy {
      display: block; } }
  .page-buy__item {
    margin: 0 15px 30px;
    width: calc(50% - 30px);
    background: #F0FAFD;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 10px 0 10px 93px;
    display: flex;
    align-items: center;
    position: relative;
    transition: background .3s; }
    @media (max-width: 768px) {
      .page-buy__item {
        width: 100%;
        margin: 0 0 30px; } }
    @media (max-width: 480px) {
      .page-buy__item {
        padding-left: 10px; } }
    .page-buy__item:hover {
      background: #daf3fa; }
    .page-buy__item-icon {
      position: absolute;
      left: 15px;
      width: 78px;
      height: calc(100% + 14px);
      top: -6px;
      border-radius: 0 0 5px 5px;
      background: #C2E9F5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.29412em;
      color: #99C6D2; }
      @media (max-width: 480px) {
        .page-buy__item-icon {
          display: none; } }
      .page-buy__item-icon:after, .page-buy__item-icon:before {
        content: '';
        position: absolute;
        top: 0;
        border-bottom: 6px solid #99C6D2; }
      .page-buy__item-icon:after {
        right: 100%;
        border-left: 6px solid transparent; }
      .page-buy__item-icon:before {
        left: 100%;
        border-right: 6px solid transparent; }
    .page-buy__item-name {
      text-align: center;
      font-size: 0.82353em;
      flex: 1;
      border-right: 3px solid #99C6D2; }
      .page-buy__item-name span {
        display: block;
        font-size: 0.88235em;
        font-weight: bold;
        margin-top: 1px; }
    .page-buy__item-link {
      align-self: stretch;
      width: 100%;
      max-width: 97px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.88235em;
      color: #0A4798;
      text-decoration: underline;
      font-weight: bold; }
      .page-buy__item-link:hover {
        text-decoration: none; }

.page-question {
  background: linear-gradient(172.19deg, #042C45 1.77%, #D9F0F7 98.87%);
  border-radius: 6px;
  padding: 14px 20px 22px; }
  .page-question--alt {
    background: linear-gradient(#697A0A, #E2E25A); }
  .page-question.active .page-question__title {
    margin-bottom: 12px; }
  .page-question.active .page-question__btn {
    height: 40px;
    max-width: 160px;
    pointer-events: none; }
  .page-question.active .page-question__btn-name {
    font-size: 1em; }
    .page-question.active .page-question__btn-name span {
      display: block; }
  .page-question.active .page-question__btn-val {
    display: block; }
  .page-question__title {
    text-align: center;
    font-size: 1.23529em;
    font-weight: bold;
    margin-bottom: 15px;
    color: #fff; }
  .page-question__action {
    display: flex;
    justify-content: center; }
    @media (max-width: 576px) {
      .page-question__action {
        display: block; } }
  .page-question__btn {
    max-width: 130px;
    margin-right: 30px;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
    @media (max-width: 576px) {
      .page-question__btn {
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto; } }
    .page-question__btn:last-child {
      margin-right: 0; }
      @media (max-width: 576px) {
        .page-question__btn:last-child {
          margin-bottom: 0;
          margin-right: auto; } }
    .page-question__btn.btn--accent .page-question__btn-val, .page-question__btn.btn--gray .page-question__btn-val {
      color: #0A4798; }
    .page-question__btn-name {
      font-size: 1.05882em;
      display: flex;
      white-space: nowrap; }
      .page-question__btn-name span {
        margin-left: 4px;
        display: none; }
    .page-question__btn-val {
      display: none;
      font-size: 1.41176em;
      font-weight: bold;
      margin-left: 10px; }

.page-rating {
  display: flex;
  align-items: center;
  position: relative;
  background: #FFFFFF;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
  padding: 17px 15px 12px; }
  .page-rating:after {
    content: '';
    position: absolute;
    background-image: url("../img/logo-alt.png");
    background-repeat: no-repeat;
    top: 14px;
    right: 10px;
    width: 74px;
    height: 23px;
    background-size: contain; }
    @media (max-width: 576px) {
      .page-rating:after {
        top: -2px;
        right: -1px; } }
  .page-rating__img {
    max-width: 86px;
    width: 100%;
    margin-right: 41px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 768px) {
      .page-rating__img {
        display: none; } }
    .page-rating__img img {
      display: block;
      max-width: 100%; }
  .page-rating__info {
    flex: 1;
    text-align: center; }
  .page-rating__diagram {
    width: 100%;
    max-width: 90%; }
    @media (max-width: 768px) {
      .page-rating__diagram {
        max-width: 100%;
        display: flex;
        flex-direction: row-reverse; } }
  .page-rating__title {
    font-size: 0.88235em;
    font-weight: bold;
    margin-bottom: 15px; }
  .page-rating__bottom {
    margin-top: 22px;
    position: relative;
    font-style: italic;
    font-size: 0.82353em; }
  .page-rating__points {
    position: absolute;
    right: 0;
    bottom: 0; }
    @media (max-width: 768px) {
      .page-rating__points {
        position: static;
        text-align: right;
        margin-top: 10px; } }
  @media (max-width: 1023px) {
    .page-rating__text {
      text-align: left; } }
  @media (max-width: 768px) {
    .page-rating__text {
      text-align: center; } }
  .page-rating__action {
    display: flex; }
    @media (max-width: 768px) {
      .page-rating__action {
        display: block;
        margin-right: 5px; } }
  .page-rating__item {
    flex: 1;
    text-align: center;
    height: 100%; }
    @media (max-width: 768px) {
      .page-rating__item {
        height: 40px;
        margin-bottom: 3px; }
        .page-rating__item:last-child {
          margin-bottom: 0; } }
  .page-rating__box {
    background: #C9DEE4;
    margin-left: auto;
    margin-right: auto;
    position: relative; }
    @media (min-width: 768px) {
      .page-rating__box {
        width: 40px !important; } }
    @media (max-width: 768px) {
      .page-rating__box {
        height: 40px !important;
        margin-left: 0; } }
    .page-rating__box span {
      position: absolute;
      bottom: 14px;
      left: 0;
      width: 100%;
      font-size: 0.88235em;
      font-weight: bold; }
      @media (max-width: 768px) {
        .page-rating__box span {
          bottom: 50%;
          left: 30px;
          transform: translateY(50%);
          text-align: left; } }
  .page-rating__btn {
    color: #fff;
    display: inline-flex;
    align-items: center;
    padding: 0 10px;
    height: 26px;
    background: #B88B58;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    font-size: 0.82353em; }
    @media (max-width: 768px) {
      .page-rating__btn {
        display: flex;
        height: 100%;
        justify-content: center; } }
    .page-rating__btn:hover {
      background: #9b7142; }
  .page-rating__pic {
    height: 75px;
    margin-bottom: 17px;
    display: flex;
    align-items: flex-end;
    position: relative; }
    @media (max-width: 768px) {
      .page-rating__pic {
        display: block;
        flex: 1;
        height: auto; } }
    .page-rating__pic .page-rating__item {
      display: flex;
      align-items: flex-end; }
    .page-rating__pic:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 3px solid #19124A;
      width: 90%; }
      @media (max-width: 768px) {
        .page-rating__pic:after {
          bottom: auto;
          width: 0;
          height: 100%;
          top: 0;
          left: 0;
          transform: translateX(0);
          border-bottom: none;
          border-left: 3px solid #19124A; } }

.page-navigation {
  margin-bottom: 30px;
  display: flex; }
  @media (max-width: 768px) {
    .page-navigation {
      display: block; } }
  .page-navigation__advert {
    margin-right: 55px; }
    @media (max-width: 768px) {
      .page-navigation__advert {
        margin-right: 0; } }
  .page-navigation__main {
    flex: 1; }
    .page-navigation__main .title {
      margin-bottom: 24px; }
  .page-navigation__list {
    margin-bottom: 23px; }
    .page-navigation__list li {
      margin-bottom: 10px; }
      .page-navigation__list li:last-child {
        margin-bottom: 0; }
    .page-navigation__list a {
      font-size: 1.05882em;
      line-height: 1;
      text-decoration: underline; }
      .page-navigation__list a:hover {
        text-decoration: none; }
  .page-navigation__action {
    display: flex;
    justify-content: flex-end; }
  .page-navigation__btn {
    font-weight: bold;
    font-size: 0.88235em;
    border-bottom: 2px solid #CC1B28; }
    .page-navigation__btn:hover {
      border-bottom-color: transparent; }

.page-banner {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 400px;
  padding: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 50px; }
  @media (max-width: 480px) {
    .page-banner {
      padding: 10px; } }
  .page-banner--big {
    min-height: 565px;
    margin-bottom: 70px; }
  .page-banner__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .page-banner__cont {
    position: relative;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    width: 100%;
    max-width: 224px;
    color: #fff;
    padding: 12px 20px 18px; }
    @media (max-width: 480px) {
      .page-banner__cont {
        max-width: 100%; } }
    .page-banner__cont--alt {
      background: rgba(255, 255, 255, 0.9);
      min-width: 285px;
      padding: 27px 11px 11px;
      color: #19124A; }
  .page-banner__info {
    margin-top: 25px;
    font-size: 0.76471em;
    text-align: right; }
  .page-banner__lines {
    display: table;
    width: 100%; }
    @media (max-width: 480px) {
      .page-banner__lines {
        display: block; } }
    .page-banner__lines-all {
      display: flex;
      justify-content: center; }
  .page-banner__line {
    display: table-row; }
    @media (max-width: 480px) {
      .page-banner__line {
        display: block; } }
    .page-banner__line:last-child {
      margin-bottom: 0; }
    .page-banner__line-num {
      text-align: right;
      font-size: 2.05882em;
      font-weight: bold;
      white-space: nowrap;
      padding-right: 7px; }
    .page-banner__line-num, .page-banner__line-name {
      display: table-cell;
      padding-bottom: 20px;
      vertical-align: baseline;
      line-height: 1; }
      @media (max-width: 480px) {
        .page-banner__line-num, .page-banner__line-name {
          display: block;
          text-align: center; } }
    .page-banner__line-name {
      font-size: 0.94118em; }
    .page-banner__line-accent .page-banner__line-num,
    .page-banner__line-accent .page-banner__line-name {
      display: block;
      padding-bottom: 0;
      padding-right: 0; }
    .page-banner__line-accent .page-banner__line-num {
      text-align: left;
      color: #CC1B28; }
      @media (max-width: 480px) {
        .page-banner__line-accent .page-banner__line-num {
          text-align: center; } }
  .page-banner__title {
    text-align: center;
    font-size: 0.88235em;
    margin-bottom: 22px;
    font-weight: bold; }
  .page-banner__list {
    display: block; }
    .page-banner__list.classification-list {
      margin-bottom: 10px; }
      .page-banner__list.classification-list li {
        margin-bottom: 14px; }
  .page-banner__stars {
    justify-content: center;
    margin-bottom: 4px; }
    .page-banner__stars .stars-view__item {
      margin-right: 6px; }
    .page-banner__stars .icon {
      font-size: 0.82353em; }
  .page-banner__points {
    font-size: 0.82353em;
    text-align: center; }

.page-social .social ul {
  justify-content: center; }

.page-controls {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  @media (max-width: 1023px) {
    .page-controls {
      display: block; } }
  .page-controls .breadcrumbs {
    margin-right: auto;
    margin-bottom: 0; }
    @media (max-width: 1023px) {
      .page-controls .breadcrumbs {
        margin-bottom: 15px; } }
  .page-controls .favorite__btn {
    position: relative;
    bottom: -1px; }
    .page-controls .favorite__btn:hover .favorite__btn-text {
      text-decoration: underline; }
    .page-controls .favorite__btn .icon {
      font-size: 1.17647em;
      height: 20px;
      position: relative;
      bottom: -1px; }
  .page-controls .favorite__btn-text {
    text-decoration: none;
    margin-left: 0;
    font-size: 15px; }
  .page-controls__btn {
    display: flex;
    align-items: center;
    margin-left: 36px;
    font-size: 15px;
    line-height: 1; }
    @media (max-width: 1023px) {
      .page-controls__btn {
        display: inline-flex;
        margin-left: 0;
        margin-right: 30px;
        vertical-align: bottom; } }
    .page-controls__btn-size {
      background-image: url("../img/size__img.svg");
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 43px;
      min-height: 19px; }
      .page-controls__btn-size span {
        position: relative;
        bottom: -1px; }
    .page-controls__btn:hover span {
      text-decoration: underline; }
    .page-controls__btn span {
      margin-left: 13px; }
    .page-controls__btn .icon {
      font-size: 1.17647em; }

.page-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 35px; }
  @media (max-width: 1023px) {
    .page-top {
      display: block; } }
  .page-top .social {
    margin-left: auto; }
    @media (max-width: 1023px) {
      .page-top .social {
        margin-left: 0; } }
  .page-top__btn {
    max-width: 168px;
    height: 40px;
    margin-right: auto; }
    @media (max-width: 576px) {
      .page-top__btn {
        margin-bottom: 10px; } }
  .page-top__btns {
    display: flex;
    white-space: nowrap; }
    @media (max-width: 576px) {
      .page-top__btns {
        flex-wrap: wrap;
        margin-bottom: 10px; } }
    .page-top__btns .favorite__btn {
      margin-left: 0; }
      .page-top__btns .favorite__btn:hover .favorite__btn-text {
        text-decoration: underline; }
    .page-top__btns .favorite__btn-text {
      text-decoration: none; }
  @media (max-width: 1200px) {
    .page-top--alt {
      flex-wrap: wrap; } }
  @media (max-width: 1023px) {
    .page-top--alt {
      display: flex; }
      .page-top--alt .page-controls__btn-size {
        margin-right: 0; } }
  @media (max-width: 576px) {
    .page-top--alt {
      display: block; } }
  @media (max-width: 1200px) {
    .page-top--alt .social {
      margin-right: 0;
      margin-left: auto;
      margin-top: 10px; } }
  @media (max-width: 1023px) {
    .page-top--alt .social {
      margin-left: auto; } }
  @media (max-width: 576px) {
    .page-top--alt .social {
      margin-top: 0; } }

.page-user {
  display: flex;
  align-items: center;
  font-size: 0.88235em;
  padding-left: 42px; }
  @media (max-width: 1023px) {
    .page-user {
      margin-bottom: 15px;
      flex-wrap: wrap; } }
  .page-user__avatar {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-right: 7px;
    overflow: hidden;
    display: block;
    margin-left: -42px; }
    .page-user__avatar img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .page-user__name {
    margin-right: 5px; }
    .page-user__name a:hover {
      text-decoration: underline; }
  .page-user__firm {
    margin-right: 18px; }
    .page-user__firm a {
      color: #CC1B28; }
      .page-user__firm a:hover {
        text-decoration: underline; }

.page-nav {
  background: #F0FAFD;
  margin-bottom: 80px;
  padding: 20px 20px 30px; }
  @media (max-width: 768px) {
    .page-nav {
      margin-bottom: 50px; } }
  .page-nav__box {
    margin-top: 44px; }
    .page-nav__box li {
      margin-bottom: 12px; }
      .page-nav__box li:last-child {
        margin-bottom: 0; }
    .page-nav__box a {
      font-size: 1.11765em;
      font-weight: bold; }
      .page-nav__box a:hover {
        text-decoration: underline; }
  .page-nav__col {
    width: 100%;
    max-width: 33.3333%;
    flex: 0 0 33.33333%;
    padding: 0 38px; }
    @media (max-width: 1200px) {
      .page-nav__col {
        padding: 0 20px; } }
    @media (max-width: 768px) {
      .page-nav__col {
        max-width: 100%;
        margin-bottom: 30px; }
        .page-nav__col:last-child {
          margin-bottom: 0; } }
    .page-nav__cols {
      margin: 0 -38px;
      display: flex;
      flex-wrap: wrap; }
      @media (max-width: 1200px) {
        .page-nav__cols {
          margin: 0 -20px; } }
      @media (max-width: 768px) {
        .page-nav__cols {
          display: block; } }
    .page-nav__col-title {
      font-size: 1.11765em;
      margin-bottom: 30px;
      font-weight: bold;
      text-align: center; }
    .page-nav__col-list {
      column-count: 2;
      column-gap: 30px; }
      .page-nav__col-list + .page-nav__col-list {
        margin-top: 10px;
        padding-top: 6px;
        border-top: 1px solid #19124A; }
      .page-nav__col-list li {
        margin-bottom: 5px; }
      .page-nav__col-list a {
        font-size: 0.94118em; }
        .page-nav__col-list a:hover {
          text-decoration: underline; }

.page-box {
  max-width: 33.33333%;
  flex: 0 0 33.33333%;
  padding: 0 10px; }
  @media (max-width: 1023px) {
    .page-box {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 20px; } }
  .page-box--sm {
    max-width: 36.26%;
    flex: 0 0 36.26%; }
  .page-box--alt .page-box__content {
    padding: 15px 13px 30px; }
    @supports (flex-direction: column) {
      .page-box--alt .page-box__content {
        display: flex;
        flex-direction: column; } }
  @supports (flex-direction: column) {
    .page-box--alt .page-box__action {
      margin-top: auto; } }
  @supports (flex-direction: column) {
    .page-box--comb .page-box__content {
      display: flex;
      flex-direction: column; }
    .page-box--comb .page-box__box:nth-child(1) {
      flex: 1; }
    .page-box--comb .page-box__box:nth-child(2) {
      flex: 2; } }
  .page-box--comb .page-box__content {
    background-color: transparent; }
  .page-box__box {
    background: #F0FAFD;
    margin-bottom: 20px;
    padding: 12px 20px; }
    .page-box__box:last-child {
      margin-bottom: 0; }
    @supports (flex-direction: column) {
      .page-box__box.page-form {
        display: flex;
        flex-direction: column; }
        .page-box__box.page-form .form {
          flex: 1;
          display: flex;
          flex-direction: column; }
          .page-box__box.page-form .form > * {
            margin-top: auto; } }
  .page-box__img img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
  .page-box__title {
    text-align: center;
    margin-bottom: 7px;
    font-weight: bold;
    font-size: 1.17647em; }
  .page-boxs {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px; }
    .page-boxs--alt {
      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
      margin-right: -20px;
      flex-wrap: nowrap; }
      @media (max-width: 1023px) {
        .page-boxs--alt {
          flex-wrap: wrap; } }
      .page-boxs--alt .page-box {
        padding: 0 20px;
        max-width: 100%;
        flex: 1; }
        .page-boxs--alt .page-box--sm {
          max-width: 36%;
          flex: 0 0 36%; }
        @media (max-width: 1023px) {
          .page-boxs--alt .page-box {
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 20px; } }
  .page-box__text {
    font-size: 0.88235em; }
    .page-box__text b a {
      text-decoration: none;
      color: inherit; }
    .page-box__text a {
      font-weight: bold;
      color: #0A4798;
      text-decoration: underline; }
      .page-box__text a:hover {
        text-decoration: none; }
  .page-box__content {
    background: #F0FAFD;
    height: 100%;
    position: relative; }
  .page-box__top {
    top: -8px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
    .page-box__top-img {
      margin-right: 30px;
      display: block; }
  .page-box__cont {
    padding: 114px 20px 47px;
    text-align: center; }
    @media (max-width: 1200px) {
      .page-box__cont {
        padding: 100px 20px 25px; } }
    @supports (flex-direction: column) {
      .page-box__cont {
        display: flex;
        flex-direction: column;
        height: 100%; } }
  .page-box__text {
    margin-bottom: 32px;
    line-height: 22px; }
  .page-box__action {
    margin-top: auto; }
    .page-box__action .btn {
      max-width: 154px;
      height: 40px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto; }
      .page-box__action .btn:last-child {
        margin-bottom: 0; }
  .page-box__btn {
    height: 40px; }

.page-login__avatar {
  border-radius: 50%;
  background: #19124A;
  width: 80px;
  height: 80px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.47059em; }
  .page-login__avatar .icon {
    position: relative;
    bottom: -4px; }

.page-login__title {
  font-weight: bold;
  font-size: 1.41176em;
  margin-bottom: 12px;
  color: #CC1B28; }

.page-faq__top {
  background: #19124A;
  color: #fff;
  padding: 0 24px;
  align-items: center;
  font-size: 1.17647em;
  font-weight: bold;
  height: 53px;
  display: inline-flex;
  white-space: nowrap; }
  @supports (clip-path: polygon(0 0, 100% 100%)) {
    .page-faq__top {
      clip-path: polygon(5px 0, 95% 3px, 100% 100%, 0 99%); } }
  .page-faq__top .icon {
    font-size: 1.88235em;
    margin-right: 15px; }

.page-board {
  padding: 55px 20px 40px;
  text-align: center; }
  @supports (flex-direction: column) {
    .page-board {
      display: flex;
      flex-direction: column;
      height: 100%; }
      .page-board .page-box__action {
        margin-top: auto; } }
  .page-board__top {
    color: #fff;
    background: #19124A;
    height: 48px;
    padding: 0 20px 0 12px;
    display: flex;
    align-items: center;
    font-size: 1.17647em;
    font-weight: bold;
    white-space: nowrap; }
  .page-board .btn {
    max-width: 188px; }

.page-form {
  padding-bottom: 40px;
  position: relative; }
  .page-form:after {
    content: '';
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 160px;
    height: 7px;
    background-image: url("../img/bg-line.png");
    background-repeat: repeat-x; }
  .page-form .form {
    max-width: 203px;
    margin-left: auto;
    margin-right: auto; }
  .page-form .form__input {
    text-align: center; }
  .page-form .btn {
    max-width: 144px;
    margin-left: auto;
    margin-right: auto; }

.page-search {
  padding: 20px 20px 30px; }
  .page-search .page-box__title {
    margin-bottom: 15px; }

.page-line {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 8px; }
  .page-line > * {
    margin-bottom: 10px; }
  @media (max-width: 768px) {
    .page-line {
      display: block; } }

.page-forum {
  display: flex;
  margin-bottom: 34px; }
  @media (max-width: 768px) {
    .page-forum {
      display: block; } }
  .page-forum__faq {
    width: 325px;
    min-width: 325px;
    margin-right: 50px;
    position: relative;
    padding-right: 40px; }
    @media (max-width: 768px) {
      .page-forum__faq {
        min-width: 0;
        width: 100%;
        margin-right: 0;
        margin-bottom: 40px;
        padding-right: 0; } }
    .page-forum__faq:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      border: 1px solid #D9F0F7;
      height: 100%;
      max-height: 347px; }
      @media (max-width: 768px) {
        .page-forum__faq:after {
          display: none; } }
  .page-forum__now {
    flex: 1; }
  .page-forum__title {
    margin-top: 33px;
    text-align: center;
    margin-bottom: 17px;
    font-size: 1.11765em; }
    .page-forum__title:first-child {
      margin-top: 0; }
  .page-forum__list li {
    margin-bottom: 11px; }
    .page-forum__list li:last-child {
      margin-bottom: 0; }
  .page-forum__list a {
    text-decoration: underline; }
    .page-forum__list a:hover {
      text-decoration: none; }
  .page-forum__action {
    margin-top: 27px;
    display: flex;
    justify-content: center; }
    .page-forum__action .btn {
      width: auto;
      padding: 0 30px; }
  .page-forum__question li {
    margin-bottom: 8px; }
    .page-forum__question li:last-child {
      margin-bottom: 0; }
  .page-forum__question a {
    font-size: 1.05882em;
    font-style: italic;
    margin-right: 20px; }
    .page-forum__question a:hover {
      text-decoration: underline; }
  .page-forum__question-num {
    display: inline-block;
    padding: 2px 7px;
    font-size: 0.82353em;
    background: #F0FAFD; }
  .page-forum__more {
    margin-top: 15px; }
    .page-forum__more a {
      text-decoration: underline;
      font-weight: bold;
      color: #0A4798; }
      .page-forum__more a:hover {
        text-decoration: none; }
  .page-forum__comment {
    margin-bottom: 24px;
    font-style: italic;
    line-height: 22px; }
    .page-forum__comment:last-child {
      margin-bottom: 0; }
    .page-forum__comment-name {
      font-weight: bold;
      margin-right: 5px;
      color: #CC1B28; }
    .page-forum__comment-question {
      font-weight: bold;
      margin-right: 15px; }
    .page-forum__comment-text {
      letter-spacing: 0.02em;
      line-height: 23px; }

.page-links {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 5px 25px;
  background: #F0FAFD; }
  .page-links li {
    margin-right: 30px;
    margin-bottom: 15px; }
  .page-links a {
    font-size: 1.11765em;
    font-weight: bold; }
    .page-links a:hover {
      color: #0A4798; }

.page-rezult__box {
  margin-bottom: 64px;
  position: relative; }
  .page-rezult__box:last-child {
    margin-bottom: 20px; }
  .page-rezult__box--simple {
    margin-bottom: 0; }
  .page-rezult__box.active .page-rezult__btn:after {
    transform: translateY(-50%) rotate(180deg); }
  .page-rezult__box.active .page-rezult__box-wrap {
    max-height: 999999px; }
    .page-rezult__box.active .page-rezult__box-wrap:after {
      display: none; }
  .page-rezult__box-title {
    position: absolute;
    top: 0;
    left: 70px;
    background: #fff;
    font-size: 1.23529em;
    transform: translateY(-50%);
    padding: 0 15px;
    z-index: 2; }
  .page-rezult__box-wrap {
    border: 4px solid #D9F0F7;
    overflow: hidden;
    max-height: 316px;
    position: relative; }
    .page-rezult__box-wrap:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 92px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.47) 0%, #FFFFFF 100%);
      z-index: 1; }
  .page-rezult__box-content {
    margin-bottom: 23px;
    padding: 37px 20px 0;
    column-count: 3;
    column-gap: 150px; }
    @media (max-width: 1200px) {
      .page-rezult__box-content {
        column-gap: 100px; } }
    @media (max-width: 576px) {
      .page-rezult__box-content {
        column-count: 2;
        column-gap: 50px; } }
    @media (max-width: 480px) {
      .page-rezult__box-content {
        column-count: 2;
        column-gap: 20px; } }

.page-rezult__action {
  margin-top: 23px;
  display: flex;
  justify-content: center; }

.page-rezult__btn {
  width: auto;
  padding: 0 45px 0 30px;
  position: relative; }
  .page-rezult__btn:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-top: 12px solid #19124A;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent; }

.page-rezult__list {
  margin-bottom: 10px; }
  .page-rezult__list-cont {
    display: inline-block; }
  .page-rezult__list-title {
    margin-bottom: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    width: 30px;
    height: 31px;
    font-size: 1.17647em;
    font-weight: bold;
    font-family: "Roboto Slab", serif;
    border: 2px solid #C2E9F5;
    border-radius: 2px; }
  .page-rezult__list li {
    margin-bottom: 3px; }
  .page-rezult__list a:hover {
    text-decoration: underline; }

.page-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }
  @media (max-width: 768px) {
    .page-info {
      display: block; } }
  .page-info__img {
    margin-right: 58px;
    min-width: 150px; }
    @media (max-width: 768px) {
      .page-info__img {
        margin-bottom: 30px;
        margin-right: 0;
        min-width: 0;
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center; } }
    .page-info__img img {
      display: block;
      max-width: 100%; }
  .page-info__text {
    font-style: italic; }
    .page-info__text a {
      font-weight: bold;
      text-decoration: underline;
      color: inherit; }
      .page-info__text a:hover {
        text-decoration: none; }

.page-sapid {
  padding: 0 50px;
  margin-bottom: 30px; }
  @media (max-width: 768px) {
    .page-sapid {
      padding: 0; } }
  .page-sapid__title {
    margin-bottom: 30px; }
  .page-sapid ul {
    column-count: 2;
    column-gap: 25px; }
    @media (max-width: 576px) {
      .page-sapid ul {
        column-count: 1; } }
  .page-sapid li {
    margin-bottom: 15px; }
  .page-sapid a {
    text-decoration: underline;
    font-size: 1.11765em;
    position: relative;
    padding-left: 21px;
    display: inline-block; }
    .page-sapid a:hover {
      text-decoration: none; }
    .page-sapid a:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 10px;
      height: 10px;
      border-bottom: 3px solid;
      border-right: 3px solid;
      transform: translateY(-50%) rotate(135deg); }

.page-sapid-other {
  margin-top: 50px;
  margin-bottom: 10px;
  padding: 0 20px; }
  @media (max-width: 768px) {
    .page-sapid-other {
      padding: 0; } }
  .page-sapid-other ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
    .page-sapid-other ul:last-child {
      margin-bottom: 0; }
    .page-sapid-other ul li {
      max-width: 45%; }
      .page-sapid-other ul li:nth-child(odd) a {
        padding-left: 23px; }
        .page-sapid-other ul li:nth-child(odd) a:before {
          left: 0;
          transform: rotate(135deg); }
      .page-sapid-other ul li:nth-child(even) a {
        padding-right: 23px;
        text-align: right; }
        .page-sapid-other ul li:nth-child(even) a:before {
          right: 0;
          transform: rotate(-45deg); }
    .page-sapid-other ul a {
      color: #0A4798;
      text-decoration: underline;
      font-size: 18px;
      font-weight: bold;
      position: relative;
      display: block; }
      @media (max-width: 768px) {
        .page-sapid-other ul a {
          font-size: 14px; } }
      .page-sapid-other ul a:hover {
        text-decoration: none; }
      .page-sapid-other ul a:before {
        content: '';
        position: absolute;
        top: 7px;
        width: 11px;
        height: 11px;
        border-bottom: 5px solid #0A4798;
        border-right: 5px solid #0A4798; }

.form__tooltip {
  position: relative; }
  .form__tooltip-btn {
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 0.82353em;
    box-shadow: none;
    border: none;
    background-color: transparent; }
    .form__tooltip-btn:hover i {
      text-decoration: none; }
    .form__tooltip-btn:hover + .form__tooltip-drop {
      display: block; }
    .form__tooltip-btn span {
      color: #CC1B28;
      font-size: 1.17647em;
      font-weight: bold;
      margin-right: 10px; }
    .form__tooltip-btn i {
      text-decoration: underline; }
  .form__tooltip-drop {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    background: #F0FAFD;
    border-radius: 3px;
    width: 100vw;
    max-width: 297px;
    padding: 15px 20px; }
    .form__tooltip-drop-item {
      font-size: 0.70588em;
      margin-bottom: 6px; }
      .form__tooltip-drop-item:last-child {
        margin-bottom: 0; }
      .form__tooltip-drop-item span {
        font-weight: bold;
        color: #0A4798; }
      .form__tooltip-drop-item i {
        display: block;
        color: #0A4798; }

.form__items {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px; }
  .form__items .form__label--half {
    width: 100%;
    max-width: calc(50% - 30px);
    flex: 0 0 calc(50% - 30px);
    margin-right: 30px; }
    @media (max-width: 768px) {
      .form__items .form__label--half {
        max-width: calc(100% - 30px);
        flex: 0 0 calc(100% - 30px); } }

.form__avatar {
  display: flex;
  align-items: center; }
  .form__avatar-img {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 25px; }
    .form__avatar-img img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .form__avatar-btn {
    text-decoration: underline;
    font-size: 15px;
    color: #19124A;
    margin-bottom: 6px; }
    .form__avatar-btn:last-child {
      margin-bottom: 0; }
    .form__avatar-btn:hover {
      text-decoration: none; }
    .form__avatar-btn label, .form__avatar-btn-del {
      border: none;
      text-decoration: inherit;
      background-color: transparent;
      font-size: inherit;
      color: inherit;
      padding: 0;
      cursor: pointer; }

.form__add-rub {
  border: none;
  background-color: transparent;
  padding-left: 25px;
  padding-right: 0;
  font-size: 17px;
  color: #19124A;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-top: 10px; }
  .form__add-rub:hover {
    color: #0A4798; }
  .form__add-rub:nth-child(1), .form__add-rub:nth-child(2), .form__add-rub:nth-child(3) {
    margin-top: 0; }
  @media (max-width: 768px) {
    .form__add-rub:nth-child(3) {
      margin-top: 10px; } }
  @media (max-width: 768px) {
    .form__add-rub:nth-child(2) {
      margin-top: 10px; } }
  .form__add-rub:after, .form__add-rub:before {
    content: '';
    position: absolute;
    top: 50%;
    background: #CC1B28;
    transform: translateY(-50%); }
  .form__add-rub:after {
    left: 0;
    width: 13px;
    height: 3px; }
  .form__add-rub:before {
    left: 5px;
    height: 13px;
    width: 3px; }

.form__act {
  margin-bottom: 10px;
  font-size: 19px; }
  .form__act:last-child {
    margin-bottom: 0; }
  .form__act--right {
    text-align: right; }
  .form__act--small {
    font-size: 15px; }
  .form__act a {
    color: #0A4798; }
    .form__act a:hover {
      text-decoration: underline; }
  .form__act--new a {
    text-decoration: underline; }
    .form__act--new a:hover {
      text-decoration: none; }

.form__checkbox {
  display: none; }
  .form__checkbox:checked ~ .form__label-check-box:after {
    opacity: 1; }

.form > .form__help {
  margin-left: 0;
  margin-top: 20px; }

.form__help {
  display: inline-block;
  margin-left: 40px;
  font-size: 15px;
  font-style: italic; }

.form__label {
  display: block;
  margin-bottom: 20px; }
  .form__label--datepicker {
    position: relative; }
    .form__label--datepicker input {
      cursor: pointer; }
    .form__label--datepicker:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 36px;
      height: 36px;
      background: #D9F0F7;
      border-radius: 0 3px 3px 0;
      cursor: pointer; }
    .form__label--datepicker:before {
      content: '';
      height: 8px;
      width: 8px;
      border-bottom: 2px solid #19124A;
      border-right: 2px solid #19124A;
      transform: rotate(45deg);
      position: absolute;
      bottom: 15px;
      right: 13px;
      z-index: 1;
      cursor: pointer; }
  .form__label-withcheck {
    display: flex; }
    @media (max-width: 576px) {
      .form__label-withcheck {
        display: block; } }
    .form__label-withcheck-box {
      width: 100%;
      max-width: 345px;
      margin-right: 30px;
      display: flex;
      align-items: center; }
      @media (max-width: 576px) {
        .form__label-withcheck-box {
          margin-bottom: 15px; } }
      .form__label-withcheck-box .form__select {
        margin-right: 15px; }
        .form__label-withcheck-box .form__select:last-child {
          margin-right: 0; }
    .form__label-withcheck .form__label-check {
      margin-bottom: 0;
      display: inline-flex; }
    .form__label-withcheck .form__label-check-box {
      margin-right: 15px; }
    .form__label-withcheck .form__label-name {
      font-style: italic;
      font-size: 15px;
      color: #828282;
      margin-bottom: 2px; }
  .form__label-line {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px; }
    .form__label-line .form__select,
    .form__label-line .form__input {
      margin-right: 20px;
      margin-top: 10px;
      width: 100%;
      max-width: calc(33.333% - 20px);
      flex: 0 0 calc(33.333% - 20px); }
      @media (max-width: 768px) {
        .form__label-line .form__select,
        .form__label-line .form__input {
          max-width: calc(50% - 20px);
          flex: 0 0 calc(50% - 20px); } }
      @media (max-width: 576px) {
        .form__label-line .form__select,
        .form__label-line .form__input {
          max-width: calc(100% - 20px);
          flex: 0 0 calc(100% - 20px); } }
      .form__label-line .form__select:nth-child(1), .form__label-line .form__select:nth-child(2), .form__label-line .form__select:nth-child(3),
      .form__label-line .form__input:nth-child(1),
      .form__label-line .form__input:nth-child(2),
      .form__label-line .form__input:nth-child(3) {
        margin-top: 0; }
      @media (max-width: 768px) {
        .form__label-line .form__select:nth-child(3),
        .form__label-line .form__input:nth-child(3) {
          margin-top: 10px; } }
      @media (max-width: 576px) {
        .form__label-line .form__select:nth-child(2),
        .form__label-line .form__input:nth-child(2) {
          margin-top: 10px; } }
  .form__label--sm {
    max-width: 327px; }
  .form__label--min {
    max-width: 260px; }
  .form__label-name {
    display: block;
    margin-bottom: 8px; }
    .form__label-name--line {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 576px) {
        .form__label-name--line {
          display: block; } }
      .form__label-name--line-alt {
        display: flex;
        align-items: center; }
        .form__label-name--line-alt span {
          font-size: 15px;
          color: #828282;
          margin-left: 20px; }
    .form__label-name small {
      font-size: 15px;
      white-space: nowrap; }
      @media (max-width: 576px) {
        .form__label-name small {
          display: block;
          white-space: normal;
          margin-top: 5px; } }
  .form__label-comb {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px; }
    .form__label-comb .form__input {
      margin-right: 15px;
      width: auto; }
  .form__label-check {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px; }
    .form__label-check-box {
      display: block;
      min-width: 14px;
      width: 14px;
      height: 14px;
      border-radius: 3px;
      border: 1px solid #19124A;
      margin-right: 1px;
      position: relative; }
      .form__label-check-box:after {
        content: '';
        position: absolute;
        top: 0;
        left: 4px;
        width: 5px;
        height: 10px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        transform: rotate(45deg);
        opacity: 0; }
    .form__label-check-circle {
      min-width: 16px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid #19124A;
      position: absolute; }
      .form__label-check-circle:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: #19124A;
        opacity: 0; }
    .form__label-check-name {
      margin-left: 6px;
      font-size: 16px;
      white-space: nowrap; }
    .form__label-check-color {
      width: 9px;
      height: 14px; }
      .form__label-check-color--mix {
        position: relative;
        width: 12px; }
        .form__label-check-color--mix:after, .form__label-check-color--mix:before {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 50%; }
        .form__label-check-color--mix:after {
          bottom: 0;
          background-image: linear-gradient(90deg, #219653 50%, #CC1B28 50%); }
        .form__label-check-color--mix:before {
          top: 0;
          background-image: linear-gradient(90deg, #0A4798 50%, #EB8E02 50%); }
  .form__label-file {
    display: flex;
    align-items: center;
    cursor: pointer; }
    .form__label-file:hover .icon {
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25); }
    .form__label-file-name {
      font-size: 15px; }
  .form__label--line {
    display: flex;
    align-items: center; }
    .form__label--line .form__label-name {
      margin-right: 8px;
      margin-bottom: 0; }

.form__input {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #C2E9F5;
  border-radius: 5px;
  background: #fff;
  height: 36px;
  padding: 0 10px;
  font-size: 15px; }
  .form__input + .form__input {
    margin-top: 10px; }
  .form__input--cl {
    color: #CC1B28;
    font-size: 17px !important; }
  .form__input-checkbox {
    display: none; }
    .form__input-checkbox:checked ~ .form__label-check-box:after {
      opacity: 1; }
  .form__input-radio {
    display: none; }
    .form__input-radio:checked ~ .form__label-check-circle:after {
      opacity: 1; }
  .form__input-file {
    display: none; }

.form__textarea {
  border: 1px solid #D9F0F7;
  border-radius: 3px;
  display: block;
  width: 100%;
  min-height: 60px;
  padding: 10px;
  font-size: 14px;
  resize: vertical; }
  .form__textarea--big {
    height: 270px; }

.form__action {
  display: flex;
  justify-content: center; }
  .form__action-alt {
    display: flex;
    align-items: flex-end; }
    @media (max-width: 768px) {
      .form__action-alt {
        flex-wrap: wrap; }
        .form__action-alt .form__help {
          flex: 0 0 100%;
          width: 100%;
          margin-top: 15px;
          margin-left: 0; } }

.form__btn {
  width: auto;
  padding: 0 30px; }

.form__select--data .jq-selectbox__trigger {
  background-color: transparent; }

.form__select--data .jq-selectbox__trigger-arrow {
  border: none;
  transform: rotate(0);
  left: 20px;
  width: 13px; }
  .form__select--data .jq-selectbox__trigger-arrow:after, .form__select--data .jq-selectbox__trigger-arrow:before {
    content: '';
    position: absolute;
    left: 0;
    border: none;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent; }
  .form__select--data .jq-selectbox__trigger-arrow:after {
    border-bottom: 7px solid #19124A;
    bottom: 6px; }
  .form__select--data .jq-selectbox__trigger-arrow:before {
    top: 6px;
    border-top: 8px solid #19124A; }

.form__select-day {
  max-width: 96px; }

.form__select-mon {
  max-width: 120px; }

.form__select-year {
  max-width: 96px; }

.form__select--alt .jq-selectbox__select {
  border-radius: 10px; }

.form__select--alt .jq-selectbox__trigger {
  background-color: transparent; }

.form__select--alt .jq-selectbox__select-text,
.form__select--alt .jq-selectbox__select-text.placeholder {
  color: #19124A; }

.form__select--cl {
  max-width: 215px; }
  .form__select--cl .jq-selectbox__select-text,
  .form__select--cl .jq-selectbox__select-text.placeholder {
    color: #CC1B28; }

.form__select--min {
  max-width: 190px; }

.form__select--other {
  min-width: 170px; }
  .form__select--other .jq-selectbox__select {
    background: #D9F0F7 !important;
    border-radius: 10px;
    padding-right: 30px; }
  .form__select--other .jq-selectbox__trigger {
    background-color: transparent;
    width: 22px; }
  .form__select--other .jq-selectbox__trigger-arrow {
    right: 12px; }
  .form__select--other .jq-selectbox__select-text,
  .form__select--other .jq-selectbox__select-text.placeholder {
    font-size: 14px; }

.files-rezult {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap; }
  .files-rezult li {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative; }
    .files-rezult li:hover button {
      opacity: 1; }
    .files-rezult li .delete-link {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      right: 0;
      cursor: pointer; }
      .files-rezult li .delete-link:after, .files-rezult li .delete-link:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 2px;
        background: #CC1B28; }
      .files-rezult li .delete-link:after {
        transform: translate(-50%, -50%) rotate(45deg); }
      .files-rezult li .delete-link:before {
        transform: translate(-50%, -50%) rotate(-45deg); }
  .files-rezult button {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: 0; }
    .files-rezult button:after, .files-rezult button:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 2px;
      background: #19124A; }
    .files-rezult button:after {
      transform: translate(-50%, -50%) rotate(45deg); }
    .files-rezult button:before {
      transform: translate(-50%, -50%) rotate(-45deg); }
  .files-rezult img {
    display: block;
    max-width: 100%;
    max-height: 100%; }

.files-area {
  border: 1px solid #D9F0F7;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  padding: 2px 8px;
  align-items: center; }
  .files-area .form__label-file:hover {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25); }
    .files-area .form__label-file:hover .icon {
      box-shadow: none; }

.files .files-rezult {
  display: flex;
  flex-wrap: wrap; }
  .files .files-rezult__item {
    display: none; }
    .files .files-rezult__item-input {
      display: none; }
    .files .files-rezult__item.active {
      display: block; }

.files-add {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .files-add__input {
    display: none; }
  .files-add label {
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D9F0F7;
    border-radius: 5px;
    cursor: pointer; }
  .files-add .icon {
    font-size: 25px; }

.editor__help {
  margin-left: auto; }
  .editor__help a {
    text-decoration: underline;
    color: #CC1B28; }
    .editor__help a:hover {
      text-decoration: none; }

.breadcrumbs {
  margin-bottom: 17px; }
  .breadcrumbs ul {
    display: flex;
    flex-wrap: wrap; }
  .breadcrumbs li {
    padding-right: 7px;
    margin-right: 7px;
    margin-bottom: 5px;
    border-right: 1px solid;
    color: #828282;
    font-size: 14px;
    line-height: 1; }
    .breadcrumbs li:last-child {
      border-right: none;
      padding-right: 0;
      margin-right: 0; }
  .breadcrumbs a {
    color: inherit; }
    .breadcrumbs a:hover {
      color: #19124A; }

.inner {
  display: flex; }
  .inner--alt .inner__aside {
    padding-top: 0; }
  .inner__main {
    flex: 1;
    width: calc(100% - 345px); }
    @media (max-width: 1200px) {
      .inner__main {
        width: calc(100% - 330px); } }
    @media (max-width: 1023px) {
      .inner__main {
        width: 100%; } }
  .inner__aside {
    margin-left: 45px;
    width: 100%;
    max-width: 300px;
    flex: 0 0 300px;
    height: inherit;
    padding-top: 30px; }
    @media (max-width: 1200px) {
      .inner__aside {
        margin-left: 30px; } }
    @media (max-width: 1023px) {
      .inner__aside {
        display: none; } }
    .inner__aside .btn--dzen {
      margin-bottom: 50px; }
    .inner__aside .calendar-box {
      margin-top: 77px; }
      .inner__aside .calendar-box:first-child {
        margin-top: 0; }
      .inner__aside .calendar-box__more a {
        height: 31px;
        padding: 0 14px; }
    .inner__aside-item {
      margin-bottom: 50px; }
      .inner__aside-item:last-child {
        margin-bottom: 0; }
    .inner__aside .page-form {
      border: 1px solid #C2E9F5;
      padding-top: 9px; }
    .inner__aside .card__img:before {
      padding-top: 77.6%; }
  .inner .calendar-box {
    border: 1px solid #C2E9F5; }
    .inner .calendar-box__top {
      margin-top: -19px;
      background: #fff;
      padding: 0 10px 0 20px;
      margin-left: -10px; }
    .inner .calendar-box__num {
      left: 20px;
      top: -19px; }
    .inner .calendar-box__title {
      margin-left: 56px; }
    .inner .calendar-box__text {
      font-size: 16px;
      margin-bottom: 15px; }
    .inner .calendar-box__date-month {
      margin-right: auto; }
    .inner .calendar-box__pic {
      width: 33px; }

.aside-search {
  border: 5px solid #C2E9F5;
  padding: 33px 25px 22px; }

.aside-rating.active .aside-rating__item {
  pointer-events: none;
  padding-top: 10px;
  padding-bottom: 25px; }
  .aside-rating.active .aside-rating__item:before {
    content: attr(data-num);
    position: absolute;
    top: 4px;
    left: 4px;
    font-size: 16px;
    font-weight: bold; }
  .aside-rating.active .aside-rating__item-line {
    display: block;
    width: 100px;
    height: 12px;
    border: 1px solid #CC1B28;
    position: absolute;
    bottom: 6px;
    left: 50%;
    margin-left: -50px; }
    .aside-rating.active .aside-rating__item-line-val {
      display: block;
      height: 100%;
      background: #CC1B28; }

.aside-rating.active .aside-rating__btn,
.aside-rating.active .aside-rating__points {
  display: none; }

.aside-rating.active .aside-rating__status {
  display: block;
  color: #0A4798; }

.aside-rating.active .form__label-check-circle {
  display: none; }

.aside-rating.active .form__label-check-name {
  display: inline-block; }

.aside-rating.active .aside-rating__item-val {
  display: block;
  top: 10px;
  right: 10px;
  font-weight: bold;
  font-size: 15px; }

.aside-rating__title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 6px;
  text-align: center; }

.aside-rating__name {
  margin-bottom: 6px;
  text-align: center; }

.aside-rating__list {
  margin-bottom: 6px; }

.aside-rating__item {
  background: #F0FAFD;
  display: block;
  padding: 17px 15px 17px 45px;
  margin-bottom: 6px;
  position: relative;
  cursor: pointer; }
  .aside-rating__item:last-child {
    margin-bottom: 0; }
  .aside-rating__item:nth-child(even) {
    background: #F9F9F9; }
  .aside-rating__item-name {
    font-size: 14px;
    line-height: 1; }
  .aside-rating__item-val {
    position: absolute;
    display: none; }
  .aside-rating__item .form__label-check-circle {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%); }
  .aside-rating__item-line {
    display: none; }

.aside-rating__action {
  font-size: 13px;
  line-height: 1; }

.aside-rating__points {
  text-align: right; }

.aside-rating__status {
  text-align: right;
  display: none; }

.aside-rating__btn {
  margin-top: 8px;
  width: auto;
  padding: 0 30px;
  margin-left: auto;
  margin-right: auto; }

.aside-sort {
  border: 1px solid #C2E9F5;
  padding: 20px;
  position: relative; }
  .aside-sort__title {
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    background: #fff;
    padding: 0 15px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    white-space: nowrap; }
    .aside-sort__title span {
      font-weight: normal; }
  .aside-sort__content {
    max-height: 284px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative; }
    .aside-sort__content:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: calc(100% - 14px);
      height: 40px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
      z-index: 1; }
  .aside-sort__box {
    margin-bottom: 6px; }
    .aside-sort__box:last-child {
      margin-bottom: 0; }
    .aside-sort__box-title {
      font-size: 18px;
      font-weight: bold;
      color: #E31E26;
      margin-bottom: 3px; }
    .aside-sort__box-list li {
      margin-bottom: 3px; }
      .aside-sort__box-list li:last-child {
        margin-bottom: 0; }
    .aside-sort__box-list a {
      font-size: 14px;
      font-weight: bold; }
      .aside-sort__box-list a:hover {
        text-decoration: underline; }

.aside-card {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start; }
  .aside-card:last-child {
    margin-bottom: 0; }
  .aside-card:hover img {
    opacity: 1; }
  .aside-cards .card:last-child {
    margin-bottom: 0; }
  .aside-card__img {
    width: 140px;
    min-width: 140px;
    height: 100px;
    margin-right: 9px;
    overflow: hidden; }
    .aside-card__img img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: .3s;
      opacity: .5; }
  .aside-card__name {
    line-height: 19px;
    font-weight: bold;
    font-size: 15px; }

.aside-action {
  text-align: right;
  margin-top: 7px; }
  .aside-action__link {
    text-decoration: underline;
    font-weight: bold;
    font-size: 15px;
    color: #0A4798; }
    .aside-action__link:hover {
      text-decoration: none; }

.sort__list {
  display: flex;
  justify-content: flex-end; }
  @media (max-width: 1023px) {
    .sort__list {
      flex-wrap: wrap; } }
  .sort__list li {
    margin-right: 15px; }
    @media (max-width: 1023px) {
      .sort__list li {
        margin-bottom: 10px;
        margin-right: 0;
        margin-left: 15px; }
        .sort__list li:first-child {
          margin-left: 0; } }
    .sort__list li:last-child {
      margin-right: 0; }
    .sort__list li.active a {
      border-bottom-color: #CC1B28; }
  .sort__list a {
    font-size: 15px;
    font-weight: bold;
    border-bottom: 2px solid transparent;
    white-space: nowrap; }
    .sort__list a:hover {
      border-bottom-color: #CC1B28; }

.search-page {
  display: flex;
  width: 100%;
  max-width: 417px; }
  @media (max-width: 768px) {
    .search-page {
      max-width: 100%;
      margin-bottom: 20px; } }
  .search-page--big {
    max-width: 100%;
    margin-bottom: 20px; }
    .search-page--big.filter-search {
      max-width: 100%;
      margin-top: 30px; }
  .search-page .form__label {
    border: 1px solid #C2E9F5;
    margin-right: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    width: 289px;
    margin-bottom: 0;
    overflow: hidden; }
    @media (max-width: 768px) {
      .search-page .form__label {
        width: 100%; } }
  .search-page .form__input {
    border: none;
    flex: 1;
    min-width: 0; }
  .search-page__search {
    border: none;
    background-color: transparent;
    padding-right: 19px;
    font-size: 23px;
    color: #BDBDBD;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 48px; }
    .search-page__search .icon {
      position: relative;
      bottom: -1px; }
  .search-page__btn {
    height: 40px;
    max-width: 106px; }

.filter-select {
  margin-right: 30px;
  max-width: 340px; }
  @media (max-width: 768px) {
    .filter-select {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 20px; } }

.filter-cat {
  display: block;
  margin-left: -10px;
  margin-right: -10px; }
  .filter-cat li {
    display: inline-block;
    padding: 0 10px;
    margin-bottom: 5px; }
  .filter-cat a {
    border-bottom: 2px solid transparent; }
    .filter-cat a.active {
      font-weight: bold; }
    .filter-cat a:hover, .filter-cat a.active {
      color: #0A4798;
      border-color: #CC1B28; }
  .filter-cat--rez {
    font-size: 16px;
    display: block;
    margin-left: 0;
    margin-right: 0;
    column-count: 8;
    column-gap: 15px;
    margin-bottom: 30px; }
    @media (max-width: 1200px) {
      .filter-cat--rez {
        column-count: 6; } }
    @media (max-width: 768px) {
      .filter-cat--rez {
        column-count: 4; } }
    @media (max-width: 480px) {
      .filter-cat--rez {
        column-count: 3; } }
    .filter-cat--rez li {
      padding: 0;
      display: block; }

.filter--alt {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 1200px) {
    .filter--alt {
      flex-wrap: wrap; } }
  @media (max-width: 1200px) {
    .filter--alt .pagination {
      flex: 0 0 100%;
      width: 100%;
      margin-top: 20px; } }
  @media (max-width: 1200px) {
    .filter--alt .pagination ul {
      justify-content: flex-end; } }
  @media (max-width: 768px) {
    .filter--alt .pagination ul {
      justify-content: flex-start; } }
  .filter--alt .form__label {
    margin-bottom: 0; }

.filter-sort {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  .filter-sort .sort {
    margin-left: auto; }
  @media (max-width: 768px) {
    .filter-sort--alt {
      display: block; } }

.filter-info {
  margin-top: 27px; }
  .filter-info__box {
    position: relative;
    padding-left: 10px; }
    .filter-info__box:before {
      content: '*';
      position: absolute;
      top: 0;
      left: 0; }

.filter__title {
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 18px; }
  .filter__title--simple {
    font-weight: 400; }

.filter-letters-rezult {
  background: #F0FAFD;
  padding: 13px 20px 25px;
  margin-bottom: 50px;
  margin-top: -20px;
  display: none;
  max-height: 485px;
  height: 70vh;
  min-height: 350px; }
  .filter-letters-rezult.active {
    display: block; }
  .filter-letters-rezult__action {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px; }
    .filter-letters-rezult__action button {
      cursor: pointer;
      font-size: 0.82353em;
      max-width: 150px; }
  .filter-letters-rezult__box {
    margin-bottom: 25px; }
    .filter-letters-rezult__box:last-child {
      margin-bottom: 0; }
    .filter-letters-rezult__box.loaded {
      display: none; }
    .filter-letters-rezult__box-title {
      margin-bottom: 9px;
      font-weight: bold;
      font-size: 15px; }
      .filter-letters-rezult__box-title span {
        color: #0A4798; }
    .filter-letters-rezult__box ul {
      column-count: 3;
      column-gap: 30px; }
      @media (max-width: 768px) {
        .filter-letters-rezult__box ul {
          column-count: 2;
          column-gap: 20px; } }
      @media (max-width: 576px) {
        .filter-letters-rezult__box ul {
          column-count: 1;
          column-gap: 0; } }
      .filter-letters-rezult__box ul li {
        margin-bottom: 5px; }
      .filter-letters-rezult__box ul a:hover {
        text-decoration: underline;
        color: #0A4798; }

.filter-letters__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 560px;
  margin-bottom: 50px; }
  .filter-letters__list li {
    margin-right: 10px;
    margin-bottom: 15px; }
  .filter-letters__list a {
    font-weight: bold;
    font-size: 20px;
    border-radius: 2px;
    border: 2px solid #C2E9F5;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    text-transform: uppercase;
    font-family: "Roboto Slab", serif; }
    .filter-letters__list a.active, .filter-letters__list a:hover {
      color: #fff;
      background: #19124A;
      border-color: #19124A; }

.filter-reset {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: flex-end;
  display: none; }
  .filter-reset.active {
    display: flex; }
  .filter-reset__btn {
    background-color: transparent;
    border: none;
    font-size: 15px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: #0A4798; }
    .filter-reset__btn:hover {
      text-decoration: none; }

.filter-cols {
  display: flex;
  min-width: 100%;
  margin: 0 -9px;
  margin-bottom: 20px; }
  @media (max-width: 1200px) {
    .filter-cols {
      flex-wrap: wrap; } }

.filter-col {
  display: block;
  margin-bottom: 0px;
  padding: 0 9px;
  vertical-align: top;
  flex: 1;
  min-width: 100px;
  max-width: 300px; }
  @media (max-width: 1200px) {
    .filter-col {
      min-width: 150px;
      margin-bottom: 15px; } }
  .filter-col__content {
    position: relative;
    overflow: hidden; }
    .filter-col__content:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
      height: 100%;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0), white); }
  .filter-col__title {
    background: #D9F0F7;
    height: 40px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    padding: 0 10px;
    line-height: 1;
    font-size: 15px;
    font-weight: bold; }
  .filter-col__line {
    display: flex;
    flex-wrap: wrap; }
    .filter-col__line .form__label-check {
      margin-right: 9px; }
      .filter-col__line .form__label-check:last-child {
        margin-right: 0; }

.filter-search {
  margin-left: auto;
  margin-right: auto;
  max-width: 515px;
  margin-top: 50px; }
  .filter-search .form__label {
    flex: 1;
    overflow: hidden; }

.filter-nav__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .filter-nav__list li {
    margin-right: 24px;
    padding-bottom: 10px; }
    .filter-nav__list li.flexMenu-viewMore {
      margin-left: auto;
      margin-right: 0;
      position: relative; }
      .filter-nav__list li.flexMenu-viewMore.active a span {
        background-color: transparent; }
        .filter-nav__list li.flexMenu-viewMore.active a span:after {
          transform: translateY(50%) rotate(45deg);
          bottom: 50%;
          margin-bottom: 0; }
        .filter-nav__list li.flexMenu-viewMore.active a span:before {
          transform: translateY(-50%) rotate(-45deg);
          top: 50%;
          margin-top: 0; }
      .filter-nav__list li.flexMenu-viewMore li {
        padding-bottom: 0; }
        .filter-nav__list li.flexMenu-viewMore li a {
          padding: 8px 10px; }
      .filter-nav__list li.flexMenu-viewMore a {
        border: none !important; }
      .filter-nav__list li.flexMenu-viewMore > a {
        color: #CC1B28;
        font-weight: bold; }
        .filter-nav__list li.flexMenu-viewMore > a span {
          width: 30px;
          height: 4px;
          background: #CC1B28;
          display: inline-block;
          position: relative;
          top: -4px;
          margin-left: 5px; }
          .filter-nav__list li.flexMenu-viewMore > a span:after, .filter-nav__list li.flexMenu-viewMore > a span:before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            background: #CC1B28;
            transform-origin: center; }
          .filter-nav__list li.flexMenu-viewMore > a span:after {
            bottom: 100%;
            margin-bottom: 3px; }
          .filter-nav__list li.flexMenu-viewMore > a span:before {
            top: 100%;
            margin-top: 3px; }
  .filter-nav__list--alt li.flexMenu-viewMore > a {
    color: #0A4798; }
    .filter-nav__list--alt li.flexMenu-viewMore > a span {
      background: #0A4798; }
      .filter-nav__list--alt li.flexMenu-viewMore > a span:after, .filter-nav__list--alt li.flexMenu-viewMore > a span:before {
        background: #0A4798; }
  .filter-nav__list a {
    border-bottom: 2px solid transparent;
    white-space: nowrap; }
    .filter-nav__list a:hover, .filter-nav__list a.active {
      border-color: #CC1B28; }

.card-sim {
  max-width: 33.33333%;
  flex: 0 0 33.33333%;
  padding: 0 10px;
  margin-bottom: 30px; }
  @media (max-width: 768px) {
    .card-sim {
      max-width: 50%;
      flex: 0 0 50%; } }
  @media (max-width: 576px) {
    .card-sim {
      max-width: 100%;
      flex: 0 0 100%; } }
  .card-sim__content {
    padding: 10px 10px 20px;
    background: #F2F2F2;
    height: 100%;
    position: relative; }
    .card-sim__content:hover {
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
    @supports (flex-direction: column) {
      .card-sim__content {
        display: flex;
        flex-direction: column; } }
  .card-sim__status {
    position: absolute;
    top: 21px;
    left: 6px;
    font-size: 0.82353em;
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #fff;
    background: #19124A;
    z-index: 2; }
    .card-sim__status:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      border-top: 3px solid #06446B;
      border-left: 5px solid transparent; }
  .card-sim__img {
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 12px; }
    .card-sim__img:before {
      content: '';
      display: block;
      padding-top: 75%; }
    .card-sim__img img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .card-sim__action {
    margin-bottom: 13px;
    display: flex;
    align-items: center; }
    .card-sim__action .stars-view {
      margin-right: auto; }
  .card-sim__favorite {
    font-size: 0.64706em;
    display: flex;
    align-items: center; }
    .card-sim__favorite-text {
      text-decoration: underline; }
      .card-sim__favorite-text:hover {
        text-decoration: none; }
    .card-sim__favorite .icon {
      font-size: 0.76471em;
      margin-right: 8px;
      position: relative;
      bottom: -1px; }
  .card-sim__title {
    margin-bottom: 11px;
    font-size: 0.88235em;
    font-weight: bold; }
    .card-sim__title-text {
      color: #0A4798;
      margin-right: 4px; }
    .card-sim__title a:hover {
      text-decoration: underline; }
  .card-sim__text {
    font-style: italic;
    font-size: 0.70588em;
    margin-bottom: 21px; }
  .card-sim__bottom {
    margin-top: auto;
    text-align: right; }
  .card-sim__view {
    font-size: 0.88235em;
    font-weight: bold;
    text-decoration: underline; }
    .card-sim__view:hover {
      text-decoration: none; }

.stars-view {
  display: flex;
  align-items: center; }
  .stars-view__list {
    display: flex;
    align-items: center; }
  .stars-view__item {
    margin-right: 4px;
    font-size: 12px;
    color: #E0E0E0; }
    .stars-view__item.active {
      color: #FFD428; }
    .stars-view__item:last-child {
      margin-right: 0; }
    .stars-view__item .icon {
      position: relative;
      bottom: -1px; }
  .stars-view__points {
    margin-left: 11px;
    font-size: 11px; }

.text {
  position: relative; }
  .text p a {
    text-decoration: underline; }
    .text p a:hover {
      text-decoration: none; }
  .text-names {
    column-gap: 100px;
    column-count: 2; }
    @media (max-width: 768px) {
      .text-names {
        column-gap: 50px; } }
    @media (max-width: 480px) {
      .text-names {
        column-count: 1; } }
  .text-tooltip:hover .text-tooltip__content {
    opacity: 1;
    visibility: visible; }
  .text-tooltip__btn {
    font-style: italic;
    color: #0A4798;
    text-decoration: underline; }
  .text-tooltip__content {
    position: absolute;
    z-index: 1;
    background: #F2F2F2;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    padding: 15px;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 40px;
    opacity: 0;
    visibility: hidden;
    max-width: 100%; }
    @media (max-width: 768px) {
      .text-tooltip__content {
        margin-left: 10px;
        margin-right: 10px; } }
    @media (max-width: 576px) {
      .text-tooltip__content {
        display: block; } }
    .text-tooltip__content:before {
      content: '';
      position: absolute;
      left: 0;
      top: -20px;
      height: 20px;
      width: 100%; }
  .text-tooltip__img {
    width: 150px;
    min-width: 150px;
    margin-right: 30px;
    min-height: 110px;
    overflow: hidden;
    display: block; }
    @media (max-width: 576px) {
      .text-tooltip__img {
        margin-right: 0;
        margin-bottom: 15px; } }
    .text-tooltip__img img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .text-tooltip__info {
    display: block; }
  .text-tooltip__name {
    display: block;
    margin-bottom: 5px; }
    .text-tooltip__name a {
      font-size: 1.05882em;
      font-weight: bold; }
      .text-tooltip__name a:hover {
        text-decoration: underline;
        color: #0A4798; }
  .text-tooltip__text {
    display: block;
    margin-bottom: 3px;
    font-size: 0.88235em; }
  .text-tooltip__action {
    display: flex;
    justify-content: flex-end; }
  .text-tooltip__link {
    font-weight: bold;
    color: #0A4798;
    font-size: 0.88235em; }
    .text-tooltip__link:hover {
      text-decoration: underline; }
  .text--alt {
    background: #F0FAFD;
    padding: 25px 25px 30px;
    font-style: italic; }
  .text-action {
    display: flex;
    justify-content: center;
    padding-top: 30px; }
    .text-action .btn {
      width: auto;
      display: inline-flex;
      padding: 0 35px;
      height: 40px; }
  .text .section {
    padding: 0;
    margin: 50px 0; }
  .text h2 {
    text-align: center;
    font-weight: bold;
    font-size: 1.35294em;
    line-height: 1.76471em;
    margin-bottom: 20px;
    margin-top: 30px; }
    .text h2:first-child {
      margin-top: 0; }
  .text h3 {
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    margin-top: 30px; }
    .text h3:first-child {
      margin-top: 0; }
  .text ul,
  .text ol {
    margin: 20px 0;
    padding-left: 15px; }
    .text ul li,
    .text ol li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 5px;
      font-style: italic; }
      .text ul li:last-child,
      .text ol li:last-child {
        margin-bottom: 0; }
      .text ul li:before,
      .text ol li:before {
        content: '';
        position: absolute;
        left: 0;
        top: 6px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 3px solid #0A4798; }
  .text ul.list-alt > li:before {
    border-color: #CC1B28; }
  .text table {
    width: 100%;
    border-spacing: 4px;
    border-collapse: separate;
    margin-top: 30px; }
    .text table tr {
      border-bottom: 3px solid #fff; }
    .text table td,
    .text table th {
      border-right: 3px solid #fff; }
      .text table td:last-child,
      .text table th:last-child {
        border-right: none; }
    .text table th {
      background: #D9F0F7;
      padding: 10px 14px;
      height: 49px;
      text-align: center;
      vertical-align: middle;
      font-size: 0.88235em; }
    .text table td {
      background: #F0FAFD;
      padding: 10px 14px;
      vertical-align: middle;
      font-style: italic;
      text-align: center;
      min-width: 130px;
      font-size: 0.88235em; }
    .text table.classification-table td:nth-child(1) {
      font-weight: bold; }
    .text table.classification-table td:nth-child(2) {
      text-align: left; }
  .text .table-col-info {
    min-width: 300px; }
  .text .table__info {
    text-align: right;
    margin-bottom: 30px; }
    .text .table__info span {
      display: inline-block;
      background: #19124A;
      padding: 6px 20px;
      font-style: italic;
      font-size: 0.88235em;
      color: #fff; }
      @media (max-width: 1023px) {
        .text .table__info span {
          margin-top: 0;
          margin-right: 0; } }

.classification-box {
  margin-bottom: 30px; }
  .classification-box__item {
    display: flex;
    align-items: center;
    margin-bottom: 30px; }
    @media (max-width: 576px) {
      .classification-box__item {
        display: block; } }
    .classification-box__item p:last-child, .classification-box__item:last-child {
      margin-bottom: 0; }
    .classification-box__item-img {
      width: 77px;
      min-width: 77px;
      margin-right: 23px;
      display: flex;
      justify-content: center; }
      @media (max-width: 576px) {
        .classification-box__item-img {
          margin-right: 0;
          min-width: 0;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 15px; } }
      .classification-box__item-img img {
        max-width: 55px;
        display: block; }

.classification-row {
  margin-bottom: 30px; }
  .classification-row__item {
    margin-bottom: 20px;
    font-size: 1.05882em;
    letter-spacing: 0.02em;
    line-height: 1.35294em; }
    .classification-row__item:last-child {
      margin-bottom: 0; }
    .classification-row__item-name {
      display: inline;
      font-weight: bold;
      color: #0A4798; }
    .classification-row__item-text {
      display: inline; }
      .classification-row__item-text p {
        margin-top: 10px; }
        .classification-row__item-text p:first-child {
          display: inline; }
        .classification-row__item-text p:last-child {
          margin-bottom: 0; }

ul.classification-list {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px; }
  ul.classification-list li {
    margin-right: 12px;
    margin-bottom: 10px;
    padding: 0; }
    ul.classification-list li:last-child {
      margin-right: 0; }
    ul.classification-list li:before {
      display: none; }
    ul.classification-list li a {
      color: #fff;
      line-height: 1.17647em;
      white-space: nowrap;
      font-size: 0.88235em;
      font-weight: 400;
      position: relative;
      border-radius: 2px;
      height: 20px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      font-style: normal;
      background: #B88B58; }
      ul.classification-list li a:hover {
        background: #9b7142;
        box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25); }
        ul.classification-list li a:hover:before {
          border-left-color: #9b7142; }
        ul.classification-list li a:hover:after {
          content: '';
          position: absolute;
          left: 100%;
          top: 3px;
          border-left: 6px solid #9b7142;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: none;
          border-radius: 0;
          filter: blur(2px);
          z-index: 1; }
      ul.classification-list li a:before {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        border-left: 5px solid #B88B58;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: none;
        border-radius: 0;
        z-index: 3; }

ol.classification-list {
  counter-reset: lits-num;
  padding-left: 0; }
  ol.classification-list > li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 40px; }
    ol.classification-list > li:last-child {
      margin-bottom: 0; }
    ol.classification-list > li:before {
      counter-increment: lits-num;
      content: counter(lits-num);
      border: none;
      width: 15px;
      height: 18px;
      top: 0;
      font-weight: bold;
      font-size: 0.82353em;
      background: #D9F0F7;
      display: flex;
      justify-content: center;
      border-radius: 3px; }
    ol.classification-list > li:after {
      content: '';
      position: absolute;
      top: 0;
      left: 15px;
      border-left: 4px solid #D9F0F7;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent; }

.table-wrap {
  overflow-x: auto;
  max-width: 100%; }
  .table-wrap table {
    border-spacing: 0;
    border-collapse: collapse; }
  .table-wrap.scroll-scrollx_visible {
    padding-bottom: 17px; }

.alert {
  background-color: #F0FAFD;
  margin-bottom: 30px;
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-top: 30px; }
  .alert--comb {
    display: flex;
    margin-right: -38px; }
    @media (max-width: 768px) {
      .alert--comb {
        display: block;
        margin-right: 0; } }
    .alert--comb .alert {
      width: 100%;
      max-width: calc(50% - 38px);
      margin-right: 38px;
      flex: 0 0 calc(50% - 38px); }
      @media (max-width: 768px) {
        .alert--comb .alert {
          max-width: 100%;
          margin-right: 0; } }
  .alert--arrow {
    position: relative; }
    .alert--arrow:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 23px;
      height: 100%;
      background: #D9F0F7; }
      @supports (clip-path: polygon(0 0, 100% 100%)) {
        .alert--arrow:before {
          clip-path: polygon(0 0, 10px 0, 100% 50%, 10px 100%, 0 100%); } }
  .alert--full {
    width: 100%;
    margin-left: 0; }
  .alert--ok {
    background-color: #F4F9F1; }
  .alert--bad {
    background-color: #FBF3F3; }
  .alert__content {
    padding: 19px 19px 19px 71px;
    font-style: italic;
    background-repeat: no-repeat;
    background-position: 12px 40%;
    line-height: 22px; }
  .alert__title {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    padding-top: 10px; }
    .alert__title + .alert__content {
      padding-top: 10px; }
  .alert ul {
    padding-left: 0;
    margin: 0; }

.review {
  margin-top: 50px;
  margin-bottom: 50px; }
  .review:first-child {
    margin-top: 0; }
  .review:last-child {
    margin-bottom: 0; }
  .review__content {
    background: #F0FAFD;
    padding: 20px 25px; }
  .review__stars {
    float: left;
    margin-right: 10px;
    position: relative;
    bottom: -2px; }
    .review__stars .stars-view__item {
      margin-right: 6px; }
    .review__stars .icon {
      font-size: 16px; }
  .review__text {
    letter-spacing: 0.02em;
    font-size: 18px;
    line-height: 23px;
    font-style: italic; }
  .review__user {
    display: flex;
    justify-content: flex-end; }
    .review__user-cont {
      background: #D9F0F7;
      display: flex;
      align-items: center;
      padding: 0 10px 0 25px;
      height: 38px;
      position: relative; }
      @media (max-width: 576px) {
        .review__user-cont {
          height: auto;
          width: 100%;
          padding-top: 5px;
          padding-bottom: 5px;
          justify-content: flex-end; } }
      .review__user-cont:before {
        content: '';
        position: absolute;
        right: 100%;
        top: 0;
        border-top: 38px solid #D9F0F7;
        border-left: 38px solid transparent; }
        @media (max-width: 576px) {
          .review__user-cont:before {
            display: none; } }
    .review__user-avatar {
      width: 27px;
      min-width: 27px;
      height: 27px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 9px;
      display: block; }
      .review__user-avatar img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .review__user-name {
      letter-spacing: 0.02em;
      font-size: 18px;
      line-height: 23px;
      font-style: italic;
      margin-right: 10px;
      flex: 1; }
      @media (max-width: 576px) {
        .review__user-name {
          flex: initial; } }
      .review__user-name a:hover {
        text-decoration: underline; }
      .review__user-name span {
        font-style: normal;
        font-size: 15px;
        display: inline-block;
        margin-right: 13px; }
    .review__user-like {
      display: flex;
      align-items: center;
      margin-left: auto; }
      .review__user-like-val {
        font-size: 15px;
        margin-right: 3px;
        position: relative;
        bottom: -2px;
        font-weight: bold;
        position: relative;
        top: 0px; }
    .review__user-btn {
      border: none;
      background-color: transparent;
      font-size: 21px;
      cursor: pointer;
      padding: 0;
      margin-left: 4px; }
  .reviews {
    margin-bottom: 50px; }
    .reviews__list {
      margin-bottom: 25px; }
    .reviews__action {
      display: flex;
      align-items: center; }
      @media (max-width: 768px) {
        .reviews__action {
          display: block; } }
      .reviews__action .btn {
        margin-right: 25px;
        max-width: 297px; }
        @media (max-width: 768px) {
          .reviews__action .btn {
            max-width: 100%;
            margin-right: 0;
            margin-bottom: 15px; } }
    .reviews__info {
      flex: 1;
      font-size: 15px;
      color: #0A4798; }
  .review-prof {
    margin-bottom: 30px; }
    .review-prof__title {
      padding-left: 260px;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 3px; }
      @media (max-width: 576px) {
        .review-prof__title {
          padding-left: 0;
          text-align: center;
          margin-bottom: 10px; } }
    .review-prof__main {
      background: #F0FAFD;
      display: flex;
      padding: 20px 12px; }
      @media (max-width: 576px) {
        .review-prof__main {
          display: block; } }
    .review-prof__info {
      margin-right: 40px;
      width: 100%;
      max-width: 220px;
      background: inherit;
      margin-top: -55px;
      padding-top: 20px;
      margin-left: -12px;
      padding-left: 12px;
      position: relative; }
      @media (max-width: 576px) {
        .review-prof__info {
          margin-left: 0;
          padding-left: 0;
          margin-top: 0;
          padding-top: 0;
          margin-bottom: 20px;
          max-width: 100%; } }
      .review-prof__info:after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        border-bottom: 35px solid #F0FAFD;
        border-right: 20px solid transparent; }
    .review-prof__avatar {
      position: relative;
      display: inline-block;
      margin-bottom: 17px; }
      .review-prof__avatar-img {
        display: block;
        border-radius: 50%;
        overflow: hidden;
        width: 60px;
        height: 60px; }
        .review-prof__avatar-img img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center; }
    .review-prof__name {
      font-size: 15px;
      font-weight: bold;
      font-style: italic;
      margin-bottom: 4px;
      color: #0A4798; }
      .review-prof__name a {
        color: inherit; }
      .review-prof__name:hover {
        text-decoration: underline; }
    .review-prof__prof {
      font-style: italic;
      font-size: 15px;
      color: #0A4798; }
    .review-prof__exp {
      position: absolute;
      left: 100%;
      bottom: 0;
      background: #FFD428;
      border-radius: 6px;
      padding: 3px 10px 3px 23px;
      font-size: 13px;
      margin-left: -7px; }
      .review-prof__exp:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -7px;
        transform: translateY(-50%);
        width: 22px;
        height: 27px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../img/shild.svg"); }
    .review-prof__text {
      flex: 1;
      letter-spacing: 0.02em;
      font-size: 18px;
      line-height: 23px; }

.like.active .like__btn {
  color: #FFD428; }

.slider-cards__list {
  margin: 0 -10px 0; }

.slider-cards__item {
  padding-top: 10px;
  padding-bottom: 10px; }

.slider-cards .card-sim {
  max-width: 100%;
  margin-bottom: 0; }

.slider-cards__more {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end; }
  .slider-cards__more a {
    text-decoration: underline;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center; }
    .slider-cards__more a:hover {
      text-decoration: none; }
    .slider-cards__more a:after {
      content: '';
      width: 11px;
      height: 11px;
      border-bottom: 3px solid #CC1B28;
      border-right: 3px solid #CC1B28;
      transform: rotate(-45deg);
      margin-left: 8px; }

.slider-cards .slick-arrow {
  top: 222px; }
  @media (max-width: 1400px) {
    .slider-cards .slick-arrow {
      top: 166px; } }
  @media (max-width: 1200px) {
    .slider-cards .slick-arrow {
      top: 200px; } }
  @media (max-width: 1023px) {
    .slider-cards .slick-arrow {
      top: 240px; } }
  @media (max-width: 768px) {
    .slider-cards .slick-arrow {
      top: 48%; } }

.comment {
  margin-bottom: 30px; }
  .comments-alert {
    width: 100%;
    max-width: 600px;
    padding: 60px;
    background: #F2F2F2;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    line-height: 23px;
    color: #19124A;
    text-align: center; }
    @media (max-width: 1023px) {
      .comments-alert {
        padding: 20px; } }
  .comments__form {
    margin-top: 40px; }
  .comments-form > .form__help {
    margin-top: 20px;
    opacity: .7; }
  .comments-form .files-rezult li {
    width: auto;
    max-width: 84px;
    max-height: 60px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px; }
    .comments-form .files-rezult li:hover {
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25); }
    .comments-form .files-rezult li img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    .comments-form .files-rezult li .delete-link {
      width: 12px;
      height: 12px;
      background: #D9F0F7;
      border-radius: 1px;
      top: 4px;
      right: 4px; }
      .comments-form .files-rezult li .delete-link:before, .comments-form .files-rezult li .delete-link:after {
        background: #19124A; }
  .comments-form__title {
    font-size: 0.88235em;
    margin-bottom: 5px; }
  .comments-form__top {
    display: flex;
    align-items: center;
    margin-bottom: 15px; }
    @media (max-width: 1023px) {
      .comments-form__top {
        flex-wrap: wrap; } }
    .comments-form__top .form__label {
      margin-right: 15px;
      flex: 1;
      margin-bottom: 0; }
      @media (max-width: 480px) {
        .comments-form__top .form__label {
          flex: 0 0 100%;
          width: 100%;
          margin-top: 10px; }
          .comments-form__top .form__label:first-child {
            margin-top: 0; } }
    .comments-form__top .form__input {
      height: 36px; }
  .comments-form__text {
    color: #828282;
    font-size: 0.82353em;
    margin-right: 30px; }
    @media (max-width: 1023px) {
      .comments-form__text {
        margin: 15px 0;
        flex: 0 0 100%;
        width: 100%; } }
  .comments-form__cabinet {
    max-width: 95px;
    margin-left: 30px; }
  .comments-form__social {
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 1023px) {
      .comments-form__social {
        margin-left: 0;
        margin-right: 0; } }
    .comments-form__social ul {
      flex-wrap: nowrap; }
  .comments-form__cont {
    margin-bottom: 20px; }
    @media (max-width: 768px) {
      .comments-form__cont .form__help {
        display: block;
        margin-left: 0;
        margin-top: 5px; } }
  .comments-form__bottom {
    display: flex;
    align-items: center; }
    @media (max-width: 576px) {
      .comments-form__bottom {
        display: block; } }
  .comments-form__btn {
    width: auto;
    height: 40px;
    padding: 0 30px;
    margin-left: auto; }
    @media (max-width: 576px) {
      .comments-form__btn {
        margin-top: 15px; } }
  .comments-form__icon {
    background: #D9F0F7;
    border-radius: 5px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    margin-right: 10px;
    font-size: 1.52941em; }
  .comments-form__info {
    margin-top: 27px;
    color: #0A4798;
    font-size: 0.88235em;
    line-height: 16px; }
  .comments__content {
    position: relative;
    overflow: hidden;
    max-height: 520px; }
    .comments__content:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.491713) 25.97%, #FFFFFF 82.87%);
      z-index: 1; }
  .comments.active .comments__content {
    overflow: visible;
    max-height: 99999px; }
    .comments.active .comments__content:after {
      display: none; }
  .comments.active .comments__more a:after {
    transform: translateY(-50%) rotate(180deg); }
  .comments__more {
    display: flex;
    justify-content: center; }
    .comments__more .btn {
      width: auto;
      padding: 0 45px 0 27px;
      position: relative; }
      .comments__more .btn:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        border-top: 12px solid #19124A;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent; }
  .comment__top {
    display: flex;
    align-items: flex-end;
    margin-bottom: 6px;
    flex-wrap: wrap; }
  .comment__avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 12px; }
    .comment__avatar img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .comment__name {
    letter-spacing: 0.02em;
    font-size: 1.05882em;
    font-style: italic;
    margin-right: 20px; }
  .comment__date {
    color: #828282; }
  .comment__text {
    background: #F0FAFD;
    border-radius: 3px;
    padding: 15px 19px;
    margin-bottom: 20px; }
  .comment__action {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px; }
  .comment__btn {
    width: auto;
    padding: 0 30px;
    height: 40px; }
  .comment__like {
    font-size: 0.82353em;
    display: flex;
    align-items: center; }
    .comment__like-val {
      margin-right: 11px; }
    .comment__like-btn {
      font-size: 1.58824em;
      border: none;
      background-color: transparent;
      padding: 0;
      cursor: pointer; }
  .comment__answer {
    padding-left: 25px; }

.faq-list__val {
  text-align: right;
  font-weight: bold;
  font-size: 15px;
  padding: 3px 0; }
  .faq-list__val .faq-list__more {
    display: inline-block;
    margin-top: 30px; }
    .faq-list__val .faq-list__more:hover {
      text-decoration: underline; }

.faq-list__more {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto; }

.faq-list__action {
  margin-top: 13px;
  margin-bottom: 25px; }

.faq-box__action {
  display: flex;
  justify-content: center;
  align-items: center; }
  .faq-box__action .btn {
    margin-right: 11px; }
    .faq-box__action .btn:last-child {
      margin-right: 0; }
  .faq-box__action .social {
    margin-left: auto; }
    .faq-box__action .social li {
      margin-bottom: 0; }

.faq-box__info {
  font-style: italic;
  font-size: 15px;
  margin-top: 19px;
  max-width: 290px;
  text-align: left;
  margin-left: auto; }

.faq-box.active .faq-box__btn-old,
.faq-box.active .faq-box__text-old {
  display: none; }

.faq-box.active .faq-box__text-alt {
  display: block; }

.faq-box__text {
  max-width: 520px;
  margin-left: auto;
  margin-right: auto; }
  .faq-box__text-alt {
    display: none;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto; }

.faq-box--alt {
  position: relative; }
  .faq-box--alt.opened .title-box__content {
    max-height: 99999px; }
  .faq-box--alt.opened .faq-box__trigger {
    display: none; }
  .faq-box--alt:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
    height: 114px;
    box-shadow: inset 0px -30px 25px rgba(255, 255, 255, 0.95); }
  .faq-box--alt .title-box__content {
    overflow: hidden;
    max-height: 114px; }
  .faq-box--alt .title-box__text {
    text-align: left;
    font-size: 17px;
    line-height: 22px; }

.faq-box__trigger {
  position: absolute;
  top: 100%;
  margin-top: 15px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer; }

.faq-box--mod {
  padding-left: 74px;
  padding-right: 74px; }
  @media (max-width: 1200px) {
    .faq-box--mod {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (max-width: 768px) {
    .faq-box--mod .faq-box__action {
      flex-wrap: wrap;
      justify-content: flex-start; }
      .faq-box--mod .faq-box__action .btn {
        margin-bottom: 10px; } }
  @media (max-width: 768px) {
    .faq-box--mod .social {
      flex: 0 0 100%;
      width: 100%; } }
  .faq-box--mod .title-box__text {
    max-width: 100%;
    text-align: left; }

.faq-section {
  display: none; }
  .faq-section.active {
    display: block;
    margin-bottom: 20px; }

.faq__btn {
  margin-left: 0;
  width: auto;
  padding: 0 30px; }

.card-faq {
  margin-bottom: 30px;
  border: 1px solid #C2E9F5;
  position: relative;
  display: flex; }
  @media (max-width: 768px) {
    .card-faq {
      display: block; } }
  .card-faq:hover {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-color: transparent; }
  .card-faq:last-child {
    margin-bottom: 0; }
  .card-faq__favorite {
    position: absolute;
    top: 3px;
    right: -5px;
    font-size: 1.17647em; }
  .card-faq__text a:hover, .card-faq__name a:hover {
    text-decoration: underline;
    color: #0A4798; }
  .card-faq__img {
    width: 260px;
    min-width: 260px;
    overflow: hidden;
    margin-right: 4px;
    display: block; }
    @media (max-width: 1200px) {
      .card-faq__img {
        width: 200px;
        min-width: 200px; } }
    @media (max-width: 768px) {
      .card-faq__img {
        width: 100%;
        max-width: 100%; } }
    .card-faq__img img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .card-faq__info {
    padding: 8px 15px 8px 15px;
    flex: 1; }
    @supports (flex-direction: column) {
      .card-faq__info {
        display: flex;
        flex-direction: column; } }
  .card-faq__name {
    font-size: 1.05882em;
    font-weight: bold;
    margin-bottom: 10px; }
    .card-faq__name span {
      color: #0A4798; }
  @supports (flex-direction: column) {
    .card-faq__text {
      margin-top: auto;
      margin-bottom: auto; } }
  .card-faq__bottom {
    display: flex;
    margin-top: 8px;
    align-items: center;
    padding-right: 15px;
    font-size: 0.82353em; }
    @media (max-width: 768px) {
      .card-faq__bottom {
        display: block; } }
  .card-faq__user {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding-right: 10px; }
    .card-faq__user:hover .card-faq__user-name {
      text-decoration: underline;
      color: #0A4798; }
    .card-faq__user-avatar {
      border-radius: 50%;
      overflow: hidden;
      width: 25px;
      min-width: 25px;
      height: 25px;
      margin-right: 10px; }
      .card-faq__user-avatar img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .card-faq__user-name {
      font-size: 0.82353em; }
  .card-faq .icon {
    margin-right: 10px;
    position: relative;
    bottom: -2px; }
  .card-faq__comments {
    margin-left: 44px;
    padding: 3px 9px;
    background: #F0FAFD;
    border-radius: 7px;
    display: flex;
    align-items: center; }
    @media (max-width: 1200px) {
      .card-faq__comments {
        margin-left: auto; } }
    @media (max-width: 768px) {
      .card-faq__comments {
        display: inline-block; } }
    .card-faq__comments .icon {
      font-size: 0.76471em; }
  .card-faq__view {
    display: flex;
    align-items: center;
    margin-left: 22px; }
    @media (max-width: 768px) {
      .card-faq__view {
        margin-left: 0;
        display: inline-block;
        margin-right: 10px; } }
    .card-faq__view .icon {
      font-size: 1.17647em; }
  .card-faq__time {
    display: flex;
    align-items: center; }
    @media (max-width: 768px) {
      .card-faq__time {
        display: inline-block;
        margin-right: 10px; } }
    .card-faq__time .icon {
      font-size: 0.70588em; }

@media (max-width: 480px) {
  .pagination__prev, .pagination__next {
    display: none; } }

.pagination ul {
  display: flex;
  align-items: center;
  justify-content: center; }

.pagination li {
  border: 1px solid #C2E9F5;
  margin-right: 3px; }
  .pagination li:last-child {
    margin-right: 0; }
  .pagination li.active a {
    background: #C2E9F5;
    color: #0A4798;
    text-decoration: underline;
    font-weight: bold; }
  .pagination li.disabled a {
    color: #BDBDBD;
    pointer-events: none; }
  .pagination li:nth-child(1), .pagination li:nth-child(2), .pagination li:nth-last-child(2), .pagination li:nth-last-child(3) {
    margin-right: 10px; }

.pagination a {
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 8px;
  font-size: 15px; }
  .pagination a:hover {
    background: #C2E9F5; }

.card-download {
  max-width: 33.33333%;
  flex: 0 0 33.33333%;
  padding: 0 10px;
  margin-bottom: 30px; }
  @media (max-width: 768px) {
    .card-download {
      max-width: 50%;
      flex: 0 0 50%; } }
  @media (max-width: 480px) {
    .card-download {
      max-width: 100%;
      flex: 0 0 100%; } }
  .card-download__content {
    padding: 22px 35px;
    background: #F0FAFD;
    height: 100%;
    text-align: center; }
    @supports (flex-direction: column) {
      .card-download__content {
        display: flex;
        flex-direction: column; } }
    .card-download__content:hover {
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
  .card-download__img {
    height: 210px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .card-download__img img {
      max-width: 100%;
      max-height: 100%;
      display: block; }
  .card-download__name {
    font-weight: bold;
    margin-bottom: 7px; }
  .card-download__title {
    margin-bottom: 9px;
    color: #0A4798; }
  .card-download__text {
    font-size: 0.88235em;
    margin-bottom: 28px; }
  .card-download__btn {
    width: auto;
    padding: 0 30px;
    display: inline-flex; }
  @supports (flex-direction: column) {
    .card-download__action {
      margin-top: auto; } }

.joke {
  margin-bottom: 44px;
  background: #fff;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  padding: 30px 30px 17px;
  letter-spacing: 0.02em;
  font-size: 18px;
  line-height: 23px; }
  .joke:hover {
    box-shadow: 2px 2px 17px rgba(0, 0, 0, 0.25); }
  .jokes__more {
    margin-top: 50px; }
    .jokes__more .btn {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      height: 40px; }
  .joke:last-child {
    margin-bottom: 0; }
  .joke__text {
    margin-bottom: 20px; }
  .joke__bottom {
    display: flex;
    align-items: center; }
    @media (max-width: 768px) {
      .joke__bottom {
        display: block; } }
  .joke__stars {
    margin-right: auto; }
    .joke__stars .icon {
      font-size: 16px; }
    .joke__stars .stars-view__list {
      position: relative;
      bottom: -2px; }
  .joke__points {
    line-height: 23px;
    margin-left: 15px; }
  @media (max-width: 768px) {
    .joke__user {
      margin-top: 10px; } }
  @media (max-width: 480px) {
    .joke__user-title {
      display: none; } }
  .joke__user .review__user-cont {
    background-color: transparent;
    padding: 0; }
    .joke__user .review__user-cont:before {
      display: none; }
  .joke__user-title {
    margin-right: 25px; }

.competition-tile {
  margin-bottom: 70px; }
  .competition-tile__content {
    max-width: 875px;
    margin-left: auto;
    margin-right: auto; }
  .competition-tile__list {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .competition-tile__list {
        display: block; } }
  .competition-tile__item {
    position: relative; }
    @media (max-width: 768px) {
      .competition-tile__item {
        margin-bottom: 20px; }
        .competition-tile__item:last-child {
          margin-bottom: 0; } }
    .competition-tile__item-name {
      position: absolute;
      width: 100%;
      max-width: 155px;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 17px;
      text-align: center; }
    @media (max-width: 768px) {
      .competition-tile__item-img {
        display: flex;
        justify-content: center; } }

.competition-gard {
  border: 5px solid #2ABFCB;
  border-top: none;
  padding: 56px 35px 35px;
  position: relative;
  margin-bottom: 70px; }
  .competition-gard__box-title {
    font-size: 31px;
    font-weight: bold;
    margin-bottom: 8px;
    line-height: 1;
    color: #2ABFCB;
    text-transform: uppercase;
    text-align: center; }
    @media (max-width: 768px) {
      .competition-gard__box-title {
        font-size: 24px; } }
  .competition-gard__box-name {
    color: #4F4F4F;
    font-size: 21px;
    text-align: center;
    margin-bottom: 20px; }
  .competition-gard__box-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -30px; }
    .competition-gard__box-list li {
      width: 100%;
      max-width: 365px;
      margin-right: 30px;
      color: #4F4F4F;
      font-size: 24px;
      font-weight: bold; }
      @media (max-width: 576px) {
        .competition-gard__box-list li {
          font-size: 20px; } }
      .competition-gard__box-list li:last-child {
        max-width: 100%;
        text-align: center; }
        @media (max-width: 1023px) {
          .competition-gard__box-list li:last-child {
            max-width: 365px;
            text-align: left; } }
      .competition-gard__box-list li div {
        margin-bottom: 10px; }
      .competition-gard__box-list li span {
        color: #2ABFCB; }
  .competition-gard__wrap {
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding-top: 70px;
    margin-bottom: 70px; }
  .competition-gard__title {
    position: absolute;
    font-size: 39px;
    font-weight: bold;
    text-transform: uppercase;
    color: #2ABFCB;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    padding: 0 20px; }
    @media (max-width: 768px) {
      .competition-gard__title {
        font-size: 24px; } }
    @media (max-width: 576px) {
      .competition-gard__title {
        white-space: pre-wrap;
        width: 100%;
        max-width: calc(100% - 20px);
        text-align: center; } }
    .competition-gard__title:after, .competition-gard__title:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100vw;
      border-bottom: 5px solid #2ABFCB; }
    .competition-gard__title:after {
      left: 100%; }
    .competition-gard__title:before {
      right: 100%; }
  .competition-gard__content {
    color: #4F4F4F;
    text-align: center; }

.competition-cont.active {
  position: relative; }
  .competition-cont.active > * {
    filter: blur(2px); }
  .competition-cont.active:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .competition-cont.active .competition-cont__form {
    display: block;
    filter: blur(0); }

.competition-cont__form {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #D9F0F7;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 100%;
  max-width: 622px;
  padding: 26px 40px 18px;
  z-index: 10;
  text-align: center; }
  @media (max-width: 480px) {
    .competition-cont__form {
      padding-left: 15px;
      padding-right: 15px; } }
  .competition-cont__form-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 35px; }
  .competition-cont__form-content {
    max-width: 314px;
    margin-left: auto;
    margin-right: auto; }
    .competition-cont__form-content .btn {
      max-width: 178px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      height: 40px; }
    .competition-cont__form-content .form__input {
      height: 45px; }
    .competition-cont__form-content .social {
      margin-bottom: 24px; }
      .competition-cont__form-content .social ul {
        justify-content: center; }

.competition-box {
  border: 2px solid #D9F0F7;
  padding: 30px;
  text-align: center;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 30px; }
  .competition-box__user {
    color: #CC1B28; }

.competition__form-action {
  justify-content: flex-start;
  flex-wrap: wrap; }
  .competition__form-action + .form__help {
    margin-top: 0; }
  .competition__form-action .btn {
    margin-right: 15px;
    width: auto;
    padding: 0 30px;
    display: inline-flex;
    margin-bottom: 10px; }
    .competition__form-action .btn:last-child {
      margin-right: 0; }

.competition-banner {
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-bottom: 50px;
  overflow: hidden; }
  .competition-banner__tile {
    position: relative;
    height: 100%;
    z-index: 1; }
    .competition-banner__tile:after {
      content: '';
      position: absolute;
      top: 14px;
      left: 14px;
      right: 14px;
      bottom: 14px;
      border: 7px solid #FFD428; }
      @media (max-width: 768px) {
        .competition-banner__tile:after {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-width: 3px; } }
    .competition-banner__tile-logo {
      position: absolute;
      right: -14px;
      top: 57px;
      z-index: 1; }
      @media (max-width: 768px) {
        .competition-banner__tile-logo {
          right: 50%;
          transform: translateX(50%);
          top: 30px; } }
      @media (max-width: 480px) {
        .competition-banner__tile-logo img {
          display: block;
          max-width: 260px; } }
    .competition-banner__tile-cont {
      height: 100%;
      background: #fff;
      max-width: 343px;
      padding: 52px 38px 52px 55px;
      text-align: right; }
      @media (max-width: 768px) {
        .competition-banner__tile-cont {
          max-width: 100%;
          text-align: center;
          padding: 150px 10px 30px; } }
    .competition-banner__tile-title {
      font-size: 70px;
      color: #000;
      letter-spacing: -0.07em;
      text-transform: uppercase;
      line-height: 1.1;
      margin-bottom: 20px;
      letter-spacing: -0.07em;
      display: flex;
      justify-content: flex-end; }
      @media (max-width: 768px) {
        .competition-banner__tile-title {
          justify-content: center;
          font-size: 55px; } }
    .competition-banner__tile-name {
      color: #000;
      font-size: 45px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      line-height: 0.9; }
      @media (max-width: 768px) {
        .competition-banner__tile-name {
          font-size: 40px; } }
      .competition-banner__tile-name sup {
        font-size: 96px; }
      .competition-banner__tile-name span {
        color: #76BA1E;
        font-size: 110px;
        position: relative;
        right: -4px; }
  .competition-banner__gard {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 40px 30px;
    margin-top: 53px;
    margin-left: 64px;
    width: 100%;
    max-width: 314px;
    position: relative;
    z-index: 1; }
    @media (max-width: 480px) {
      .competition-banner__gard {
        margin-left: 10px;
        margin-right: 10px;
        max-width: calc(100% - 20px); } }
    .competition-banner__gard img {
      max-width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 15px; }
    .competition-banner__gard-title {
      margin-bottom: 20px;
      font-size: 21px;
      color: #4F4F4F;
      text-align: center; }
    .competition-banner__gard-name {
      color: #2ABFCB;
      font-size: 25px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 30px; }
    .competition-banner__gard p:last-child {
      margin-bottom: 0; }
  .competition-banner__logo {
    position: absolute;
    top: 28px;
    right: 21px; }
    @media (max-width: 1023px) {
      .competition-banner__logo {
        position: static;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        padding-top: 30px; } }
    @media (max-width: 576px) {
      .competition-banner__logo {
        position: relative;
        top: 0;
        right: 0;
        z-index: 2; } }
  .competition-banner__box {
    max-width: 490px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 75px; }
    @media (max-width: 1023px) {
      .competition-banner__box {
        padding-top: 0; } }
    @media (max-width: 576px) {
      .competition-banner__box {
        position: relative;
        z-index: 2; } }
  .competition-banner__text {
    font-weight: bold;
    font-size: 27px;
    line-height: 45px;
    color: #000;
    text-align: center;
    margin-bottom: 55px; }
    @media (max-width: 1023px) {
      .competition-banner__text {
        font-size: 24px;
        margin-bottom: 40px; } }
    @media (max-width: 576px) {
      .competition-banner__text {
        line-height: 1.3; } }
    @media (max-width: 480px) {
      .competition-banner__text {
        font-size: 20px; } }

.competition-action {
  display: flex;
  justify-content: center; }
  .competition-action--alt {
    margin-bottom: 60px; }
  .competition-action .btn {
    height: 40px;
    width: auto;
    padding: 0 30px;
    margin-right: 30px;
    min-width: 139px; }
    @media (max-width: 576px) {
      .competition-action .btn {
        margin-right: 15px;
        padding: 0 20px;
        min-width: 120px; } }
    .competition-action .btn:last-child {
      margin-right: 0; }

.competition-title {
  font-size: 24px;
  line-height: 31px;
  color: #333;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: center; }
  .competition-title--gard {
    font-weight: bold;
    color: #2ABFCB; }
  .competition-title--tile {
    text-transform: none;
    font-weight: bold;
    font-size: 23px;
    color: #19124A; }

.competition-text {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
  text-align: center;
  max-width: 615px; }
  .competition-text__info {
    color: #19124A;
    font-size: 15px;
    font-style: italic;
    text-align: right;
    margin-right: -25px;
    margin-top: 20px; }
    @media (max-width: 1023px) {
      .competition-text__info {
        margin-right: 0; } }
    .competition-text__info a {
      text-decoration: underline; }
      .competition-text__info a:hover {
        text-decoration: none; }
  .competition-text--lime {
    max-width: 868px;
    margin-top: 50px;
    background: #F0FAFD;
    padding: 30px 90px; }
    @media (max-width: 1023px) {
      .competition-text--lime {
        padding: 20px; } }
    .competition-text--lime .competition-title {
      color: #19124A;
      font-size: 23px;
      font-weight: bold;
      margin-bottom: 20px;
      text-transform: none; }
    .competition-text--lime .competition-text__content {
      letter-spacing: 0.02em;
      font-size: 18px;
      color: #19124A;
      text-align: left; }

.competition-social {
  margin-top: 30px; }
  .competition-social--original {
    margin-bottom: 100px; }
    @media (max-width: 1023px) {
      .competition-social--original {
        margin-bottom: 70px; } }
  .competition-social__title {
    margin-bottom: 17px;
    text-align: center; }
  .competition-social--green .icon {
    background: #54B331; }
  .competition-social--orange .icon {
    background: #F15922; }

.competition-date {
  margin-bottom: 70px; }
  .competition-date--green .competition-date__item-top {
    background: #54B331; }
  .competition-date--gard .competition-date__item-top {
    background: #2ABFCB;
    border-radius: 13px; }
  .competition-date--gard .competition-title {
    color: #2ABFCB;
    font-weight: bold; }
  .competition-date__list {
    display: flex;
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px; }
    @media (max-width: 1200px) {
      .competition-date__list {
        margin: 0 -10px; } }
  .competition-date__item {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
    padding: 0 30px; }
    @media (max-width: 1200px) {
      .competition-date__item {
        padding: 0 10px; } }
    @media (max-width: 576px) {
      .competition-date__item {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 30px; }
        .competition-date__item:last-child {
          margin-bottom: 0; } }
    .competition-date__item-top {
      height: 50px;
      margin-bottom: 25px;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center; }
      @media (max-width: 576px) {
        .competition-date__item-top {
          margin-bottom: 10px; } }
    .competition-date__item-text {
      font-size: 18px;
      line-height: 21px;
      color: #000;
      text-align: center; }

.competition-info {
  margin-bottom: 144px; }
  @media (max-width: 1023px) {
    .competition-info {
      margin-bottom: 100px; } }
  @media (max-width: 768px) {
    .competition-info {
      margin-bottom: 50px; } }
  .competition-info--green .competition-info__box {
    background: #54B331; }
  .competition-info__box {
    display: flex;
    font-size: 24px;
    line-height: 31px;
    color: #fff; }
    @media (max-width: 768px) {
      .competition-info__box {
        display: block; } }
  .competition-info__img {
    width: 400px;
    min-width: 400px; }
    @media (max-width: 1023px) {
      .competition-info__img {
        width: 250px;
        min-width: 250px; } }
    @media (max-width: 768px) {
      .competition-info__img {
        width: 100%;
        max-width: 100%; } }
    .competition-info__img img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .competition-info__text {
    padding: 38px 100px;
    align-self: center; }
    @media (max-width: 1200px) {
      .competition-info__text {
        padding: 20px 50px; } }
    @media (max-width: 1023px) {
      .competition-info__text {
        font-size: 18px; } }
    @media (max-width: 768px) {
      .competition-info__text {
        padding: 15px; } }

.competition-place {
  display: flex;
  align-items: flex-end;
  padding: 0 65px;
  margin-bottom: 55px; }
  @media (max-width: 1023px) {
    .competition-place {
      flex-wrap: wrap;
      padding: 0; } }
  .competition-place-tile {
    margin-bottom: 70px; }
    .competition-place-tile__list {
      display: flex;
      align-items: flex-end;
      max-width: 877px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px; }
      @media (max-width: 768px) {
        .competition-place-tile__list {
          flex-wrap: wrap; } }
    .competition-place-tile__item {
      flex: 1;
      margin: 0 8px; }
      @media (max-width: 768px) {
        .competition-place-tile__item {
          width: 100%;
          flex: 0 0 100%;
          margin: 0 0 25px 0; } }
      @media (max-width: 768px) {
        .competition-place-tile__item:nth-child(2) {
          order: -4; } }
      .competition-place-tile__item:nth-child(2) .competition-place-tile__item-area {
        height: 134px; }
      @media (max-width: 768px) {
        .competition-place-tile__item:nth-child(3) {
          order: -5; } }
      .competition-place-tile__item:nth-child(3) .competition-place-tile__item-area {
        height: 164px; }
        .competition-place-tile__item:nth-child(3) .competition-place-tile__item-area > span {
          font-size: 96px; }
          .competition-place-tile__item:nth-child(3) .competition-place-tile__item-area > span span {
            margin-top: 15px; }
      @media (max-width: 768px) {
        .competition-place-tile__item:nth-child(4) {
          order: -3; } }
      .competition-place-tile__item:nth-child(4) .competition-place-tile__item-area {
        height: 111px; }
      .competition-place-tile__item-img {
        display: flex;
        justify-content: center;
        margin-bottom: 10px; }
        .competition-place-tile__item-img img {
          display: block;
          max-width: 100%; }
      .competition-place-tile__item-area {
        background: #BDBDBD;
        position: relative;
        height: 12px;
        margin-top: 30px; }
        .competition-place-tile__item-area > span {
          position: absolute;
          bottom: 100%;
          left: 0;
          text-align: center;
          color: #BDBDBD;
          letter-spacing: -0.03em;
          font-size: 24px;
          width: 100%; }
          .competition-place-tile__item-area > span span {
            margin-left: 3px; }
      .competition-place-tile__item--alt .competition-place-tile__item-area {
        margin-top: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end; }
        .competition-place-tile__item--alt .competition-place-tile__item-area > span {
          position: static;
          display: block;
          color: #FFFFFF;
          font-size: 72px;
          line-height: 1;
          padding-bottom: 14px;
          font-weight: bold; }
          .competition-place-tile__item--alt .competition-place-tile__item-area > span span {
            display: block;
            font-size: 24px;
            letter-spacing: -0.03em;
            line-height: 1;
            margin-left: 0; }
    .competition-place-tile__text {
      text-align: center;
      font-size: 18px;
      letter-spacing: 0.02em;
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 35px; }
    .competition-place-tile__action {
      display: flex;
      justify-content: center; }
      .competition-place-tile__action .btn {
        max-width: 140px; }
  .competition-place-gard__line {
    margin-bottom: 70px; }
    .competition-place-gard__line-title {
      text-transform: uppercase;
      font-size: 31px;
      margin-bottom: 53px;
      font-weight: bold;
      color: #2ABFCB;
      text-align: center; }
    .competition-place-gard__line-content {
      padding: 0 100px;
      display: flex;
      justify-content: center;
      margin: 0 45px; }
      @media (max-width: 1200px) {
        .competition-place-gard__line-content {
          padding: 0; } }
      @media (max-width: 1023px) {
        .competition-place-gard__line-content {
          flex-wrap: wrap; } }
    .competition-place-gard__line-card {
      max-width: 25%;
      flex: 0 0 25%;
      padding: 0 8px;
      margin-bottom: 16px; }
      @media (max-width: 1023px) {
        .competition-place-gard__line-card {
          max-width: 50%;
          flex: 0 0 50%; } }
      @media (max-width: 576px) {
        .competition-place-gard__line-card {
          max-width: 100%;
          flex: 0 0 100%; } }
      .competition-place-gard__line-cards {
        display: flex;
        flex-wrap: wrap;
        margin-left: -8px;
        margin-right: -8px; }
      .competition-place-gard__line-card-content {
        background: #C4C4C4;
        border-radius: 13px;
        padding: 55px 37px 24px;
        position: relative;
        height: 100%; }
        .competition-place-gard__line-card-content:hover {
          box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25); }
        @supports (flex-direction: column) {
          .competition-place-gard__line-card-content {
            display: flex;
            flex-direction: column; } }
      .competition-place-gard__line-card-num {
        position: absolute;
        letter-spacing: -0.03em;
        color: #fff;
        font-size: 100px;
        font-weight: bold;
        line-height: 1;
        top: 0;
        left: 5px; }
      .competition-place-gard__line-card-img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 14px;
        height: 100px; }
        .competition-place-gard__line-card-img img {
          display: block;
          max-width: 100%;
          max-height: 100%; }
      .competition-place-gard__line-card-name {
        color: #fff;
        font-size: 15px;
        margin-bottom: 20px;
        text-align: center;
        font-weight: bold;
        max-width: 140px;
        margin-left: auto;
        margin-right: auto; }
        @supports (flex-direction: column) {
          .competition-place-gard__line-card-name {
            margin-top: auto; } }
  .competition-place-gard__item {
    position: relative;
    padding: 0 45px;
    width: 100%;
    max-width: 50%;
    flex: 0 0 50%;
    min-height: 160px;
    padding-left: 225px;
    display: flex;
    align-items: flex-end; }
    @media (max-width: 1023px) {
      .competition-place-gard__item {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 30px; }
        .competition-place-gard__item:last-child {
          margin-bottom: 0; } }
    @media (max-width: 768px) {
      .competition-place-gard__item {
        padding-left: 0;
        padding-right: 0;
        min-height: 0;
        display: block;
        text-align: center; } }
    .competition-place-gard__item-name {
      font-size: 15px;
      line-height: 1.1;
      font-weight: bold;
      margin-bottom: 20px;
      color: #4F4F4F; }
    .competition-place-gard__item-btn {
      max-width: 131px;
      margin-left: auto;
      margin-right: auto;
      text-decoration: underline; }
      .competition-place-gard__item-btn:hover {
        text-decoration: none; }
    .competition-place-gard__item-img {
      position: absolute;
      width: 205px;
      height: 160px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 768px) {
        .competition-place-gard__item-img {
          position: static;
          transform: translateY(0);
          margin-bottom: 20px;
          margin-left: auto;
          margin-right: auto; } }
      .competition-place-gard__item-img img {
        display: block;
        max-width: 100%;
        max-height: 100%; }
    .competition-place-gard__item--big {
      max-width: 510px;
      flex: 0 0 510px;
      padding-left: 0;
      height: 240px;
      margin-top: -50px; }
      @media (max-width: 768px) {
        .competition-place-gard__item--big {
          height: auto; } }
      .competition-place-gard__item--big .competition-place-gard__item-img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateY(0); }
        @media (max-width: 768px) {
          .competition-place-gard__item--big .competition-place-gard__item-img {
            position: static;
            height: auto; } }
      .competition-place-gard__item--big .competition-place-gard__item-name {
        position: absolute;
        max-width: 234px;
        top: 50%;
        left: 0;
        transform: translateY(-50%); }
        @media (max-width: 768px) {
          .competition-place-gard__item--big .competition-place-gard__item-name {
            position: static;
            transform: translateY(0);
            max-width: 100%; } }
      .competition-place-gard__item--big .competition-place-gard__item-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%; }
        @media (max-width: 768px) {
          .competition-place-gard__item--big .competition-place-gard__item-btn {
            position: static; } }
  .competition-place__box {
    flex: 1; }
    @media (max-width: 1023px) {
      .competition-place__box {
        width: 100%;
        flex: 0 0 100%;
        margin-bottom: 30px; }
        .competition-place__box:last-child {
          margin-bottom: 0; } }
    .competition-place__box-second .competition-place__box-title {
      font-size: 30px; }
      @media (max-width: 1200px) {
        .competition-place__box-second .competition-place__box-title {
          font-size: 26px; } }
    .competition-place__box-second .competition-place__box-position {
      font-size: 39px;
      height: 59px; }
    @media (max-width: 1023px) {
      .competition-place__box-first {
        order: -2; } }
    .competition-place__box-first .competition-place__box-title {
      font-size: 40px; }
      @media (max-width: 1200px) {
        .competition-place__box-first .competition-place__box-title {
          font-size: 30px; } }
    .competition-place__box-first .competition-place__box-position {
      font-size: 56px;
      height: 87px; }
    .competition-place__box-cont {
      margin-left: auto;
      margin-right: auto;
      max-width: 290px; }
      @media (max-width: 1200px) {
        .competition-place__box-cont {
          max-width: 230px; } }
      @media (max-width: 1023px) {
        .competition-place__box-cont {
          max-width: 100%; } }
    .competition-place__box-title {
      font-size: 27px;
      line-height: 32px;
      letter-spacing: -0.03em;
      color: #54B331;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 16px; }
      @media (max-width: 1200px) {
        .competition-place__box-title {
          font-size: 24px; } }
      @media (max-width: 768px) {
        .competition-place__box-title {
          text-align: center; } }
    .competition-place__box-text {
      color: #000;
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 41px; }
      @media (max-width: 768px) {
        .competition-place__box-text {
          margin-bottom: 20px;
          text-align: center; } }
    .competition-place__box-card {
      border: 3px solid #54B331;
      border-radius: 13px;
      padding: 17px 10px;
      position: relative;
      height: 126px;
      width: 231px;
      margin-bottom: 13px; }
      @media (max-width: 768px) {
        .competition-place__box-card {
          margin-left: auto;
          margin-right: auto; } }
      .competition-place__box-card-content {
        display: inline-block; }
      .competition-place__box-card-name {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.03em; }
        .competition-place__box-card-name span {
          display: inline-block;
          padding-bottom: 2px;
          border-bottom: 1px solid #54B331; }
      .competition-place__box-card-price {
        letter-spacing: -0.03em;
        font-size: 27px;
        color: #54B331;
        font-weight: bold;
        text-align: center; }
      .competition-place__box-card-logo {
        position: absolute;
        bottom: 16px;
        right: 12px;
        width: 77px;
        height: 46px; }
        .competition-place__box-card-logo img {
          display: block;
          width: 100%; }
    .competition-place__box-position {
      height: 38px;
      text-align: center;
      padding-top: 2px;
      font-weight: bold;
      font-size: 28px;
      line-height: 1;
      color: #fff;
      background: #54B331;
      display: flex;
      align-items: center;
      align-items: center;
      justify-content: center; }
      @media (max-width: 1023px) {
        .competition-place__box-position {
          display: none; } }

.competition-steps {
  margin-bottom: 50px;
  position: relative; }
  .competition-steps--tile .competition-steps__item:before {
    background: #FFD428; }
  .competition-steps--tile .competition-steps__content {
    padding-top: 160px; }
  .competition-steps--tile .competition-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50px; }
  .competition-steps--green .competition-steps__item:before {
    background: #54B331; }
  .competition-steps--gard .competition-title {
    font-weight: bold;
    color: #2ABFCB;
    font-size: 31px; }
    @media (max-width: 576px) {
      .competition-steps--gard .competition-title {
        font-size: 24px; } }
  .competition-steps--gard .competition-steps__item:before {
    background: #2ABFCB; }
  .competition-steps__content {
    padding: 130px 55px 50px;
    display: flex;
    flex-wrap: wrap;
    background: #E6E6E6;
    justify-content: space-between;
    position: relative; }
    @media (max-width: 1023px) {
      .competition-steps__content {
        padding-top: 70px; } }
    @media (max-width: 480px) {
      .competition-steps__content {
        padding: 30px; } }
  .competition-steps__item {
    position: relative;
    width: calc(50% - 110px);
    background: #FFFFFF;
    border-radius: 7px;
    margin-bottom: 80px;
    padding: 40px 40px 35px 55px;
    letter-spacing: -0.03em;
    margin-left: 50px;
    color: #000; }
    @media (max-width: 1200px) {
      .competition-steps__item {
        margin-left: 0;
        width: calc(50% - 30px); } }
    @media (max-width: 768px) {
      .competition-steps__item {
        width: 100%; } }
    @media (max-width: 480px) {
      .competition-steps__item {
        margin-bottom: 30px; } }
    .competition-steps__item a {
      color: #0A4798; }
      .competition-steps__item a:hover {
        text-decoration: underline; }
    .competition-steps__item:before {
      content: attr(data-num);
      position: absolute;
      top: -50px;
      left: -50px;
      width: 110px;
      height: 110px;
      border-radius: 50%;
      border: 10px solid #E6E6E6;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 60px;
      font-weight: 600;
      line-height: 1; }
      @media (max-width: 480px) {
        .competition-steps__item:before {
          font-size: 40px;
          width: 70px;
          height: 70px;
          top: -20px;
          left: -20px; } }
  .competition-steps__action {
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    justify-content: center; }
    .competition-steps__action .btn {
      width: auto;
      padding: 0 30px;
      display: inline-flex; }

.competition-works__info {
  padding-left: 40px;
  font-size: 14px;
  line-height: 1;
  color: #000; }

.card-comp {
  max-width: 33.33333%;
  flex: 0 0 33.33333%;
  padding: 0 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 30px;
  font-size: 0.88235em; }
  @media (max-width: 768px) {
    .card-comp {
      max-width: 50%;
      flex: 0 0 50%; } }
  @media (max-width: 576px) {
    .card-comp {
      max-width: 100%;
      flex: 0 0 100%; } }
  .card-comp:hover {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
  .card-comp__title {
    position: absolute;
    bottom: 12px;
    right: 0;
    background: #19124A;
    padding: 7px 30px;
    text-align: center;
    font-size: 0.88235em;
    font-weight: bold;
    min-width: 130px;
    color: #fff; }
    .card-comp__title--full {
      left: 0; }
  .card-comp.card--full {
    max-width: 100%;
    flex: 0 0 100%; }
    .card-comp.card--full .card-comp__content {
      display: flex; }
      @media (max-width: 576px) {
        .card-comp.card--full .card-comp__content {
          display: block; } }
    .card-comp.card--full .card-comp__img {
      width: 400px;
      min-width: 400px;
      margin-right: 13px;
      margin-bottom: 0; }
      @media (max-width: 1200px) {
        .card-comp.card--full .card-comp__img {
          width: 250px;
          min-width: 250px; } }
      @media (max-width: 576px) {
        .card-comp.card--full .card-comp__img {
          width: 100%;
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 15px; } }
      .card-comp.card--full .card-comp__img:before {
        padding-top: 71%; }
    .card-comp.card--full .card-comp__name {
      margin-bottom: 20px; }
    .card-comp.card--full .card-comp__text {
      margin-bottom: 20px; }
    .card-comp.card--full .card-comp__action {
      justify-content: flex-end; }
      .card-comp.card--full .card-comp__action .btn {
        width: auto;
        display: inline-flex;
        padding: 0 30px; }
  .card-comp.card--big {
    max-width: 50%;
    flex: 0 0 50%; }
    @media (max-width: 576px) {
      .card-comp.card--big {
        max-width: 100%;
        flex: 0 0 100%; } }
    .card-comp.card--big .card-comp__name {
      font-size: 18px;
      min-height: 47px; }
  .card-comp__content {
    height: 100%;
    position: relative; }
  .card-comp__date {
    position: absolute;
    top: 11px;
    right: 6px;
    background: #19124A;
    border-radius: 18px;
    font-size: 0.82353em;
    font-style: italic;
    padding: 0 15px;
    height: 27px;
    display: flex;
    align-items: center;
    z-index: 1;
    color: #fff; }
  .card-comp__img {
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 6px; }
    .card-comp__img:before {
      content: '';
      display: block;
      padding-top: 58.5%; }
      @media (max-width: 768px) {
        .card-comp__img:before {
          padding-top: 300px; } }
    .card-comp__img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .card-comp__name {
    margin-bottom: 5px;
    font-weight: bold;
    min-height: 55px; }
    @media (max-width: 576px) {
      .card-comp__name {
        min-height: 0; } }
    .card-comp__name span {
      color: #0A4798; }
    .card-comp__name a:hover {
      text-decoration: underline; }
    .card-comp__name-corp {
      color: #CC1B28; }
  .card-comp__text {
    font-size: 17px; }
  .card-comp__action {
    margin-top: 8px;
    display: flex;
    align-items: center; }
  .card-comp__link {
    margin-left: auto;
    font-weight: bold;
    text-decoration: underline; }
    .card-comp__link:hover {
      text-decoration: none; }
  .card-comp__stats {
    display: flex;
    align-items: center;
    font-size: 0.70588em;
    color: #828282; }
  .card-comp__stat {
    margin-right: 9px; }
    .card-comp__stat .icon {
      margin-right: 7px;
      position: relative;
      bottom: -1.9px;
      font-size: 1.11765em; }

.contacts-box {
  padding: 0 25px; }
  @media (max-width: 1200px) {
    .contacts-box {
      padding: 0; } }
  .contacts-box__email {
    max-width: 215px; }
  .contacts-box__form {
    border: 5px solid #D9F0F7;
    padding: 30px 30px 80px;
    border-radius: 5px;
    margin-top: -5px; }
    @media (max-width: 1200px) {
      .contacts-box__form {
        padding: 15px; } }
    @media (max-width: 1200px) {
      .contacts-box__form .comments-form__top {
        flex-wrap: wrap; } }
    @media (max-width: 1200px) {
      .contacts-box__form .comments-form__text {
        flex: 0 0 100%;
        width: 100%;
        margin: 10px 0; } }
    @media (max-width: 1200px) {
      .contacts-box__form .comments-form__social {
        margin-left: 0;
        margin-right: 30px;
        position: relative;
        bottom: -3px; } }
    @media (max-width: 1200px) {
      .contacts-box__form .comments-form__cabinet {
        margin-left: 0; } }
  .contacts-box__text {
    margin-bottom: 30px;
    font-size: 15px;
    font-weight: bold; }
  .contacts-box__items {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 480px) {
      .contacts-box__items {
        display: block; } }
  .contacts-box__item {
    width: calc(50% - 10px);
    display: flex;
    cursor: pointer;
    padding: 15px;
    border-radius: 5px 5px 0px 0px; }
    @media (max-width: 768px) {
      .contacts-box__item {
        padding: 10px; } }
    @media (max-width: 576px) {
      .contacts-box__item {
        display: block; } }
    @media (max-width: 480px) {
      .contacts-box__item {
        display: flex;
        width: 100%; } }
    .contacts-box__item:hover {
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
    .contacts-box__item-arrow {
      position: absolute;
      bottom: 0;
      right: 0;
      color: #CC1B28;
      font-size: 20px; }
    .contacts-box__item.active {
      background: #D9F0F7; }
      .contacts-box__item.active:hover {
        box-shadow: none; }
    .contacts-box__item-avatar {
      width: 90px;
      min-width: 90px;
      height: 90px;
      overflow: hidden;
      margin-right: 13px; }
      @media (max-width: 768px) {
        .contacts-box__item-avatar {
          margin-right: 10px;
          width: 50px;
          min-width: 50px;
          height: 50px; } }
      @media (max-width: 576px) {
        .contacts-box__item-avatar {
          margin-bottom: 10px;
          width: 80px;
          min-width: 80px;
          height: 80px; } }
      .contacts-box__item-avatar img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .contacts-box__item-info {
      font-size: 15px;
      line-height: 19px;
      position: relative;
      max-width: 210px; }
    .contacts-box__item-name {
      font-weight: bold;
      margin-bottom: 6px; }
    .contacts-box__item-text {
      margin-bottom: 6px; }
    .contacts-box__item-email {
      font-size: 14px;
      line-height: 1;
      font-weight: bold; }
  .contacts-box__content {
    display: none;
    position: relative;
    z-index: 2;
    background: #fff; }
    .contacts-box__content.active {
      display: block; }

.nav-alt {
  flex: 1;
  margin: 0 45px; }
  @media (max-width: 1200px) {
    .nav-alt {
      margin: 0 0 30px; } }

.tabs {
  margin-bottom: 20px; }
  .tabs__controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .tabs__btn {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 35px;
    border-radius: 6px;
    letter-spacing: -0.03em;
    font-size: 18px;
    margin: 0 3px; }
    .tabs__btn:hover {
      background: rgba(255, 212, 40, 0.5); }
    .tabs__btn.active {
      background: #FFD428;
      pointer-events: none; }

.card-work {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 10px;
  margin-bottom: 30px; }
  @media (max-width: 1023px) {
    .card-work {
      max-width: 100%;
      flex: 0 0 100%; } }
  .card-work__content {
    border: 1px solid #C2E9F5;
    padding: 15px;
    height: 100%;
    font-size: 0.88235em;
    position: relative; }
    @media (max-width: 576px) {
      .card-work__content {
        padding-bottom: 5px; } }
    @supports (flex-direction: column) {
      .card-work__content {
        display: flex;
        flex-direction: column; } }
    .card-work__content:hover {
      border-color: transparent;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
  .card-work__main {
    position: relative;
    padding-right: 180px;
    min-height: 120px; }
    @media (max-width: 576px) {
      .card-work__main {
        padding-right: 0;
        min-height: 0; } }
    @supports (flex-direction: column) {
      .card-work__main {
        margin-bottom: auto; } }
  .card-work__img {
    position: absolute;
    top: 0;
    right: 0;
    width: 165px;
    height: 120px;
    overflow: hidden; }
    @media (max-width: 576px) {
      .card-work__img {
        position: static;
        margin-bottom: 20px;
        height: auto;
        width: 100%; } }
    .card-work__img img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .card-work__title {
    font-weight: bold;
    margin-bottom: 10px; }
    .card-work__title a:hover {
      text-decoration: underline; }
  .card-work__text {
    font-size: 17px; }
  .card-work__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 20px;
    padding-top: 7px; }
    @media (max-width: 576px) {
      .card-work__bottom {
        display: block; }
        .card-work__bottom > * {
          display: inline-flex;
          margin-left: 0;
          margin-right: 15px;
          vertical-align: middle;
          margin-bottom: 10px; } }
    .card-work__bottom:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border: 1px solid #D9F0F7;
      width: calc(100% - 170px); }
  .card-work__user {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2; }
    .card-work__user-avatar {
      width: 25px;
      min-width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 15px;
      overflow: hidden;
      display: block; }
      .card-work__user-avatar img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .card-work__user-name {
      font-weight: bold;
      font-size: 14px; }
      .card-work__user-name a:hover {
        text-decoration: underline; }
  .card-work__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .card-work__comment, .card-work__view, .card-work__date {
    display: flex;
    align-items: center;
    font-size: 12px;
    position: relative;
    bottom: -1px; }
    .card-work__comment .icon, .card-work__view .icon, .card-work__date .icon {
      font-size: 1.29412em !important;
      position: relative;
      bottom: -2px;
      margin-right: 8px; }
  .card-work__date .icon {
    font-size: 10px; }
  .card-work__view .icon {
    font-size: 12px; }
  .card-work__like {
    position: relative;
    z-index: 2; }
    .card-work__like.active .like__btn {
      color: #19124A; }
    .card-work__like .like__btn {
      display: flex;
      background: #FFD428;
      border-radius: 7px;
      border: none;
      align-items: center;
      height: 23px;
      padding: 0 8px;
      cursor: pointer;
      font-size: 12px; }
      .card-work__like .like__btn .icon {
        margin-right: 8px; }

.card-mark {
  max-width: 25%;
  flex: 0 0 25%;
  padding: 0 5px;
  margin-bottom: 30px; }
  @media (max-width: 1200px) {
    .card-mark {
      max-width: 33.33333%;
      flex: 0 0 33.33333%; } }
  @media (max-width: 768px) {
    .card-mark {
      max-width: 50%;
      flex: 0 0 50%; } }
  @media (max-width: 480px) {
    .card-mark {
      max-width: 100%;
      flex: 0 0 100%; } }
  .card-mark__content {
    height: 100%;
    padding: 12px 12px 21px;
    border: 1px solid #C2E9F5; }
    .card-mark__content:hover {
      border-color: transparent;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
    @supports (flex-direction: column) {
      .card-mark__content {
        display: flex;
        flex-direction: column; } }
  .card-mark__img {
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 14px; }
    .card-mark__img:before {
      content: '';
      display: block;
      padding-top: 75%; }
    .card-mark__img img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%); }
  .card-mark__line {
    display: flex;
    align-items: center; }
  .card-mark__favorite {
    margin-left: auto; }
  .card-mark__name {
    font-weight: bold;
    font-size: 0.88235em;
    margin-top: 14px;
    margin-bottom: 8px; }
    .card-mark__name a:hover {
      text-decoration: underline; }
  .card-mark__text {
    font-size: 0.82353em;
    margin-bottom: 12px; }
    @supports (flex-direction: column) {
      .card-mark__text {
        margin-top: auto; } }
  .card-mark__price {
    white-space: nowrap;
    font-size: 1em;
    font-weight: bold; }
  .card-mark__btn {
    padding: 0 20px;
    width: auto;
    display: inline-flex;
    margin-left: auto; }

.market {
  display: flex;
  margin-bottom: 80px; }
  @media (max-width: 1023px) {
    .market {
      display: block;
      margin-bottom: 50px; } }
  .market__filter {
    width: 208px;
    min-width: 208px;
    margin-right: 26px; }
    @media (max-width: 1023px) {
      .market__filter {
        margin-right: 0;
        margin-bottom: 30px;
        min-width: 0;
        width: 100%; } }
  .market__main {
    flex: 1; }
  .market__cards {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px; }
  .market-filter__list {
    margin-bottom: 20px; }
    .market-filter__list-alt .market-filter__item-title {
      text-align: center;
      justify-content: center;
      background-color: transparent; }
    .market-filter__list-alt .market-filter__item-content {
      border: none;
      margin-left: 0;
      padding-left: 0;
      padding-top: 10px; }
  .market-filter__title {
    margin-bottom: 4px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    background: #D9F0F7;
    padding: 7px 10px; }
  .market-filter__item {
    margin-bottom: 4px; }
    .market-filter__item:last-child {
      margin-bottom: 0; }
    .market-filter__item.active .market-filter__item-title {
      font-weight: bold; }
      .market-filter__item.active .market-filter__item-title:after {
        top: 17px;
        transform: rotate(-135deg); }
    .market-filter__item.active .market-filter__item-content {
      display: block; }
    .market-filter__item-title {
      border: none;
      font-size: 15px;
      height: 36px;
      padding: 0 23px 0 11px;
      display: flex;
      align-items: center;
      background: #D9F0F7;
      width: 100%;
      position: relative;
      cursor: pointer;
      color: #19124A; }
      .market-filter__item-title:hover {
        background: #afdfee; }
      .market-filter__item-title span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .market-filter__item-title:after {
        content: '';
        position: absolute;
        top: 14px;
        right: 9px;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        transform: rotate(45deg); }
    .market-filter__item-content {
      display: none;
      margin: 4px 0 19px 15px;
      border: 1px solid #D9F0F7;
      padding: 7px; }
      .market-filter__item-content li {
        margin-bottom: 3px; }
        .market-filter__item-content li:last-child {
          margin-bottom: 0; }
      .market-filter__item-content a {
        font-size: 14px;
        line-height: 18px; }
        .market-filter__item-content a.active {
          font-weight: bold; }
        .market-filter__item-content a:hover {
          text-decoration: underline; }
    .market-filter__item-range {
      padding-right: 15px; }
      .market-filter__item-range .range-slider {
        margin-left: 10px;
        margin-right: 10px; }
      .market-filter__item-range-val {
        display: flex;
        align-items: center; }
        .market-filter__item-range-val input {
          border: 1px solid #D9F0F7;
          border-radius: 3px;
          height: 25px;
          padding: 0 7px;
          display: inline-flex;
          align-items: center;
          width: auto;
          min-width: 0;
          font-size: 14px; }
          .market-filter__item-range-val input:first-child {
            text-align: right; }
        .market-filter__item-range-val span {
          margin: 0 5px; }
    .market-filter__item-btn {
      width: 25px;
      max-width: 25px;
      min-width: 25px;
      height: 25px;
      font-size: 15px;
      margin-left: 10px;
      border-radius: 5px;
      cursor: pointer; }

.section-slider {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
  margin-top: 40px;
  padding: 30px 18px; }
  .section-slider__item {
    padding: 5px 0; }
    .section-slider__item a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 10px; }
      .section-slider__item a:hover {
        box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25); }
      .section-slider__item a img {
        display: block;
        max-width: 100%; }
  .section-slider .slick-arrow {
    background-color: transparent; }

.calculator {
  padding-left: 40px;
  padding-right: 40px; }
  @media (max-width: 1200px) {
    .calculator {
      padding-left: 0;
      padding-right: 0; } }
  .calculator__list {
    margin-top: 65px; }
  .calculator-box {
    position: relative;
    min-height: 123px;
    padding-left: 230px;
    margin-bottom: 76px; }
    @media (max-width: 1200px) {
      .calculator-box {
        padding-left: 200px;
        margin-bottom: 50px; } }
    @media (max-width: 480px) {
      .calculator-box {
        padding-left: 0;
        margin-bottom: 30px;
        min-height: 0; } }
    @supports (flex-direction: column) {
      .calculator-box {
        display: flex;
        flex-direction: column;
        justify-content: center; } }
    .calculator-box__img {
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translateY(-50%); }
      @media (max-width: 480px) {
        .calculator-box__img {
          display: none; } }
    .calculator-box__title {
      max-width: 300px;
      font-weight: bold;
      margin-bottom: 26px;
      text-align: center; }
      @media (max-width: 480px) {
        .calculator-box__title {
          max-width: 100%;
          margin-bottom: 18px; } }
      .calculator-box__title + .calculator-box__img {
        margin-top: 23px; }
    .calculator-box__list li {
      margin-bottom: 6px; }
      .calculator-box__list li:last-child {
        margin-bottom: 0; }
    .calculator-box__list a {
      text-decoration: underline; }
      .calculator-box__list a:hover {
        color: #0A4798; }

.brif {
  margin-top: 90px;
  max-width: 590px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #C2E9F5;
  border-radius: 5px;
  padding: 47px 25px 40px; }
  @media (max-width: 1023px) {
    .brif {
      max-width: 100%; } }
  .brif.section {
    padding-bottom: 20px; }
  @media (max-width: 576px) {
    .brif .form__label-comb {
      display: block;
      margin-right: 0; } }
  @media (max-width: 576px) {
    .brif .form__label-comb .form__input {
      width: 100%;
      margin-bottom: 10px; }
      .brif .form__label-comb .form__input:last-child {
        margin-bottom: 0; } }
  .brif__title {
    margin-bottom: 21px;
    padding-left: 25px;
    padding-right: 25px; }
  .brif__form {
    padding: 0 30px; }
    .brif__form .form__input {
      height: 30px; }
    .brif__form .form__input,
    .brif__form .form__textarea {
      font-size: 14px; }
  .brif__info {
    font-style: italic;
    font-size: 15px;
    line-height: 1;
    text-align: center;
    width: 100%;
    max-width: 390px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: 0; }
  .brif__action {
    margin-top: 30px; }

.firm {
  display: flex; }
  @media (max-width: 768px) {
    .firm {
      display: block; } }
  .firm__stats {
    width: 130px;
    min-width: 130px;
    margin-right: 60px; }
    @media (max-width: 768px) {
      .firm__stats {
        width: 100%;
        min-width: 0;
        margin-right: 0;
        margin-bottom: 30px;
        position: relative;
        padding-left: 145px; } }
  .firm__logo {
    margin-bottom: 20px; }
    @media (max-width: 768px) {
      .firm__logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 130px; } }
    .firm__logo img {
      max-width: 100%;
      display: block; }
  .firm__list {
    margin-bottom: 40px; }
    .firm__list li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px;
      font-size: 14px; }
      .firm__list li:last-child {
        margin-bottom: 0; }
      .firm__list li a {
        font-weight: bold;
        text-decoration: underline; }
  .firm__info {
    flex: 1;
    font-size: 15px; }
  .firm__text {
    margin-bottom: 15px; }
  .firm__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 576px) {
      .firm__items {
        display: block; } }
  .firm__item {
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    margin-bottom: 10px; }
    .firm__item--half {
      width: calc(50% - 20px);
      flex: 0 0 calc(50% - 20px); }
      @media (max-width: 576px) {
        .firm__item--half {
          width: 100%; } }
    .firm__item-name {
      white-space: nowrap;
      margin-right: 24px;
      font-weight: bold; }
      @media (max-width: 576px) {
        .firm__item-name {
          min-width: 60px; } }
    .firm__item li {
      margin-bottom: 5px; }
      .firm__item li:last-child {
        margin-bottom: 0; }
    .firm__item-links a {
      text-decoration: underline; }

.card-exp {
  max-width: 33.33333%;
  flex: 0 0 33.33333%;
  padding: 0 22px;
  margin-bottom: 35px; }
  @media (max-width: 1200px) {
    .card-exp {
      padding: 0 10px; } }
  @media (max-width: 768px) {
    .card-exp {
      max-width: 50%;
      flex: 0 0 50%; } }
  @media (max-width: 480px) {
    .card-exp {
      max-width: 100%;
      flex: 0 0 100%; } }
  .card-exp__content {
    border: 1px solid #C2E9F5;
    padding: 18px 15px 10px;
    text-align: center;
    height: 100%; }
    .card-exp__content:hover {
      border-color: transparent;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
    @supports (flex-direction: column) {
      .card-exp__content {
        display: flex;
        flex-direction: column; } }
  .card-exp__avatar {
    overflow: hidden;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto; }
    .card-exp__avatar img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .card-exp__name {
    min-height: 60px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 20px;
    position: relative; }
    .card-exp__name:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 1px solid #C2E9F5;
      width: 80%; }
    .card-exp__name span {
      width: 100%;
      flex: 0 0 100%; }
  .card-exp__comp {
    color: #CC1B28; }
  .card-exp__title {
    font-size: 0.88235em;
    margin-bottom: 15px;
    font-weight: bold; }
  .card-exp__text {
    margin-bottom: 24px;
    font-size: 0.88235em; }
  .card-exp__btn {
    height: 30px;
    padding: 0 22px;
    width: auto;
    display: inline-flex; }
  .card-exp__action {
    margin-bottom: 25px; }
    @supports (flex-direction: column) {
      .card-exp__action {
        margin-top: auto; } }
  .card-exp__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.64706em; }
  .card-exp__stat span {
    color: #CC1B28; }

.popup {
  width: 100%; }

.onTop {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 20;
  background: #C2E9F5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: .3s; }
  @media (max-width: 1400px) {
    .onTop {
      right: 30px;
      bottom: 30px; } }
  .onTop.active {
    opacity: 1;
    visibility: visible; }
  .onTop:after {
    content: '';
    width: 20px;
    height: 20px;
    border-bottom: 7px solid #fff;
    border-right: 7px solid #fff;
    transform: rotate(-135deg);
    border-radius: 3px;
    margin-top: 5px; }

.tests-wrap .tests-rezult,
.tests-wrap .tests {
  display: none; }

.tests-wrap.testing .tests {
  display: block; }

.tests-wrap.testing .tests-rezult,
.tests-wrap.testing .tests-box--start {
  display: none; }

.tests-wrap.done .tests-rezult {
  display: block; }

.tests-wrap.done .tests-box--start,
.tests-wrap.done .tests {
  display: none; }

.tests-rezult {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  max-width: 735px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0 40px;
  position: relative;
  background: #fff; }
  @media (max-width: 576px) {
    .tests-rezult {
      padding-top: 80px; } }
  .tests-rezult__cont {
    padding: 0 90px;
    margin-bottom: 40px; }
    @media (max-width: 768px) {
      .tests-rezult__cont {
        padding: 0 20px; } }
  .tests-rezult__num {
    font-weight: bold;
    font-size: 36px;
    color: #0A4798;
    margin-bottom: 2px;
    text-align: center; }
  .tests-rezult__title {
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: bold;
    color: #0A4798;
    text-align: center; }
  .tests-rezult__text {
    text-align: center;
    font-size: 21px;
    color: #19124A;
    margin-bottom: 30px; }
  .tests-rezult__btn {
    display: flex;
    justify-content: center; }
    .tests-rezult__btn a {
      font-size: 17px;
      color: #0A4798;
      display: flex;
      align-items: center;
      margin-bottom: 35px; }
      .tests-rezult__btn a:hover {
        color: #073168; }
      .tests-rezult__btn a .icon {
        font-size: 18px;
        margin-right: 13px; }
  .tests-rezult__action {
    border-top: 2px solid #C2E9F5;
    padding-top: 30px;
    display: flex;
    justify-content: center; }
    .tests-rezult__action .btn {
      width: auto;
      padding: 0 30px; }
  .tests-rezult__social {
    position: absolute;
    top: 20px;
    right: 10px;
    text-align: center; }
    .tests-rezult__social-title {
      font-size: 14px; }
    .tests-rezult__social a {
      width: 23px;
      height: 23px; }
  .tests-rezult__bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 50px; }
    @media (max-width: 576px) {
      .tests-rezult__bottom {
        display: block; } }
    .tests-rezult__bottom-box {
      max-width: 270px; }
      @media (max-width: 768px) {
        .tests-rezult__bottom-box {
          max-width: 205px; } }
      @media (max-width: 576px) {
        .tests-rezult__bottom-box {
          max-width: 100%;
          margin-bottom: 20px; }
          .tests-rezult__bottom-box:last-child {
            margin-bottom: 0; } }
      .tests-rezult__bottom-box:hover .tests-rezult__bottom-box-btn {
        color: #0A4798; }
        .tests-rezult__bottom-box:hover .tests-rezult__bottom-box-btn:after {
          border-color: #0A4798; }
      .tests-rezult__bottom-box-title {
        font-size: 14px;
        margin-bottom: 14px;
        color: #828282;
        display: block; }
      .tests-rezult__bottom-box-btn {
        font-size: 18px;
        font-weight: bold;
        display: block;
        position: relative; }
        .tests-rezult__bottom-box-btn:after {
          content: '';
          position: absolute;
          top: 50%;
          border-bottom: 4px solid #19124A;
          border-right: 4px solid #19124A;
          width: 10px;
          height: 10px; }
      .tests-rezult__bottom-box--prev .tests-rezult__bottom-box-btn:after {
        transform: translateY(-50%) rotate(135deg);
        right: 100%;
        margin-right: 15px; }
      .tests-rezult__bottom-box--next {
        margin-left: auto; }
        .tests-rezult__bottom-box--next .tests-rezult__bottom-box-btn:after {
          transform: translateY(-50%) rotate(-45deg);
          left: 100%;
          margin-left: 15px; }
        .tests-rezult__bottom-box--next .tests-rezult__bottom-box-title {
          text-align: right; }

.tests-box {
  background: #F0FAFD;
  padding: 120px 30px 150px;
  margin-bottom: 50px;
  position: relative; }
  @media (max-width: 768px) {
    .tests-box {
      padding: 80px 20px; } }
  .tests-box:last-child {
    margin-bottom: 0; }
    .tests-box:last-child .tests-box__arrow {
      display: none; }
  .tests-box--error .tests-box__text,
  .tests-box--error .tests-box__answer, .tests-box--success .tests-box__text,
  .tests-box--success .tests-box__answer {
    display: none; }
  .tests-box--error .tests-box__message, .tests-box--success .tests-box__message {
    display: flex; }
  .tests-box__message {
    display: none;
    margin-top: 40px;
    text-align: center;
    justify-content: center;
    color: #19124A;
    font-weight: bold;
    font-size: 21px;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 768px) {
      .tests-box__message {
        font-size: 18px; } }
  .tests-box--error .tests-box__content {
    background: #EFD2D1; }
  .tests-box--success .tests-box__content {
    background: #D4F8E3; }
  @media (max-width: 576px) {
    .tests-box--start {
      padding: 20px; } }
  .tests-box--start .tests-box__content {
    max-width: 500px;
    padding: 25px 65px 50px; }
    @media (max-width: 576px) {
      .tests-box--start .tests-box__content {
        padding: 20px; } }
  .tests-box--start .tests-box__text {
    margin-bottom: 50px; }
  .tests-box__num {
    position: absolute;
    font-size: 72px;
    top: 27px;
    left: 14px;
    color: #0A4798;
    font-weight: bold;
    line-height: 1; }
    @media (max-width: 768px) {
      .tests-box__num {
        font-size: 50px;
        top: 15px;
        left: 50%;
        transform: translateX(-50%); } }
  .tests-box__arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 26px;
    color: #19124A; }
    @media (max-width: 768px) {
      .tests-box__arrow {
        bottom: 20px; } }
    .tests-box__arrow:hover {
      color: #0A4798; }
  .tests-box__img {
    margin-bottom: 25px; }
    .tests-box__img img {
      display: block;
      width: 100%; }
  .tests-box__answer button {
    background: #F2F2F2;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 40px;
    padding: 9px;
    border: none;
    width: 100%;
    font-size: 17px;
    color: #0A4798;
    cursor: pointer; }
    .tests-box__answer button:hover {
      background: #e5e5e5; }
    .tests-box__answer button:last-child {
      margin-bottom: 0; }
  .tests-box__content {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    background: #fff;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    padding: 45px; }
    @media (max-width: 768px) {
      .tests-box__content {
        padding: 20px; } }
  .tests-box__title {
    font-size: 21px;
    margin-bottom: 30px;
    font-weight: bold;
    color: #19124A;
    text-align: center; }
  .tests-box__text {
    margin-bottom: 20px; }
  .tests-box__action {
    display: flex;
    justify-content: center;
    margin-bottom: 40px; }
    .tests-box__action .btn {
      width: auto;
      padding: 0 30px;
      height: 40px; }
  .tests-box__social ul {
    justify-content: center; }
  .tests-box__social a {
    width: 24px;
    height: 24px; }

.tests__all {
  display: flex;
  justify-content: center;
  margin-top: 30px; }
  .tests__all a {
    position: relative;
    text-decoration: underline;
    font-weight: bold;
    font-size: 17px;
    color: #0A4798;
    display: inline-flex;
    align-items: center; }
    .tests__all a:hover {
      text-decoration: none; }
    .tests__all a:after {
      margin-left: 10px;
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-bottom: 5px solid #CC1B28;
      border-right: 5px solid #CC1B28;
      transform-origin: center;
      transform: rotate(-45deg);
      position: relative;
      bottom: -1px; }

.tests-partner {
  padding-top: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  min-height: 1000px;
  position: relative; }
  @media (max-width: 768px) {
    .tests-partner {
      background: none !important; } }
  .tests-partner__rezult {
    display: none; }
  .tests-partner-box {
    display: none;
    width: 100%;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    padding: 20px 45px 25px;
    position: absolute;
    top: 127px;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 576px) {
      .tests-partner-box {
        top: 160px;
        padding-left: 20px;
        padding-right: 20px; } }
    .tests-partner-box__nav {
      margin-bottom: 24px; }
      .tests-partner-box__nav ul {
        display: flex;
        justify-content: center; }
      .tests-partner-box__nav li {
        margin-right: 7px; }
        .tests-partner-box__nav li:last-child {
          margin-right: 0; }
      .tests-partner-box__nav a {
        display: flex;
        min-width: 15px;
        text-align: center;
        justify-content: center;
        font-size: 15px;
        color: #19124A;
        border-bottom: 3px solid #C4C4C4;
        padding-bottom: 3px; }
        .tests-partner-box__nav a:hover, .tests-partner-box__nav a.active {
          font-weight: bold;
          color: #0A4798;
          border-bottom-color: #0A4798; }
        .tests-partner-box__nav a.error {
          color: #CC1B28;
          border-color: #CC1B28; }
        .tests-partner-box__nav a.success {
          color: #219653;
          border-color: #219653; }
    .tests-partner-box__item {
      display: none; }
      .tests-partner-box__item.active {
        display: block; }
      .tests-partner-box__item-action {
        display: flex;
        justify-content: center;
        margin-top: 35px; }
        .tests-partner-box__item-action .btn {
          width: auto;
          padding: 0 30px; }
      .tests-partner-box__item-text {
        font-size: 15px;
        margin-bottom: 30px; }
      .tests-partner-box__item-img {
        margin-bottom: 25px; }
        .tests-partner-box__item-img img {
          display: block;
          width: 100%; }
      .tests-partner-box__item-answer {
        background: #F2F2F2;
        padding: 9px;
        min-height: 40px;
        margin-bottom: 5px;
        position: relative;
        border-left: 5px solid transparent;
        cursor: pointer; }
        .tests-partner-box__item-answer:hover {
          background: #e5e5e5; }
        .tests-partner-box__item-answer:last-child {
          margin-bottom: 0; }
        .tests-partner-box__item-answer-title {
          font-size: 17px;
          color: #0A4798;
          text-align: center; }
        .tests-partner-box__item-answer-text {
          margin-top: 7px;
          color: #19124A;
          font-size: 17px; }
          .tests-partner-box__item-answer-text span {
            color: #0A4798; }
          .tests-partner-box__item-answer-text a {
            text-decoration: underline;
            color: inherit; }
            .tests-partner-box__item-answer-text a:hover {
              text-decoration: none; }
        .tests-partner-box__item-answer-percent, .tests-partner-box__item-answer-text {
          display: none; }
        .tests-partner-box__item-answer-percent {
          position: absolute;
          top: 12px;
          line-height: 1;
          right: 5px;
          color: #0A4798; }
        .tests-partner-box__item-answer.selected .tests-partner-box__item-answer-percent,
        .tests-partner-box__item-answer.selected .tests-partner-box__item-answer-text {
          display: block; }
      .tests-partner-box__item.answed .tests-partner-box__item-answer:hover {
        background: #F2F2F2; }
      .tests-partner-box__item.answed .tests-partner-box__item-answer[data-val="true"] .tests-partner-box__item-answer-percent,
      .tests-partner-box__item.answed .tests-partner-box__item-answer[data-val="true"] .tests-partner-box__item-answer-title {
        color: #219653; }
      .tests-partner-box__item.answed .tests-partner-box__item-answer[data-val="true"] .tests-partner-box__item-answer-title {
        font-weight: bold; }
      .tests-partner-box__item.answed .tests-partner-box__item-answer[data-val="true"] .tests-partner-box__item-answer-percent {
        display: block; }
      .tests-partner-box__item.error .tests-partner-box__item-answer[data-val="true"] {
        border-color: #219653; }
      .tests-partner-box__item.error .selected {
        border-color: #CC1B28; }
      .tests-partner-box__item.success .tests-partner-box__item-answer-percent {
        display: block; }
  .tests-partner__start {
    background: #FFFFFF;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    padding: 30px 75px;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 576px) {
      .tests-partner__start {
        padding: 20px; } }
    .tests-partner__start-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 30px;
      border-bottom: 2px solid #C2E9F5;
      margin-bottom: 10px; }
      @media (max-width: 576px) {
        .tests-partner__start-top {
          justify-content: space-around; } }
    .tests-partner__start-logo {
      border: 1px solid #E0E0E0;
      width: 150px;
      display: flex;
      height: 108px;
      align-items: center;
      justify-content: center;
      padding: 5px; }
      .tests-partner__start-logo img {
        display: block;
        max-width: 100%; }
    .tests-partner__start-name {
      max-width: 200px;
      text-align: center;
      font-size: 17px; }
      .tests-partner__start-name span {
        display: block;
        margin-top: 20px;
        font-size: 15px;
        color: #828282; }
    .tests-partner__start-title {
      text-align: center;
      font-weight: bold;
      font-size: 21px;
      color: #19124A;
      max-width: 360px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto; }
    .tests-partner__start-text {
      margin-bottom: 45px; }
    .tests-partner__start-action {
      margin-bottom: 40px;
      display: flex;
      justify-content: center; }
      .tests-partner__start-action .btn {
        width: auto;
        padding: 0 30px; }
    .tests-partner__start-social ul {
      justify-content: center; }
    .tests-partner__start-social a {
      width: 23px;
      height: 23px; }
  .tests-partner__content.testing .tests-partner__start {
    display: none; }
  .tests-partner__content.testing .tests-partner-box {
    display: block; }
  .tests-partner__content.done .tests-partner__start,
  .tests-partner__content.done .tests-partner-box {
    display: none; }
  .tests-partner__content.done .tests-partner__rezult {
    display: block; }
  .tests-partner__header {
    background: #fff;
    margin-bottom: 100px; }
    @media (max-width: 768px) {
      .tests-partner__header {
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25); } }
    .tests-partner__header-content {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 1023px) {
        .tests-partner__header-content {
          flex-wrap: wrap; } }
      @media (max-width: 576px) {
        .tests-partner__header-content {
          justify-content: center; } }
  .tests-partner__logo img {
    display: block;
    max-width: 100%; }
  .tests-partner__link {
    color: #19124A;
    font-size: 17px;
    font-weight: bold; }
    @media (max-width: 1023px) {
      .tests-partner__link {
        margin-left: auto;
        margin-right: auto; } }
    .tests-partner__link:hover {
      text-decoration: underline; }
  @media (max-width: 576px) {
    .tests-partner__nav {
      flex: 0 0 100%;
      width: 100%;
      margin: 10px 0; } }
  .tests-partner__nav ul {
    display: flex;
    align-items: center; }
    @media (max-width: 576px) {
      .tests-partner__nav ul {
        justify-content: center; } }
  .tests-partner__nav li {
    margin-right: 50px; }
    .tests-partner__nav li:last-child {
      margin-right: 0; }
  .tests-partner__nav a {
    font-size: 15px;
    font-weight: bold;
    color: #19124A;
    text-decoration: underline; }
    .tests-partner__nav a:hover {
      text-decoration: none; }

.exp-form.disabled {
  opacity: .54;
  pointer-events: none; }

.exp-form .form__textarea,
.exp-form .form__select,
.exp-form .form__input {
  font-size: 15px; }

.exp-form .form__textarea {
  min-height: 121px; }

.exp-form .form__label-name--line small {
  color: #0A4798; }

.exp-form .form__label-check-box:after {
  border-color: #CC1B28; }

.exp__box {
  padding-top: 20px; }
  .exp__box--alt .exp__box-line {
    margin-bottom: 7px;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto; }
  .exp__box--alt .exp__box-text span {
    color: #CC1B28; }
  .exp__box-line {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px; }
    @media (max-width: 768px) {
      .exp__box-line {
        flex-wrap: wrap; } }
    @media (max-width: 480px) {
      .exp__box-line {
        display: block; } }
    .exp__box-line:last-child {
      margin-bottom: 0; }
    .exp__box-line span {
      white-space: nowrap;
      text-decoration: underline; }
      @media (max-width: 768px) {
        .exp__box-line span {
          width: 100%;
          flex: 0 0 100%;
          margin-bottom: 10px; } }
      @media (max-width: 480px) {
        .exp__box-line span {
          display: block; } }
    .exp__box-line .btn {
      width: auto;
      padding: 0 30px;
      text-decoration: underline; }
      @media (max-width: 768px) {
        .exp__box-line .btn {
          margin-right: 15px; } }
  @media (max-width: 768px) and (max-width: 480px) {
    .exp__box-line .btn {
      margin-right: 0; } }
      @media (max-width: 768px) {
          .exp__box-line .btn:last-child {
            margin-right: 0; } }
      .exp__box-line .btn:hover {
        text-decoration: none; }
  .exp__box-text {
    text-decoration: underline; }
  .exp__box-item {
    margin-right: 23px; }
    @media (max-width: 768px) {
      .exp__box-item {
        margin-right: 0; } }
    @media (max-width: 480px) {
      .exp__box-item {
        margin-bottom: 15px; } }
    .exp__box-item:last-child {
      margin-right: 0; }
      @media (max-width: 480px) {
        .exp__box-item:last-child {
          margin-bottom: 0; } }
  @media (max-width: 480px) {
    .exp__box .social {
      margin-bottom: 0; } }
  .exp__box .social ul {
    flex-wrap: nowrap; }
    @media (max-width: 480px) {
      .exp__box .social ul {
        justify-content: center; } }

.calendar__text {
  font-size: 18px;
  color: #19124A;
  letter-spacing: 0.02em;
  margin-bottom: 50px; }

.calendar-card {
  margin-bottom: 30px;
  width: 100%;
  max-width: calc(33.33333% - 21px);
  flex: 0 0 calc(33.33333% - 21px);
  background: #F0FAFD;
  margin-right: 21px;
  position: relative;
  padding: 10px; }
  @media (max-width: 768px) {
    .calendar-card {
      max-width: calc(50% - 21px);
      flex: 0 0 calc(50% - 21px); } }
  @media (max-width: 480px) {
    .calendar-card {
      max-width: calc(100% - 21px);
      flex: 0 0 calc(100% - 21px); } }
  .calendar-cards {
    margin-right: -21px;
    display: flex;
    flex-wrap: wrap; }
  .calendar-card.today .calendar-card__date-num span {
    background: #CC1B28; }
  .calendar-card.today:before {
    content: 'Сегодня';
    position: absolute;
    bottom: 100%;
    right: 0;
    font-size: 13px;
    font-weight: bold;
    color: #CC1B28;
    background: #F0FAFD;
    padding: 3px 12px; }
    @supports (clip-path: polygon(0 0, 100% 100%)) {
      .calendar-card.today:before {
        padding-left: 22px;
        clip-path: polygon(12px 0, 100% 0, 100% 100%, 0 100%); } }
  .calendar-card__year {
    font-weight: bold;
    font-size: 15px; }
  .calendar-card__line {
    color: #19124A;
    font-size: 15px;
    margin-bottom: 10px; }
    .calendar-card__line--main {
      font-size: 17px;
      display: flex;
      flex-wrap: wrap; }
    .calendar-card__line--alt {
      display: flex;
      padding-right: 25px;
      padding-left: 15px;
      align-items: center; }
  .calendar-card__moon {
    min-width: 41px;
    width: 41px;
    margin-right: 50%; }
  .calendar-card__symbol {
    min-width: 44px;
    width: 44px; }
  .calendar-card__moon img, .calendar-card__symbol img {
    display: block;
    max-width: 100%; }
  .calendar-card__day {
    font-weight: bold; }
    .calendar-card__day-moon {
      margin-right: 15px; }
  .calendar-card__date {
    display: flex;
    align-items: center;
    position: relative;
    top: -18px; }
    .calendar-card__date-num {
      display: flex;
      margin-right: 12px; }
      .calendar-card__date-num span {
        display: block;
        background: #19124A;
        margin-right: 2px;
        color: #fff;
        font-size: 36px;
        font-weight: bold;
        width: 30px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1; }
        .calendar-card__date-num span:last-child {
          margin-right: 0; }
    .calendar-card__date-month {
      position: relative;
      bottom: -4px;
      color: #19124A;
      font-weight: bold;
      font-size: 23px; }
  .calendar-card__bottom {
    display: flex;
    justify-content: flex-end; }
  .calendar-card__link {
    font-size: 15px;
    font-weight: bold;
    color: #0A4798; }
    .calendar-card__link:hover span {
      text-decoration: none; }
    .calendar-card__link span {
      text-decoration: underline; }

.calendar-slider {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px; }
  .calendar-slider .slick-arrow {
    background-color: transparent; }
    .calendar-slider .slick-arrow:after {
      border-color: #19124A; }
  .calendar-slider .slick-prev {
    left: -20px; }
  .calendar-slider .slick-next {
    right: -20px; }
  .calendar-slider__item {
    padding: 7px 8px 0;
    width: 64px; }
    .calendar-slider__item.slick-center {
      padding: 0 0 0; }
      .calendar-slider__item.slick-center .calendar-slider__item-area {
        height: 73px; }
      .calendar-slider__item.slick-center .calendar-slider__item-text {
        font-size: 44px; }
        .calendar-slider__item.slick-center .calendar-slider__item-text span {
          font-size: 18px; }
      .calendar-slider__item.slick-center + .slick-slide {
        padding: 4px 4px 0; }
        .calendar-slider__item.slick-center + .slick-slide .calendar-slider__item-area {
          height: 64px; }
        .calendar-slider__item.slick-center + .slick-slide .calendar-slider__item-text {
          font-size: 36px; }
          .calendar-slider__item.slick-center + .slick-slide .calendar-slider__item-text span {
            font-size: 15px; }
    .calendar-slider__item.today .calendar-slider__item-text {
      color: #CC1B28; }
      .calendar-slider__item.today .calendar-slider__item-text span {
        color: #19124A; }
    .calendar-slider__item-area {
      background: #F0FAFD;
      color: #19124A;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 57px; }
    .calendar-slider__item-text {
      font-weight: bold;
      font-size: 27px;
      line-height: 1; }
      .calendar-slider__item-text span {
        font-size: 12px;
        display: block; }

.calendar-tabs {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-bottom: 40px; }
  .calendar-tabs__item {
    width: 100%;
    max-width: 192px;
    text-align: center;
    cursor: pointer;
    opacity: .6; }
    @media (max-width: 480px) {
      .calendar-tabs__item {
        max-width: 120px; } }
    .calendar-tabs__item.active {
      opacity: 1; }
    .calendar-tabs__item-img {
      display: flex;
      margin-bottom: 35px;
      justify-content: center; }
      @media (max-width: 480px) {
        .calendar-tabs__item-img {
          height: 120px;
          align-items: center; } }
      .calendar-tabs__item-img img {
        display: block;
        max-width: 100%; }
        @media (max-width: 480px) {
          .calendar-tabs__item-img img {
            max-height: 100%; } }
    .calendar-tabs__item-title {
      font-size: 18px;
      font-weight: bold;
      color: #19124A; }
      @media (max-width: 480px) {
        .calendar-tabs__item-title {
          font-size: 14px; } }

.testing-banner {
  padding: 70px;
  background-size: cover;
  background-position: center;
  min-height: 325px;
  display: flex;
  align-items: center;
  color: #fff;
  text-align: center;
  margin-bottom: 25px; }
  @media (max-width: 576px) {
    .testing-banner {
      padding: 30px; } }
  .testing-banner__title {
    font-size: 1.47059em;
    font-weight: bold;
    margin-bottom: 15px; }

.testing-tabs {
  margin-bottom: 30px; }
  .testing-tabs ul {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #C2E9F5; }
    @media (max-width: 768px) {
      .testing-tabs ul {
        display: block;
        text-align: center; } }
    @media (max-width: 768px) {
      .testing-tabs ul li {
        margin-bottom: 8px; } }
    @media (max-width: 768px) {
      .testing-tabs ul li:last-child {
        margin-bottom: 0; } }
    .testing-tabs ul a {
      font-weight: bold;
      font-size: 1.05882em;
      color: #19124A;
      border-bottom: 2px solid transparent; }
      .testing-tabs ul a:hover, .testing-tabs ul a.active {
        border-bottom-color: #CC1B28;
        color: #0A4798; }

.testing-other {
  background: #F0FAFD;
  padding: 15px;
  margin: 50px 0; }
  .testing-other__title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 1.17647em; }
  .testing-other__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .testing-other__item {
    width: 100%;
    max-width: calc(50% - 23px);
    flex: 0 0 calc(50% - 23px);
    margin-bottom: 10px; }
    @media (max-width: 576px) {
      .testing-other__item {
        max-width: 100%;
        flex: 0 0 100%; } }
    .testing-other__item a {
      position: relative;
      display: inline-block;
      padding-left: 17px; }
      .testing-other__item a:hover {
        text-decoration: underline; }
      .testing-other__item a:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        width: 10px;
        height: 10px;
        border-bottom: 4px solid #19124A;
        border-right: 4px solid #19124A;
        transform: rotate(135deg); }
      .testing-other__item a span {
        color: #0A4798; }

.testing-info {
  margin-bottom: 90px;
  color: #0A4798; }

.testing-cont {
  margin-bottom: 35px; }
  .testing-cont:last-child {
    margin-bottom: 0; }
  .testing-cont .section-title {
    text-align: left;
    margin-bottom: 20px; }
  .testing-cont .faq-list__action {
    margin-top: 30px; }

.testing-block {
  margin-top: 30px; }
  .testing-block__title {
    text-align: center;
    margin-bottom: 30px; }
  .testing-block__items {
    display: flex;
    flex-wrap: wrap;
    margin-right: -40px; }
    @media (max-width: 1200px) {
      .testing-block__items {
        margin-right: -20px; } }
  .testing-block__item {
    width: 100%;
    max-width: calc(33.3333% - 40px);
    flex: 0 0 calc(33.3333% - 40px);
    margin-right: 40px;
    padding: 0 44px;
    margin-bottom: 30px; }
    @media (max-width: 1200px) {
      .testing-block__item {
        padding: 0 20px;
        max-width: calc(33.3333% - 20px);
        flex: 0 0 calc(33.3333% - 20px);
        margin-right: 20px; } }
    @media (max-width: 576px) {
      .testing-block__item {
        max-width: calc(50% - 20px);
        flex: 0 0 calc(50% - 20px); } }
    @media (max-width: 480px) {
      .testing-block__item {
        max-width: calc(100% - 20px);
        flex: 0 0 calc(100% - 20px); } }
    .testing-block__item-img {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding-left: 12px;
      margin-bottom: 35px;
      height: 60px; }
      .testing-block__item-img img {
        display: block;
        max-width: 100%;
        max-height: 100%; }
    .testing-block__item ul.testing-block__item-list {
      margin: 0;
      padding: 0; }
      .testing-block__item ul.testing-block__item-list li {
        padding: 0;
        margin: 0 0 3px 0; }
        .testing-block__item ul.testing-block__item-list li:last-child {
          margin-bottom: 0; }
        .testing-block__item ul.testing-block__item-list li:before {
          display: none; }
      .testing-block__item ul.testing-block__item-list a {
        color: #0A4798;
        text-decoration: underline;
        font-size: 1em; }
        .testing-block__item ul.testing-block__item-list a:hover {
          text-decoration: none; }

.testing-box {
  margin-bottom: 30px; }
  .testing-box.opened .testing-box__drop {
    display: block; }
  .testing-box.opened .testing-box__btn {
    background-color: transparent;
    box-shadow: none;
    color: #0A4798;
    padding: 0; }
    .testing-box.opened .testing-box__btn:hover {
      text-decoration: underline; }
    .testing-box.opened .testing-box__btn:after {
      border-top-color: #0A4798;
      right: 50%;
      transform: translateX(50%) rotate(180deg);
      top: auto;
      bottom: 100%;
      margin-bottom: -5px; }
  .testing-box__text {
    color: #0A4798; }
  .testing-box__drop {
    display: none; }
  .testing-box__action {
    display: flex;
    justify-content: center;
    margin-top: 20px; }
  .testing-box__btn {
    width: auto;
    padding: 0 45px 0 30px;
    position: relative; }
    .testing-box__btn:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      border-top: 12px solid #19124A;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent; }

.testing-slider {
  padding: 0 40px;
  margin-top: 50px; }
  @media (max-width: 768px) {
    .testing-slider {
      padding: 0 20px; } }
  @media (max-width: 768px) {
    .testing-slider .slick-prev {
      margin-left: -20px; } }
  @media (max-width: 768px) {
    .testing-slider .slick-next {
      margin-right: -20px; } }
  .testing-slider .slick-arrow {
    top: 90px;
    background-color: transparent; }
    @media (max-width: 1400px) {
      .testing-slider .slick-arrow {
        top: 70px; } }
    @media (max-width: 1200px) {
      .testing-slider .slick-arrow {
        top: 55px; } }
    @media (max-width: 1023px) {
      .testing-slider .slick-arrow {
        top: 60px; } }
    @media (max-width: 768px) {
      .testing-slider .slick-arrow {
        top: 100px; } }
    .testing-slider .slick-arrow.slick-disabled {
      opacity: .6; }
    .testing-slider .slick-arrow:after {
      border-color: #19124A; }
  .testing-slider__item .card {
    max-width: 100%; }
  @media (max-width: 768px) {
    .testing-slider__item .card-alt .card__img:before {
      padding-top: 200px; } }

.popup-test-request {
  max-width: 786px;
  background: #F8F8F8;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 3px; }

.user-card {
  max-width: 20%;
  flex: 0 0 20%;
  padding: 0 10px;
  margin-bottom: 30px; }
  @media (max-width: 768px) {
    .user-card {
      max-width: 25%;
      flex: 0 0 25%; } }
  @media (max-width: 576px) {
    .user-card {
      max-width: 33.33333%;
      flex: 0 0 33.33333%; } }
  @media (max-width: 480px) {
    .user-card {
      max-width: 50%;
      flex: 0 0 50%; } }
  .user-card__content {
    height: 100%; }
    @supports (flex-direction: column) {
      .user-card__content {
        display: flex;
        flex-direction: column; }
        .user-card__content .user-card__name {
          margin-bottom: auto; } }
  .user-card__avatar {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
    position: relative; }
    .user-card__avatar a {
      border-radius: 50%;
      overflow: hidden;
      width: 80px;
      height: 80px;
      display: block; }
      .user-card__avatar a img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
  .user-card__status {
    background: #FFD428;
    border-radius: 3px;
    text-align: center;
    font-size: 0.58824em;
    padding: 0 10px;
    line-height: 1;
    padding-bottom: 1px;
    position: absolute;
    top: 100%;
    left: 50%;
    height: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    white-space: nowrap; }
  .user-card__name {
    text-align: center;
    font-size: 0.88235em; }
    .user-card__name a:hover {
      text-decoration: underline; }
  .user-card__city {
    margin-top: 6px;
    color: #0A4798;
    font-size: 0.76471em;
    text-align: center; }

.rezult-card {
  max-width: 33.33333%;
  flex: 0 0 33.33333%;
  padding: 0 10px;
  margin-bottom: 35px; }
  @media (max-width: 768px) {
    .rezult-card {
      max-width: 50%;
      flex: 0 0 50%; } }
  @media (max-width: 480px) {
    .rezult-card {
      max-width: 100%;
      flex: 0 0 100%; } }
  .rezult-card__content {
    height: 100%;
    border: 1px solid #C2E9F5;
    padding: 10px 10px 14px; }
    .rezult-card__content:hover {
      background: #fff;
      border-color: transparent;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); }
    @supports (flex-direction: column) {
      .rezult-card__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between; } }
  .rezult-card.view .card-sim__action,
  .rezult-card.view .rezult-card__img {
    display: none; }
  .rezult-card.view .rezult-card__list {
    display: block; }
  .rezult-card.view .rezult-card__bottom .btn {
    display: none; }
  .rezult-card.view .rezult-card__bottom .rezult-card__btn-full {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 190px;
    padding: 0 10px; }
  .rezult-card__img {
    position: relative;
    overflow: hidden;
    margin-bottom: 14px;
    display: block; }
    .rezult-card__img:before {
      content: '';
      display: block;
      padding-top: 62.5%; }
    .rezult-card__img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      ofject-fit: cover;
      ofjct-position: center; }
  .rezult-card .card-sim__action .icon {
    font-size: 14px; }
  .rezult-card__title {
    font-size: 0.88235em;
    font-weight: bold;
    min-height: 36px;
    margin-bottom: 5px; }
    .rezult-card__title span {
      color: #0A4798; }
    .rezult-card__title a:hover {
      text-decoration: underline; }
  .rezult-card__firm {
    text-align: center;
    font-size: 0.82353em;
    margin-bottom: 20px; }
  .rezult-card__tester {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px; }
    .rezult-card__tester-title {
      font-size: 0.64706em;
      margin-right: 7px;
      margin-bottom: 3px;
      margin-top: 3px; }
    .rezult-card__tester-user {
      display: flex;
      align-items: center; }
      .rezult-card__tester-user a:hover {
        text-decoration: underline; }
    .rezult-card__tester-avatar {
      margin-right: 6px;
      width: 25px;
      height: 26px;
      min-width: 26px;
      border-radius: 50%;
      overflow: hidden; }
      .rezult-card__tester-avatar img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .rezult-card__tester-name {
      font-weight: bold;
      font-size: 0.82353em; }
  .rezult-card__list {
    display: none;
    margin-bottom: 15px; }
  .rezult-card__item {
    display: flex;
    margin-bottom: 10px; }
    .rezult-card__item:last-child {
      margin-bottom: 0; }
    .rezult-card__item .stars-view {
      margin-right: 10px; }
      .rezult-card__item .stars-view .icon {
        font-size: 14px; }
    .rezult-card__item-name {
      font-size: 0.88235em; }
  .rezult-card__bottom {
    display: flex;
    justify-content: flex-end; }
    .rezult-card__bottom .btn {
      margin-left: auto;
      width: auto;
      padding: 0 22px; }
  .rezult-card__btn-full {
    display: none; }

.board-title p {
  font-size: 17px; }

.board-block {
  display: none; }
  .board-block.active {
    display: block; }

.board-form__action {
  justify-content: flex-start;
  flex-wrap: wrap; }
  .board-form__action .btn {
    width: auto;
    padding: 0 30px;
    margin-right: 30px;
    height: 40px;
    margin-bottom: 10px; }
    .board-form__action .btn:last-child {
      margin-right: 0; }

.tabs-alt {
  margin-bottom: 50px;
  margin-top: 50px; }
  .tabs-alt ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .tabs-alt ul li:hover, .tabs-alt ul li.active {
      position: relative; }
      .tabs-alt ul li:hover a, .tabs-alt ul li.active a {
        background: #D9F0F7;
        border-bottom-color: #cc1b28;
        box-shadow: inset 0 -2px 0 -1px #cc1b28; }
      .tabs-alt ul li:hover:hover a, .tabs-alt ul li.active:hover a {
        background: rgba(217, 240, 247, 0.5);
        border-bottom-color: rgba(204, 27, 40, 0.6); }
    .tabs-alt ul li:first-child {
      position: relative;
      z-index: 1;
      margin-right: -9px; }
      .tabs-alt ul li:first-child a {
        min-width: 0;
        padding: 0 12px;
        border-left: 1px solid #D9F0F7;
        border-right: 1px solid #D9F0F7;
        transform: skew(0deg); }
        .tabs-alt ul li:first-child a span {
          transform: skew(0deg); }
    .tabs-alt ul a {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      padding: 0 15px;
      position: relative;
      min-width: 123px;
      background: #fff;
      border: 1px solid #D9F0F7;
      transform: skew(20deg); }
      .tabs-alt ul a span {
        transform: skew(-20deg); }

.board-filter {
  display: flex; }
  .board-filter__select {
    position: relative;
    width: 100%;
    max-width: 160px; }
    .board-filter__select.opened .board-filter__select-text {
      border-bottom-color: #E0E0E0; }
    .board-filter__select.opened .board-filter__select-drop {
      display: block; }
    .board-filter__select-trigger {
      width: 100%;
      background: #D9F0F7;
      border-radius: 5px;
      cursor: pointer;
      position: relative;
      padding-right: 32px; }
      .board-filter__select-trigger:after {
        content: '';
        border-bottom: 3px solid #19124A;
        border-right: 3px solid #19124A;
        transform: rotate(45deg);
        width: 10px;
        height: 10px;
        margin-left: auto;
        position: absolute;
        top: 12px;
        right: 12px; }
    .board-filter__select-text {
      height: 30px;
      display: flex;
      align-items: center;
      margin: 0 10px;
      white-space: nowrap;
      font-size: 14px;
      height: 35px;
      position: relative;
      z-index: 1;
      border-bottom: 1px solid transparent;
      overflow: hidden;
      text-overflow: ellipsis; }
      .board-filter__select-text span {
        text-decoration: none; }
    .board-filter__select-drop {
      position: absolute;
      display: none;
      top: 100%;
      margin-top: -3px;
      padding-top: 3px;
      left: 0;
      background: #D9F0F7;
      border-radius: 0 0 5px 5px;
      padding: 7px 15px;
      width: 100%;
      cursor: initial; }
      .board-filter__select-drop ul {
        padding-top: 8px; }
      .board-filter__select-drop li {
        margin-bottom: 9px; }
        .board-filter__select-drop li:last-child {
          margin-bottom: 0; }
      .board-filter__select-drop label {
        display: flex; }
        .board-filter__select-drop label input {
          display: none; }
          .board-filter__select-drop label input + span {
            border: 0.5px solid #19124A;
            border-radius: 2px;
            display: block;
            width: 14px;
            min-width: 14px;
            height: 14px;
            margin-right: 10px;
            position: relative; }
            .board-filter__select-drop label input + span:after {
              content: '';
              display: block;
              width: 5px;
              height: 8px;
              border-bottom: 2px solid #19124A;
              border-right: 2px solid #19124A;
              transform: rotate(45deg);
              position: absolute;
              top: 1px;
              left: 5px;
              opacity: 0; }
          .board-filter__select-drop label input:checked + span:after {
            opacity: 1; }
        .board-filter__select-drop label span {
          line-height: 1;
          font-size: 14px; }
    .board-filter__select-action {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end; }
    .board-filter__select-btn {
      font-size: 13px;
      font-weight: bold;
      color: #0A4798;
      text-decoration: underline; }
      .board-filter__select-btn:hover {
        text-decoration: none; }

.price-list__title {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center; }

.price-list__top {
  text-align: center;
  position: relative;
  margin-top: 58px;
  padding-bottom: 50px;
  border-bottom: 1px solid #19124A;
  margin-bottom: 30px; }
  .price-list__top-title {
    font-size: 24px;
    margin-bottom: 7px; }
  .price-list__top-line {
    margin-bottom: 9px;
    font-size: 15px; }
    .price-list__top-line:last-child {
      margin-bottom: 0; }
    .price-list__top-line span {
      color: #CC1B28; }
  .price-list__top-logo {
    position: absolute;
    top: 0;
    right: 0;
    width: 126px; }
    @media (max-width: 768px) {
      .price-list__top-logo {
        display: none; } }
    .price-list__top-logo img {
      width: 100%;
      display: block;
      margin-bottom: 8px; }
    .price-list__top-logo span {
      font-style: italic;
      font-size: 11px;
      line-height: 1; }

.table-box {
  margin-bottom: 15px; }
  @media (max-width: 768px) {
    .table-box {
      min-width: 768px; } }
  .table-box__wrap {
    overflow-x: auto;
    margin-bottom: 10px; }
    .table-box__wrap:last-child {
      margin-bottom: 35px; }
  .table-box__title {
    max-width: calc(100% - 195px); }
    @media (max-width: 768px) {
      .table-box__title {
        max-width: 100%; } }
  .table-box--sm {
    max-width: calc(100% - 195px); }
    @media (max-width: 768px) {
      .table-box--sm {
        max-width: 100%; } }
    .table-box--sm .table-box__box:nth-child(2) {
      width: 151px;
      min-width: 151px; }
    .table-box--sm .table-box__head .table-box__box:first-child {
      font-size: 17px; }
    .table-box--sm .table-box__body .table-box__line:nth-child(odd) .table-box__box {
      background-color: transparent; }
    .table-box--sm .table-box__body .table-box__line:nth-child(even) .table-box__box {
      background: #F2F2F2; }
  .table-box__info {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-style: italic;
    margin-top: 10px; }
    .table-box__info-alt {
      font-size: 14px;
      margin-top: 10px;
      max-width: 500px; }
  .table-box__head .table-box__box {
    background: #D9F0F7;
    color: #19124A;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 50px;
    font-size: 15px;
    padding: 0 10px; }
    .table-box__head .table-box__box:first-child {
      font-size: 21px; }
  .table-box__body .table-box__line:nth-child(odd) .table-box__box {
    background: #F2F2F2; }
  .table-box__body .table-box__box {
    padding: 15px 27px;
    justify-content: center; }
    .table-box__body .table-box__box:first-child {
      display: block; }
  .table-box__line {
    display: flex; }
  .table-box__box {
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-size: 15px; }
    .table-box__box:last-child {
      margin-right: 0; }
    .table-box__box:nth-child(1) {
      flex: 1; }
    .table-box__box:nth-child(2) {
      width: 151px;
      min-width: 151px; }
    .table-box__box:last-child {
      width: 185px;
      min-width: 185px; }
