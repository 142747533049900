.brif {
  margin-top: 90px;
  max-width: 590px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #C2E9F5;
  border-radius: 5px;
  padding: 47px 25px 40px;

  @include media($md) {
    max-width: 100%;
  }

  &.section {
    padding-bottom: 20px;
  }

  .form__label-comb {
    @include media($xl) {
      display: block;
      margin-right: 0;
    }

    .form__input {
      @include media($xl) {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__title {
    margin-bottom: 21px;
    padding-left: 25px;
    padding-right: 25px;
  }

  &__form {
    padding: 0 30px;

    .form__input {
      height: 30px;
    }

    .form__input,
    .form__textarea {
      font-size: 14px;
    }
  }

  &__info {
    font-style: italic;
    font-size: 15px;
    line-height: 1;
    text-align: center;
    width: 100%;
    max-width: 390px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: 0;
  }

  &__action {
    margin-top: 30px;
  }
}