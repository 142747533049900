.search-page {
  display: flex;
  width: 100%;
  max-width: 417px;

  @include media($sm) {
    max-width: 100%;
    margin-bottom: 20px;
  }

  &--big {
    max-width: 100%;
    margin-bottom: 20px;

    &.filter-search {
      max-width: 100%;
      margin-top: 30px;
    }
  }

  .form__label {
    border: 1px solid #C2E9F5;
    margin-right: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    width: 289px;
    margin-bottom: 0;
    overflow: hidden;

    @include media($sm) {
      width: 100%;
    }
  }

  .form__input {
    border: none;
    flex: 1;
    min-width: 0;
  }

  &__search {
    border: none;
    background-color: transparent;
    padding-right: 19px;
    font-size: 23px;
    color: #BDBDBD;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 48px;

    .icon {
      position: relative;
      bottom: -1px;
    }
  }

  &__btn {
    height: 40px;
    max-width: 106px;
  }
}