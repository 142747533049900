.card-exp {
  @include col(1,3);
  padding: 0 22px;
  margin-bottom: 35px;

  @include media($lg) {
    padding: 0 10px;
  }

  @include media($sm) {
    @include col(1,2);
  }

  @include media($min) {
    @include col(1,1);
  }

  &__content {
    border: 1px solid #C2E9F5;
    padding: 18px 15px 10px;
    text-align: center;
    height: 100%;

    &:hover {
      border-color: transparent;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    }

    @supports (flex-direction: column) {
      display: flex;
      flex-direction: column;
    }
  }

  &__avatar {
    overflow: hidden;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__name {
    min-height: 60px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 20px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 1px solid #C2E9F5;
      width: 80%;
    }

    span {
      width: 100%;
      flex: 0 0 100%;
    }
  }

  &__comp {
    color: #CC1B28;
  }

  &__title {
    font-size: em(15);
    margin-bottom: 15px;
    font-weight: bold;
  }

  &__text {
    margin-bottom: 24px;
    font-size: em(15);
  }

  &__btn {
    height: 30px;
    padding: 0 22px;
    width: auto;
    display: inline-flex;
  }

  &__action {
    margin-bottom: 25px;

    @supports (flex-direction: column) {
      margin-top: auto;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: em(11);
  }

  &__stat {
    span {
      color: #CC1B28;
    }
  }
}