.page {

  &-product {

    &__box {
      display: none;

      &.active {
        display: block;
      }

      &-cards {
        @include row(-12px);
        align-items: center;
      }

      &-card {
        width: 100%;
        max-width: calc(20% - 24px);
        flex: 0 0 calc(20% - 24px);
        margin: 0 12px;
        margin-bottom: 10px;
        display: block;

          @include media($lg) {
            max-width: calc(25% - 24px);
            flex: 0 0 calc(25% - 24px);
          }

          @include media($sm) {
            max-width: calc(33.3333% - 24px);
            flex: 0 0 calc(33.3333% - 24px);
          }

          @include media($xl) {
            max-width: calc(50% - 24px);
            flex: 0 0 calc(50% - 24px);
          }

        &:hover {
          box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
        }

        img {
          display: block;
          width: 100%;
          max-width: 100%;
        }
      }

      &-items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 40px;

        li {
          @include col(1,5);
          padding: 0 10px;
          margin-bottom: 10px;

          @include media($lg) {
            @include col(1,4);
          }

          @include media($sm) {
            @include col(1,3);
          }

          @include media($xl) {
            @include col(1,2);
          }
        }

        a {
          text-decoration: underline;
          color: #0A4798;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &__controls {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 0 20px;
    }

    &__btn {
      margin: 0 22px;
      display: block;
      border-bottom: 2px solid transparent;
      font-size: em(18);
      color: #19124A;
      font-weight: bold;
      margin-bottom: 10px;

      &:hover,
      &.active {
        border-bottom-color: #CC1B28;
        color: #0A4798;
      }
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    .btn {
      height: 40px;
      width: auto;
      padding: 0 30px;
    }
  }

  &-calc {
    border-radius: 8px;
    margin-bottom: 50px;
    padding: 10px;
    border: 1px solid $colorAccent;
    overflow: hidden;

    &__title {
      font-weight: bold;
      text-align: center;
      margin-bottom: 2px;
      background: #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      height: 40px;
    }

    &__item {
      margin-bottom: 2px;
      display: flex;
      align-items: center;

      &-name {
        background: #ddd;
        margin-right: 2px;
        width: 300px;
        min-width: 300px;
        height: 40px;
        padding: 0 10px;
        display: flex;
        align-items: center;
      }
      &-val {
        display: flex;
        align-items: center;
        flex: 1;
        background: #ddd;
        height: 40px;
        padding: 0 10px;

        input {
          height: 30px;
          width: 300px;
          padding: 0 10px;
        }
      }
    }

    &__action {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
      background: #ddd;

      .btn {
        display: inline-flex;
        width: auto;
        padding: 0 30px;
        height: 30px;
      }
    }
  }

  &-combox {
    display: flex;
    margin-bottom: 50px;

    @include media($sm) {
      display: block;
    }

    &__img {
      width: 260px;
      min-width: 260px;
      margin-right: 20px;

      @include media($sm) {
        margin-right: 0;
        margin-bottom: 20px;
        min-width: 0;
        width: 100%;
        max-width: 400px;
        max-height: 300px;
        overflow: hidden;
      }

      img {
        display: block;
        width: 100%;

        @include media($sm) {
          max-height: 100%;
        }
      }
    }

    &__text {}
  }

  &-experts {
    background: #F0FAFD;
    padding: 30px 20px 70px;

    @include media($sm) {
      padding-bottom: 40px;
    }

    &__content {
      position: relative;

      &--cards {
        min-height: 380px;

        @include media($sm) {
          min-height: 0;
          display: flex;
          flex-wrap: wrap;
          margin-right: -20px;
        }
      }
    }

    &__cols {
      display: flex;
      padding: 0 40px;
      margin-left: -65px;
      margin-right: -65px;

      @include media($sm) {
        display: block;
        padding: 0 20px;
      }
    }

    &__col {
      padding: 0 65px;
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%;
      text-align: center;
      margin-top: 50px;

      @include media($sm) {
        max-width: 100%;
      }

      &-text {
        margin-bottom: 45px;
        font-size: 15px;
        max-width: 276px;
        margin-left: auto;
        margin-right: auto;
      }

      &-btn {
        max-width: 185px;
        margin-left: auto;
        margin-right: auto;
      }

      &-title {
        font-size: 19px;
        margin-bottom: 40px;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
      }

      &-img {

        img {
          display: block;
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &__price {
      display: inline-flex;
      position: relative;
      font-size: 30px;
      font-weight: bold;
      color: #CC1B28;
      margin-bottom: 45px;
      padding-top: 20px;

      span {
        position: absolute;
        color: #19124A;

        &:first-child {
          right: 100%;
          margin-right: 13px;
          font-size: 25px;
          bottom: -5px;
        }

        &:last-child {
          font-weight: 400;
          right: -5px;
          top: 0;
          top: -3px;
          font-size: 18px;
        }
      }
    }

    &-card {
      position: absolute;
      background: #fff;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
      padding: 20px 13px 15px;
      text-align: center;
      width: 24.7%;
      top: 50%;
      transform: translateY(-50%);
      transition: .3s;

      @include media($lg) {
        padding-left: 10px;
        padding-right: 10px;
      }

      @include media($sm) {
        position: static;
        transform: translate(0);
        width: 100%;
        max-width: calc(50% - 20px);
        flex: 0 0 calc(50% - 20px);
        margin-right: 20px;
        margin-bottom: 20px;

        @supports (flex-direction: column) {
          display: flex;
          flex-direction: column;
        }
      }

      @include media($xl) {
        max-width: calc(100% - 20px);
        flex: 0 0 calc(100% - 20px);
      }

      &:hover {
        z-index: 999;
        transform: translateY(-50%) scale(1.1);
        box-shadow: 2px 2px 17px rgba(0, 0, 0, 0.25);

        @include media($sm) {
          transform: translateY(0) scale(1.1);
        }
      }

      &:nth-child(1) {
        left: 0;
      }

      &:nth-child(2) {
        left: 16.45%;
      }

      &:nth-child(3) {
        left: 50%;
        transform: translate(-50%,-50%);

        @include media($sm) {

        transform: translate(0);
        }
      }

      &:nth-child(4) {
        right: 16.45%;
      }

      &:nth-child(5) {
        right: 0;
      }

      &--sm {
        z-index: 2;
        width: 26.2%;

        .page-experts-card__avatar {
          width: 72px;
          height: 72px;

          @include media($sm) {
            width: 80px;
            height: 80px;
          }
        }
        .page-experts-card__name {
          font-size: em(18);
          line-height: em(23);
          margin-bottom: 25px;
          padding-bottom: 2px;

          @include media($sm) {
            font-size: em(21);
            margin-bottom: 30px;
          }
        }
        .page-experts-card__title {
          font-size: em(13);
          margin-bottom: 18px;

          @include media($sm) {
            margin-bottom: 25px;
            font-size: em(15);
          }
        }
        .page-experts-card__text {
          min-height: 76px;
          font-size: em(15);
          margin-bottom: 24px;

          @include media($sm) {
            min-height: 0;
            font-size: em(17);
            margin-bottom: 30px;
          }
        }
        .page-experts-card__action {
          font-size: em(10);

          @include media($sm) {
            font-size: em(11);
          }
        }
      }

      &--big {
        width: 29.3%;
        z-index: 3;

        .page-experts-card__avatar {
          width: 80px;
          height: 80px;
          margin-bottom: 12px;
        }
        .page-experts-card__name {
          font-size: em(21);
          margin-bottom: 30px;
          border-bottom: 10px;
          padding-bottom: 3px;
        }
        .page-experts-card__title {
          font-size: em(15);
          margin-bottom: 25px;
        }
        .page-experts-card__text {
          font-size: em(17);
          margin-bottom: 30px;
          min-height: 85px;

          @include media($sm) {
            min-height: 0;
          }
        }
        .page-experts-card__action {
          font-size: em(11);
        }
      }

      &__avatar {
        border-radius: 50%;
        overflow: hidden;
        width: 67px;
        height: 67px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;

        @include media($sm) {
          width: 80px;
          height: 80px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          display: block;
        }
      }

      &__name {
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: em(16);
        margin-bottom: 23px;
        position: relative;

        @include media($sm) {
          font-size: em(21);
          margin-bottom: 30px;
        }

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-bottom: 2px solid #D9F0F7;
          width: 80%;
        }
      }

      &__title {
        text-align: center;
        font-size: em(12);
        font-weight: bold;
        margin-bottom: 20px;

        @include media($sm) {
          margin-bottom: 25px;
          font-size: em(15);
        }
      }

      &__text {
        min-height: 72px;
        font-size: em(13);
        line-height: 17px;
        margin-bottom: 17px;

        @include media($sm) {
          min-height: 0;
          font-size: em(17);
          margin-bottom: 30px;
        }
      }

      &__action {
        display: flex;
        justify-content: space-between;
        font-size: em(9);

        @include media($sm) {
          font-size: em(11);

          @supports (flex-direction: column) {
            margin-top: auto;
          }
        }
      }

      &__stat {
        span {
          color: #CC1B28;
        }
      }
    }
  }

  &-cat {
    margin: 0 -12px;

    li {
      display: inline-block;
      margin-bottom: 14px;
      padding: 0 12px;
    }

    a {
      border-bottom: 3px solid transparent;

      &:hover,
      &.active {
        color: #19124A;
        border-bottom-color: #CC1B28;
      }

      &.active {
        font-weight: bold;
      }
    }
  }

  &-cont {
    border: 1px solid #D9F0F7;
    padding: 20px;

    .text {
      font-style: italic;

      p {
        a {
          color: #0A4798;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      ul {
        padding-left: 0;
      }
    }
  }

  &-block {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      text-align: center;
      height: 60px;
      font-size: em(21);
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #D9F0F7;

      &:hover {
        background: darken(#D9F0F7,10%);
      }
    }

    &__content {
      background: #F0FAFD;
      padding: 20px 25px 25px;

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        font-size: em(19);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &-col {
    @include col(1,3);
    padding: 0 10px;

    @include media($sm) {
      @include col(1,1);
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &s {
      @include row(-10px);
    }
  }

  &-need {
    margin: 30px 0;
    border: 2px solid #C2E9F5;
    padding: 20px 20px 32px;

    &__title {
      margin-bottom: 35px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
    }

    &__item {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-slider {
    padding: 0 66px;

    @include media($sm) {
      padding: 0;
    }

    &__item {
      position: relative;

      &-img {
        display: block;
        position: relative;
        overflow: hidden;

        &:before {
          content: '';
          display: block;
          padding-top: 67.3%;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      &-title {
        position: absolute;
        left: 0;
        bottom: 76px;
        background: rgba(0, 0, 0, 0.5);
        padding: 14px;
        font-size: em(18);
        font-style: italic;
        font-weight: bold;
        color: #fff;

        @include media($md) {
          position: static;
          background-color: transparent;
          color: #000;
          font-size: 14px;
          padding: 10px 0;
        }

        @include media($xl) {
          display: none;
        }

        @include media($min) {
          bottom: 60px;
        }
      }
    }

    &__dots {
      flex: 1;
      width: 100%;

      &-wrap {
        position: relative;
        display: flex;
        margin-top: 15px;
        overflow: hidden;
      }

      &-link {
        background: #D9F0F7;
        border-radius: 3px;
        width: 70px;
        min-width: 70px;
        height: 50px;
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-size: em(25);
        cursor: pointer;
        transition: .3s;
        margin-right: 15px;

        span {
          flex: 0  0 100%;
          font-size: em(8);
          text-align: center;
        }

        .icon {
          margin-bottom: -10px;
        }

        &:hover {
          background: darken(#D9F0F7,10%);
          box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
        }
      }

      &-item {
        height: 50px;
        margin: 0 8px 15px;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        img {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        &.slick-current {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid red;
          }
        }
      }

      .slick-track {
        margin-left: 0;
      }
    }
  }

  &-files {
    display: flex;
    flex-wrap: wrap;
    margin-right: -17px;

    &__info {
      padding-left: 34px;
      font-size: em(15);
      color: #0A4798;
      font-style: italic;
    }

    &__item {
      width: 100%;
      max-width: calc(20% - 17px);
      flex: 0 0 calc(20% - 17px);
      margin-right: 17px;
      margin-bottom: 17px;

      @include media($sm) {
        max-width: calc(33.3333% - 17px);
        flex: 0 0 calc(33.3333% - 17px);
      }

      @include media($min) {
        max-width: calc(50% - 17px);
        flex: 0 0 calc(50% - 17px);
      }

      a {
        display: block;
        position: relative;
        overflow: hidden;

        &:before {
          content: '';
          display: block;
          padding-top: 70%;
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      &-add {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        font-size: em(15);
        font-style: italic;

        @include media($lg) {
          font-size: em(13);
        }

        &-wrap {
          background: #D9F0F7;
          border-radius: 8px;
          transition: .3s;

          &:hover {
            background: darken(#D9F0F7,10%);
            box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
          }
        }

        span {
          display: block;
        }

        .icon {
          font-size: em(54);

          @include media($lg) {
            font-size: em(48);
          }
        }
      }
    }
  }

  &-video {
    height: 451px;
  }

  &-next-post {
    height: 0;
    display: flex;
    justify-content: center;
    position: relative;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 50%;
      background: #B88B58;
      width: 115px;
      height: 115px;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      font-size: em(16);
      letter-spacing: 0.04em;
      color: #fff;
      padding-bottom: 7px;
      position: relative;
      z-index: 10;

      &.fixed {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &:after {
        position: absolute;
        left: 50%;
        margin-left: -11px;
        bottom: 18px;
        content: '';
        border-top: 11px solid;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
      }
    }
  }

  &-add {
    padding: 0 35px;

    &__line {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      @include media($xl) {
        display: block;
        text-align: center;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__action {
      margin-right: 36px;
      width: 100%;
      max-width: 228px;
      min-width: 228px;

      @include media($xl) {
        min-width: 0;
        max-width: 100%;
        margin-bottom: 15px;
      }

      .btn {
        height: 40px;
      }
    }

    &__text {
      font-style: italic;
      font-size: em(15);
      line-height: 1;
    }
  }

  &-stats {
    border: 1px solid #C2E9F5;
    padding: 20px 25px 25px;

    &__main {
      display: flex;
      align-items: flex-start;
      margin-bottom: 18px;

      @include media($xl) {
        display: block;
      }
    }

    &__info {
      display: flex;
      align-items: flex-start;
      flex: 1;
    }

    &__avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 28px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__text {
      flex: 1;
      font-size: em(16);
      font-style: italic;

      a {
        font-weight: bold;

        &:hover {
          text-decoration: underline;
          color: #0A4798;
        }
      }
    }

    &__action {
      margin-left: 32px;
      width: 100%;
      max-width: 208px;

      @include media($xl) {
        max-width: 100%;
        margin-left: 0;
        margin-top: 15px;
      }

      .btn {
        margin-bottom: 8px;
        height: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__stats {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      @include media($sm) {
        flex-wrap: wrap;
      }
    }

    &__stat {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-size: em(15);
      padding: 0 20px;
      align-items: center;
      min-width: 150px;

      @include media($lg) {
        padding: 0 10px;
        min-width: 100px;
      }

      @include media($sm) {
        margin-bottom: 10px;
      }

      &-icon {
        font-size: em(25);

        .icon {
          position: relative;
          bottom: -2px;
        }
      }

      &-icon,
      &-num {
        padding: 0 8px;
      }

      &-name {
        margin-top: 10px;
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        white-space: nowrap;
      }
    }

    &__social {
      justify-content: center;
      display: flex;
    }
  }

  &-buy {
    display: flex;
    flex-wrap: wrap;

    @include media($sm) {
      display: block;
    }

    &__item {
      margin: 0 15px 30px;
      width: calc(50% - 30px);
      background: #F0FAFD;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      padding: 10px 0 10px 93px;
      display: flex;
      align-items: center;
      position: relative;
      transition: background .3s;

      @include media($sm) {
        width: 100%;
        margin: 0 0 30px;
      }

      @include media($min) {
        padding-left: 10px;
      }

      &:hover {
        background: darken(#F0FAFD,5%);
      }

      &-icon {
        position: absolute;
        left: 15px;
        width: 78px;
        height: calc(100% + 14px);
        top: -6px;
        border-radius: 0 0 5px 5px;
        background: #C2E9F5;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: em(39);
        color: #99C6D2;

        @include media($min) {
          display: none;
        }

        &:after,
        &:before {
          content: '';
          position: absolute;
          top: 0;
          border-bottom: 6px solid #99C6D2;
        }

        &:after {
          right: 100%;
          border-left: 6px solid transparent;
        }

        &:before {
          left: 100%;
          border-right: 6px solid transparent;
        }
      }

      &-name {
        text-align: center;
        font-size: em(14);
        flex: 1;
        border-right: 3px solid #99C6D2;

        span {
          display: block;
          font-size: em(15);
          font-weight: bold;
          margin-top: 1px;
        }
      }

      &-link {
        align-self: stretch;
        width: 100%;
        max-width: 97px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: em(15);
        color: #0A4798;
        text-decoration: underline;
        font-weight: bold;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &-question {
    background: linear-gradient(172.19deg, #042C45 1.77%, #D9F0F7 98.87%);
    border-radius: 6px;
    padding: 14px 20px 22px;

    &--alt {
      background: linear-gradient(#697A0A,#E2E25A);
    }

    &.active {
      .page-question__title {
        margin-bottom: 12px;
      }
      .page-question__btn {
        height: 40px;
        max-width: 160px;
        pointer-events: none;
      }
      .page-question__btn-name {
        font-size: em(17);

        span {
          display: block;
        }
      }
      .page-question__btn-val {
        display: block;
      }
    }

    &__title {
      text-align: center;
      font-size: em(21);
      font-weight: bold;
      margin-bottom: 15px;
      color: #fff;
    }

    &__action {
      display: flex;
      justify-content: center;

      @include media($xl) {
        display: block;
      }
    }

    &__btn {
      max-width: 130px;
      margin-right: 30px;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);

      @include media($xl) {
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
      }

      &:last-child {
        margin-right: 0;

        @include media($xl) {
          margin-bottom: 0;
          margin-right: auto;
        }
      }

      &.btn--accent,
      &.btn--gray {
        .page-question__btn-val {
          color: #0A4798;
        }
      }

      &-name {
        font-size: em(18);
        display: flex;
        white-space: nowrap;

        span {
          margin-left: 4px;
          display: none;
        }
      }

      &-val {
        display: none;
        font-size: em(24);
        font-weight: bold;
        margin-left: 10px;
      }
    }
  }

  &-rating {
    display: flex;
    align-items: center;
    position: relative;
    background: #FFFFFF;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    margin-bottom: 30px;
    padding: 17px 15px 12px;

    &:after {
      content: '';
      position: absolute;
      background-image: url('../img/logo-alt.png');
      background-repeat: no-repeat;
      top: 14px;
      right: 10px;
      width: 74px;
      height: 23px;
      background-size: contain;

      @include media($xl) {
        top: -2px;
        right: -1px;
      }
    }

    &__img {
      max-width: 86px;
      width: 100%;
      margin-right: 41px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media($sm) {
        display: none;
      }

      img {
        display: block;
        max-width: 100%;
      }
    }

    &__info {
      flex: 1;
      text-align: center;
    }

    &__diagram {
      width: 100%;
      max-width: 90%;

      @include media($sm) {
        max-width: 100%;
        display: flex;
        flex-direction: row-reverse;
      }
    }

    &__title {
      font-size: em(15);
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__bottom {
      margin-top: 22px;
      position: relative;
      font-style: italic;
      font-size: em(14);
    }

    &__points {
      position: absolute;
      right: 0;
      bottom: 0;

      @include media($sm) {
        position: static;
        text-align: right;
        margin-top: 10px;
      }
    }

    &__text {
      @include media($md) {
        text-align: left;
      }

      @include media($sm) {
        text-align: center;
      }
    }

    &__action {
      display: flex;

      @include media($sm) {
        display: block;
        margin-right: 5px;
      }
    }

    &__item {
      flex: 1;
      text-align: center;
      height: 100%;

      @include media($sm) {
        height: 40px;
        margin-bottom: 3px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__box {
      background: #C9DEE4;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      @include media($sm, min) {
        width: 40px !important;
      }

      @include media($sm) {
        height: 40px !important;
        margin-left: 0;
      }

      span {
        position: absolute;
        bottom: 14px;
        left: 0;
        width: 100%;
        font-size: em(15);
        font-weight: bold;

        @include media($sm) {
          bottom: 50%;
          left: 30px;
          transform: translateY(50%);
          text-align: left;
        }
      }
    }

    &__btn {
      color: #fff;
      display: inline-flex;
      align-items: center;
      padding: 0 10px;
      height: 26px;
      background: #B88B58;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      font-size: em(14);

      @include media($sm) {
        display: flex;
        height: 100%;
        justify-content: center;
      }

      &:hover {
        background: darken(#B88B58,10%);
      }
    }

    &__pic {
      height: 75px;
      margin-bottom: 17px;
      display: flex;
      align-items: flex-end;
      position: relative;

      @include media($sm) {
        display: block;
        flex: 1;
        height: auto;
      }

      .page-rating__item {
        display: flex;
        align-items: flex-end;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 3px solid #19124A;
        width: 90%;

        @include media($sm) {
          bottom: auto;
          width: 0;
          height: 100%;
          top: 0;
          left: 0;
          transform: translateX(0);
          border-bottom: none;
          border-left: 3px solid #19124A;
        }
      }
    }
  }

  &-navigation {
    margin-bottom: 30px;
    display: flex;

    @include media($sm) {
      display: block;
    }

    &__advert {
      margin-right: 55px;

      @include media($sm) {
        margin-right: 0;
      }
    }

    &__main {
      flex: 1;

      .title {
        margin-bottom: 24px;
      }
    }

    &__list {
      margin-bottom: 23px;

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        font-size: em(18);
        line-height: 1;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__action {
      display: flex;
      justify-content: flex-end;
    }

    &__btn {
      font-weight: bold;
      font-size: em(15);
      border-bottom: 2px solid #CC1B28;

      &:hover {
        border-bottom-color: transparent;
      }
    }
  }

  &-banner {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 400px;
    padding: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 50px;

    @include media($min) {
      padding: 10px;
    }

    &--big {
      min-height: 565px;
      margin-bottom: 70px;
    }

    &__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &__cont {
      position: relative;
      z-index: 1;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      width: 100%;
      max-width: 224px;
      color: #fff;
      padding: 12px 20px 18px;

      @include media($min) {
        max-width: 100%;
      }

      &--alt {
        background: rgba(#FFFFFF, .9);
        min-width: 285px;
        padding: 27px 11px 11px;
        color: #19124A;
      }
    }

    &__info {
      margin-top: 25px;
      font-size: em(13);
      text-align: right;
    }

    &__lines {
      display: table;
      width: 100%;

      @include media($min) {
        display: block;
      }

      &-all {
        display: flex;
        justify-content: center;
      }
    }

    &__line {
      display: table-row;

      @include media($min) {
        display: block;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &-num {
        text-align: right;
        font-size: em(35);
        font-weight: bold;
        white-space: nowrap;
        padding-right: 7px;
      }

      &-num,
      &-name {
        display: table-cell;
        padding-bottom: 20px;
        vertical-align: baseline;
        line-height: 1;

        @include media($min) {
          display: block;
          text-align: center;
        }
      }

      &-name {
        font-size: em(16);
      }

      &-accent {

        .page-banner__line-num,
        .page-banner__line-name {
          display: block;
          padding-bottom: 0;
          padding-right: 0;
        }

        .page-banner__line-num {
          text-align: left;
          color: #CC1B28;

          @include media($min) {
            text-align: center;
          }
        }
      }
    }

    &__title {
      text-align: center;
      font-size: em(15);
      margin-bottom: 22px;
      font-weight: bold;
    }

    &__list {
      display: block;

      &.classification-list {
        margin-bottom: 10px;

        li {
          margin-bottom: 14px;
        }
      }
    }

    &__stars {
      justify-content: center;
      margin-bottom: 4px;

      .stars-view__item {
        margin-right: 6px;
      }

      .icon {
        font-size: em(14);
      }
    }

    &__points {
      font-size: em(14);
      text-align: center;
    }
  }

  &-social {
    .social {
      ul {
        justify-content: center;
      }
    }
  }

  &-controls {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @include media($md) {
      display: block;
    }

    .breadcrumbs {
      margin-right: auto;
      margin-bottom: 0;

      @include media($md) {
        margin-bottom: 15px;
      }
    }

    .favorite__btn {
      position: relative;
      bottom: -1px;

      &:hover {
        .favorite__btn-text {
          text-decoration: underline;
        }
      }

      .icon {
        font-size: em(20);
        height: 20px;
        position: relative;
        bottom: -1px;
      }
    }

    .favorite__btn-text {
      text-decoration: none;
      margin-left: 0;
      font-size: 15px;
    }

    &__btn {
      display: flex;
      align-items: center;
      margin-left: 36px;
      font-size: 15px;
      line-height: 1;

      @include media($md) {
        display: inline-flex;
        margin-left: 0;
        margin-right: 30px;
        vertical-align: bottom;
      }

      &-size {
        background-image: url('../img/size__img.svg');
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 43px;
        min-height: 19px;

        span {
          position: relative;
          bottom: -1px;
        }
      }

      &:hover {
        span {
          text-decoration: underline;
        }
      }

      span {
        margin-left: 13px;
      }

      .icon {
        font-size: em(20);
      }
    }
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 35px;

    @include media($md) {
      display: block;
    }

    .social {
      margin-left: auto;

      @include media($md) {
        margin-left: 0;
      }
    }

    &__btn {
      max-width: 168px;
      height: 40px;
      margin-right: auto;

      @include media($xl) {
        margin-bottom: 10px;
      }
    }

    &__btns {
      display: flex;
      white-space: nowrap;

      @include media($xl) {
        flex-wrap: wrap;
        margin-bottom: 10px;
      }

      .favorite__btn {
        margin-left: 0;

        &:hover {
          .favorite__btn-text {
            text-decoration: underline;
          }
        }
      }

      .favorite__btn-text {
        text-decoration: none;
      }
    }

    &--alt {
      @include media($lg) {
        flex-wrap: wrap;
      }

      @include media($md) {
        display: flex;

        .page-controls__btn-size {
          margin-right: 0;
        }
      }

      @include media($xl) {
        display: block;
      }

      .social {
        @include media($lg) {
          margin-right: 0;
          margin-left: auto;
          margin-top: 10px;
        }

        @include media($md) {
          margin-left: auto;
        }

        @include media($xl) {
          margin-top: 0;
        }
      }
    }
  }

  &-user {
    display: flex;
    align-items: center;
    font-size: em(15);
    padding-left: 42px;

    @include media($md) {
      margin-bottom: 15px;
      flex-wrap: wrap;
    }

    &__avatar {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      margin-right: 7px;
      overflow: hidden;
      display: block;
      margin-left: -42px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__name {
      margin-right: 5px;

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__firm {
      margin-right: 18px;

      a {
        color: #CC1B28;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__date {}
  }

  &-nav {
    background: #F0FAFD;
    margin-bottom: 80px;
    padding: 20px 20px 30px;

    @include media($sm) {
      margin-bottom: 50px;
    }

    &__box {
      margin-top: 44px;

      li {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        font-size: em(19);
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__col {
      width: 100%;
      max-width: 33.3333%;
      flex: 0 0 33.33333%;
      padding: 0 38px;

      @include media($lg) {
        padding: 0 20px;
      }

      @include media($sm) {
        max-width: 100%;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &s {
        margin: 0 -38px;
        display: flex;
        flex-wrap: wrap;

        @include media($lg) {
          margin: 0 -20px;
        }

        @include media($sm) {
          display: block;
        }
      }

      &-title {
        font-size: em(19);
        margin-bottom: 30px;
        font-weight: bold;
        text-align: center;
      }

      &-list {
        column-count: 2;
        column-gap: 30px;

        & + .page-nav__col-list {
          margin-top: 10px;
          padding-top: 6px;
          border-top: 1px solid #19124A;
        }

        li {
          margin-bottom: 5px;
        }

        a {
          font-size: em(16);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-box {
    @include col(1,3);
    padding: 0 10px;

    @include media($md) {
      @include col(1,1);
      margin-bottom: 20px;
    }

    &--sm {
      @include col(36.26%);
    }

    &--alt {
      .page-box__content {
        padding: 15px 13px 30px;

        @supports (flex-direction: column) {
          display: flex;
          flex-direction: column;
        }
      }

      .page-box__action {
        @supports (flex-direction: column) {
          margin-top: auto;
        }
      }
    }

    &--comb {

      @supports (flex-direction: column) {

        .page-box__content {
          display: flex;
          flex-direction: column;
        }

        .page-box__box:nth-child(1) {
          flex: 1;
        }

        .page-box__box:nth-child(2) {
          flex: 2;
        }
      }

      .page-box__content {
        background-color: transparent;
      }
    }

    &__box {
      background: #F0FAFD;
      margin-bottom: 20px;
      padding: 12px 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &.page-form {

        @supports (flex-direction: column) {
          display: flex;
          flex-direction: column;

          .form {
            flex: 1;
            display: flex;
            flex-direction: column;

            & > * {
              margin-top: auto;
            }
          }
        }
      }
    }

    &__img {

      img {
        display: block;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__title {
      text-align: center;
      margin-bottom: 7px;
      font-weight: bold;
      font-size: em(20);
    }

    &s {
      @include row(-10px);

      &--alt {
        @include row(-20px);
        flex-wrap: nowrap;

        @include media($md) {
          flex-wrap: wrap;
        }

        .page-box {
          padding: 0 20px;
          max-width: 100%;
          flex: 1;

          &--sm {
            @include col(36%);
          }

          @include media($md) {
            @include col(1,1);
            margin-bottom: 20px;
          }
        }
      }
    }

    &__text {
      font-size: em(15);

      b {
        a {
          text-decoration: none;
          color: inherit;
        }
      }

      a {
        font-weight: bold;
        color: #0A4798;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__content {
      background: #F0FAFD;
      height: 100%;
      position: relative;
    }

    &__top {
      top: -8px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      &-img {
        margin-right: 30px;
        display: block;
      }
    }

    &__cont {
      padding: 114px 20px 47px;
      text-align: center;

      @include media($lg) {
        padding: 100px 20px 25px;
      }

      @supports (flex-direction: column) {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    &__text {
      margin-bottom: 32px;
      line-height: 22px;
    }

    &__action {
      margin-top: auto;
      
      .btn {
        max-width: 154px;
        height: 40px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__btn {
      height: 40px;
    }
  }

  &-login {

    &__avatar {
      border-radius: 50%;
      background: #19124A;
      width: 80px;
      height: 80px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: em(59);

      .icon {
        position: relative;
        bottom: -4px;
      }
    }

    &__title {
      font-weight: bold;
      font-size: em(24);
      margin-bottom: 12px;
      color: #CC1B28;
    }
  }

  &-faq {

    &__top {
      background: #19124A;
      color: #fff;
      padding: 0 24px;
      align-items: center;
      font-size: em(20);
      font-weight: bold;
      height: 53px;
      display: inline-flex;
      white-space: nowrap;

      @supports (clip-path: polygon(0 0, 100% 100%)) {
        clip-path: polygon(5px 0, 95% 3px, 100% 100%, 0 99%);
      }

      .icon {
        font-size: em(32);
        margin-right: 15px;
      }
    }
  }

  &-board {
    padding: 55px 20px 40px;
    text-align: center;

    @supports (flex-direction: column) {
      display: flex;
      flex-direction: column;
      height: 100%;

      .page-box__action {
        margin-top: auto;
      }
    }

    &__top {
      color: #fff;
      background: #19124A;
      height: 48px;
      padding: 0 20px 0 12px;
      display: flex;
      align-items: center;
      font-size: em(20);
      font-weight: bold;
      white-space: nowrap;
    }

    .btn {
      max-width: 188px;
    }
  }

  &-form {
    padding-bottom: 40px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 160px;
      height: 7px;
      background-image: url('../img/bg-line.png');
      background-repeat: repeat-x;
    }

    .form {
      max-width: 203px;
      margin-left: auto;
      margin-right: auto;
    }

    .form__input {
      text-align: center;
    }

    .btn {
      max-width: 144px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-search {
    padding: 20px 20px 30px;

    .page-box__title {
      margin-bottom: 15px;
    }
  }

  &-line {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 8px;

    & > * {
      margin-bottom: 10px;
    }

    @include media($sm) {
      display: block;
    }
  }

  &-forum {
    display: flex;
    margin-bottom: 34px;

    @include media($sm) {
      display: block;
    }

    &__faq {
      width: 325px;
      min-width: 325px;
      margin-right: 50px;
      position: relative;
      padding-right: 40px;

      @include media($sm) {
        min-width: 0;
        width: 100%;
        margin-right: 0;
        margin-bottom: 40px;
        padding-right: 0;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        border: 1px solid #D9F0F7;
        height: 100%;
        max-height: 347px;

        @include media($sm) {
          display: none;
        }
      }
    }

    &__now {
      flex: 1;
    }

    &__title {
      margin-top: 33px;
      text-align: center;
      margin-bottom: 17px;
      font-size: em(19);

      &:first-child {
        margin-top: 0;
      }
    }

    &__list {

      li {
        margin-bottom: 11px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__action {
      margin-top: 27px;
      display: flex;
      justify-content: center;

      .btn {
        width: auto;
        padding: 0 30px;
      }
    }

    &__question {

      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        font-size: em(18);
        font-style: italic;
        margin-right: 20px;

        &:hover {
          text-decoration: underline;
        }
      }

      &-num {
        display: inline-block;
        padding: 2px 7px;
        font-size: em(14);
        background: #F0FAFD;
      }
    }

    &__more {
      margin-top: 15px;

      a {
        text-decoration: underline;
        font-weight: bold;
        color: #0A4798;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__comment {
      margin-bottom: 24px;
      font-style: italic;
      line-height: 22px;

      &:last-child {
        margin-bottom: 0;
      }

      &-name  {
        font-weight: bold;
        margin-right: 5px;
        color: #CC1B28;
      }

      &-question {
        font-weight: bold;
        margin-right: 15px;
      }

      &-text {
        letter-spacing: 0.02em;
        line-height: 23px;
      }
    }
  }

  &-links {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 5px 25px;
    background: #F0FAFD;

    li {
      margin-right: 30px;
      margin-bottom: 15px;
    }

    a {
      font-size: em(19);
      font-weight: bold;

      &:hover {
        color: #0A4798;
      }
    }
  }

  &-rezult {

    &__box {
      margin-bottom: 64px;
      position: relative;

      &:last-child {
        margin-bottom: 20px;
      }

      &--simple {
        margin-bottom: 0;
      }

      &.active {

        .page-rezult__btn {
          &:after {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        .page-rezult__box-wrap {
          max-height: 999999px;

          &:after {
            display: none;
          }
        }
      }

      &-title {
        position: absolute;
        top: 0;
        left: 70px;
        background: #fff;
        font-size: em(21);
        transform: translateY(-50%);
        padding: 0 15px;
        z-index: 2;
      }

      &-wrap {
        border: 4px solid #D9F0F7;
        overflow: hidden;
        max-height: 316px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 92px;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.47) 0%, #FFFFFF 100%);
          z-index: 1;
        }
      }

      &-content {
        margin-bottom: 23px;
        padding: 37px 20px 0;
        column-count: 3;
        column-gap: 150px;

        @include media($lg) {
          column-gap: 100px;
        }

        @include media($xl) {
          column-count: 2;
          column-gap: 50px;
        }

        @include media($min) {
          column-count: 2;
          column-gap: 20px;
        }
      }
    }

    &__action {
      margin-top: 23px;
      display: flex;
      justify-content: center;
    }

    &__btn {
      width: auto;
      padding: 0 45px 0 30px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        border-top: 12px solid $colorTextMain;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
      }
    }

    &__list {
      margin-bottom: 10px;

      &-cont {
        display: inline-block;
      }

      &-title {
        margin-bottom: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        width: 30px;
        height: 31px;
        font-size: em(20);
        font-weight: bold;
        font-family: $ffAlt;
        border: 2px solid #C2E9F5;
        border-radius: 2px;
      }

      li {
        margin-bottom: 3px;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @include media($sm) {
      display: block;
    }

    &__img {
      margin-right: 58px;
      min-width: 150px;

      @include media($sm) {
        margin-bottom: 30px;
        margin-right: 0;
        min-width: 0;
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
      }

      img {
        display: block;
        max-width: 100%;
      }
    }

    &__text {
      font-style: italic;

      a {
        font-weight: bold;
        text-decoration: underline;
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &-sapid {
    padding: 0 50px;
    margin-bottom: 30px;

    @include media($sm) {
      padding: 0;
    }

    &__title {
      margin-bottom: 30px;
    }

    ul {
      column-count: 2;
      column-gap: 25px;

      @include media($xl) {
        column-count: 1;
      }
    }

    li {
      margin-bottom: 15px;
    }

    a {
      text-decoration: underline;
      font-size: em(19);
      position: relative;
      padding-left: 21px;
      display: inline-block;

      &:hover {
        text-decoration: none;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 10px;
        height: 10px;
        border-bottom: 3px solid;
        border-right: 3px solid;
        transform: translateY(-50%) rotate(135deg);
      }
    }
  }

  &-sapid-other {
    margin-top: 50px;
    margin-bottom: 10px;
    padding: 0 20px;

    @include media($sm) {
      padding: 0;
    }

    ul {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      li {
        max-width: 45%;

        &:nth-child(odd) {
          a {
            padding-left: 23px;

            &:before {
              left: 0;
              transform: rotate(135deg);
            }
          }
        }

        &:nth-child(even) {
          a {
            padding-right: 23px;
            text-align: right;

            &:before {
              right: 0;
              transform: rotate(-45deg);
            }
          }
        }
      }

      a {
        color: #0A4798;
        text-decoration: underline;
        font-size: 18px;
        font-weight: bold;
        position: relative;
        display: block;

        @include media($sm) {
          font-size: 14px;
        }

        &:hover {
          text-decoration: none;
        }

        &:before {
          content: '';
          position: absolute;
          top: 7px;
          width: 11px;
          height: 11px;
          border-bottom: 5px solid #0A4798;
          border-right: 5px solid #0A4798;
        }
      }
    }
  }
}