.jq-selectbox {
  background: transparent;
  width: 100%;

  &.focused,
  &:active {
    box-shadow: none;

    .jq-selectbox__select {
      background: transparent;
      box-shadow: none;
      border: 1px solid #D9F0F7;
    }
  }
}

.jq-selectbox__select {
  background: transparent;
  border: 1px solid #D9F0F7;
  border-radius: 3px;
  padding: 0  43px 0 10px;
  box-shadow: none;
  height: 36px;

  &:hover {
    background: transparent;
  }
}

.jq-selectbox__trigger {
  background: #D9F0F7;
  width: 36px;
  border: none;
}

.jq-selectbox__trigger-arrow {
  top: 12px;
  height: 8px;
  width: 8px;
  border: none;
  border-bottom: 2px solid $colorTextMain;
  border-right: 2px solid $colorTextMain;
  transform: rotate(45deg);
}

.jq-selectbox__dropdown {}

.jq-selectbox__select-text {
  height: 100%;
  font-size: 15px;
  text-shadow: none;

  &.placeholder {
    color: #828282;
  }
}

.jq-selectbox li {
  &:hover {
    background: darken(#D9F0F7,10%);
  }
}

.slider--alt {
  .slick-arrow {

    &:after {
      border-color: #CC1B28;
    }

    &.slick-prev {
      left: 23px;
    }

    &.slick-next {
      right: 23px;
    }
  }
}

.slick-arrow {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #F2F2F2;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  border: none;
  font-size: 0;
  z-index: 1;
  cursor: pointer;


  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-bottom: 4px solid #CC1B28;
    border-right: 4px solid #CC1B28;
    transform-origin: center;
  }

  &:hover {}

  &.slick-prev {
    left: 10px;

    &:after {
      margin-left: 1px;
      transform: translate(-50%,-50%) rotate(135deg);
    }
  }

  &.slick-next {
    right: 10px;

    &:after {
      margin-left: -1px;
      transform: translate(-50%,-50%) rotate(-45deg);
    }
  }

  &.slick-disabled {
    cursor: auto;

    &:after {
      border-color: lighten(#CC1B28,40%);
    }
  }
}

.slick-dots {
  position: absolute;
  bottom: 7px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 1;
  padding: 0 10px;

  li {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &.slick-active {

      button {
        background: $colorAccent;
      }
    }
  }

  button {
    border: none;
    border-radius: 50%;
    background: #fff;
    font-size: 0;
    padding: 0;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
}




.noUi-horizontal {
  height: 3px;
  border: none;
  background: #f3f3f3;
  margin-bottom: 25px;
}
.noUi-connect {
  background: #D9F0F7;
}
.noUi-pips-horizontal {
  display: none;
}
.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  box-shadow: none;
  background: #19124A;
  border-radius: 3px;
  border: none;
  top: -9px;
  cursor: pointer;

  &:after {
    display: none;
  }

  &:before {
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    background: none;
    top: 50%;
    left: 50%;
  }

  &.noUi-handle-lower {
    &:before {
      margin-left: -1px;
      transform: translate(-50%,-50%) rotate(-45deg);
    }
  }

  &.noUi-handle-upper {
    &:before {
      margin-left: 2px;
      transform: translate(-50%,-50%) rotate(135deg);
    }
  }
}
html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -10px;
}


ul.flexMenu-popup {
  z-index: 20;
  background: #fff;
  padding: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  top: 100%;
  margin-top: 5px;
  right: 0;

  li {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    white-space: nowrap;
    border-bottom: 2px solid transparent;

    &:hover,
    &.active {
      border-color: #CC1B28;
    }
  }
}


/******************* SCROLLBAR *******************/
.scroll-element.scroll-y.scroll-scrolly_visible {
  background: #f3f3f3;
  cursor: pointer;
  position: absolute;
  height: 100%;
  // max-height: 186px;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  border-radius: 4px;
}

.scroll-element.scroll-y.scroll-scrolly_visible {
  right: 0;
}

.scroll-element.scroll_y.scroll-scrolly_visible div
{
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
}

.scroll-element.scroll-y.scroll-scrolly_visible .scroll-bar {
    background: #C2E9F5;
    z-index: 12;
    height: 46px;
    border-radius: 4px !important;
    cursor: pointer;
}


.scroll-element.scroll-x.scroll-scrollx_visible {
  background: #f3f3f3;
  cursor: pointer;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 12px;
  border-radius: 4px;
}

.scroll-element.scroll-x.scroll-scrollx_visible {
  bottom: 4px;
}

.scroll-element.scroll_x.scroll-scrollx_visible div
{
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
}

.scroll-element.scroll-x.scroll-scrollx_visible .scroll-bar {
  background: #C2E9F5;
  z-index: 12;
  border-radius: 4px !important;
  cursor: pointer;
}




.fancybox-close-small svg{
  color: #CC1B28;
}

ul.flexMenu-popup {
  padding: 0;

  li {
    margin: 0 0 3px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: #F0FAFD;

      a {
        color: #0A4798;
      }
    }

    a {
      display: inline-block;
      padding: 5px 10px;
    }
  }
}