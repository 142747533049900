.card-comp {
  @include col(1,3);
  padding: 0 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 30px;
  font-size: em(15);

  @include media($sm) {
    @include col(1,2);
  }

  @include media($xl) {
    @include col(1,1);
  }

  &:hover {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  }

  &__title {
    position: absolute;
    bottom: 12px;
    right: 0;
    background: #19124A;
    padding: 7px 30px;
    text-align: center;
    font-size: em(15);
    font-weight: bold;
    min-width: 130px;
    color: #fff;

    &--full {
      left: 0;
    }
  }

  &.card--full {
    @include col(1,1);

    .card-comp__content {
      display: flex;

      @include media($xl) {
        display: block;
      }
    }

    .card-comp__img {
      width: 400px;
      min-width: 400px;
      margin-right: 13px;
      margin-bottom: 0;

      @include media($lg) {
        width: 250px;
        min-width: 250px;
      }

      @include media($xl) {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
      }

      &:before {
        padding-top: 71%;
      }
    }

    .card-comp__name {
      margin-bottom: 20px;
    }
    .card-comp__text {
      margin-bottom: 20px;
    }
    .card-comp__action {
      justify-content: flex-end;

      .btn {
        width: auto;
        display: inline-flex;
        padding: 0 30px;
      }
    }
  }

  &.card--big {
    @include col(1,2);

    @include media($xl) {
      @include col(1,1);
    }

    .card-comp__name {
      font-size: 18px;
      min-height: 47px;
    }
  }

  &__content {
    height: 100%;
    position: relative;
  }

  &__info {}

  &__date {
    position: absolute;
    top: 11px;
    right: 6px;
    background: #19124A;
    border-radius: 18px;
    font-size: em(14);
    font-style: italic;
    padding: 0 15px;
    height: 27px;
    display: flex;
    align-items: center;
    z-index: 1;
    color: #fff;
  }

  &__img {
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 6px;

    &:before {
      content: '';
      display: block;
      padding-top: 58.5%;

      @include media($sm) {
        padding-top: 300px;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__name {
    margin-bottom: 5px;
    font-weight: bold;
    min-height: 55px;

    @include media($xl) {
      min-height: 0;
    }

    span {
      color: #0A4798;
    }

    a {

      &:hover {
        text-decoration: underline;
      }
    }

    &-corp {
      color: #CC1B28;
    }
  }

  &__text {
    font-size: 17px;
  }

  &__action {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }

  &__link {
    margin-left: auto;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__stats {
    display: flex;
    align-items: center;
    font-size: em(12);
    color: #828282;
  }

  &__stat {
    margin-right: 9px;

    .icon {
      margin-right: 7px;
      position: relative;
      bottom: -1.9px;
      font-size: em(19);
    }
  }
}