.market {
  display: flex;
  margin-bottom: 80px;

  @include media($md) {
    display: block;
    margin-bottom: 50px;
  }

  &__filter {
    width: 208px;
    min-width: 208px;
    margin-right: 26px;

    @include media($md) {
      margin-right: 0;
      margin-bottom: 30px;
      min-width: 0;
      width: 100%;
    }
  }

  &__main {
    flex: 1;
  }

  &__cards {
    @include row(-5px);
  }

  &-filter {

    &__list {
      margin-bottom: 20px;

      &-alt {
        .market-filter__item-title {
          text-align: center;
          justify-content: center;
          background-color: transparent;
        }
        .market-filter__item-content {
          border: none;
          margin-left: 0;
          padding-left: 0;
          padding-top: 10px;
        }
      }
    }

    &__title {
      margin-bottom: 4px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      background: #D9F0F7;
      padding: 7px 10px;
    }

    &__item {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        .market-filter__item-title {
          font-weight: bold;

          &:after {
            top: 17px;
            transform: rotate(-135deg);
          }
        }
        .market-filter__item-content {
          display: block;
        }
      }
      
      &-title {
        border: none;
        font-size: 15px;
        height: 36px;
        padding: 0 23px 0 11px;
        display: flex;
        align-items: center;
        background: #D9F0F7;
        width: 100%;
        position: relative;
        cursor: pointer;
        color: #19124A;

        &:hover {
          background: darken(#D9F0F7,10%);
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:after {
          content: '';
          position: absolute;
          top: 14px;
          right: 9px;
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-right: 2px solid;
          transform: rotate(45deg);
        }
      }

      &-content {
        display: none;
        margin: 4px 0 19px 15px;
        border: 1px solid #D9F0F7;
        padding: 7px;

        li {
          margin-bottom: 3px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          font-size: 14px;
          line-height: 18px;

          &.active {
            font-weight: bold;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &-range {
        padding-right: 15px;

        .range-slider {
          margin-left: 10px;
          margin-right: 10px;
        }

        &-val {
          display: flex;
          align-items: center;

          input {
            border: 1px solid #D9F0F7;
            border-radius: 3px;
            height: 25px;
            padding: 0 7px;
            display: inline-flex;
            align-items: center;
            width: auto;
            min-width: 0;
            font-size: 14px;

            &:first-child {
              text-align: right;
            }
          }

          span {
            margin: 0 5px;
          }
        }
      }

      &-btn {
        width: 25px;
        max-width: 25px;
        min-width: 25px;
        height: 25px;
        font-size: 15px;
        margin-left: 10px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}