.calendar-slider {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;

  .slick-track {
    // display: flex !important;
    // align-items: center;
  }

  .slick-arrow {
    background-color: transparent;

    &:after {
      border-color: #19124A;
    }
  }

  .slick-prev {
    left: -20px;
  }

  .slick-next {
    right: -20px;
  }

  &__item {
    padding: 7px 8px 0;
    width: 64px;

    &.slick-center {
      padding: 0 0 0;

      .calendar-slider__item-area {
        // width: 64px;
        height: 73px;
      }
      .calendar-slider__item-text {
        font-size: 44px;

        span {
          font-size: 18px;
        }
      }

      & + .slick-slide {
        padding: 4px 4px 0;

        .calendar-slider__item-area {
          height: 64px;
        }
        .calendar-slider__item-text {
          font-size: 36px;

          span {
            font-size: 15px;
          }
        }
      }
    }

    &.today {
      .calendar-slider__item-text {
        color: #CC1B28;

        span {
          color: #19124A;
        }
      }
    }

    &-area {
      background: #F0FAFD;
      color: #19124A;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 48px;
      height: 57px;
    }

    &-text {
      font-weight: bold;
      font-size: 27px;
      line-height: 1;

      span {
        font-size: 12px;
        display: block;
      }
    }
  }
}