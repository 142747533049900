.calendar-box {
  padding: 13px 20px 28px;
  position: relative;

  &__top {
    display: flex;
    padding-left: 38px;
    margin-bottom: 24px;

    @include media($lg) {
      margin-bottom: 15px;
    }
  }

  &__title {
    margin-top: -21px;
    padding: 0 37px 0 41px;
    background: #19124A;
    color: #fff;
    height: 62px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    margin-right: auto;

    @include media($lg) {
      height: 55px;
      font-size: 16px;
      line-height: 19px;
      padding: 0 30px 0 30px;
    }

    @supports (clip-path: polygon(0 0, 100% 100%)) {
      clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%);
    }
  }

  &__pic {
    margin-right: 16px;
    width: 44px;

    @include media($lg) {
      margin-left: 8px;
      margin-right: 0;
      width: 35px;
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      max-width: 100%;
    }
  }

  &__num {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 53px;
    line-height: 62px;
    font-weight: bold;
    color: #fff;
    display: flex;
    z-index: 1;

    @include media($lg) {
      font-size: 48px;
      line-height: 55px;
    }

    span {
      display: block;
      width: 40px;
      height: 62px;
      text-align: center;
      background: #CC1B28;
      margin-right: 3px;

      @include media($lg) {
        height: 55px;
        width: 36px;
      }
    }
  }

  &__date {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    font-size: 24px;

    &-month {
      font-weight: bold;
      margin-right: 30px;
    }

    &-day {
      font-weight: 400;
    }
  }

  &__info {
    margin-bottom: 16px;

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      .calendar-box__date-day {
        display: inline-block;
        font-weight: bold;
        margin-right: 20px;
      }
    }

    &-line {
      display: flex;
      justify-content: space-between;
    }
  }

  &__text {
    margin-bottom: 3px;
    line-height: 22px;

    p {
      margin-bottom: 0;
    }

    &-name {
      float: left;
      display: inline-block;
      margin-right: 3px;
      color: #0A4798;
      font-weight: bold;
    }
  }

  &__more {
    text-align: right;

    a {
      font-size: 15px;
      text-decoration: underline;
      font-weight: bold;

      &:hover {
        text-decoration: none;
      }
    }

    .btn {
      text-decoration: none;
      display: inline-flex;
      width: auto;
      padding: 0 10px;
      font-weight: 400;
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    li {
      padding-right: 10px;
      margin-right: 10px;
      border-right: 1px solid #0A4798;
      line-height: 1;

      &:last-child {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
      }
    }

    a {
      font-size: 15px;
      color: #0A4798;
      font-weight: bold;
    }
  }
}

.calendar-block {
  margin-bottom: 30px;

  &__action {
    display: flex;
    justify-content: center;
    margin-top: 5px;

    .btn {
      max-width: 180px;
    }
  }

  &__title {
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
  }

  &__btn {
    height: 40px;margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: calc(100% - 40px);
  }

  &__content {
    display: flex;
    align-items: flex-start;

    @include media($xl) {
      display: block;
    }
  }

  &__item {
    margin-bottom: 15px;
    font-size: 18px;

    &:last-child {
      margin-bottom: 0;
    }

    &-title {
      display: inline;
      color: #0A4798;
      font-weight: bold;
    }
    &-text {
      display: inline;
    }
  }

  &__card {
    min-width: 261px;
    width: 261px;
    margin-right: 20px;

    @include media($xl) {
      min-width: 0;
      margin-right: 0;
      width: 100%;
      margin-bottom: 20px;
    }

    .calendar-card {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 15px;

      &.today {
        &:before {
          display: none;
        }
      }
    }
  }

  &--half {
    .calendar-block__content {
      max-height: 225px;
      overflow: hidden;
      position: relative;
      padding-top: 25px;
      margin-top: -25px;

      @include media($xl) {
        max-height: 450px;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(179.48deg, rgba(255, 255, 255, 0) 0, #FFFFFF 80%);
        height: 50px;
      }
      
      .calendar-card {

        &.today {
          &:before {
            display: block;
          }
        }
      }
    }
  }
}