.table-box {
  margin-bottom: 15px;

  @include media($sm) {
    min-width: 768px;
  }

  &__wrap {
    overflow-x: auto;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 35px;
    }
  }

  &__title {
    max-width: calc(100% - 195px);

    @include media($sm) {
      max-width: 100%;
    }
  }

  &--sm {
    max-width: calc(100% - 195px);

    @include media($sm) {
      max-width: 100%;
    }

    .table-box__box {
      &:nth-child(2) {
        width: 151px;
        min-width: 151px;
      }
    }

    .table-box__head {
      .table-box__box {
        &:first-child {
          font-size: 17px;
        }
      }
    }

    .table-box__body {
      .table-box__line {

        &:nth-child(odd) {

          .table-box__box {
            background-color: transparent;
          }
        }

        &:nth-child(even) {
          .table-box__box {
            background: #F2F2F2;
          }
        }
      }
    }
  }

  &__info {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-style: italic;
    margin-top: 10px;

    &-alt {
      font-size: 14px;
      margin-top: 10px;
      max-width: 500px;
    }
  }

  &__head {
    .table-box__box {
      background: #D9F0F7;
      color: #19124A;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 50px;
      font-size: 15px;
      padding: 0 10px;

      &:first-child {
        font-size: 21px;
      }
    }
  }

  &__body {
    .table-box__line {

      &:nth-child(odd) {

        .table-box__box {
          background: #F2F2F2;
        }
      }

      &:nth-child(even) {}
    }

    .table-box__box {
      padding: 15px 27px;
      justify-content: center;

      &:first-child {
        display: block;
      }
    }
  }

  &__line {
    display: flex;
  }

  &__box {
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-size: 15px;

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(1) {
      flex: 1;
    }

    &:nth-child(2) {
      width: 151px;
      min-width: 151px;
    }

    &:last-child {
      width: 185px;
      min-width: 185px;
    }
  }
}