.card-alt {
  margin-top: -10px;
  padding: 10px;

  &:hover {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  }

  .card__info {
    position: static;
    color: #19124A;
    padding: 8px 0 15px;
  }

  .card__img {
    &:before {
      padding-top: 58.5%;

      @include media($sm) {
        padding-top: 300px;
      }
    }
  }

  .card__categories {
    padding-top: 8px;

    li {
      border-color: #19124A;
      color: inherit;

      &:hover {
        color: #fff;
        background: #19124A;
      }
    }
  }

  .card__text {
    margin-top: 8px;
    display: block;
  }

  .card__btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
    margin-left: 0;
    z-index: 3;
  }
}