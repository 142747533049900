.rezult-card {
  @include col(1,3);
  padding: 0 10px;
  margin-bottom: 35px;

  @include media($sm) {
    @include col(1,2);
  }

  @include media($min) {
    @include col(1,1);
  }

  &__content {
    height: 100%;
    border: 1px solid #C2E9F5;
    padding: 10px 10px 14px;

    &:hover {
      background: #fff;
      border-color: transparent;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    }

    @supports (flex-direction: column) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &.view {
    .card-sim__action,
    .rezult-card__img {
      display: none;
    }
    .rezult-card__list {
      display: block;
    }
    .rezult-card__bottom {
      .btn {
        display: none;
      }
      .rezult-card__btn-full {
        display: flex;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 190px;
        padding: 0 10px;
      }
    }
  }

  &__img {
    position: relative;
    overflow: hidden;
    margin-bottom: 14px;
    display: block;

    &:before {
      content: '';
      display: block;
      padding-top: 62.5%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      ofject-fit: cover;
      ofjct-position: center;
    }
  }

  .card-sim__action {
    .icon {
      font-size: 14px;
    }
  }

  &__title {
    font-size: em(15);
    font-weight: bold;
    min-height: 36px;
    margin-bottom: 5px;

    span {
      color: #0A4798;
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__firm {
    text-align: center;
    font-size: em(14);
    margin-bottom: 20px;
  }

  &__tester {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;

    &-title {
      font-size: em(11);
      margin-right: 7px;
      margin-bottom: 3px;
      margin-top: 3px;
    }

    &-user {
      display: flex;
      align-items: center;

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-avatar {
      margin-right: 6px;
      width: 25px;
      height: 26px;
      min-width: 26px;
      border-radius: 50%;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-name {
      font-weight: bold;
      font-size: em(14);
    }
  }

  &__list {
    display: none;
    margin-bottom: 15px;
  }

  &__item {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .stars-view {
      margin-right: 10px;

      .icon {
        font-size: 14px;
      }
    }

    &-name {
      font-size: em(15);
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-left: auto;
      width: auto;
      padding: 0 22px;
    }
  }

  &__btn {
    &-full {
      display: none;
    }
  }
}