.services {

  &-box {
    background: linear-gradient(100.95deg, #8CAEB8 11.04%, rgba(240, 250, 253, 0.14) 98.93%);
    padding: 25px 0 35px 30px;
    display: flex;
    align-items: flex-start;

    @include media($md) {
      flex-wrap: wrap;
    }

    @include media($sm) {
      display: block;
      text-align: center;
      padding: 20px;
    }

    &__nav {
      width: 33.33333%;
      padding-right: 30px;

      @include media($md) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 30px;
      }

      ul {
        column-count: 2;
        margin-bottom: 30px;

        @include media($min) {
          column-count: 1;
        }

        li {
          margin-bottom: 6px;
          padding-right: 10px;

          @include media($min) {
            text-align: center;
          }
        }

        a {
          font-weight: bold;
          color: #19124A;
        }
      }
    }

    &__item {
      width: 33.33333%;
      padding-right: 30px;
      display: flex;

      @include media($md) {
        width: 50%;
      }

      @include media($sm) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include media($min) {
        display: block;
      }

      &-img {
        width: 150px;
        min-width: 150px;
        margin-right: 20px;

        @include media($min) {
          min-width: 0;
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
          display: flex;
          justify-content: center;
        }

        img {
          display: block;
          width: 100%;

          @include media($min) {
            max-width: 100%;
            width: auto;
          }
        }
      }

      &-info {
        padding-top: 6px;
        text-align: center;

        @include media($sm) {
          width: 100%;
          padding: 0 15px;
        }
      }

      &-name {
        color: #0A4798;
        margin-bottom: 7px;
        font-weight: bold;
      }

      &-theme {
        font-weight: bold;
        color: #19124A;
        margin-bottom: 9px;
      }

      &-text {
        margin-bottom: 23px;
      }

      &-btn {
        max-width: 134px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__btn {
      height: 48px;
      width: auto;
      display: inline-flex;
      max-width: 100%;
      padding: 0 23px 0 55px;
      justify-content: flex-start;
      background-image: url('../img/dzen.svg');
      background-repeat: no-repeat;
      background-position: 7px center;
    }
  }
}