.card-sim {
  @include col(1,3);
  padding: 0 10px;
  margin-bottom: 30px;

  @include media($sm) {
    @include col(1,2);
  }

  @include media($xl) {
    @include col(1,1);
  }

  &__content {
    padding: 10px 10px 20px;
    background: #F2F2F2;
    height: 100%;
    position: relative;

    &:hover {
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    }

    @supports (flex-direction: column) {
      display: flex;
      flex-direction: column;
    }
  }

  &__status {
    position: absolute;
    top: 21px;
    left: 6px;
    font-size: em(14);
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #fff;
    background: #19124A;
    z-index: 2;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      border-top: 3px solid #06446B;
      border-left: 5px solid transparent;
    }
  }

  &__img {
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 12px;

    &:before {
      content: '';
      display: block;
      padding-top: 75%;
    }

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__action {
    margin-bottom: 13px;
    display: flex;
    align-items: center;

    .stars-view {
      margin-right: auto;
    }
  }

  &__favorite {
    font-size: em(11);
    display: flex;
    align-items: center;

    &-text {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .icon {
      font-size: em(13);
      margin-right: 8px;
      position: relative;
      bottom: -1px;
    }
  }

  &__title {
    margin-bottom: 11px;
    font-size: em(15);
    font-weight: bold;

    &-text {
      color: #0A4798;
      margin-right: 4px;
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__text {
    font-style: italic;
    font-size: em(12);
    margin-bottom: 21px;
  }

  &__bottom {
    margin-top: auto;
    text-align: right;
  }
  &__view {
    font-size: em(15);
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}