.card-download {
  @include col(1,3);
  padding: 0 10px;
  margin-bottom: 30px;

  @include media($sm) {
    @include col(1,2);
  }

  @include media($min) {
    @include col(1,1);
  }

  &__content {
    padding: 22px 35px;
    background: #F0FAFD;
    height: 100%;
    text-align: center;

    @supports (flex-direction: column) {
      display: flex;
      flex-direction: column;
    }

    &:hover {
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    }
  }

  &__img {
    height: 210px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }

  &__name {
    font-weight: bold;
    margin-bottom: 7px;
  }

  &__title {
    margin-bottom: 9px;
    color: #0A4798;
  }

  &__text {
    font-size: em(15);
    margin-bottom: 28px;
  }

  &__btn {
    width: auto;
    padding: 0 30px;
    display: inline-flex;
  }

  &__action {

    @supports (flex-direction: column) {
      margin-top: auto;
    }
  }
}