.calendar-tabs {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-bottom: 40px;

  &__item {
    width: 100%;
    max-width: 192px;
    text-align: center;
    cursor: pointer;
    opacity: .6;

    @include media($min) {
      max-width: 120px;
    }

    &.active {
      opacity: 1;
    }

    &-img {
      display: flex;
      margin-bottom: 35px;
      justify-content: center;

      @include media($min) {
        height: 120px;
        align-items: center;
      }

      img {
        display: block;
        max-width: 100%;

        @include media($min) {
          max-height: 100%;
        }
      }
    }

    &-title {
      font-size: 18px;
      font-weight: bold;
      color: #19124A;

      @include media($min) {
        font-size: 14px;
      }
    }
  }
}