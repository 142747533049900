.tests {

  &-wrap {

    .tests-rezult,
    .tests {
      display: none;
    }

    &.testing {
      .tests {
        display: block;
      }

      .tests-rezult,
      .tests-box--start {
        display: none;
      }
    }

    &.done {
      .tests-rezult {
        display: block;
      }

      .tests-box--start,
      .tests {
        display: none;
      }
    }
  }

  &-rezult {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    max-width: 735px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 0 40px;
    position: relative;
    background: #fff;

    @include media($xl) {
      padding-top: 80px;
    }

    &__cont {
      padding: 0 90px;
      margin-bottom: 40px;

      @include media($sm) {
        padding: 0 20px;
      }
    }

    &__num {
      font-weight: bold;
      font-size: 36px;
      color: #0A4798;
      margin-bottom: 2px;
      text-align: center;
    }

    &__title {
      margin-bottom: 10px;
      font-size: 21px;
      font-weight: bold;
      color: #0A4798;
      text-align: center;
    }

    &__text {
      text-align: center;
      font-size: 21px;
      color: #19124A;
      margin-bottom: 30px;
    }

    &__btn {
      display: flex;
      justify-content: center;

      a {
        font-size: 17px;
        color: #0A4798;
        display: flex;
        align-items: center;
        margin-bottom: 35px;

        &:hover {
          color: darken(#0A4798,10%);
        }

        .icon {
          font-size: 18px;
          margin-right: 13px;
        }
      }
    }

    &__action {
      border-top: 2px solid #C2E9F5;
      padding-top: 30px;
      display: flex;
      justify-content: center;

      .btn {
        width: auto;
        padding: 0 30px;
      }
    }

    &__social {
      position: absolute;
      top: 20px;
      right: 10px;
      text-align: center;

      &-title {
        font-size: 14px;
      }

      a {
        width: 23px;
        height: 23px;
      }
    }

    &__bottom {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0 50px;

      @include media($xl) {
        display: block;
      }

      &-box {
        max-width: 270px;

        @include media($sm) {
          max-width: 205px;
        }

        @include media($xl) {
          max-width: 100%;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &:hover {

          .tests-rezult__bottom-box-btn {
            color: #0A4798;

            &:after {
              border-color: #0A4798;
            }
          }
        }

        &-title {
          font-size: 14px;
          margin-bottom: 14px;
          color: #828282;
          display: block;
        }

        &-btn {
          font-size: 18px;
          font-weight: bold;
          display: block;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            border-bottom: 4px solid #19124A;
            border-right: 4px solid #19124A;
            width: 10px;
            height: 10px;
          }
        }

        &--prev {
          .tests-rezult__bottom-box-btn {
            &:after {
              transform: translateY(-50%) rotate(135deg);
              right: 100%;
              margin-right: 15px;
            }
          }
        }

        &--next {
          margin-left: auto;

          .tests-rezult__bottom-box-btn {
            &:after {
              transform: translateY(-50%) rotate(-45deg);
              left: 100%;
              margin-left: 15px;
            }
          }

          .tests-rezult__bottom-box-title {
            text-align: right;
          }
        }
      }
    }
  }

  &-box {
    background: #F0FAFD;
    padding: 120px 30px 150px;
    margin-bottom: 50px;
    position: relative;

    @include media($sm) {
      padding: 80px 20px;
    }

    &:last-child {
      margin-bottom: 0;

      .tests-box__arrow {
        display: none;
      }
    }

    &--error,
    &--success {
      .tests-box__text,
      .tests-box__answer {
        display: none;
      }
      .tests-box__message {
        display: flex;
      }
      .tests-box__content {
        // padding-top: 60px;
      }
    }

    &__message {
      display: none;
      margin-top: 40px;
      text-align: center;
      justify-content: center;
      color: #19124A;
      font-weight: bold;
      font-size: 21px;
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;

      @include media($sm) {
        font-size: 18px;
      }
    }

    &--error {
      .tests-box__content {
        background: #EFD2D1;
      }
    }

    &--success {
      .tests-box__content {
        background: #D4F8E3;
      }
    }

    &--start {

      @include media($xl) {
        padding: 20px;
      }

      .tests-box__content {
        max-width: 500px;
        padding: 25px 65px 50px;

        @include media($xl) {
          padding: 20px;
        }
      }
      .tests-box__text {
        margin-bottom: 50px;
      }
    }

    &__num {
      position: absolute;
      font-size: 72px;
      top: 27px;
      left: 14px;
      color: #0A4798;
      font-weight: bold;
      line-height: 1;

      @include media($sm) {
        font-size: 50px;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__arrow {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 50px;
      cursor: pointer;
      background-color: transparent;
      border: none;
      font-size: 26px;
      color: #19124A;

      @include media($sm) {
        bottom: 20px;
      }

      &:hover {
        color: #0A4798;
      }
    }

    &__img {
      margin-bottom: 25px;

      img {
        display: block;
        width: 100%;
      }
    }

    &__answer {

      button {
        background: #F2F2F2;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height: 40px;
        padding: 9px;
        border: none;
        width: 100%;
        font-size: 17px;
        color: #0A4798;
        cursor: pointer;

        &:hover {
          background: darken(#F2F2F2,5%);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__content {
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
      background: #fff;
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;
      padding: 45px;

      @include media($sm) {
        padding: 20px;
      }
    }

    &__title {
      font-size: 21px;
      margin-bottom: 30px;
      font-weight: bold;
      color: #19124A;
      text-align: center;
    }

    &__text {
      margin-bottom: 20px;
    }

    &__action {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;

      .btn {
        width: auto;
        padding: 0 30px;
        height: 40px;
      }
    }

    &__social {

      ul {
        justify-content: center;
      }

      a {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__all {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    a {
      position: relative;
      text-decoration: underline;
      font-weight: bold;
      font-size: 17px;
      color: #0A4798;
      display: inline-flex;
      align-items: center;

      &:hover {
        text-decoration: none;
      }

      &:after {
        margin-left: 10px;
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-bottom: 5px solid #CC1B28;
        border-right: 5px solid #CC1B28;
        transform-origin: center;
        transform: rotate(-45deg);
        position: relative;
        bottom: -1px;
      }
    }
  }
}