.header {
  margin-bottom: 50px;
  position: relative;
  z-index: 10;

  @include media($lg) {
    margin-bottom: 0;
  }

  &__mobile {
    display: none;
    justify-content: space-between;
    align-items: center;

    @include media($lg) {
      display: flex;
      padding: 20px 0;
    }

    @include media($md) {
      padding: 10px 0;
    }

    .logo {
      @include media($md) {
        max-width: 150px;
      }
    }
  }

  &__content {}

  &__logo {
    @include media($lg) {
      display: none;
    }
  }

  &__nav {

    @include media($lg) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      opacity: 0;
      visibility: hidden;
      transition: $transitionMain;
    }

    &-bg,
    &-close {
      display: none;
    }

    &-bg {
      @include media($lg) {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 101;
        background: rgba(0,0,0,.4);
        transition: $transitionMain;
      }
    }

    &-close {
      @include media($lg) {
        display: block;
        position: fixed;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        border: none;
        background: transparent;
        box-shadow: none;
        cursor: pointer;
        z-index: 110;

        &:after,
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 15px;
          height: 2px;
          background: #000;
        }

        &:after {
          transform: translate(-50%,-50%) rotate(45deg);
        }

        &:before {
          transform: translate(-50%,-50%) rotate(-45deg);
        }
      }
    }

    &-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      @include media($lg) {
        // display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 320px;
        height: 100vh;
        background: #fff;
        padding: 50px 10px;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 103;
        transform: translateX(100%);
        transition: $transitionMain;
      }
    }
  }

  &__items {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;

    @include media($lg) {
      margin-left: 0;
      display: block;
      margin-top: 30px;
      order: 3;
    }

    li {
      margin-right: 27px;

      @include media($lg) {
        margin-right: 0;
        margin-bottom: 15px;
      }

      &:last-child {
        margin-right: 0;

        @include media($lg) {
          margin-bottom: 0;
        }
      }

      &:nth-child(odd) {
        .icon {
          background: #FFD428;
          color: #19124A;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      font-size: 15px;
    }

    .icon {
      margin-right: 6px;
      width: 25px;
      height: 25px;
      background: #19124A;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 13px;
    }
  }

  &__search-box {
    max-width: 157px;

    @include media($lg) {
      max-width: 100%;
      margin-bottom: 30px;
    }
  }

  &__btn {
    margin-left: 45px;
    max-width: 95px;

    @include media($lg) {
      margin-left: 0;
    }
  }
}