.testing {

  &-other {
    background: #F0FAFD;
    padding: 15px;
    margin: 50px 0;

    &__title {
      text-align: center;
      font-weight: bold;
      margin-bottom: 20px;
      font-size: em(20);
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__item {
      width: 100%;
      max-width: calc(50% - 23px);
      flex: 0 0 calc(50% - 23px);
      margin-bottom: 10px;

      @include media($xl) {
        max-width: 100%;
        flex: 0 0 100%;
      }

      a {
        position: relative;
        display: inline-block;
        padding-left: 17px;

        &:hover {
          text-decoration: underline;
        }

        &:before {
          content: '';
          position: absolute;
          top: 5px;
          left: 0;
          width: 10px;
          height: 10px;
          border-bottom: 4px solid #19124A;
          border-right: 4px solid #19124A;
          transform: rotate(135deg);
        }

        span {
          color: #0A4798;
        }
      }
    }
  }

  &-info {
    margin-bottom: 90px;
    color: #0A4798;
  }

  &-cont {
    margin-bottom: 35px;

    &:last-child {
      margin-bottom: 0;
    }

    .section-title {
      text-align: left;
      margin-bottom: 20px;
    }

    .faq-list__action {
      margin-top: 30px;
    }
  }

  &-block {
    margin-top: 30px;

    &__title {
      text-align: center;
      margin-bottom: 30px;
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      margin-right: -40px;

      @include media($lg) {
        margin-right: -20px;
      }
    }

    &__item {
      width: 100%;
      max-width: calc(33.3333% - 40px);
      flex: 0 0 calc(33.3333% - 40px);
      margin-right: 40px;
      padding: 0 44px;
      margin-bottom: 30px;

      @include media($lg) {
        padding: 0 20px;
        max-width: calc(33.3333% - 20px);
        flex: 0 0 calc(33.3333% - 20px);
        margin-right: 20px;
      }

      @include media($xl) {
        max-width: calc(50% - 20px);
        flex: 0 0 calc(50% - 20px);
      }

      @include media($min) {
        max-width: calc(100% - 20px);
        flex: 0 0 calc(100% - 20px);
      }

      &-img {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding-left: 12px;
        margin-bottom: 35px;
        height: 60px;

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
        }
      }

      ul.testing-block__item-list {
        margin: 0;
        padding: 0;

        li {
          padding: 0;
          margin: 0 0 3px 0;

          &:last-child {
            margin-bottom: 0;
          }

          &:before {
            display: none;
          }
        }

        a {
          color: #0A4798;
          text-decoration: underline;
          font-size: 1em;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  &-box {
    margin-bottom: 30px;

    &.opened {
      .testing-box__drop {
        display: block;
      }

      .testing-box__btn {
        background-color: transparent;
        box-shadow: none;
        color: #0A4798;
        padding: 0;

        &:hover {
          text-decoration: underline;
        }

        &:after {
          border-top-color: #0A4798;
          right: 50%;
          transform: translateX(50%) rotate(180deg);
          top: auto;
          bottom: 100%;
          margin-bottom: -5px;
        }
      }
    }

    &__text {
      color: #0A4798;
    }

    &__drop {
      display: none;
    }

    &__action {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    &__btn {
      width: auto;
      padding: 0 45px 0 30px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        border-top: 12px solid #19124A;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
      }
    }
  }
}