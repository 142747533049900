.breadcrumbs {
  margin-bottom: 17px;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    padding-right: 7px;
    margin-right: 7px;
    margin-bottom: 5px;
    border-right: 1px solid;
    color: #828282;
    font-size: 14px;
    line-height: 1;

    &:last-child {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }
  }

  a {
    color: inherit;

    &:hover {
      color: $colorTextMain;
    }
  }
}