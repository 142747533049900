.section {
  padding: 20px 0 30px;

  @include media($md) {
    padding: 10px 0 20px;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .advert {
    margin-bottom: 0;
  }

  &-scroll {
    margin-bottom: 30px;
  }

  &__header {
    margin-bottom: 20px;
  }

  &-comb {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media($md) {
      display: block;
    }

    .section {
      width: 100%;
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 10px;

      @include media($md) {
        max-width: 100%;
      }

      .card--full {
        padding: 0 10px;

        @include media($md) {
          max-width: 100%;
          flex: 0 0 100%;
        }

        .card__img {
          &:before {
            padding-top: 53.5%;

            @include media($sm) {
              padding-top: 300px;
            }
          }
        }
      }

      .card-alt {
        @include col(1,2);

        @include media($sm) {
          @include col(1,1);
        }
      }
    }
  }
}