.text {
  position: relative;

  p {
    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &-names {
    column-gap: 100px;
    column-count: 2;

    @include media($sm) {
      column-gap: 50px;
    }

    @include media($min) {
      column-count: 1;
    }
  }

  &-tooltip {

    &:hover {
      .text-tooltip__content {
        opacity: 1;
        visibility: visible;
      }
    }

    &__btn {
      font-style: italic;
      color: #0A4798;
      text-decoration: underline;
    }

    &__content {
      position: absolute;
      z-index: 1;
      background: #F2F2F2;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
      padding: 15px;
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 40px;
      opacity: 0;
      visibility: hidden;
      max-width: 100%;

      @include media($sm) {
        margin-left: 10px;
        margin-right: 10px;
      }

      @include media($xl) {
        display: block;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: -20px;
        height: 20px;
        width: 100%;
      }
    }

    &__img {
      width: 150px;
      min-width: 150px;
      margin-right: 30px;
      min-height: 110px;
      overflow: hidden;
      display: block;

      @include media($xl) {
        margin-right: 0;
        margin-bottom: 15px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__info {
      display: block;
    }

    &__name {
      display: block;
      margin-bottom: 5px;

      a {
        font-size: em(18);
        font-weight: bold;

        &:hover {
          text-decoration: underline;
          color: #0A4798;
        }
      }
    }

    &__text {
      display: block;
      margin-bottom: 3px;
      font-size: em(15);
    }

    &__action {
      display: flex;
      justify-content: flex-end;
    }

    &__link {
      font-weight: bold;
      color: #0A4798;
      font-size: em(15);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--alt {
    background: #F0FAFD;
    padding: 25px 25px 30px;
    font-style: italic;
  }

  &-action {
    display: flex;
    justify-content: center;
    padding-top: 30px;

    .btn {
      width: auto;
      display: inline-flex;
      padding: 0 35px;
      height: 40px;
    }
  }

  .section {
    padding: 0;
    margin: 50px 0;
  }

  h2 {
    text-align: center;
    font-weight: bold;
    font-size: em(23);
    line-height: em(30);
    margin-bottom: 20px;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }

  ul,
  ol {
    margin: 20px 0;
    padding-left: 15px;

    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 5px;
      font-style: italic;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 6px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 3px solid #0A4798;
      }
    }
  }

  ul.list-alt {
    & > li {
      &:before {
        border-color: #CC1B28;
      }
    }
  }

  table {
    width: 100%;
    border-spacing: 4px;
    border-collapse: separate;
    margin-top: 30px;

    tr {
      border-bottom: 3px solid #fff;

      &:last-child {
        // border-bottom: none;
      }
    }

    td,
    th {
      border-right: 3px solid #fff;

      &:last-child {
        border-right: none;
      }
    }

    th {
      background: #D9F0F7;
      padding: 10px 14px;
      height: 49px;
      text-align: center;
      vertical-align: middle;
      font-size: em(15);
    }

    td {
      background: #F0FAFD;
      padding: 10px 14px;
      vertical-align: middle;
      font-style: italic;
      text-align: center;
      min-width: 130px;
      font-size: em(15);
    }

    &.classification-table {

      td:nth-child(1) {
        font-weight: bold;
      }

      td:nth-child(2) {
        text-align: left;
      }
    }
  }

  .table-col-info {
    min-width: 300px;
  }

  .table__info {
    text-align: right;
    margin-bottom: 30px;

    span {
      display: inline-block;
      background: #19124A;
      padding: 6px 20px;
      font-style: italic;
      font-size: em(15);
      color: #fff;

      @include media($md) {
        margin-top: 0;
        margin-right: 0;
      }
    }
  }

}
.classification-box {
  margin-bottom: 30px;

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @include media($xl) {
      display: block;
    }

    p:last-child,
    &:last-child {
      margin-bottom: 0;
    }

    &-img {
      width: 77px;
      min-width: 77px;
      margin-right: 23px;
      display: flex;
      justify-content: center;

      @include media($xl) {
        margin-right: 0;
        min-width: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
      }

      img {
        max-width: 55px;
        display: block;
      }
    }

    &-text {}
  }
}
.classification-row {
  margin-bottom: 30px;

  &__item {
    margin-bottom: 20px;
    font-size: em(18);
    letter-spacing: 0.02em;
    line-height: em(23);

    &:last-child {
      margin-bottom: 0;
    }

    &-name {
      display: inline;
      font-weight: bold;
      color: #0A4798;
    }

    &-text {
      display: inline;

      p {
        margin-top: 10px;

        &:first-child {
          display: inline;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

ul.classification-list {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  li {
    margin-right: 12px;
    margin-bottom: 10px;
    padding: 0;

    &:last-child {
      margin-right: 0;
    }

    &:before {
      display: none;
    }

    a {
      color: #fff;
      line-height: em(20);
      white-space: nowrap;
      font-size: em(15);
      font-weight: 400;
      position: relative;
      border-radius: 2px;
      height: 20px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      font-style: normal;
      background: #B88B58;

      &:hover {
        background: darken(#B88B58,10%);
        box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);

        &:before {
          border-left-color: darken(#B88B58,10%);
        }

        &:after {
          content: '';
          position: absolute;
          left: 100%;
          top: 3px;
          border-left: 6px solid darken(#B88B58,10%);
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: none;
          border-radius: 0;
          filter: blur(2px);
          z-index: 1;
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        border-left: 5px solid #B88B58;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: none;
        border-radius: 0;
        z-index: 3;
      }
    }
  }
}

ol.classification-list {
  counter-reset: lits-num;
  padding-left: 0;

  & > li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      counter-increment: lits-num;
      content: counter(lits-num);
      border: none;
      width: 15px;
      height: 18px;
      top: 0;
      font-weight: bold;
      font-size: em(14);
      background: #D9F0F7;
      display: flex;
      justify-content: center;
      border-radius: 3px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 15px;
      border-left: 4px solid #D9F0F7;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
    }
  }
}

.table-wrap {
  overflow-x: auto;
  max-width: 100%;

  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  &.scroll-scrollx_visible {
    padding-bottom: 17px;
  }
}
