.firm {
  display: flex;

  @include media($sm) {
    display: block;
  }

  &__stats {
    width: 130px;
    min-width: 130px;
    margin-right: 60px;

    @include media($sm) {
      width: 100%;
      min-width: 0;
      margin-right: 0;
      margin-bottom: 30px;
      position: relative;
      padding-left: 145px;
    }
  }

  &__logo {
    margin-bottom: 20px;

    @include media($sm) {
      position: absolute;
      top: 0;
      left: 0;
      width: 130px;
    }

    img {
      max-width: 100%;
      display: block;
    }
  }

  &__list {
    margin-bottom: 40px;

    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px;
      font-size: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  &__info {
    flex: 1;
    font-size: 15px;
  }

  &__text {
    margin-bottom: 15px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media($xl) {
      display: block;
    }
  }

  &__item {
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    margin-bottom: 10px;

    &--half {
      width: calc(50% - 20px);
      flex: 0 0 calc(50% - 20px);

      @include media($xl) {
        width: 100%;
      }
    }

    &-name {
      white-space: nowrap;
      margin-right: 24px;
      font-weight: bold;

      @include media($xl) {
        min-width: 60px;
      }
    }

    li {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-links {
      a {
        text-decoration: underline;
      }
    }
  }
}