.filter {

  &-select {
    margin-right: 30px;
    max-width: 340px;

    @include media($sm) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &-cat {
    display: block;
    // text-align: justify;
    margin-left: -10px;
    margin-right: -10px;

    li {
      display: inline-block;
      padding: 0 10px;
      margin-bottom: 5px;
    }

    a {
      border-bottom: 2px solid transparent;

      &.active {
        font-weight: bold;
      }

      &:hover,
      &.active {
        color: #0A4798;
        border-color: #CC1B28;
      }
    }

    &--rez {
      font-size: 16px;
      display: block;
      margin-left: 0;
      margin-right: 0;
      column-count: 8;
      column-gap: 15px;
      margin-bottom: 30px;

      @include media($lg) {
        column-count: 6;
      }

      @include media($sm) {
        column-count: 4;
      }

      @include media($min) {
        column-count: 3;
      }

      li {
        padding: 0;
        display: block;
      }
    }
  }

  &--alt {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media($lg) {
      flex-wrap: wrap;
    }

    .pagination {
      @include media($lg) {
        flex: 0 0 100%;
        width: 100%;
        margin-top: 20px;
      }

      ul {
        @include media($lg) {
          justify-content: flex-end;
        }

        @include media($sm) {
          justify-content: flex-start;
        }
      }
    }

    .form__label {
      margin-bottom: 0;
    }
  }

  &-sort {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .sort {
      margin-left: auto;
    }

    &--alt {
      @include media($sm) {
        display: block;
      }
    }
  }

  &-info {
    margin-top: 27px;

    &__box {
      position: relative;
      padding-left: 10px;

      &:before {
        content: '*';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 18px;

    &--simple {
      font-weight: 400;
    }
  }

  &-letters {

    &-rezult {
      background: #F0FAFD;
      padding: 13px 20px 25px;
      margin-bottom: 50px;
      margin-top: -20px;
      display: none;
      max-height: 485px;
      height: 70vh;
      min-height: 350px;

      &.active {
        display: block;
      }

      &__action {
        display: flex;
        justify-content: flex-end;
        margin-top: 25px;

        button {
          cursor: pointer;
          font-size: em(14);
          max-width: 150px;
        }
      }

      &__box {
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }

        &.loaded {
          display: none;
        }

        &-title {
          margin-bottom: 9px;
          font-weight: bold;
          font-size: 15px;

          span {
            color: #0A4798;
          }
        }

        ul {
          column-count: 3;
          column-gap: 30px;

          @include media($sm) {
            column-count: 2;
            column-gap: 20px;
          }

          @include media($xl) {
            column-count: 1;
            column-gap: 0;
          }

          li {
            margin-bottom: 5px;
          }

          a {
            &:hover {
              text-decoration: underline;
              color: #0A4798;
            }
          }
        }
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 560px;
      margin-bottom: 50px;

      li {
        margin-right: 10px;
        margin-bottom: 15px;
      }

      a {
        font-weight: bold;
        font-size: 20px;
        border-radius: 2px;
        border: 2px solid #C2E9F5;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        text-transform: uppercase;
        font-family: $ffAlt;

        &.active,
        &:hover {
          color: #fff;
          background: #19124A;
          border-color: #19124A;
        }
      }
    }
  }

  &-reset {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: flex-end;
    display: none;

    &.active {
      display: flex;
    }

    &__btn {
      background-color: transparent;
      border: none;
      font-size: 15px;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
      color: #0A4798;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &-cols {
    display: flex;
    min-width: 100%;
    margin: 0 -9px;
    margin-bottom: 20px;

    @include media($lg) {
      flex-wrap: wrap;
    }
  }

  &-col {
    display: block;
    margin-bottom: 0px;
    padding: 0 9px;
    vertical-align: top;
    flex: 1;
    min-width: 100px;
    max-width: 300px;

    @include media($lg) {
      min-width: 150px;
      margin-bottom: 15px;
    }

    &__content {
      position: relative;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        height: 100%;
        background: linear-gradient(90deg,rgba(255,255,255,0),rgba(255,255,255,1));
      }
    }

    &__title {
      background: #D9F0F7;
      height: 40px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
      padding: 0 10px;
      line-height: 1;
      font-size: 15px;
      font-weight: bold;
    }

    &__line {
      display: flex;
      flex-wrap: wrap;

      .form__label-check {
        margin-right: 9px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-search {
    margin-left: auto;
    margin-right: auto;
    max-width: 515px;
    margin-top: 50px;

    .form__label {
      flex: 1;
      overflow: hidden;
    }
  }

  &-nav {

    &__list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      li {
        margin-right: 24px;
        padding-bottom: 10px;

        &.flexMenu-viewMore {
          margin-left: auto;
          margin-right: 0;
          position: relative;

          &.active {
            a {
              span {
                background-color: transparent;

                &:after {
                  transform: translateY(50%) rotate(45deg);
                  bottom: 50%;
                  margin-bottom: 0;
                }

                &:before {
                  transform: translateY(-50%) rotate(-45deg);
                  top: 50%;
                  margin-top: 0;
                }
              }
            }
          }

          li {
            padding-bottom: 0;

            a {
              padding: 8px 10px;
            }
          }

          a {
            border: none !important;
          }

          & > a {
            color: #CC1B28;
            font-weight: bold;

            span {
              width: 30px;
              height: 4px;
              background: #CC1B28;
              display: inline-block;
              position: relative;
              top: -4px;
              margin-left: 5px;

              &:after,
              &:before {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                background: #CC1B28;
                transform-origin: center;
              }

              &:after {
                bottom: 100%;
                margin-bottom: 3px;
              }

              &:before {
                top: 100%;
                margin-top: 3px;
              }
            }
          }
        }

        &:last-child {
          // margin-right: 0;
          // margin-left: auto;
        }
      }

      &--alt {
        li {
          &.flexMenu-viewMore {
            & > a {
              color: #0A4798;

              span {
                background: #0A4798;

                &:after,
                &:before {
                  background: #0A4798;
                }
              }
            }
          }
        }
      }

      a {
        border-bottom: 2px solid transparent;
        white-space: nowrap;

        &:hover,
        &.active {
          border-color: #CC1B28;
        }
      }
    }
  }
}