.logo {
  display: block;
  font-size: 38px;
  font-weight: bold;
  text-transform: uppercase;

  &:first-letter {
    font-size: 48px;
  }

  span {
    color: #CC1B28;
  }
}