.review {
  margin-top: 50px;
  margin-bottom: 50px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__content {
    background: #F0FAFD;
    padding: 20px 25px;
  }

  &__stars {
    float: left;
    margin-right: 10px;
    position: relative;
    bottom: -2px;

    .stars-view__item {
      margin-right: 6px;
    }

    .icon {
      font-size: 16px;
    }
  }

  &__text {
    letter-spacing: 0.02em;
    font-size: 18px;
    line-height: 23px;
    font-style: italic;
  }

  &__user {
    display: flex;
    justify-content: flex-end;

    &-cont {
      background: #D9F0F7;
      display: flex;
      align-items: center;
      padding: 0 10px 0 25px;
      height: 38px;
      position: relative;

      @include media($xl) {
        height: auto;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        justify-content: flex-end;
      }

      &:before {
        content: '';
        position: absolute;
        right: 100%;
        top: 0;
        border-top: 38px solid #D9F0F7;
        border-left: 38px solid transparent;

        @include media($xl) {
          display: none;
        }
      }
    }

    &-avatar {
      width: 27px;
      min-width: 27px;
      height: 27px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 9px;
      display: block;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-name {
      letter-spacing: 0.02em;
      font-size: 18px;
      line-height: 23px;
      font-style: italic;
      margin-right: 10px;
      flex: 1;

      @include media($xl) {
        flex: initial;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }

      span {
        font-style: normal;
        font-size: 15px;
        display: inline-block;
        margin-right: 13px;
      }
    }

    &-like {
      display: flex;
      align-items: center;
      margin-left: auto;

      &-val {
        font-size: 15px;
        margin-right: 3px;
        position: relative;
        bottom: -2px;
        font-weight: bold;
        position: relative;
        top: 0px;
      }
    }

    &-btn {
      border: none;
      background-color: transparent;
      font-size: 21px;
      cursor: pointer;
      padding: 0;
      margin-left: 4px;
    }
  }

  &s {
    margin-bottom: 50px;

    &__list {
      margin-bottom: 25px;
    }

    &__action {
      display: flex;
      align-items: center;

      @include media($sm) {
        display: block;
      }

      .btn {
        margin-right: 25px;
        max-width: 297px;

        @include media($sm) {
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }

    &__info {
      flex: 1;
      font-size: 15px;
      color: #0A4798;
    }
  }

  &-prof {
    margin-bottom: 30px;

    &__title {
      padding-left: 260px;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 3px;

      @include media($xl) {
        padding-left: 0;
        text-align: center;
        margin-bottom: 10px;
      }
    }

    &__main {
      background: #F0FAFD;
      display: flex;
      padding: 20px 12px;

      @include media($xl) {
        display: block;
      }
    }

    &__info {
      margin-right: 40px;
      width: 100%;
      max-width: 220px;
      background: inherit;
      margin-top: -55px;
      padding-top: 20px;
      margin-left: -12px;
      padding-left: 12px;
      position: relative;

      @include media($xl) {
        margin-left: 0;
        padding-left: 0;
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 20px;
        max-width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        border-bottom: 35px solid #F0FAFD;
        border-right: 20px solid transparent;
      }
    }

    &__avatar {
      position: relative;
      display: inline-block;
      margin-bottom: 17px;

      &-img {
        display: block;
        border-radius: 50%;
        overflow: hidden;
        width: 60px;
        height: 60px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    &__name {
      font-size: 15px;
      font-weight: bold;
      font-style: italic;
      margin-bottom: 4px;
      color: #0A4798;

      a {
        color: inherit;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &__prof {
      font-style: italic;
      font-size: 15px;
      color: #0A4798;
    }

    &__exp {
      position: absolute;
      left: 100%;
      bottom: 0;
      background: #FFD428;
      border-radius: 6px;
      padding: 3px 10px 3px 23px;
      font-size: 13px;
      margin-left: -7px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -7px;
        transform: translateY(-50%);
        width: 22px;
        height: 27px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../img/shild.svg');
      }
    }

    &__text {
      flex: 1;
      letter-spacing: 0.02em;
      font-size: 18px;
      line-height: 23px;
    }
  }
}