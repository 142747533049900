.alert {
  background-color: #F0FAFD;
  margin-bottom: 30px;
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-top: 30px;

  &--comb {
    display: flex;
    margin-right: -38px;

    @include media($sm) {
      display: block;
      margin-right: 0;
    }

    .alert {
      width: 100%;
      max-width: calc(50% - 38px);
      margin-right: 38px;
      flex: 0 0 calc(50% - 38px);

      @include media($sm) {
        max-width: 100%;
        margin-right: 0;
      }
    }
  }

  &--arrow {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 23px;
      height: 100%;
      background: #D9F0F7;

      @supports (clip-path: polygon(0 0, 100% 100%)) {
        clip-path: polygon(0 0, 10px 0, 100% 50%, 10px 100%, 0 100%);
      }
    }
  }

  &--full {
    width: 100%;
    margin-left: 0;
  }

  &--ok {
    background-color: #F4F9F1;
  }

  &--bad {
    background-color: #FBF3F3;
  }

  &__content {
    padding: 19px 19px 19px 71px;
    font-style: italic;
    background-repeat: no-repeat;
    background-position: 12px 40%;
    line-height: 22px;
  }

  &__title {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    padding-top: 10px;

    & + .alert__content {
      padding-top: 10px;
    }
  }

  ul {
    padding-left: 0;
    margin: 0;
  }
}