.wrapper {
  width: 100%;
  overflow: hidden;

  @supports (flex-direction: column) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

.content {
  flex: 1;
  padding-bottom: 80px;

  @include media($md) {
    padding-bottom: 30px;
  }
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  // padding: 0 $marginGridMain;

  &-sm {
    max-width: 814px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media($big) {
    max-width: 1170px;
  }

  @include media($lg) {
    max-width: 1023px;
    padding: 0 15px;
  }

  @include media($md) {
    max-width: 768px;
  }

  @include media($sm) {
    max-width: 100%;
  }
}