.nav {
  flex: 0 0 100%;
  border-top: 1px solid #D9F0F7;
  padding-top: 19px;
  margin-top: 10px;

  &__list {
    display: flex;
    justify-content: space-between;

    @include media($lg) {
      display: block;
    }
  }

  &__item {
    position: relative;

    @include media($lg) {
      margin-bottom: 10px;

      &.opened {
        & > a {
          color: #0A4798;
          &:after {
            border-color: #0A4798;
          }
        }
      }
    }

    &:hover {
      & > a {
        border-bottom: 2px solid red;

        @include media($lg) {
          border: none;
        }
      }
    }

    &-drop {

      @include media($lg) {
        & > a {
          display: flex;
          align-items: center;

          &:after {
            content: '';
            display: block;
            border-bottom: 2px solid $colorTextMain;
            border-right: 2px solid $colorTextMain;
            transform: rotate(45deg);
            width: 10px;
            height: 10px;
            margin-left: 20px;
          }
        }
      }

      &.opened {
        @include media($lg) {
          .nav-drop {
            display: block;
          }
        }
      }

      &:hover {
        .nav-drop {
          opacity: 1;
          visibility: visible;
        }

        &:after {
          opacity: 1;
          visibility: visible;
        }
      }

      &:after {
        opacity: 0;
        visibility: hidden;
        content: '';
        position: absolute;
        top: 100%;
        margin-top: 5px;
        left: 40%;
        border-bottom: 13px solid #D9F0F7;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;

        @include media($lg) {
          display: none;
        }
      }
    }

    &-all {
      .nav-drop {
        left: auto;
        right: 0;
      }

      & > a:after {
        @include media($lg) {
          border-color: red;
        }
      }
    }

    &.active {

      & > a {
        border-bottom: 2px solid red;
      }
    }

    &:last-child {}
  }

  &__link {
    font-size: 21px;

    &--alt {
      color: #CC1B28;
    }
  }

  &-drop {
    position: absolute;
    background: #D9F0F7;
    border-radius: 10px;
    display: flex;
    padding: 15px;
    left: 0;
    top: 100%;
    margin-top: 15px;
    opacity: 0;
    visibility: hidden;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);

    @include media($lg) {
      position: static;
      display: block;
      padding: 0;
      background-color: transparent;
      visibility: visible;
      opacity: 1;
      display: none;
      margin-bottom: 20px;
      box-shadow: none;
      padding-left: 15px;
    }

    &:before {
      position: absolute;
      content: '';
      height: 15px;
      left: 0;
      bottom: 100%;
      width: 100%;

      @include media($lg) {
        display: none;
      }
    }

    &__more {
      text-align: right;

      @include media($lg) {
        text-align: left;
      }

      a {
        margin-top: 7px;
        font-size: 16px;
        color: #CC1B28;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__col {
      margin-right: 35px;
      min-width: 140px;

      @include media($lg) {
        margin-right: 0;
        min-width: 0;
        margin-bottom: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__box {
      margin-bottom: 25px;

      @include media($lg) {
        margin-bottom: 10px;
      }

      &.opened {
        @include media($lg) {
          .nav-drop__box-title + .nav-drop__box-content {
            display: block;
          }

          .nav-drop__box-title {
            color: #0A4798;

            &:after {
              border-color: #0A4798;
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &-link {
        margin-bottom: 20px;

        a {
          font-size: 19px;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &-btn {
        display: inline-flex;
        width: auto;
        padding: 0 22px;
        height: 40px;
      }

      &-title {
        font-size: 19px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        white-space: nowrap;

        & + .nav-drop__box-content {
          @include media($lg) {
            display: none;
          }
        }

        &:after {
          content: '';
          display: block;
          margin-left: 7px;
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-right: 2px solid;
          transform: rotate(45deg);
        }
      }

      ul {

        li {
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          font-size: 16px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}