@font-face {
	font-family: "icons";
	src: url('../fonts/icons/icons.eot');
	src: url('../fonts/icons/icons.eot?#iefix') format('eot'),
		url('../fonts/icons/icons.woff2') format('woff2'),
		url('../fonts/icons/icons.woff') format('woff'),
		url('../fonts/icons/icons.ttf') format('truetype'),
		url('../fonts/icons/icons.svg#icons') format('svg');
}

.icon:before {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-arrow-alt:before {
	content: "\E001";
}

.icon-avatar:before {
	content: "\E002";
}

.icon-basket:before {
	content: "\E003";
}

.icon-board:before {
	content: "\E004";
}

.icon-calc:before {
	content: "\E005";
}

.icon-clock:before {
	content: "\E006";
}

.icon-close:before {
	content: "\E007";
}

.icon-cloud:before {
	content: "\E008";
}

.icon-eye:before {
	content: "\E009";
}

.icon-facebook:before {
	content: "\E00A";
}

.icon-faq:before {
	content: "\E00B";
}

.icon-file:before {
	content: "\E00C";
}

.icon-flag:before {
	content: "\E00D";
}

.icon-like-full:before {
	content: "\E00E";
}

.icon-like:before {
	content: "\E00F";
}

.icon-long-arrow-down:before {
	content: "\E010";
}

.icon-medal:before {
	content: "\E011";
}

.icon-od:before {
	content: "\E012";
}

.icon-pi:before {
	content: "\E013";
}

.icon-pinterest:before {
	content: "\E014";
}

.icon-print:before {
	content: "\E015";
}

.icon-reload:before {
	content: "\E016";
}

.icon-search:before {
	content: "\E017";
}

.icon-star-full:before {
	content: "\E018";
}

.icon-star:before {
	content: "\E019";
}

.icon-telegram:before {
	content: "\E01A";
}

.icon-test:before {
	content: "\E01B";
}

.icon-trello:before {
	content: "\E01C";
}

.icon-twitter:before {
	content: "\E01D";
}

.icon-vk:before {
	content: "\E01E";
}
