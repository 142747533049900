.joke {
  margin-bottom: 44px;
  background: #fff;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  padding: 30px 30px 17px;
  letter-spacing: 0.02em;
  font-size: 18px;
  line-height: 23px;

  &:hover {
    box-shadow: 2px 2px 17px rgba(0, 0, 0, 0.25);
  }

  &s {

    &__more {
      margin-top: 50px;

      .btn {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        height: 40px;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__text {
    margin-bottom: 20px;
  }

  &__bottom {
    display: flex;
    align-items: center;

    @include media($sm) {
      display: block;
    }
  }

  &__stars {
    margin-right: auto;

    .icon {
      font-size: 16px;
    }

    .stars-view__list{
      position: relative;
      bottom: -2px;
    }
  }

  &__points {
    line-height: 23px;
    margin-left: 15px;
  }

  &__user {

    @include media($sm) {
      margin-top: 10px;
    }

    &-title {
      @include media($min) {
        display: none;
      }
    }

    .review__user-cont {
      background-color: transparent;
      padding: 0;

      &:before {
        display: none;
      }
    }

    &-title {
      margin-right: 25px;
    }
  }
}