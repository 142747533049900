.tabs-alt {
  margin-bottom: 50px;
  margin-top: 50px;

  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {

      &:hover,
      &.active {
        position: relative;

        a {
          background: #D9F0F7;
          border-bottom-color: rgba(#CC1B28,1);
          box-shadow: inset 0 -2px 0 -1px rgba(#CC1B28,1);
        }

        &:hover {
          a {
            background: rgba(#D9F0F7,.5);
            border-bottom-color: rgba(#CC1B28,.6);
          }
        }
      }

      &:first-child {
        position: relative;
        z-index: 1;
        margin-right: -9px;

        a {
          min-width: 0;
          padding: 0 12px;
          border-left: 1px solid #D9F0F7;
          border-right: 1px solid #D9F0F7;
          transform: skew(0deg);

          span {
            transform: skew(0deg);
          }
        }
      }
    }

    a {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      padding: 0 15px;
      position: relative;
      min-width: 123px;
      background: #fff;
      border: 1px solid #D9F0F7;
      transform: skew(20deg);

      span {
        transform: skew(-20deg);
      }
    }
  }
}