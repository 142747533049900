.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 37px;
  border-radius: 10px;
  width: 100%;
  font-size: 15px;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  }

  &--emp {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &--banner {
    background: linear-gradient(162.63deg, #042C45 1.77%, #D9F0F7 98.87%);
    border-radius: 6px;
    height: 49px;
    max-width: 152px;
    color: #fff;

    position: absolute;
    bottom: 40px;
    right: 214px;

    @include media($lg) {
      right: 160px;
      bottom: 30px;
      max-width: 130px;
      height: 40px;
    }

    @include media($md) {
      right: 100px;
      bottom: 20px;
    }
  }

  &--accent {
    background: $colorAccent;
    color: #19124A;

    &:hover {
      background: #F4C400;
    }
  }

  &--alt {
    color: #fff;
    background: #19124A;

    &:hover {
      background: #0F0B2D;
    }
  }

  &--light {
    background: #D9F0F7;

    &:hover {
      background: #C2E9F5;
    }
  }

  &--dzen {
    height: 65px;
    max-width: 225px;
    padding: 0 30px 0 85px;
    justify-content: flex-start;
    background-image: url('../img/dzen.svg');
    background-repeat: no-repeat;
    background-size: 49px;
    background-position: 7px center;
    font-size: 17px;
    line-height: 22px;
    margin-left: auto;
    margin-right: auto;

    &:hover {
      background-image: url('../img/dzen.svg');
      background-repeat: no-repeat;
      background-size: 49px;
      background-position: 7px center;
    }
  }

  &--green {
    background: #54B331;
    color: #fff;
  }

  &--orange {
    background: #F15922;
    color: #fff;
  }

  &--lime {
    background: #76BA1E;
    color: #fff;
  }
}