.tests-partner {
  padding-top: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  min-height: 1000px;
  position: relative;

  @include media($sm) {
    background: none !important;
  }

  &__rezult {
    display: none;
  }

  &-box {
    display: none;
    width: 100%;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    padding: 20px 45px 25px;
    position: absolute;
    top: 127px;
    left: 50%;
    transform: translateX(-50%);

    @include media($xl) {
      top: 160px;
      padding-left: 20px;
      padding-right: 20px;
    }

    &__nav {
      margin-bottom: 24px;

      ul {
        display: flex;
        justify-content: center;
      }

      li {
        margin-right: 7px;

        &:last-child {
          margin-right: 0;
        }
      }

      a {
        display: flex;
        min-width: 15px;
        text-align: center;
        justify-content: center;
        font-size: 15px;
        color: #19124A;
        border-bottom: 3px solid #C4C4C4;
        padding-bottom: 3px;

        &:hover,
        &.active {
          font-weight: bold;
          color: #0A4798;
          border-bottom-color: #0A4798;
        }

        &.error {
          color: #CC1B28;
          border-color: #CC1B28;
        }

        &.success {
          color: #219653;
          border-color: #219653;
        }
      }
    }

    &__item {
      display: none;

      &.active {
        display: block;
      }

      &-action {
        display: flex;
        justify-content: center;
        margin-top: 35px;

        .btn {
          width: auto;
          padding: 0 30px;
        }
      }

      &-text {
        font-size: 15px;
        margin-bottom: 30px;
      }

      &-img {
        margin-bottom: 25px;

        img {
          display: block;
          width: 100%;
        }
      }

      &-answer {
        background: #F2F2F2;
        padding: 9px;
        min-height: 40px;
        margin-bottom: 5px;
        position: relative;
        border-left: 5px solid transparent;
        cursor: pointer;

        &:hover {
          background: darken(#F2F2F2,5%);
        }

        &:last-child {
          margin-bottom: 0;
        }

        &-title {
          font-size: 17px;
          color: #0A4798;
          text-align: center;
        }

        &-text {
          margin-top: 7px;
          color: #19124A;
          font-size: 17px;

          span {
            color: #0A4798;
          }

          a {
            text-decoration: underline;
            color: inherit;

            &:hover{
              text-decoration: none;
            }
          }
        }

        &-percent,
        &-text {
          display: none;
        }

        &-percent {
          position: absolute;
          top: 12px;
          line-height: 1;
          right: 5px;
          color: #0A4798;
        }

        &.selected {
          .tests-partner-box__item-answer-percent,
          .tests-partner-box__item-answer-text {
            display: block;
          }
        }
      }

      &.answed {

        .tests-partner-box__item-answer {

          &:hover {
            background: #F2F2F2;
          }

          &[data-val="true"] {
            .tests-partner-box__item-answer-percent,
            .tests-partner-box__item-answer-title {
              color: #219653;
            }
            .tests-partner-box__item-answer-title {
              font-weight: bold;
            }
            .tests-partner-box__item-answer-percent {
              display: block;
            }
          }
        }
      }

      &.error {
        .tests-partner-box__item-answer[data-val="true"] {
          border-color: #219653;
        }
        .selected {
          border-color: #CC1B28;
        }
      }
      &.success {
        .tests-partner-box__item-answer-percent {
          display: block;
        }
      }
    }
  }

  &__start {
    background: #FFFFFF;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    padding: 30px 75px;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;

    @include media($xl) {
      padding: 20px;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 30px;
      border-bottom: 2px solid #C2E9F5;
      margin-bottom: 10px;

      @include media($xl) {
        justify-content: space-around;
      }
    }

    &-logo {
      border: 1px solid #E0E0E0;
      width: 150px;
      display: flex;
      height: 108px;
      align-items: center;
      justify-content: center;
      padding: 5px;

      img {
        display: block;
        max-width: 100%;
      }
    }

    &-name {
      max-width: 200px;
      text-align: center;
      font-size: 17px;

      span {
        display: block;
        margin-top: 20px;
        font-size: 15px;
        color: #828282;
      }
    }

    &-title {
      text-align: center;
      font-weight: bold;
      font-size: 21px;
      color: #19124A;
      max-width: 360px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
    }

    &-text {
      margin-bottom: 45px;
    }

    &-action {
      margin-bottom: 40px;
      display: flex;
      justify-content: center;

      .btn {
        width: auto;
        padding: 0 30px;
      }
    }

    &-social {

      ul {
        justify-content: center;
      }

      a {
        width: 23px;
        height: 23px;
      }
    }
  }

  &__content {

    &.testing {
      .tests-partner__start {
        display: none;
      }
      .tests-partner-box {
        display: block;
      }
    }

    &.done {
      .tests-partner__start,
      .tests-partner-box {
        display: none;
      }
      .tests-partner__rezult {
        display: block;
      }
    }
  }

  &__header {
    background: #fff;
    margin-bottom: 100px;

    @include media($sm) {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media($md) {
        flex-wrap: wrap;
      }

      @include media($xl) {
        justify-content: center;
      }
    }
  }

  &__logo {

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__link {
    color: #19124A;
    font-size: 17px;
    font-weight: bold;

    @include media($md) {
      margin-left: auto;
      margin-right: auto;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__nav {

    @include media($xl) {
      flex: 0 0 100%;
      width: 100%;
      margin: 10px 0;
    }

    ul {
      display: flex;
      align-items: center;

      @include media($xl) {
        justify-content: center;
      }
    }

    li {
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      font-size: 15px;
      font-weight: bold;
      color: #19124A;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}