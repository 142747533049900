.card-mark {
  @include col(1,4);
  padding: 0 5px;
  margin-bottom: 30px;

  @include media($lg) {
    @include col(1,3);
  }

  @include media($sm) {
    @include col(1,2);
  }

  @include media($min) {
    @include col(1,1);
  }

  &__content {
    height: 100%;
    padding: 12px 12px 21px;
    border: 1px solid #C2E9F5;

    &:hover {
      border-color: transparent;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    }

    @supports (flex-direction: column) {
      display: flex;
      flex-direction: column;
    }
  }

  &__img {
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 14px;

    &:before {
      content: '';
      display: block;
      padding-top: 75%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%,-50%);
    }
  }

  &__line {
    display: flex;
    align-items: center;
  }

  &__favorite {
    margin-left: auto;
  }

  &__name {
    font-weight: bold;
    font-size: em(15);
    margin-top: 14px;
    margin-bottom: 8px;

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__text {
    font-size: em(14);
    margin-bottom: 12px;

    @supports (flex-direction: column) {
      margin-top: auto;
    }
  }

  &__price {
    white-space: nowrap;
    font-size: em(17);
    font-weight: bold;
  }

  &__btn {
    padding: 0 20px;
    width: auto;
    display: inline-flex;
    margin-left: auto;
  }
}