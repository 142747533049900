// ЦВЕТА
$colorAccent: #FFD428;
// $colorAlt: ;
// $colorSecondary: ;
$hover: #0A4798;

$colorTextMain: #19124A;
// $colorTextAlt: ;
// $colorTextSecondary: ;

$colorBgMain: #ffffff;
// $colorBgAlt: ;
// $colorBgSecondary: ;

// ШРИФТЫ
$fontFamilyMain: "PT Sans";
$ffAlt: 'Roboto Slab', serif;
$ffBook: "TextBookC";
$ffMyr: "Myriad Pro";

// РАЗМЕРЫ
$fontSizeMain: 17;
$lineHeightMain: 1.29;
$fontWeightMain: 400;

$marginGridMain: 54px;

$big: 1400px; // @include media($big)
$lg: 1200px;  // @include media($lg)
// $md: 992px;   // @include media($md)
$md: 1023px;
$sm: 768px;   // @include media($sm)
$xl: 576px;   // @include media($xl)
$min: 480px;  // @include media($min)

$bdrsMain: 0px;

$transitionMain: .3s;

