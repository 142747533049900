@mixin absolute($num: false) {
  position: absolute;
  @if $num {
    top: $num;
    left: $num;
    right: $num;
    bottom: $num;
    margin: auto;
  }
  @else {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

@function rem($size) {
  @return ($size/$fontSizeMain) + rem;
}

@function em($size) {
  @return ($size/$fontSizeMain) + em;
}

@mixin size($a, $b: false) {
  width: $a;
  @if $b {
    height: $b;
  }
  @else {
    height: $a;
  }
}

@mixin row($num) {
  display: flex;
  flex-wrap: wrap;
  margin-left: $num;
  margin-right: $num;
}

@mixin row-m($num) {
  margin-left: $num;
  margin-right: $num;
}

@mixin col($a,$b:false) {
  @if $b {
    $b: (100 / $b) * $a + %;
  }
  @else {
    $b: $a;
  }
  max-width: $b;
  flex: 0 0 $b;
}

@mixin col-p($a,$b:false) {
  @if $b {
    $b: (100 / $b) * $a + %;
  }
  @else {
    $b: $a;
  }
  max-width: $b;
  flex: 0 0 $b;
  padding-left: $marginGridMain;
  padding-right: $marginGridMain;
}

@mixin media($val, $type: false) {
  @if $type {
    $type: 'min-width: ';
  }
  @else {
    $type: 'max-width: ';
  }
  @media ($type + $val) {
    @content
  }
}

@mixin transition($dur: false) {
  @if $dur {
    $dur: $dur
  }
  @else {
    $dur: $transitionMain
  }
  transition: $dur cubic-bezier(0.250, 0.100, 0.250, 1.000);
}