.banner {
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;

  &-tmp {
    position: relative;
    width: 100%;
    max-width: 525px;
    margin: 0 auto;
    padding: 40px 0 35px;
    text-align: center;

    @include media($md) {
      background: #F2F2F2;
      max-width: 100%;
      padding: 20px 35px;
    }
      @include media($sm) {
        padding-bottom: 80px;
        padding-left: 15px;
        padding-right: 15px;
      }

    & ~ .btn {
      @include media($md) {
        right: 30px;
      }
      @include media($sm) {
        position: relative;
        bottom: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: -60px;
      }
    }

    &__title {
      font-size: 20px;
      margin-bottom: 19px;
      color: #042C45;
    }

    &__name {
      font-size: 24px;
      font-weight: bold;
      color: #A5A73C;
      margin-bottom: 20px;
    }

    &__text {
      font-weight: bold;
      font-size: 16px;
      color: #042C45;

      p {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-pic {
    position: absolute;
    bottom: 37px;
    left: 170px;
    width: 92px;
    height: 90px;

    @include media($md) {
      display: none;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-stat {
    font-weight: bold;
    top: 25px;
    right: 50px;
    position: absolute;
    color: #B88B58;
    font-size: 24px;

    @include media($md) {
      display: none;
    }

    span {
      font-size: 39px;
    }
  }

  &__btn-pic {
    position: absolute;
    right: 30px;
    bottom: 15px;
    max-width: 134px;
    height: 40px;
    z-index: 1;
  }

  &-interesting {
    margin: 30px 0;
    display: flex;
    background: linear-gradient(#697A0A,#E2E25A);
    position: relative;
    width: calc(100% - 30px);

    &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      height: 100%;
      width: 30px;
      background: linear-gradient(#697A0A,#E2E25A);

      @supports (clip-path: polygon(0 0 ,100% 100%)) {
        clip-path: polygon(0 0, 100% 50%, 0 100%);
      }
    }

    &__img {
      overflow: hidden;
      width: 170px;
      min-width: 170px;
      margin-right: 20px;

      @include media($sm) {
        display: none;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__info {
      padding: 16px 30px 10px 0;

      @include media($sm) {
        padding-left: 30px;
      }

      @supports (flex-direction: column) {
        display: flex;
        flex-direction: column;
      }
    }

    &__title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 9px;
      color: #fff;
    }

    &__action {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @supports (flex-direction: column) {
        margin-top: auto;
      }
    }

    &__status {
      font-size: 19px;
      font-weight: bold;
      color: #042C45;
    }

    &__btn {
      width: auto;
      padding: 0 30px;
    }
  }

  &-sort {

    img {
      display: block;
      width: 100%;
    }
  }

  &-stats {
    padding: 19px 23px 23px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;

    &__title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 41px;
    }

    &__btn {
      height: 40px;
      width: auto;
      display: inline-flex;
      padding: 0 30px;
    }
  }

  &--form {
    background: linear-gradient(95.67deg, #344A30 1.85%, #84A289 87.07%);
    display: flex;
    color: #fff;
    margin-bottom: 30px;

    @include media($md) {
      display: block;
    }
  }

  &__cont {
    flex: 1;
    padding: 44px 66px 30px 50px;

    @include media($sm) {
      padding: 20px;
    }
  }

  &__title {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 26px;
  }

  &__text {
    display: flex;
    align-items: flex-start;

    @include media($xl) {
      display: block;
    }

    &-main  {
      flex: 1;
      font-size: 19px;
      line-height: 25px;

      @include media($xl) {
        margin-bottom: 20px;
      }
    }
  }

  &__btn {
    max-width: 134px;
  }

  &__img {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media($md) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: center;
    }
  }

  &--blog {
    display: flex;
    background: #F0FAFD;
    padding: 20px 30px 0;

    @include media($md) {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      padding: 20px 10px 0;
    }

    .cards {
      flex: 1;

      @include media($md) {
        order: 2;
      }
    }

    .card {
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 0;

      @include media($md) {
        @include col(1,1);
      }

      &:hover {
        box-shadow: none;
      }
    }

    .card__img {
      &:before {
        padding-top: 82.7%;

        @include media($md) {
          padding-top: 60%;
        }
      }
    }

    .card__box {
      justify-content: center;
      text-align: center;
      left: 8px;
      right: 8px;
    }
  }

  &-blog {
    margin-left: 43px;
    width: 225px;
    min-width: 225px;
    text-align: center;

    @include media($md) {
      width: 100%;
      min-width: 0;
      margin-left: 0;
      order: 1;
    }

    @supports (flex-direction: column) {
      flex-direction: column;
      display: flex;
      padding-bottom: 30px;
    }

    &__top {
      margin-top: -45px;
      background-image: url('../img/banner-blog__bg.svg');
      background-repeat: no-repeat;
      background-position: center top;
      height: 97px;
      position: relative;
      margin-bottom: 28px;

      &-text {
        position: absolute;
        left: 50%;
        top: 15px;
        transform: translateX(-50%);
        padding: 0 20px;
        color: #fff;
        height: 46px;
        font-weight: bold;
        display: flex;
        align-items: center;
        font-size: 20px;
        background: #19124A;
        white-space: nowrap;

        @supports (clip-path: polygon(0 100%, 100% 0, 100% 100%)) {
          clip-path: polygon(0 5px, 100% 0, 100% 100%, 0 40px);
        }
      }
    }

    &__title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__text {
      font-size: 15px;
      margin-bottom: 20px;
    }

    &__btn {
      background: #C2E9F5;
      border-radius: 10px;
      max-width: 188px;
      margin-left: auto;
      margin-right: auto;

      @supports (flex-direction: column) {
        margin-top: auto;
      }
    }
  }
}