.aside {

  &-search {
    border: 5px solid #C2E9F5;
    padding: 33px 25px 22px;
  }

  &-rating {

    &.active {
      
      .aside-rating__item {
        pointer-events: none;
        padding-top: 10px;
        padding-bottom: 25px;

        &:before {
          content: attr(data-num);
          position: absolute;
          top: 4px;
          left: 4px;
          font-size: 16px;
          font-weight: bold;
        }

        &-line {
          display: block;
          width: 100px;
          height: 12px;
          border: 1px solid #CC1B28;
          position: absolute;
          bottom: 6px;
          left: 50%;
          margin-left: -50px;

          &-val {
            display: block;
            height: 100%;
            background: #CC1B28;
          }
        }
      }

      .aside-rating__btn,
      .aside-rating__points {
        display: none;
      }

      .aside-rating__status {
        display: block;
        color: #0A4798;
      }

      .form__label-check-circle {
        display: none;
      }

      .form__label-check-name {
        display: inline-block;
      }

      .aside-rating__item-val {
        display: block;
        top: 10px;
        right: 10px;
        font-weight: bold;
        font-size: 15px;
      }
    }

    &__title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 6px;
      text-align: center;
    }

    &__name {
      margin-bottom: 6px;
      text-align: center;
    }

    &__list {
      margin-bottom: 6px;
    }

    &__item {
      background: #F0FAFD;
      display: block;
      padding: 17px 15px 17px 45px;
      margin-bottom: 6px;
      position: relative;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(even) {
        background: #F9F9F9;
      }

      &-name {
        font-size: 14px;
        line-height: 1;
      }

      &-val {
        position: absolute;
        display: none;
      }

      .form__label-check-circle {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }

      &-line {
        display: none;
      }
    }

    &__action {
      font-size: 13px;
      line-height: 1;
    }

    &__points {
      text-align: right;
    }

    &__status {
      text-align: right;
      display: none;
    }

    &__btn {
      margin-top: 8px;
      width: auto;
      padding: 0 30px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-sort {
    border: 1px solid #C2E9F5;
    padding: 20px;
    position: relative;

    &__title {
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      background: #fff;
      padding: 0 15px;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 1;
      white-space: nowrap;

      span {
        font-weight: normal;
      }
    }

    &__content {
      max-height: 284px;
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: calc(100% - 14px);
        height: 40px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 100%);
        z-index: 1;
      }
    }

    &__box {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        font-size: 18px;
        font-weight: bold;
        color: #E31E26;
        margin-bottom: 3px;
      }

      &-list {

        li {
          margin-bottom: 3px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          font-size: 14px;
          font-weight: bold;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-card {
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }

    &s {
      .card {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__img {
      width: 140px;
      min-width: 140px;
      height: 100px;
      margin-right: 9px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: .3s;
        opacity: .5;
      }
    }

    &__name {
      line-height: 19px;
      font-weight: bold;
      font-size: 15px;
    }
  }

  &-action {
    text-align: right;
    margin-top: 7px;

    &__link {
      text-decoration: underline;
      font-weight: bold;
      font-size: 15px;
      color: #0A4798;

      &:hover {
        text-decoration: none;
      }
    }
  }
}