.contacts {

  &-box {
    padding: 0 25px;

    @include media($lg) {
      padding: 0;
    }

    &__email {
      max-width: 215px;
    }

    &__form {
      border: 5px solid #D9F0F7;
      padding: 30px 30px 80px;
      border-radius: 5px;
      margin-top: -5px;

      @include media($lg) {
        padding: 15px;
      }

      .comments-form__top {
        @include media($lg) {
          flex-wrap: wrap;
        }
      }

      .comments-form__text {
        @include media($lg) {
          flex: 0 0 100%;
          width: 100%;
          margin: 10px 0;
        }
      }

      .comments-form__social {
        @include media($lg) {
          margin-left: 0;
          margin-right: 30px;
          position: relative;
          bottom: -3px;
        }
      }

      .comments-form__cabinet {
        @include media($lg) {
          margin-left: 0;
        }
      }
    }

    &__text {
      margin-bottom: 30px;
      font-size: 15px;
      font-weight: bold;
    }

    &__items {
      display: flex;
      justify-content: space-between;

      @include media($min) {
        display: block;
      }
    }

    &__item {
      width: calc(50% - 10px);
      display: flex;
      cursor: pointer;
      padding: 15px;
      border-radius: 5px 5px 0px 0px;

      @include media($sm) {
        padding: 10px;
      }

      @include media($xl) {
        display: block;
      }

      @include media($min) {
        display: flex;
        width: 100%;
      }

      &:hover {
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
      }

      &-arrow {
        position: absolute;
        bottom: 0;
        right: 0;
        color: #CC1B28;
        font-size: 20px;
      }

      &.active {
        background: #D9F0F7;

        &:hover {
          box-shadow: none;
        }
      }

      &-avatar {
        width: 90px;
        min-width: 90px;
        height: 90px;
        overflow: hidden;
        margin-right: 13px;

        @include media($sm) {
          margin-right: 10px;
          width: 50px;
          min-width: 50px;
          height: 50px;
        }

        @include media($xl) {
          margin-bottom: 10px;
          width: 80px;
          min-width: 80px;
          height: 80px;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      &-info {
        font-size: 15px;
        line-height: 19px;
        position: relative;
        max-width: 210px;
      }

      &-name {
        font-weight: bold;
        margin-bottom: 6px;
      }

      &-text {
        margin-bottom: 6px;
      }

      &-email {
        font-size: 14px;
        line-height: 1;
        font-weight: bold;
      }
    }

    &__content {
      display: none;
      position: relative;
      z-index: 2;
      background: #fff;

      &.active {
        display: block;
      }
    }
  }
}