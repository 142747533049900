.slider {

  .card {
    margin-bottom: 0;
  }

  .card__img {
    &:before {
      padding-top: 58.9%;

      @include media($sm) {
        padding-top: 400px;
      }
    }
  }

  .card__info {
    padding: 0 20px 20px;
  }
}