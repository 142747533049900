.exp {

  &-form {

    &.disabled {
      opacity: .54;
      pointer-events: none;
    }

    .form__textarea,
    .form__select,
    .form__input {
      font-size: 15px;
    }

    .form__textarea {
      min-height: 121px;
    }

    .form__label-name--line {
      small {
        color: #0A4798;
      }
    }
    .form__label-check-box {
      &:after {
        border-color: #CC1B28;
      }
    }
  }

  &__box {
    padding-top: 20px;

    &--alt {
      .exp__box-line {
        margin-bottom: 7px;
        max-width: 460px;
        margin-left: auto;
        margin-right: auto;
      }
      .exp__box-text {
        span {
          color: #CC1B28;
        }
      }
    }

    &-line {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      @include media($sm) {
        flex-wrap: wrap;
      }

      @include media($min) {
        display: block;
      }

      &:last-child {
        margin-bottom: 0;
      }

      span {
        white-space: nowrap;
        text-decoration: underline;

        @include media($sm) {
          width: 100%;
          flex: 0 0 100%;
          margin-bottom: 10px;
        }

        @include media($min) {
          display: block;
        }
      }

      .btn {
        width: auto;
        padding: 0 30px;
        text-decoration: underline;

        @include media($sm) {
          margin-right: 15px;

          @include media($min) {
            margin-right: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    &-text {
      text-decoration: underline;
    }

    &-item {
      margin-right: 23px;

      @include media($sm) {
        margin-right: 0;
      }

      @include media($min) {
        margin-bottom: 15px;
      }

      &:last-child {
        margin-right: 0;

        @include media($min) {
          margin-bottom: 0;
        }
      }
    }

    .social {

      @include media($min) {
        margin-bottom: 0;
      }

      ul {
        flex-wrap: nowrap;

        @include media($min) {
          justify-content: center;
        }
      }
    }
  }
}