.card {
  @include col(1,3);
  padding: 0 10px;
  margin-bottom: 20px;

  @include media($md) {
    @include col(1,2);
  }

  @include media($sm) {
    @include col(1,1);
  }

  &:nth-child(even) {
    .card__box {
      background: #CC1B28;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &--big {
    @include col(1,2);

    .card__img {
      &:before {
        padding-top: 53.5%;

        @include media($md) {
          padding-top: 66.5%;
        }

        @include media($sm) {
          padding-top: 300px;
        }
      }
    }

    .card__name-text {
      font-size: em(23);

      @include media($lg) {
        font-size: em(20);
      }

      @include media($md) {
        font-size: em(16);
      }
    }
  }

  &--full {
    @include col(1,1);
    padding: 0;
  }

  &s {
    @include row(-10px);

    &__slider {
      padding: 0 10px;
      width: 100%;
      max-width: 63.5%;
      flex: 0 0 63.5%;
      margin-bottom: 20px;

      @include media($md) {
        max-width: 100%;
        flex: 0 0 100%;
      }

      .card {
        @include media($md) {
          @include col(1,1);
        }

        &__name-text {
          font-size: em(23);

          @include media($lg) {
            font-size: em(20);
          }
        }

      }
    }

    &--auto {
      .card-comp {
        padding-bottom: 10px;
      }
      .card-comp__name {
        min-height: 0;
      }
    }

    &-more {
      display: flex;
      justify-content: center;

      .btn {
        max-width: 300px;
      }
    }

    &__box {
      flex: 1;
      padding: 0 10px;
      margin-bottom: 20px;

      @include media($md) {
        width: 100%;
        flex: 0 0 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
      }

      @include media($sm) {
        display: block;
      }

      .card {
        height: calc(50% - 10px);
        margin-bottom: 20px;

        @include media($md) {
          height: auto;
          max-width: calc(50% - 10px);
          flex: 0 0 calc(50% - 10px);
        }

        @include media($sm) {
          max-width: 100%;
          flex: 0 0 100%;
        }

        &:last-child {
          margin-bottom: 0;

          @include media($md) {
            margin-bottom: 20px;
          }
        }

        &__content {
          height: 100%;
        }

        &__img {
          height: 100%;

          @include media($md) {
            height: auto;
          }

          &:before {
            display: none;

            @include media($md) {
              display: block;
            }
          }
        }
      }
    }

    &--inner {
      .card {
        @include col(1,2);

        @include media($sm) {
          @include col(1,1);
        }
      }
    }

    &--alt {
      @include row(-22px);

      @include media($lg) {
        margin-left: -10px;
        margin-right: -10px;
      }
    }
  }

  &__content {

    &:hover {
      .card__img {
        &:after {
          opacity: .9;
        }
      }
    }
  }

  &__img {
    position: relative;
    display: block;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      padding-top: 66.5%;

      @include media($sm) {
        padding-top: 300px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
      opacity: .7;
      transition: .3s;
    }

    img {
      position: absolute;
      transition: .3s;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__status {
    position: absolute;
    top: 25px;
    left: 0;
    font-size: em(15);
    color: #0A4798;
    font-weight: bold;
    padding: 0 20px;
    background: rgba(#fff,.93);
    z-index: 3;
  }

  &__info {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 0 20px 15px;
    color: #fff;
    display: block;
  }

  &__categories {
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 3;

    li {
      margin-bottom: 5px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      height: 20px;
      padding: 0 10px;
      border-radius: 5px;
      border: 1px solid;
      line-height: 18px;
      padding-bottom: 2px;

      &:hover {
        background: #fff;
        color: #19124A;
        border-color: #fff;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        color: inherit;
      }
    }
  }

  &__name {
    display: flex;
    font-size: em(16);
    font-weight: bold;
    align-items: flex-end;
    position: relative;
    z-index: 3;

    &-text {
      flex: 1;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__btn {
    white-space: nowrap;
    width: auto;
    padding: 0 10px;
    margin-left: 20px;
    font-weight: 400;

    &-alt {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-left: 0;
      bottom: 100%;
      margin-bottom: 85px;
      z-index: 3;

      @include media($big) {
        margin-bottom: 55px;
      }
    }
  }

  &__box {
    position: absolute;
    left: 0;
    right: 20px;
    bottom: 13px;
    font-weight: bold;
    font-size: em(15);
    color: #fff;
    background: #19124A;
    padding: 6px;
    min-height: 50px;
    display: flex;
    align-items: center;
  }

  &-spec {
    font-family: $ffAlt;

    .card__content {
      background: linear-gradient(160.37deg, #042C45 43.07%, #D9F0F7 98.94%);
      height: 100%;
      position: relative;
      padding-bottom: 10px;

      @supports (flex-direction: column) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }

    &--green {
      .card__content {
        background: linear-gradient(156.14deg, #284504 43.07%, #E8F7D9 98.94%);
      }
    }

    &__top {
      position: absolute;
      left: 50%;
      top: -29px;
      transform: translateX(-50%);
      padding-top: 50px;

      span {
        display: inline-block;
        height: 20px;
        padding: 0 10px;
        color: #B88B58;
        font-size: em(15);
        border: 1px solid;
        border-radius: 5px;
        line-height: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 75px;
        height: 41px;
        background-image: url('../img/crown.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &__pic {
      position: absolute;
      right: 12px;
      bottom: 12px;
      width: 200px;
      height: 200px;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: right bottom;

      @include media($lg) {
        width: 70px;
        height: 70px;
        background-size: contain;
      }

      @include media($md) {
        display: none;
      }
    }

    &__title {
      color: #B88B58;
      font-size: em(30);
      text-align: center;
      padding-top: 70px;
      margin-bottom: 22px;
      position: relative;
      z-index: 1;

      @include media($lg) {
        padding-top: 50px;
        margin-bottom: 15px;
      }

      @include media($md) {
        font-size: em(24);
      }
    }

    &__text {
      font-size: em(18);
      color: #fff;
      margin-bottom: 35px;
      margin-left: auto;
      margin-right: auto;
      max-width: 350px;
      text-align: center;
      position: relative;
      z-index: 1;

      @include media($lg) {
        margin-bottom: 15px;
      }

      @include media($md) {
        font-size: em(16);
      }
    }

    &__btn {
      max-width: 212px;
      margin: 0 auto 20px;
      font-family: $fontFamilyMain;
      position: relative;
      z-index: 1;

      @include media($lg) {
        margin-bottom: 15px;
      }
    }

    &__bottom {
      color: #042C45;
      font-size: em(18);
      text-align: center;
      position: relative;
      z-index: 1;

      @include media($md) {
        font-size: em(16);
      }
    }

    &--sm {
      .card-spec__title {
        padding-top: 59px;
        font-size: em(24);

        @include media($lg) {
          padding-top: 50px;
          font-size: em(22);
          margin-bottom: 10px;
        }

        @include media($md) {
          font-size: em(24);
        }
      }
      .card-spec__text {
        font-size: em(16);
        margin-bottom: 23px;

        @include media($lg) {
          margin-bottom: 10px;
          font-size: em(14);
        }

        @include media($md) {
          font-size: em(16);
        }
      }
      .card-spec__btn {
        margin-bottom: 19px;
        height: 35px;

        @include media($lg) {
          margin-bottom: 10px;
          height: 30px;
          font-size: em(14);
          max-width: 180px;
        }
      }
      .card-spec__bottom {
        font-size: em(16);

        @include media($lg) {
          font-size: em(14);
        }

        @include media($md) {
          font-size: em(16);
        }
      }
      .card-spec__pic {
        background-size: 60px auto;
        right: 10px;
        bottom: 28px;
      }
    }
  }
}