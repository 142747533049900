.search-box {
  display: flex;
  height: 37px;
  background: #F0FAFD;
  border-radius: 20px;

  input {
    border: none;
    background-color: transparent;
    flex: 1;
    min-width: 0;
    padding: 0 0 0 16px;
    font-size: 15px;
  }

  button {
    padding: 0;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    cursor: pointer;
    font-size: 15px;
  }
}