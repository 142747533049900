.testing-banner {
  padding: 70px;
  background-size: cover;
  background-position: center;
  min-height: 325px;
  display: flex;
  align-items: center;
  color: #fff;
  text-align: center;
  margin-bottom: 25px;

  @include media($xl) {
    padding: 30px;
  }

  &__title {
    font-size: em(25);
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__text {}
}