.favorite {

  &__btn {
    display: flex;
    align-items: center;
    font-size: 11px;

    &:hover {
      .favorite__btn-text {
        text-decoration: none;
      }
    }

    .icon {
      font-size: 15px;
      margin-right: 8px;
    }

    &-text {
      text-decoration: underline;
    }
  }
}