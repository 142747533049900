.tabs {
  margin-bottom: 20px;

  &__controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__btn {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 35px;
    border-radius: 6px;
    letter-spacing: -0.03em;
    font-size: 18px;
    margin: 0 3px;

    &:hover {
      background: rgba(#FFD428,.5);
    }

    &.active {
      background: #FFD428;
      pointer-events: none;
    }
  }
}