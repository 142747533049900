.calculator {
  padding-left: 40px;
  padding-right: 40px;

  @include media($lg) {
    padding-left: 0;
    padding-right: 0;
  }

  &__list {
    margin-top: 65px;
  }

  &-box {
    position: relative;
    min-height: 123px;
    padding-left: 230px;
    margin-bottom: 76px;

    @include media($lg) {
      padding-left: 200px;
      margin-bottom: 50px;
    }

    @include media($min) {
      padding-left: 0;
      margin-bottom: 30px;
      min-height: 0;
    }

    @supports (flex-direction: column) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__img {
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);

      @include media($min) {
        display: none;
      }
    }

    &__title {
      max-width: 300px;
      font-weight: bold;
      margin-bottom: 26px;
      text-align: center;

      @include media($min) {
        max-width: 100%;
        margin-bottom: 18px;
      }

      & + .calculator-box__img {
        margin-top: 23px;
      }
    }

    &__list {

      li {
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        text-decoration: underline;

        &:hover {
          color: #0A4798;
        }
      }
    }
  }
}